import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../App.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rangeslider/lib/index.css";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { FaTwitter } from "react-icons/fa";
import { AnyMessageParams } from "yup/lib/types";
import { RiArrowDownSLine } from "react-icons/ri";
import moment from "moment";
import NumberFormat from "react-number-format";
interface OwnProps {
  stockId: any;
  incomestatementget: any;
  reportType: any;
  profileget: any;
}

interface StateProps {}

interface DispatchProps {}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface IncomestatementProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const Incomestatement: React.FC<IncomestatementProps> = ({
  stockId,
  incomestatementget,
  reportType,
  profileget,
}) => {
  const numberFormatMillon = (num: any) => {
    return +(num / 1000000).toFixed(2) + "";
  };
  useEffect(() => {
    console.log(reportType, "reportType");
  }, [reportType]);

  useEffect(() => {
    console.log(profileget.sector, "e");
  }, [profileget]);

  return (
    <>
      <div className="outer outer_x">
        <div className="inner">
          <table className="table grad_table table_harizontal">
            <tbody className="scroll market-scroll pr-0 pb-1">
              <tr className="clr">
                <th>Year</th>
                {incomestatementget.map((flow: any) => {
                  return (
                    <td>
                      {reportType == "annual"
                        ? "FY " + flow.calendarYear
                        : flow.period + " " + flow.calendarYear}
                    </td>
                  );
                })}
              </tr>
              {profileget.sector != "Financial Services" && (
                <>
                  <tr className="clr">
                    <th>Revenue</th>
                    {incomestatementget.map((dep: any) => {
                      return (
                        <td>
                          <NumberFormat
                            value={parseFloat(
                              numberFormatMillon(dep.revenue)
                            ).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />{" "}
                        </td>
                      );
                    })}
                  </tr>
                  <tr className="clr">
                    <th>Cost of Revenue</th>
                    {incomestatementget.map((dep: any) => {
                      return (
                        <td>
                          <NumberFormat
                            value={parseFloat(
                              numberFormatMillon(dep.costOfRevenue)
                            ).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />{" "}
                        </td>
                      );
                    })}
                  </tr>
                  <tr className="clr">
                    <th>Gross Profit</th>
                    {incomestatementget.map((dep: any) => {
                      return (
                        <td>
                          <NumberFormat
                            value={parseFloat(
                              numberFormatMillon(dep.grossProfit)
                            ).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />{" "}
                        </td>
                      );
                    })}
                  </tr>
                  <tr className="clr">
                    <th>Operating Expenses</th>
                    {incomestatementget.map((dep: any) => {
                      return (
                        <td>
                          <NumberFormat
                            value={parseFloat(
                              numberFormatMillon(dep.operatingExpenses)
                            ).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                      );
                    })}
                  </tr>
                  <tr className="clr">
                    <th>Operating Income</th>
                    {incomestatementget.map((dep: any) => {
                      return (
                        <td>
                          <NumberFormat
                            value={parseFloat(
                              numberFormatMillon(dep.operatingIncome)
                            ).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                      );
                    })}
                  </tr>
                  <tr className="clr">
                    <th>Earnings Before Tax</th>
                    {incomestatementget.map((dep: any) => {
                      return (
                        <td>
                          <NumberFormat
                            value={parseFloat(
                              numberFormatMillon(dep.incomeBeforeTax)
                            ).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                      );
                    })}
                  </tr>
                  <tr className="clr">
                    <th>Income Tax Expense</th>
                    {incomestatementget.map((dep: any) => {
                      return (
                        <td>
                          <NumberFormat
                            value={parseFloat(
                              numberFormatMillon(dep.incomeTaxExpense)
                            ).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                      );
                    })}
                  </tr>
                </>
              )}
              <tr className="clr">
                <th>Net Income</th>
                {incomestatementget.map((dep: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(dep.netIncome)
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>
              <tr className="clr">
                <th>Earnings Per Share Basic</th>
                {incomestatementget.map((dep: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={dep.eps ? dep.eps.toFixed(2) : ""}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>
              <tr className="clr">
                <th>Earnings Per Share Diluted</th>
                {incomestatementget.map((dep: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={dep.epsdiluted ? dep.epsdiluted.toFixed(2) : ""}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>
              <tr className="clr">
                <th>Weighted Average Shares Outstanding</th>
                {incomestatementget.map((dep: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(dep.weightedAverageShsOut)
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>
              
              <tr className="clr">
                <th>Weighted Average Shares Outstanding (Diluted)</th>
                {incomestatementget.map((dep: any) => {
                  return (
                    <td>
                     
                      <NumberFormat
                        value={parseFloat(
                            numberFormatMillon(dep.weightedAverageShsOutDil)
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: Incomestatement,
});
