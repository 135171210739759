import React, { useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from '../data/connect';

interface OwnProps {
  setIsLoggedIn: Function;
  setUsername: Function;
  logoutUser: Function
}

interface StateProps{}
interface DispatchProps{}

interface RedirectToLoginProps extends OwnProps, RouteComponentProps{};
const RedirectToLogin: React.FC<RedirectToLoginProps> = ({ history, setIsLoggedIn, setUsername, logoutUser }) => {
 
  
  useEffect(() => {
    setIsLoggedIn(false);
    setUsername(undefined);
    logoutUser();
    window.location.href = '/';
  }, [setIsLoggedIn, setUsername]);
  return null;
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: RedirectToLogin  
});
