import React, { useState, useEffect } from "react";

import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";

import { BiSearch } from "react-icons/bi";
import { RiCloseFill } from "react-icons/ri";
import { BiFilterAlt, BiBookmark } from "react-icons/bi";

import "../../App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import moment from "moment";
import Accordion from "react-bootstrap/Accordion";

import Form from "react-bootstrap/Form";

import Card from "react-bootstrap/Card";
import { Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom";
import Header from "../../header";
import Footer from "../../footer";
import Marquee from "react-fast-marquee";
import StockProfile from "./StockProfile";
import {
  IoIosNotificationsOutline,
  IoIosHelpCircle,
  IoMdCheckmark,
  IoIosCloseCircleOutline,
  IoIosArchive,
  IoIosBackspace,
  IoMdAdd,
  IoMdExit,
  IoIosHome,
  IoIosSwap,
  IoIosAdd,
  IoIosShare,
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosCloudUpload,
  IoMdNotifications,
} from "react-icons/io";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTrue } from "typescript";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import ReactGA from "react-ga4";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { RouteComponentProps } from "react-router";
import { getStockList, getvaluationbyid } from "../../data/stock/stock.actions";
import StockList from "./StockList";
import MyValuation from "./Myvaluation";
import MyValuationpb from "./Myvaluationps";
import MyValuationeve from "./Myvaluationeve";
import EmptyStock from "./EmptyStock";

type Tparams = { id: any; ed: any };

interface OwnProps extends RouteComponentProps<Tparams> {
  id: any;
  found: any;
}

interface StateProps {
  stockList: any;
  viewvaluationbyid: any;
}

interface DispatchProps {
  getStockList: typeof getStockList;
  getvaluationbyid: typeof getvaluationbyid;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface StockProps extends OwnProps, StateProps, DispatchProps {}

const Market: React.FC<StockProps> = ({
  getStockList,
  getvaluationbyid,
  viewvaluationbyid,
  stockList,
  history,
  found,
  match,
}) => {
  const [viewBusiness, setViewBusiness] = useState(false);
  const [stockId, setStockId] = useState(0);
  const [valuation_type, SetValuation_type] = useState(0);
  const [valuation_text, SetValuation_text] = useState("");
  const [valueclose, Setvalueclose] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [invalue, setinvalue] = useState("");
  const [pitchvalue, setpitchvalue] = useState("");
  const [update, setupdate] = useState(0);
  const [displayType, setDisplayType] = useState("input");
  const [pitchedited, Setpitchedited] = useState([]);
  const [hideset, sethideset] = useState("1");
  const [sr, setsr] = useState(0);
  const [usd, setusd] = useState("0");

  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  useEffect(() => {
    if (match.params.id) {
      getvaluationbyid(match.params.id);
      setsr(1);
    }
    else{
      setViewBusiness(false);
    }
  }, [match.params.id]);

  useEffect(() => {
    if (match.params.id && viewvaluationbyid) {
      setStockId(viewvaluationbyid.stock_symbol);
      SetValuation_type(viewvaluationbyid.valuation_type_id);
      SetValuation_text(viewvaluationbyid.valuation_type_id);
      setViewBusiness(true);
    }
  }, [match.params.id, viewvaluationbyid]);

  //setValuation(type.id); SetValuation_type(type.id); SetValuation_text(type.valuation_name)

  return (
    <div className="login-back">
      <Header />
      <Container fluid className="">
        <Row>
          {viewBusiness ? (
            <MyValuation
              usd={usd}
              sr={sr}
              hideset={hideset}
              Setpitchedited={Setpitchedited}
              historys={match.params.id}
              displayType={displayType}
              update={update}
              found={found}
              pitchvalue={pitchvalue}
              setpitchvalue={setpitchvalue}
              stockId={stockId}
              invalue={invalue}
              setinvalue={setinvalue}
              showModal={showModal}
              setViewBusiness={setViewBusiness}
              setShowModal={setShowModal}
              valuation_type={valuation_type}
              valuation_text={valuation_text}
              viewvaluationbyid={viewvaluationbyid}
            />
          ) : (
            <Col xs={12} sm={12} xl={6} className="mt-3">
              {" "}
              <StockList
                showModal={showModal}
                setShowModal={setShowModal}
                setStockId={setStockId}
                stockId={stockId}
              />
            </Col>
          )}
          {/*
{(() => { 
      if( valuation_type == 2 ){
            return (
<>
              {(viewBusiness ? <MyValuationpb valuation_type = {valuation_type} valuation_text = {valuation_text} /> : <Col sm={6} className="mt-4"> <StockList setStockId ={setStockId} stockId ={stockId} /></Col>)}

              </>

              )
            }
            })()}


{(() => { 
      if( valuation_type == 3 ){
            return (
<>
     
{(viewBusiness ? <MyValuationeve valuation_type = {valuation_type} valuation_text = {valuation_text} /> : <Col sm={6} className="mt-4"> <StockList setStockId ={setStockId} stockId ={stockId} /></Col>)}



</>

)
}
})()}
 

 */}

          <Col xl={viewBusiness ? 5 : 6} className="mt-3 align-self-top">
            {stockId ? (
              <StockProfile
                setusd={setusd}
                pitchedited={pitchedited}
                viewvaluationbyid={viewvaluationbyid}
                historys={match.params.id}
                pitchvalue={pitchvalue}
                setpitchvalue={setpitchvalue}
                viewBusiness={viewBusiness}
                invalue={invalue}
                setinvalue={setinvalue}
                setViewBusiness={setViewBusiness}
                showModal={showModal}
                setShowModal={setShowModal}
                stockId={stockId}
                SetValuation_text={SetValuation_text}
                SetValuation_type={SetValuation_type}
                viewbusiness={setViewBusiness}
                history={history}
              />
            ) : (
              ""
            )}
            {!stockId ? <EmptyStock /> : ""}
          </Col>
        </Row>
        <Marquee>
          <span className="text-white regular-12">
            Please zoom out as you see fit for an optimal experience. This is a beta version. Thank you!
          </span>
        </Marquee>
      </Container>

      <Footer />
      {/* <Col md={12} xl={12} className="align-self-center"> */}
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isRegisterSuccess: state.user.isRegisterSuccess,
    stockList: state.stock.stockList,
    viewvaluationbyid: state.stock.viewvaluationbyid,
  }),
  mapDispatchToProps: { getStockList, getvaluationbyid },
  component: Market,
});
