import React, { useState, useEffect } from "react";

import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { TiCameraOutline } from "react-icons/ti";
import { HiOutlineMail } from "react-icons/hi";
import { FiLock } from "react-icons/fi";
import { FaRegEye, FaRegUser } from "react-icons/fa";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Header from "./header";
import Footer from "./footer";
import { RouteComponentProps } from "react-router";
import { Plugins, CameraResultType } from "@capacitor/core";
import * as Yup from "yup";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { connect } from "./data/connect";
import {
  updateimage,
  getprofile,
  updateprofile,
  getupdatePassword,
  getDelete,
} from "./data/user/user.actions";

interface OwnProps {}

interface StateProps {
  reloadprofileupdate: any;
  setupdatePass: any;
  getuserprofile: any;
  setprofdelete: any;
  setprofimage: any;
}

interface DispatchProps {
  updateprofile: typeof updateprofile;
  getupdatePassword: typeof getupdatePassword;
  updateimage: typeof updateimage;
  getprofile: typeof getprofile;
  getDelete: typeof getDelete;
}

interface MyaccountProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const Myaccount: React.FC<MyaccountProps> = ({
  updateprofile,
  getupdatePassword,
  setupdatePass,
  reloadprofileupdate,
  setprofdelete,
  setprofimage,
  getuserprofile,
  updateimage,
  getprofile,
  history,
  getDelete,
}) => {
  const { Camera } = Plugins;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [currentPassshow, setcurrentPassshow] = useState(false);
  const [confirmpasswordShown, setconfirmPasswordShown] = useState(false);
  const MySwal = withReactContent(Swal);
  const signupSchema = Yup.object().shape({
    current_password: Yup.string().required("Current Password is required"),
    new_password: Yup.string().required("New Password is required"),
    new_confirm_password: Yup.string().when("new_password", {
      is: (vals: any) => (vals && vals.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        "Both passwords need to be the same"
      ),
    }),
  });
  useEffect(() => {
    console.log(getuserprofile, "dfg");
    setName(getuserprofile.name);
    setEmail(getuserprofile.email);
  }, [getuserprofile]);

  const profileupdate = async () => {
    //e.preventDefault();
    await updateprofile(name);
  };

  const profiledeleted = async () => {
    await getDelete();
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const togglecurrentPasswordVisiblity = () => {
    setcurrentPassshow(currentPassshow ? false : true);
  };
  const toggleconfirmPasswordVisiblity = () => {
    setconfirmPasswordShown(confirmpasswordShown ? false : true);
  };
  useEffect(() => {
    if (setprofdelete) {
      history.push("/login");
    }
  }, [setprofdelete]);

  async function takePicture() {
    var imageUrl: any = "";
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
    }).then(function (imageURI) {
      imageUrl = imageURI.dataUrl;
      setImage(imageUrl);
      updateimage(imageUrl);
    });
  }

  const onClickAccept = () => {
    // Swal.fire(
    //   'Are You Sure!',
    //   'Do you Want To Delete Your Account!',
    // )
    MySwal.fire({
      title: "Are you sure?",
      text: "Do you want to delete your account?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result: any) => {
      if (result.value) {
        getDelete();
      }
    });
  };
  useEffect(() => {
    if (setprofimage) getprofile();
  }, [setprofimage]);

  useEffect(() => {
    if (reloadprofileupdate) {
      getprofile();
    }
  }, [reloadprofileupdate]);

  return (
    <div className="">
      <div className="login-back">
        <Header />
        <Container fluid className="pb-4 contact_101">
          <Row className="justify-content-center pt-sm-2 mb-sm-1">
            <Col xl={6} md={10} className="">
              <div className="dash-table-div p-sm-5 p-4">
                <Row className="justify-content-center">
                  <Col sm={12} className="">
                    <h2 className="pb-4 text-white my_acc">My Account</h2>
                    <Tabs
                      id="uncontrolled-tab-example"
                      className="profile_tabs"
                      transition={false}
                    >
                      <Tab eventKey="All" title="Account Info" className="">
                        <div className="">
                          <Row className="mt-4">
                            <Col sm={6} className="text-left align-self-center">
                              <div className="form-group mb-3">
                                <div className="input-relative">
                                  <FaRegUser className="same-icons text-white"></FaRegUser>
                                  <input
                                    name="username"
                                    placeholder="User Name"
                                    type="text"
                                    className="form-control account-input"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="input-relative">
                                  <HiOutlineMail className="same-icons text-white"></HiOutlineMail>
                                  <input
                                    name="username"
                                    placeholder="Email"
                                    type="text"
                                    readOnly={true}
                                    className="form-control account-input"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>

                            <Col
                              sm={6}
                              className="text-md-right text-center align-self-center"
                            >
                              <div className="change-img">
                                {!getuserprofile.profile && (
                                  <img
                                    className="img-fluid mt-1 relativec upload-img"
                                    // src="/assets/images/juju.png"
                                    src={
                                      image ? image : "/assets/images/juju.png "
                                    }
                                  />
                                )}

                                {getuserprofile.profile && (
                                  <img
                                    className="img-fluid mt-1 relativec upload-img"
                                    // src="/assets/images/juju.png"

                                    src={
                                      image
                                        ? image
                                        : `https://api.mystockpitch.com/storage/images/profile/${getuserprofile.profile}`
                                    }
                                  />
                                )}

                                {console.log(image, "imageimageimage")}
                                <p className="m-0 one-two text-center cam-icon">
                                  <TiCameraOutline
                                    className="green-text"
                                    onClick={takePicture}
                                  ></TiCameraOutline>
                                </p>
                                <p
                                  className="m-0 green-text ten text-center cam-text"
                                  onClick={takePicture}
                                >
                                  Change Photo
                                </p>
                              </div>
                            </Col>

                            <Col sm={6} className="align-self-center mt-3">
                              <button
                                className="btn-account"
                                onClick={profileupdate}
                              >
                                Save Changes
                              </button>
                            </Col>

                            <Col
                              sm={6}
                              className="align-self-center mt-3 text-right"
                            >
                              <Link to="/market">
                                <button className="btn_delete">
                                  Discard Changes
                                </button>
                              </Link>
                            </Col>

                            {/*<Col sm={6} className="align-self-center mt-3 text-right">
                  <Link to="/market">
                    <button className="btn_delete">Discard Changes</button>
                    </Link>
                    </Col>*/}
                          </Row>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Review"
                        title="Password Reset"
                        className=""
                      >
                        <Formik
                          initialValues={{
                            current_password: "",
                            new_password: "",
                            new_confirm_password: "",
                          }}
                          validationSchema={signupSchema}
                          onSubmit={(
                            {
                              current_password,
                              new_password,
                              new_confirm_password,
                            },
                            { setStatus, setSubmitting }
                          ) => {
                            console.log(setSubmitting, "setSubmitting");
                            getupdatePassword({
                              current_password,
                              new_password,
                              new_confirm_password,
                            });
                            console.log(
                              new_confirm_password,
                              "dddddddddddddddddddd"
                            );
                          }}
                          render={({
                            errors,
                            status,
                            touched,
                            isSubmitting,
                          }) => (
                            <Form>
                              <div className="">
                                <Row className="mt-4">
                                  <Col sm={12} className="text-left">
                                    <div className="form-group mb-3">
                                      <div className="input-relative">
                                        {errors.current_password &&
                                        touched.current_password ? (
                                          <div className="errorCont">
                                            {errors.current_password}
                                          </div>
                                        ) : null}
                                        <div>
                                          <FiLock className="same-icons color-97"></FiLock>
                                          {(() => {
                                            if (currentPassshow == false) {
                                              return (
                                                <AiOutlineEyeInvisible
                                                  className="same-icons-rit color-97"
                                                  onClick={
                                                    togglecurrentPasswordVisiblity
                                                  }
                                                ></AiOutlineEyeInvisible>
                                              );
                                            }
                                          })()}

                                          {(() => {
                                            if (currentPassshow == true) {
                                              return (
                                                <FaRegEye
                                                  className="same-icons-rit color-97"
                                                  onClick={
                                                    togglecurrentPasswordVisiblity
                                                  }
                                                ></FaRegEye>
                                              );
                                            }
                                          })()}
                                          <Field
                                            name="current_password"
                                            placeholder="Current Password"
                                            type={
                                              currentPassshow
                                                ? "text"
                                                : "password"
                                            }
                                            className="form-control account-input"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3">
                                      <div className="input-relative">
                                        {errors.new_password &&
                                        touched.new_password ? (
                                          <div className="errorCont">
                                            {errors.new_password}
                                          </div>
                                        ) : null}
                                        <div>
                                          <FiLock className="same-icons color-97"></FiLock>
                                          {(() => {
                                            if (passwordShown == false) {
                                              return (
                                                <AiOutlineEyeInvisible
                                                  className="same-icons-rit color-97"
                                                  onClick={
                                                    togglePasswordVisiblity
                                                  }
                                                ></AiOutlineEyeInvisible>
                                              );
                                            }
                                          })()}

                                          {(() => {
                                            if (passwordShown == true) {
                                              return (
                                                <FaRegEye
                                                  className="same-icons-rit color-97"
                                                  onClick={
                                                    togglePasswordVisiblity
                                                  }
                                                ></FaRegEye>
                                              );
                                            }
                                          })()}
                                          <Field
                                            name="new_password"
                                            placeholder="New Password"
                                            type={
                                              passwordShown
                                                ? "text"
                                                : "password"
                                            }
                                            className="form-control account-input"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="input-relative">
                                        {errors.new_confirm_password &&
                                        touched.new_confirm_password ? (
                                          <div className="errorCont">
                                            {errors.new_confirm_password}
                                          </div>
                                        ) : null}
                                        <div>
                                          <FiLock className="same-icons color-97"></FiLock>
                                          {(() => {
                                            if (confirmpasswordShown == false) {
                                              return (
                                                <AiOutlineEyeInvisible
                                                  className="same-icons-rit color-97"
                                                  onClick={
                                                    toggleconfirmPasswordVisiblity
                                                  }
                                                ></AiOutlineEyeInvisible>
                                              );
                                            }
                                          })()}

                                          {(() => {
                                            if (confirmpasswordShown == true) {
                                              return (
                                                <FaRegEye
                                                  className="same-icons-rit color-97"
                                                  onClick={
                                                    toggleconfirmPasswordVisiblity
                                                  }
                                                ></FaRegEye>
                                              );
                                            }
                                          })()}
                                          <Field
                                            name="new_confirm_password"
                                            placeholder="Confirm New Password"
                                            type={
                                              confirmpasswordShown
                                                ? "text"
                                                : "password"
                                            }
                                            className="form-control account-input"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Col>

                                  <Col
                                    sm={6}
                                    className="align-self-center mt-3"
                                  >
                                    <button
                                      className="btn-account"
                                      type="submit"
                                    >
                                      Reset Password
                                    </button>
                                  </Col>

                                  <Col
                                    sm={6}
                                    className="align-self-center mt-3"
                                  >
                                    <Link to="/market">
                                      <button className="btn_delete">
                                        Discard Changes
                                      </button>
                                    </Link>
                                  </Col>
                                </Row>
                              </div>
                            </Form>
                          )}
                        />
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    reloadprofileupdate: state.user.reloadprofileupdate,
    setupdatePass: state.user.setupdatePass,
    setprofdelete: state.user.setprofdelete,
    getuserprofile: state.user.getuserprofile,
    setprofimage: state.user.setprofimage,
  }),
  mapDispatchToProps: {
    getupdatePassword,
    updateprofile,
    updateimage,
    getprofile,
    getDelete,
  },
  component: Myaccount,
});
