import { ActionType } from '../../util/types';
import { WatchlistState } from './watchlist.state';
import { showToast } from '../global/global.actions';
import { WatchlistService } from './watchlist.service';
import { toast } from 'react-toastify';



export const allwatchlistget = () => async (dispatch: React.Dispatch<any>) => {

  await WatchlistService.watchlist()
          .then(
              getallwatchlist => { 
                if(getallwatchlist.data)
                dispatch(setGetallwatchlist(getallwatchlist.data.data));
              },
              error => {
              }
          );
}
export const removewatchlist = (stocksymbol:any ) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setShowLoading(true));
  await WatchlistService.removewatchlist( stocksymbol)
  .then(
    removelist => { 
      dispatch(setRemovelist(true));
      dispatch(setShowLoading(false));
      dispatch(toast.success(' Removed sucessfully'));
    },
    error => {
      dispatch(toast.error('Error Removing'));
      dispatch(setShowLoading(false));
    }
);
}
export const addwatchlist = (stocksymbol:any ) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setShowLoading(true));
  await WatchlistService.addwatchlist( stocksymbol)
  .then(
    addwatchlist => { 
      dispatch(setAddwatchlist(true));
      dispatch(setShowLoading(false));
      dispatch(toast.success(' Watchlist Added sucessfully'));
    },
    error => {
      dispatch(toast.error('Error Adding'));
      dispatch(setShowLoading(false));
    }
);
}



export const setShowLoading = (showLoading: boolean) => ({
  type: 'show-loading',
  showLoading
} as const );
export const setShowDrawer = (showDrawer: boolean) => ({
  type: 'show-Drawer',
  showDrawer
} as const);
export const setGetallwatchlist = (getallwatchlist: any) => ({
  type: 'all-watchlist',
  getallwatchlist
} as const);
export const setRemovelist = (removelist: boolean) => ({
  type: 'all-removelist',
  removelist
} as const);
export const setAddwatchlist = (addwatchlist: boolean) => ({
  type: 'all-addwatchlist',
  addwatchlist
} as const);




export type WatchlistActions =
  | ActionType<typeof setShowLoading>
  | ActionType<typeof setShowDrawer>
  | ActionType<typeof setGetallwatchlist>
  | ActionType<typeof setRemovelist>
  | ActionType<typeof setAddwatchlist>
  