import { config } from '../_config/config';
import { authHeader, handleResponse } from '../_helpers';

var controller = new AbortController();
var signal:any='';


export const MarketService = {
    allschedule,deviceon,inserthome,gethomes,gethomename,insertroom,getroomlist,edithome,getsingleroomname,ignorevaluation,mrmarketsearch,marketprofile,
    marketratio,getliveprice,getcashflow,getbalancestatement,getincomestatement,getinstitutionalowner
};
function allschedule() {
    const requestOptions = {
        method  : 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}Users/profile_business`, requestOptions).then(handleResponse);
}
function deviceon() {
    const requestOptions = {
        method  : 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}Users/profile_business`, requestOptions).then(handleResponse);
}


function inserthome( home_name:any, ) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ home_name:home_name, })
    };

    return fetch(`${config.apiUrl}Home/inserthome`, requestOptions).then(handleResponse);
}

function gethomes() {
    const requestOptions = {
        method  : 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}Home/gethome`, requestOptions).then(handleResponse);
}

function gethomename(id:any) {
    const requestOptions = {
        method  : 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}Home/singlehome?id=${id}`, requestOptions).then(handleResponse);
}

function insertroom( room_name:any,home_id:any ) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ room_name:room_name,home_id:home_id })
    };
    return fetch(`${config.apiUrl}Rooms/insertrooms`, requestOptions).then(handleResponse);
}

function getroomlist(id:any) {
    const requestOptions = {
        method  : 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}Rooms/getrooms?home_id=${id}`, requestOptions).then(handleResponse);
}

function edithome( home_id:any,home_name:any, ) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ home_id:home_id,home_name:home_name, })
    };
    return fetch(`${config.apiUrl}Home/home_update`, requestOptions).then(handleResponse);
}

function getsingleroomname (id:any) {
    const requestOptions = {
        method  : 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}Rooms/single_room?room_id=${id}`, requestOptions).then(handleResponse);
}

function ignorevaluation(valuation_id:any ) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(valuation_id)
    };
    return fetch(`${config.apiUrl}api/ignoreValuation`, requestOptions).then(handleResponse);
}

function mrmarketsearch(input:any ) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions:RequestInit = {
        method  : 'POST',
        headers : myHeaders,
        redirect: 'follow',
        body: JSON.stringify({ url: `api/v3/search?query=${input}&limit=50&exchange=AMEX,NASDAQ,NYSE&apikey=`, method:"GET", parameters:{} })
    };     
    return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function marketprofile(inputprofile:any ) {
    
    if(signal){
        signal='';
        controller.abort();
    }else{
        signal = controller.signal; 
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions:RequestInit = {
        method  : 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify({ url: `api/v3/profile/${inputprofile}?apikey=`, method:"GET", parameters:{} })
    };
    return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function marketratio(inputprofile:any ) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions:RequestInit = {
        method  : 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify({ url: `api/v3/ratios-ttm/${inputprofile}?apikey=`, method:"GET", parameters:{} })
    };
    return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function getliveprice( ) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions:RequestInit = {
        method  : 'POST',
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({ url: `api/v3/quote/%5EGSPC,%5EIXIC,%5EDJI,%5ENYA?apikey=`, method:"GET", parameters:{} })
    };
    return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function getcashflow(stocksymbol:any,limit:any,period:any){ 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method  : 'POST',
        headers: myHeaders,
        body: JSON.stringify({ url: `api/v3/cash-flow-statement/${stocksymbol}?period=${period}&limit=${limit}&apikey=`, method:"GET", parameters:{} })
    };
    return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function getbalancestatement(stocksymbol:any,periodbalance:any,limit:any){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method  : 'POST',
        headers : myHeaders,
        body: JSON.stringify({ url: `api/v3/balance-sheet-statement/${stocksymbol}?period=${periodbalance}&limit=${limit}&apikey=`, method:"GET", parameters:{} })
    };
    return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function getincomestatement(stocksymbol:any,periodincome:any,limit:any){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method  : 'POST',
        headers : myHeaders,
        body: JSON.stringify({ url: `api/v3/income-statement/${stocksymbol}?period=${periodincome}&limit=${limit}&apikey=`, method:"GET", parameters:{} })
    };
    return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function getinstitutionalowner(stocksymbol:any){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method  : 'POST',
        headers : myHeaders,
        body: JSON.stringify({ url: `api/v3/institutional-holder/${stocksymbol}?apikey=`, method:"GET", parameters:{} })
    };
    return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}

{/*
function marketprofile(inputprofile:any ) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            "endpoint": `https://financialmodelingprep.com/api/v3/profile/${inputprofile}`,
            "params": {},
            "action": "GET"
          })
    };
    return fetch(`${config.apiUrl}api/serverconnect`, requestOptions).then(handleResponse);
}
*/}
