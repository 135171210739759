import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";
import { BiFilterAlt, BiBookmark, BiLinkExternal } from "react-icons/bi";
import "../../App.css";
import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../header";
import Footer from "../../footer";
import AddPortfolio from "./AddPortfolio";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { RouteComponentProps } from "react-router";
import { getPortfolioList } from "../../data/portfolio/portfolio.actions";

interface OwnProps {}

interface StateProps {
  isRegisterSuccess: any;
  portfolioList: any;
}

interface DispatchProps {
  getPortfolioList: typeof getPortfolioList;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface PortfolioProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const EmptyPortfolio: React.FC<PortfolioProps> = ({
  isRegisterSuccess,
  getPortfolioList,
  portfolioList,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [portfolioId, setPortfolioId] = useState(0);
  useEffect(() => {
    getPortfolioList();
  }, []);

  return (
    <Container fluid className="pb-5">
      <Row className="row justify-content-center mt-2 pt-3">
        <Col sm={12} className="align-self-center">
          <Row className="mt-4">
            <Col sm={8} className="">
              <a className="btn-folio"> My Portfolio</a>
            </Col>
            <Col sm={4} xs={12} className="text-right">
              <a href="" className="btn-dwn">
                <BiLinkExternal></BiLinkExternal> Export & Download
              </a>
            </Col>
          </Row>
          <Row className="row justify-content-center mt-2 pt-3">
            <Col sm={12} className="align-self-center text-center pb-5">
              <div className="dash-table-div pl-3 pr-3">
                <img
                  className="img-fluid mt-1"
                  src="/assets/images/Group73.png"
                />
                <p className="regular-15 m-0 color-wh-8">
                  You have not created your portfolio yet.
                </p>
                <p className="regular-15 color-wh-8">
                  Mr. Market is waiting for you to add stocks to your portfolio.
                </p>
                <a
                  className="btn-folio-create mb-5"
                  onClick={() => setShowModal(true)}
                >
                  Create Portfolio
                </a>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        show={showModal}
        cssClass="modalhome"
        onHide={() => setShowModal(false)}
      >
        <div className="modalselect">
          <AddPortfolio portfolioId={portfolioId} setShowModal={setShowModal} />
        </div>
      </Modal>
    </Container>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isRegisterSuccess: state.user.isRegisterSuccess,
    portfolioList: state.portfolio.portfolioList,
  }),
  mapDispatchToProps: { getPortfolioList },
  component: EmptyPortfolio,
});
