import React, { useEffect, useState } from "react";
import { connect } from "./data/connect";
interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

interface GeneralProps extends OwnProps, StateProps, DispatchProps {}

const General: React.FC<GeneralProps> = ({}) => {
  return (
    <table className="table dash-table scrolltable grad_table">
      <thead>
        <tr className="clr">
          <th>No</th>
          <th>Ticker</th>
          <th>Company</th>
          <th>Sector</th>
          <th>Industry</th>
          <th>Maarket Cap</th>
          <th>P/E</th>
          <th>Price</th>
          <th>Change</th>
          <th>Volume</th>
        </tr>
      </thead>
      <tbody className="scroll market-scroll pr-0">
        <tr className="clr">
          <td>1</td>
          <td>AAPL</td>
          <td>APPLE INCH</td>
          <td>vcc</td>
          <td>AAPL</td>
          <td> INCH APPLE</td>
          <td>AAPL</td>
          <td>APPNCH</td>
          <td>AAPL</td>
          <td>APPLE INCH</td>
        </tr>
        <tr className="clr">
          <td>1</td>
          <td>AAPL</td>
          <td>APPLE INCH</td>
          <td>vcc</td>
          <td>AAPL</td>
          <td> INCH APPLE</td>
          <td>AAPL</td>
          <td>APPNCH</td>
          <td>AAPL</td>
          <td>APPLE INCH</td>
        </tr>
      </tbody>
    </table>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: General,
});
