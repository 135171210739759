import { PortfolioActions } from "./portfolio.actions";
import { PortfolioState } from "./portfolio.state";

export function PortfolioReducer(
  state: PortfolioState,
  action: PortfolioActions
): PortfolioState {
  switch (action.type) {
    case "portfolio-list":
      return { ...state, portfolioList: action.portfolioList };
    case "portfolio-create":
      return { ...state, portfolioCreate: action.portfolioSuccess };
    case "portfolio-portfolioreload":
      return { ...state, delreload: action.portfolioreload };
    case "portfolio-portfolioeditreload":
      return { ...state, editreload: action.portfolioeditreload };
    case "portfolio-searchportfolio":
      return { ...state, portfoliosearch: action.searchportfolio };
    case "portfolio-portfolioupdate":
      return { ...state, updateportfolio: action.portfolioupdate };
    case "portfolio-portfoliovalue":
      return { ...state, getportfoliovalue: action.portfoliovalue };
    case "portfolio-stockperid":
      return { ...state, portfolioStocks: action.stockperid };
      
    case "set-stock-historical":
      return { ...state, stockhistorical: action.historical };

    case "set-key-metrics":
      return { ...state, keymetrics: action.metrics };
    case "set-quotereturn":
      return { ...state, getquotereturn: action.quotereturn };
    case "set-quotereturnpitch":
      return { ...state, getquotereturnpitch: action.quotereturnpitch };
    case "set-screenerset":
      return { ...state, screenerget: action.screenerinput };
    case "set-screenerlistget":
      return { ...state, screenerlistview: action.screenerlistget };
    case "set-addfbmarket":
      return { ...state, fbmarketget: action.fbmarket };
    case "set-ttmvalue":
      return { ...state, ttmvalue: action.ttmValue };
    case "set-keyttmvalue":
      return { ...state, keyttmvalue: action.metricsttmValue };
    case "set-keyexecutives":
      return { ...state, keyexecutives: action.executives };
    case "set-institutionalholder":
      return { ...state, institutionalholder: action.institutional };
    case "set-fin":
      return { ...state, getfin: action.fin };
    case "set-his":
      return { ...state, gethis: action.his };
    case "set-rs":
      return { ...state, getrs: action.rs };
    case "set-rsquar":
      return { ...state, getrsquar: action.rsquar };
    case "set-evincome":
      return { ...state, getevincomes: action.evincome };
    case "set-balancesheetev":
      return { ...state, getbalancesheetev: action.balancesheetev };
    case "set-shareoustanding":
      return { ...state, getshareoustanding: action.shareoustanding };
    case "set-change":
      return { ...state, setchange: action.change };
    case "set-valuationhide":
      return { ...state, hidevaluation: action.valuationhide };
    case "set-pitch":
      return { ...state, reloadpitch: action.pitch };
    case "set-pitchupdate":
      return { ...state, reloadpitchupdate: action.pitchupdate };
    case "set-track":
      return { ...state, gettrack: action.track };
    case "set-trackview":
      return { ...state, gettrackview: action.trackview };
    case "stock-profile":
      return { ...state, getstockProfile: action.stockProfile };
    case "set-news":
      return { ...state, viewnews: action.news };
    case "portfolio-afterpitch":
      return { ...state, afterpitchreload: action.afterpitch };
  }
}
