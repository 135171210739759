import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import Slider from "react-rangeslider";

import { BiSearch } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";

import "../../App.css";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rangeslider/lib/index.css";
import ReactGA from "react-ga4";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { getStockList, addvaluation } from "../../data/stock/stock.actions";
import moment from "moment";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import MyValuationpe from "./Myvaluationpe";
import MyValuationpb from "./Myvaluationpb";
import MyValuationeve from "./Myvaluationeve";
import Myvaluationps from "./Myvaluationps";

interface OwnProps {
  valuation_type: any;
  valuation_text: any;
  update: any;
  stockId: any;
  setViewBusiness: any;
  showModal: any;
  setShowModal: any;
  found: any;
  invalue: any;
  setinvalue: any;
  pitchvalue: any;
  setpitchvalue: any;
  displayType: any;
  historys: any;
  Setpitchedited: any;
  hideset: any;
  viewvaluationbyid: any;
  sr: any;
  usd: any;
}

interface StateProps {
  stockList: any;
  valuationDetail: any;
}

interface DispatchProps {
  getStockList: typeof getStockList;
  addvaluation: typeof addvaluation;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);

  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface MyValuationProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const Myvaluation: React.FC<MyValuationProps> = ({
  historys,
  hideset,
  sr,
  Setpitchedited,
  pitchvalue,
  displayType,
  setpitchvalue,
  found,
  update,
  valuationDetail,
  showModal,
  setViewBusiness,
  invalue,
  setinvalue,
  setShowModal,
  valuation_type,
  viewvaluationbyid,
  valuation_text,
  stockId,
  usd,
}) => {
  const [searchStock, setSearchStock] = useState({
    marketcap: "mid",
    sector: "capital_goods",
    region: "north_america",
  });
  const [size, setsize] = useState("7");

  {
    /* useEffect(() => {
    getStockList(searchStock);
  },[]);
*/
  }
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  useEffect(() => {
    console.log(valuationDetail, "valuation_output");
    console.log(valuation_type, "fdghjkliiiiiiiiiiiiiiiiiii");
  }, [valuationDetail]);

  const valuationSchema = Yup.object().shape({
    earnings_per_share: Yup.string().required("Earnings Name is required"),
    growth_ratein_earnings_per_share: Yup.string().required(
      "Growth Rate is required"
    ),
    forward_multiple: Yup.string().required("Forward Multiple is required"),
    purchase_price: Yup.string().required("Purchase Price is required"),
    holding_period: Yup.string().required("Holding Period is required"),
  });
  return (
    <>
      {(() => {
        if (valuation_type == 1) {
          return (
            <>
              <MyValuationpe
                usd={usd}
                sr={sr}
                hideset={hideset}
                Setpitchedited={Setpitchedited}
                viewvaluationbyid={viewvaluationbyid}
                historys={historys}
                displayType={displayType}
                update={update}
                size={size}
                found={found}
                pitchvalue={pitchvalue}
                setpitchvalue={setpitchvalue}
                valuation_type={valuation_type}
                invalue={invalue}
                setinvalue={setinvalue}
                showModal={showModal}
                setShowModal={setShowModal}
                setViewBusiness={setViewBusiness}
                valuation_text={valuation_text}
                stockId={stockId}
              />
            </>
          );
        }
      })()}

      {(() => {
        if (valuation_type == 2) {
          return (
            <>
              <MyValuationpb
                usd={usd}
                sr={sr}
                viewvaluationbyid={viewvaluationbyid}
                hideset={hideset}
                Setpitchedited={Setpitchedited}
                historys={historys}
                displayType={displayType}
                update={update}
                found={found}
                size={size}
                pitchvalue={pitchvalue}
                setpitchvalue={setpitchvalue}
                valuation_type={valuation_type}
                invalue={invalue}
                setinvalue={setinvalue}
                showModal={showModal}
                setShowModal={setShowModal}
                setViewBusiness={setViewBusiness}
                valuation_text={valuation_text}
                stockId={stockId}
              />
            </>
          );
        }
      })()}

      {(() => {
        if (valuation_type == 4) {
          return (
            <>
              <MyValuationeve
                usd={usd}
                sr={sr}
                viewvaluationbyid={viewvaluationbyid}
                hideset={hideset}
                Setpitchedited={Setpitchedited}
                historys={historys}
                displayType={displayType}
                update={update}
                size={size}
                pitchvalue={pitchvalue}
                setpitchvalue={setpitchvalue}
                found={found}
                valuation_type={valuation_type}
                invalue={invalue}
                setinvalue={setinvalue}
                showModal={showModal}
                setShowModal={setShowModal}
                setViewBusiness={setViewBusiness}
                valuation_text={valuation_text}
                stockId={stockId}
              />
            </>
          );
        }
      })()}
      {(() => {
        if (valuation_type == 3) {
          return (
            <>
              <Myvaluationps
                usd={usd}
                sr={sr}
                viewvaluationbyid={viewvaluationbyid}
                hideset={hideset}
                Setpitchedited={Setpitchedited}
                historys={historys}
                displayType={displayType}
                update={update}
                size={size}
                pitchvalue={pitchvalue}
                found={found}
                setpitchvalue={setpitchvalue}
                valuation_type={valuation_type}
                invalue={invalue}
                setinvalue={setinvalue}
                showModal={showModal}
                setShowModal={setShowModal}
                setViewBusiness={setViewBusiness}
                valuation_text={valuation_text}
                stockId={stockId}
              />
            </>
          );
        }
      })()}
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isRegisterSuccess: state.user.isRegisterSuccess,
    stockList: state.stock.stockList,
    valuationDetail: state.stock.valuationDetail,
  }),
  mapDispatchToProps: { getStockList, addvaluation },
  component: Myvaluation,
});
