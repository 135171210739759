import React, { useState, useEffect } from "react";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import "../../App.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rangeslider/lib/index.css";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import ReactGA from "react-ga4";
import { FaTwitter } from "react-icons/fa";
import {
  getmetirials,
  getquotes,
  gettm,
  getkeymetricsttmvalue,
  getkeyexecutives,
  getinstitutionalholder,
} from "../../data/portfolio/portfolio.actions";
import { FaInfoCircle, FaMixer, FaTimes } from "react-icons/fa";
import { AnyMessageParams } from "yup/lib/types";
import { RiArrowDownSLine } from "react-icons/ri";
import moment from "moment";
import {
  getcashflow,
  getincomestatement,
  getinstitutionalowner,
  marketratio,
} from "../../data/market/market.actions";

interface OwnProps {
  stockId: any;
  profileget: any;
}

interface StateProps {
  keymetrics: any;
  getquotereturn: any;
  ttmvalue: any;
  keyttmvalue: any;
  keyexecutives: any;
  institutionalholder: any;
  instituteowner: any;
  incomestatementget: any;
  showcontrolstate: any;
  getmarketratio: any;
}

interface DispatchProps {
  getmetirials: typeof getmetirials;
  getquotes: typeof getquotes;
  gettm: typeof gettm;
  getkeymetricsttmvalue: typeof getkeymetricsttmvalue;
  getkeyexecutives: typeof getkeyexecutives;
  getinstitutionalholder: typeof getinstitutionalholder;
  getinstitutionalowner: typeof getinstitutionalowner;
  getincomestatement: typeof getincomestatement;
  marketratio: typeof marketratio;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface InvesmentDropDownProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const InvesmentDropDown: React.FC<InvesmentDropDownProps> = ({
  stockId,
  getmetirials,
  getquotereturn,
  getquotes,
  keymetrics,
  gettm,
  showcontrolstate,
  ttmvalue,
  keyttmvalue,
  profileget,
  getkeymetricsttmvalue,
  keyexecutives,
  getkeyexecutives,
  getinstitutionalholder,
  institutionalholder,
  instituteowner,
  getinstitutionalowner,
  getincomestatement,
  incomestatementget,
  marketratio,
  getmarketratio,
}) => {
  const [showmodel, setshowmodel] = useState("");
  const [reportType, setReportType] = useState("");
  const [getpoint, setgetpoint] = useState("");
  const [topfive, settopfive] = useState<any>([]);
  const [cashflowfive, settcashflowfive] = useState<any>([]);
  const [firstvalueget, setfirstvalueget] = useState<any>("");
  const [topdate, settopdate] = useState<any>([]);
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  const sharesOutstanding = (value: any) => {
    let formattedVal = value.toFixed(2);
    return formattedVal == "0.00" ? value.toFixed(6) : formattedVal;
  };

  //const res = instituteowner.sort((a:any, b:any) => b.instituteowner.shares - a.instituteowner.shares).slice(0, 5);
  const numberFormatBillon = (num: any) => {
    return "$" + (num / 1000000000).toFixed(2) + "B";
  };
  const numberFormatBillons = (num: any) => {
    return (num / 1000000000).toFixed(2) + "B";
  };

  useEffect(() => {
    
    if (instituteowner != "") {
      let valuesdatefirst = instituteowner[0];

      setfirstvalueget(valuesdatefirst.dateReported);
      instituteowner.sort((a: any, b: any) => (a.shares < b.shares ? 1 : -1));

      settopfive(instituteowner.slice(0, 5));
      //settopdate(instituteowner.sort((a:any, b:any) => b.dateReported - a.dateReported).slice(0, 5))
    }
  }, [instituteowner]);

  const usdPopover = (
    <Popover id="popover-basic" className="insp">
      <table className="table small_table  ">
        <tbody>
          <p className="sugg pl-1">{showcontrolstate}</p>
        </tbody>
      </table>
    </Popover>
  );
  useEffect(() => {
    if (incomestatementget != "") {
      let recentRevenue = incomestatementget.slice(0, 4);
      let recentRevenues = 0;
      recentRevenue.forEach((recentRev: any) => {
        recentRevenues += recentRev.revenue;
      });
      settcashflowfive(recentRevenues);
    }
  }, [incomestatementget]);

  useEffect(() => {
    getmetirials(stockId, getpoint, reportType);
  }, [stockId, getpoint, reportType]);

  useEffect(() => {
    getquotes(stockId);
    getinstitutionalowner(stockId);
    gettm(stockId);
    getkeymetricsttmvalue(stockId);
    getkeyexecutives(stockId);
    getinstitutionalholder(stockId);
    getincomestatement(stockId, "quarter", 11);
    marketratio(stockId);
  }, [stockId]);

  return (
    <>
      <Row>
        <Col sm={6}>
          <div className="bg_share">
            <h5>
              <span className="bold_12">Share Information</span>{" "}
              <span className="regular_11">
                (@{moment().format("YYYY-MM-DD")}):
              </span>
            </h5>
            <p className="mb-0">
              <span className="regular_12">Price:</span>{" "}
              <span className="bold_12_76">
                ${parseFloat(getquotereturn.price).toFixed(2)}
              </span>
            </p>

            <p className="mb-0">
              <span className="regular_12">52-Week Range:</span>{" "}
              <span className="bold_12_76">
                $
                {getquotereturn.yearLow
                  ? getquotereturn.yearLow.toFixed(2)
                  : ""}{" "}
                - $
                {getquotereturn.yearHigh
                  ? getquotereturn.yearHigh.toFixed(2)
                  : ""}
              </span>
            </p>
            <p className="mb-0">
              <span className="regular_12">Dividend Yield:</span>{" "}
              <span className="bold_12_76">
                {keyttmvalue.dividendYieldPercentageTTM
                  ? `${keyttmvalue.dividendYieldPercentageTTM.toFixed(2)}%`
                  : "-"}
              </span>
            </p>

            <p className="mb-0">
              <span className="regular_12">Market Cap:</span>{" "}
              <span className="bold_12_76">
                {numberFormatBillon(getquotereturn.marketCap)}
              </span>
            </p>
          </div>
        </Col>
        <Col sm={6} className="pl-xl-0">
          <div className="bg_share">
            <h5>
              <span className="bold_12">Valuation Metrics</span>{" "}
              <span className="regular_11">
                (@{moment().format("YYYY-MM-DD")}):
              </span>
            </h5>
            <p className="mb-0">
              <span className="regular_12 opacity_0"></span>{" "}
              <span className="regular_12">{profileget.companyName}</span>{" "}
              <span className="regular_12 pl-2"></span>
            </p>
            <p className="mb-0">
              <span className="regular_12">P/E (TTM)</span>{" "}
              <span className="bold_12_76">
                {keyttmvalue.peRatioTTM
                  ? keyttmvalue.peRatioTTM.toFixed(2)
                  : ""}{" "}
                X
              </span>
            </p>
            <p className="mb-0">
              <span className="regular_12">P/B (TTM)</span>{" "}
              <span className="bold_12_76">
                {keyttmvalue.pbRatioTTM
                  ? keyttmvalue.pbRatioTTM.toFixed(2)
                  : ""}{" "}
                X
              </span>
            </p>
            <p className="mb-0">
              <span className="regular_12">P/S (TTM)</span>{" "}
              <span className="bold_12_76">
                {keyttmvalue.priceToSalesRatioTTM
                  ? keyttmvalue.priceToSalesRatioTTM.toFixed(2)
                  : ""}{" "}
                X
              </span>
            </p>
            <p className="mb-0">
              <span className="regular_12">EV/EBITDA (TTM)</span>{" "}
              <span className="bold_12_76">
                {getmarketratio.enterpriseValueMultipleTTM
                  ? getmarketratio.enterpriseValueMultipleTTM.toFixed(2)
                  : ""}{" "}
                X
              </span>
            </p>
          </div>
        </Col>
        <Col sm={6}>
          <div className="bg_share mt-2">
            <h5>
              <span className="bold_12">Financials (TTM):</span>
            </h5>
            <p className="mb-0">
              <span className="regular_12">Revenue:</span>{" "}
              <span className="bold_12_76">
                {showcontrolstate} {numberFormatBillons(cashflowfive)}
              </span>
              <span></span>
            </p>

            <p className="mb-0">
              <span className="regular_12">Operating Profit Margin:</span>{" "}
              <span className="bold_12_76">
                {(getmarketratio.operatingProfitMarginTTM * 100).toFixed(2)}%
              </span>
            </p>
            <p className="mb-0">
              <span className="regular_12">Net Profit Margin:</span>{" "}
              <span className="bold_12_76">
                {(getmarketratio.netProfitMarginTTM * 100).toFixed(2)}%
              </span>
            </p>
          </div>
        </Col>
        <Col sm={6} className="pl-xl-0 mt-4">
          <div className="bg_share top_15">
            <h5 className="mb-0">
              <span className="bold_12">Largest Institutional Owners</span>{" "}
            </h5>
            <p className="m-0">
              <span className="regular_11">
                (@{moment(firstvalueget).format("YYYY-MM-DD")}):
              </span>
            </p>
            <Row className="mt-2">
              <Col sm={8} className="">
                <p className="mb-0 regular_12">Company</p>
              </Col>
              <Col sm={4} className="">
                <p className="mb-0 regular_12">% Owned</p>
              </Col>
            </Row>
            {topfive.map((dep: any) => {
              console.log(dep.holder, "aaaaaaaaaaaaaa");
              console.log(
                dep.shares / getquotereturn.sharesOutstanding,
                "bbbbbbbbbbbbbb"
              );
              return (
                <Row>
                  <Col sm={8} className="">
                    <p className="regular_12 m-0">{dep.holder}</p>
                  </Col>
                  <Col sm={4} className="align-self-center">
                    <p className="m-0 bold_12_76">
                      {sharesOutstanding(
                        (dep.shares / getquotereturn.sharesOutstanding) * 100
                      )}
                      %
                    </p>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Col>
      </Row>
    </>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    keymetrics: state.portfolio.keymetrics,
    getquotereturn: state.portfolio.getquotereturn,
    ttmvalue: state.portfolio.ttmvalue,
    showcontrolstate: state.market.showcontrolstate,
    keyttmvalue: state.portfolio.keyttmvalue,
    keyexecutives: state.portfolio.keyexecutives,
    institutionalholder: state.portfolio.institutionalholder,
    instituteowner: state.market.instituteowner,
    incomestatementget: state.market.incomestatementget,
    getmarketratio: state.market.getmarketratio,
  }),
  mapDispatchToProps: {
    getmetirials,
    getquotes,
    gettm,
    getkeymetricsttmvalue,
    marketratio,
    getkeyexecutives,
    getinstitutionalholder,
    getincomestatement,
    getinstitutionalowner,
  },
  component: InvesmentDropDown,
});
