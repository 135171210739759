import React, { useEffect, useState } from "react";
import "./App.css";
import { connect } from "./data/connect";
import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RouteComponentProps } from "react-router";
import Header from "./header";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "./footer";
import { resetresetpassword } from "./data/user/user.actions";
import { Route, Link, useParams, useRouteMatch } from "react-router-dom";
interface OwnProps extends RouteComponentProps<Tparams> {
  code: any;
}
interface StateProps {
  resetuserpassword: any;
}

interface DispatchProps {
  resetresetpassword: typeof resetresetpassword;
}
type Tparams = { code: any };
type ResetProps = OwnProps & StateProps & DispatchProps;

const ResetSucessmsg: React.FC<ResetProps> = ({
  resetresetpassword,
  resetuserpassword,
  match,
}) => {
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#ffffff");
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (resetuserpassword) {
      setShowModal(true);
    }
  }, [resetuserpassword]);

  useEffect(() => {
    if (match.params.code) {
      resetuserpassword(match.params.code);
    }
  }, [match.params.code]);

  return (
    <>
      <div className="Spinner">
        {showModal == false && (
          <ClipLoader color={color} loading={loading} size={100} />
        )}
      </div>
      <Modal
        show={showModal}
        cssClass="modalhomeggg"
        onHide={() => setShowModal(false)}
      >
        <Row className="justify-content-center">
          <Col sm={9} className="align-self-center text-center">
            <h3 className=" mt-5 one-five">Password Reset Successful!</h3>

            <Link
              to="/login"
              className="btn-value mr-sm-2 mt-5 text-center mobi-block my-4 d-block"
            >
              Login to continue...{" "}
            </Link>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    resetuserpassword: state.user.resetuserpassword,
  }),
  mapDispatchToProps: { resetresetpassword },
  component: ResetSucessmsg,
});
