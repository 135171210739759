import { ActionType } from "../../util/types";
import { PitchesState } from "./pitches.state";
import { setShowLoading, showToast } from "../global/global.actions";
import { PitchesService } from "./pitches.service";
import { setvaluationbyid } from "../stock/stock.actions";
import { toast } from "react-toastify";

export const getpitches = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setShowLoading(true));
  dispatch(SetPitcheslist([]));
  await PitchesService.mypitches().then((pitcheslist) => {
    dispatch(setShowLoading(false));
    if (pitcheslist.data) dispatch(SetPitcheslist(pitcheslist.data));
  });
};

export const buypitch =
  (
    stock_symbol: any,
    portfolio_id: any,
    valuation_type_id: any,
    valuation_id: any,
    valuation_output_id: any
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    dispatch(setAddpitch(false));
    await PitchesService.buypitch(
      stock_symbol,
      portfolio_id,
      valuation_type_id,
      valuation_id,
      valuation_output_id
    ).then(
      (addpitch) => {
        dispatch(setAddpitch(true));
        dispatch(setShowLoading(false));
        dispatch(toast.success(" Pitch Added sucessfully!"));
      },
      (error) => {
        dispatch(setAddpitch(false));
        dispatch(toast.error("Error with Pitch Adding"));
        dispatch(setShowLoading(false));
      }
    );
  };

export const updatepitches =
  (valuationid: any) => async (dispatch: React.Dispatch<any>) => {
    await PitchesService.updatepitch(valuationid).then(
      (updatepitch) => {
        dispatch(getpitches());
        dispatch(setupdatepitch(true));
        dispatch(toast.success("Updated sucessfully"));
      },
      (error) => {
        dispatch(toast.error(" Error Updated"));
      }
    );
  };

export const deletetrackedpitches =
  (valuationid: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setdeletetrackedpitches(false));
    await PitchesService.deletetrackedpitches(valuationid).then(
      (deletetrackedpitches) => {
        dispatch(getpitches());
        dispatch(setdeletetrackedpitches(true));
        dispatch(setvaluationbyid({}));
        dispatch(toast.success(" Pitch Deleted Sucessfully!"));
      },
      (error) => {
        dispatch(toast.error(" Error Delete "));
      }
    );
  };

export const trackedpitchupdate =
  (inputtrackedpitches: any) => async (dispatch: React.Dispatch<any>) => {
    await PitchesService.trackedpitchupdate(inputtrackedpitches).then(
      (trakedpitchesupdate) => {
        dispatch(getpitches());
        dispatch(settrakedpitchesupdate(trakedpitchesupdate));
        dispatch(settrackupdate(true));
        dispatch(toast.success(" Pitch Tracked Successfully!"));
      },
      (error) => {
        dispatch(toast.error("Tracking Limit Reached!"));
      }
    );
  };

export const reloadpitchtrack = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(settrackupdate(false));
};

export const SetPitcheslist = (pitcheslist: any) =>
  ({
    type: "portfolio-pitcheslist",
    pitcheslist,
  } as const);
export const setAddpitch = (addpitch: boolean) =>
  ({
    type: "portfolio-addpitch",
    addpitch,
  } as const);

export const setupdatepitch = (updatepitch: boolean) =>
  ({
    type: "portfolio-updatepitch",
    updatepitch,
  } as const);

export const setdeletetrackedpitches = (deletetrackedpitches: boolean) =>
  ({
    type: "portfolio-deletetrackedpitches",
    deletetrackedpitches,
  } as const);

export const settrakedpitchesupdate = (trakedpitchesupdate: any) =>
  ({
    type: "portfolio-trakedpitchesupdate",
    trakedpitchesupdate,
  } as const);

export const settrackupdate = (trackupdate: boolean) =>
  ({
    type: "portfolio-trackupdate",
    trackupdate,
  } as const);

export type PitchesActions =
  | ActionType<typeof SetPitcheslist>
  | ActionType<typeof setAddpitch>
  | ActionType<typeof setupdatepitch>
  | ActionType<typeof setdeletetrackedpitches>
  | ActionType<typeof settrakedpitchesupdate>
  | ActionType<typeof settrackupdate>;
