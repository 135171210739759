import React, { useState, useEffect } from "react";
import {
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import Slider from "react-rangeslider";
import { BiPlus, BiSearch } from "react-icons/bi";
import { HiOutlineFilter } from "react-icons/hi";
import { GrFilter } from "react-icons/gr";
import "../../App.css";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rangeslider/lib/index.css";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import {
  gethistoricaldata,
  getStockList,
  getStockProfile,
  setvaluationSuccess
} from "../../data/stock/stock.actions";
import {
  getmonthly,
  screenerselect,
  addscreenerlist,
  screenerdeleteing,
} from "../../data/portfolio/portfolio.actions";
import moment from "moment";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import { FiMinus } from "react-icons/fi";
import { marketsearch } from "../../data/market/market.actions";
import { Link } from "react-router-dom";
import { setQuarter } from "date-fns";

interface OwnProps {
  setStockId: any;
  stockId: any;
  showModal: any;
  setShowModal: any;
}

interface StateProps {
  stockList: any;
  stockProfile: any;
  datahistorical: any;
  getsearchmarket: any;
  screenerget: any;
  screenerlistview: any;
}

interface DispatchProps {
  getStockList: typeof getStockList;
  getStockProfile: typeof getStockProfile;
  getmonthly: typeof getmonthly;
  marketsearch: typeof marketsearch;
  screenerselect: typeof screenerselect;
  addscreenerlist: typeof addscreenerlist;
  screenerdeleteing: typeof screenerdeleteing;
  setvaluationSuccess: typeof setvaluationSuccess;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface MyPortfolioProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const MyPortfolio: React.FC<MyPortfolioProps> = ({
  getStockList,
  stockList,
  stockId,
  getStockProfile,
  getmonthly,
  stockProfile,
  datahistorical,
  setStockId,
  marketsearch,
  getsearchmarket,
  screenerselect,
  screenerget,
  addscreenerlist,
  screenerlistview,
  setvaluationSuccess
}) => {
  const [searchStock, setSearchStock] = useState("");
  const [region, setRegion] = useState("US");
  const [input, setinput] = useState("");
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  const [marketcap, setMarketcap] = useState([
    { id: "largecap", value: ">=$5Bn (large cap)", isChecked: false },
    { id: "midcap", value: ">$5Bn Mid Cap>=$1Bn", isChecked: true },
    { id: "smallcap", value: "small cap<$1Bn", isChecked: false },
  ]);
  const [sector, setSector] = useState([
    { id: "Consumer Cyclical", value: "Consumer Cyclical", isChecked: true },
    { id: "Energy ", value: "Energy ", isChecked: false },
    { id: "Technology  ", value: "Technology ", isChecked: false },
    { id: "Industrials ", value: "Industrials ", isChecked: false },
    { id: "Financial ", value: "Financial ", isChecked: false },
    { id: "Services ", value: "Services ", isChecked: false },
    { id: "Basic Materials", value: "Basic Materials", isChecked: false },
    {
      id: "Communication Services",
      value: "Communication Services",
      isChecked: false,
    },
    { id: "Defensive  ", value: "Defensive  ", isChecked: false },
    { id: "Healthcare ", value: "Healthcare ", isChecked: false },
    { id: "Real Estate ", value: "Real Estate ", isChecked: false },
    { id: "Utilities  ", value: "Utilities  ", isChecked: false },
    { id: "Industrial Goods ", value: "Industrial Goods ", isChecked: false },
    { id: "Services  ", value: "Services  ", isChecked: false },
    { id: "Conglomerates ", value: "Conglomerates ", isChecked: false },
  ]);
  const [industry, setIndustry] = useState([
    { id: "Autos  ", value: "Autos  ", isChecked: false },
    { id: "Banks   ", value: "Banks  ", isChecked: false },
    { id: "Diversified  ", value: "Diversified  ", isChecked: false },
    { id: "Software  ", value: "Software  ", isChecked: false },
    { id: "Banks Regional ", value: "Banks Regional ", isChecked: false },
    {
      id: "Beverages Alcoholic",
      value: "Beverages Alcoholic",
      isChecked: false,
    },
    { id: "Beverages Brewers", value: "Beverages Brewers", isChecked: false },
    {
      id: "Beverages Non-Alcoholic  ",
      value: "Beverages Non-Alcoholic  ",
      isChecked: false,
    },
  ]);
  const [price, setPrice] = useState([
    { id: "1  ", value: "<=$1  ", isChecked: false },
    { id: "2   ", value: "<=$2  ", isChecked: false },
    { id: "3  ", value: "<=$3  ", isChecked: false },
    { id: "4  ", value: "<=$4  ", isChecked: false },
    { id: "5 ", value: "<=$5 ", isChecked: false },
    { id: "6", value: "<=$6", isChecked: false },
    { id: "7", value: "<=$7", isChecked: false },
    { id: "8 ", value: "<=$8  ", isChecked: false },
    { id: "9  ", value: "<=$9  ", isChecked: false },
    { id: "10  ", value: "<=$10  ", isChecked: false },
    { id: "15  ", value: "<=$15  ", isChecked: false },
    { id: "20 ", value: "<=$20  ", isChecked: false },
    { id: "25  ", value: "<=$25  ", isChecked: false },
    { id: "30  ", value: "<=$30  ", isChecked: false },
    { id: "35  ", value: "<=$35  ", isChecked: false },
    { id: "40  ", value: "<=$40  ", isChecked: false },
    { id: "45 ", value: "<=$45 ", isChecked: false },
    { id: "50", value: "<=$50", isChecked: false },
    { id: "55", value: "<=$55", isChecked: false },
    { id: "60 ", value: "<=$60  ", isChecked: false },
    { id: "65  ", value: "<=$65  ", isChecked: false },
    { id: "70  ", value: "<=$70  ", isChecked: false },
    { id: "75  ", value: "<=$75  ", isChecked: false },
    { id: "80 ", value: "<=$80  ", isChecked: false },
    { id: "85", value: "<=$85", isChecked: false },
    { id: "90", value: "<=$90", isChecked: false },
    { id: "95 ", value: "<=$95  ", isChecked: false },
    { id: "100  ", value: "<=$100  ", isChecked: false },
  ]);
  const [volume, setVolume] = useState([
    { id: "50", value: "<50k", isChecked: false },
    { id: "100", value: "<100k", isChecked: true },
    { id: "150", value: "<150k", isChecked: false },
    { id: "50", value: "<200k", isChecked: false },
    { id: "100", value: "<250k", isChecked: true },
    { id: "150", value: "<300k", isChecked: false },
    { id: "50", value: "<350k", isChecked: false },
    { id: "100", value: "<400k", isChecked: true },
    { id: "150", value: "<450k", isChecked: false },
    { id: "50", value: "<500k", isChecked: false },
    { id: "100", value: "<550k", isChecked: true },
    { id: "150", value: "<600k", isChecked: false },
    { id: "50", value: "<650k", isChecked: false },
    { id: "100", value: "<700k", isChecked: true },
    { id: "150", value: "<750k", isChecked: false },
    { id: "50", value: "<800k", isChecked: false },
    { id: "100", value: "<8500k", isChecked: true },
    { id: "150", value: "<900k", isChecked: false },
    { id: "50", value: "<950k", isChecked: false },
    { id: "100", value: "<1M", isChecked: true },
  ]);

  const [divident, setDivident] = useState([
    { id: "1", value: ">=1%", isChecked: false },
    { id: "2", value: ">=2%", isChecked: true },
    { id: "3", value: ">=3%", isChecked: false },
    { id: "4", value: ">=4%", isChecked: false },
    { id: "5", value: ">=5%", isChecked: true },
    { id: "6", value: ">=6%", isChecked: false },
    { id: "7", value: ">=7%", isChecked: false },
    { id: "8", value: ">=8%", isChecked: true },
    { id: "9", value: ">=9%", isChecked: false },
    { id: "10", value: ">=10%", isChecked: false },
  ]);
  const [earningdate, setEarningdate] = useState([
    { id: "thisweek", value: "This week", isChecked: false },
    { id: "previousweek", value: "Previous week ", isChecked: true },
    { id: "thismonth", value: "This month ", isChecked: false },
    { id: "nextweek", value: "Next week", isChecked: false },
  ]);
  const [pe, setPe] = useState([
    { id: "-5", value: "<=5", isChecked: false },
    { id: "-10", value: "<=10", isChecked: false },
    { id: "-15", value: "<=15", isChecked: false },
    { id: "-20", value: "<=20", isChecked: false },
    { id: "-25", value: "<=25", isChecked: false },
    { id: "-30", value: "<=30", isChecked: false },
    { id: "-35", value: "<=35", isChecked: false },
    { id: "-40", value: "<=40", isChecked: false },
    { id: "-45", value: "<=45", isChecked: false },
    { id: "-50", value: "<=50", isChecked: false },
    { id: "5", value: ">=5", isChecked: false },
    { id: "10", value: ">=10", isChecked: false },
    { id: "15", value: ">=15", isChecked: false },
    { id: "20", value: ">=20", isChecked: false },
    { id: "25", value: ">=25", isChecked: false },
    { id: "30", value: ">=30", isChecked: false },
    { id: "35", value: ">=35", isChecked: false },
    { id: "40", value: ">=40", isChecked: false },
    { id: "45", value: ">=45", isChecked: false },
    { id: "5-0", value: ">=50", isChecked: false },
  ]);
  const [ps, setPs] = useState([
    { id: "-1", value: "<=1", isChecked: false },
    { id: "-2", value: "<=2", isChecked: false },
    { id: "-3", value: "<=3", isChecked: false },
    { id: "-4", value: "<=4", isChecked: false },
    { id: "-5", value: "<=5", isChecked: false },
    { id: "-6", value: "<=6", isChecked: false },
    { id: "-7", value: "<=7", isChecked: false },
    { id: "-8", value: "<=8", isChecked: false },
    { id: "-9", value: "<=9", isChecked: false },
    { id: "-10", value: "<=10", isChecked: false },
    { id: "1", value: ">=1", isChecked: false },
    { id: "2", value: ">=2", isChecked: false },
    { id: "3", value: ">=3", isChecked: false },
    { id: "4", value: ">=4", isChecked: false },
    { id: "5", value: ">=5", isChecked: false },
    { id: "6", value: ">=6", isChecked: false },
    { id: "7", value: ">=7", isChecked: false },
    { id: "8", value: ">=8", isChecked: false },
    { id: "9", value: ">=9", isChecked: false },
    { id: "10", value: ">=10", isChecked: false },
  ]);
  const [pb, setPb] = useState([
    { id: "-1", value: "<=1", isChecked: false },
    { id: "-2", value: "<=2", isChecked: false },
    { id: "-3", value: "<=3", isChecked: false },
    { id: "-4", value: "<=4", isChecked: false },
    { id: "-5", value: "<=5", isChecked: false },
    { id: "-6", value: "<=6", isChecked: false },
    { id: "-7", value: "<=7", isChecked: false },
    { id: "-8", value: "<=8", isChecked: false },
    { id: "-9", value: "<=9", isChecked: false },
    { id: "-10", value: "<=10", isChecked: false },
    { id: "1", value: ">=1", isChecked: false },
    { id: "2", value: ">=2", isChecked: false },
    { id: "3", value: ">=3", isChecked: false },
    { id: "4", value: ">=4", isChecked: false },
    { id: "5", value: ">=5", isChecked: false },
    { id: "6", value: ">=6", isChecked: false },
    { id: "7", value: ">=7", isChecked: false },
    { id: "8", value: ">=8", isChecked: false },
    { id: "9", value: ">=9", isChecked: false },
    { id: "10", value: ">=10", isChecked: false },
  ]);
  const [pfcf, setPfcf] = useState([
    { id: "-5", value: "<=5", isChecked: false },
    { id: "-10", value: "<=10", isChecked: false },
    { id: "-15", value: "<=15", isChecked: false },
    { id: "-20", value: "<=20", isChecked: false },
    { id: "-25", value: "<=25", isChecked: false },
    { id: "-30", value: "<=30", isChecked: false },
    { id: "-35", value: "<=35", isChecked: false },
    { id: "-40", value: "<=40", isChecked: false },
    { id: "-45", value: "<=45", isChecked: false },
    { id: "-50", value: "<=50", isChecked: false },
    { id: "-55", value: "<=55", isChecked: false },
    { id: "-60", value: "<=60", isChecked: false },
    { id: "-65", value: "<=65", isChecked: false },
    { id: "-70", value: "<=70", isChecked: false },
    { id: "-75", value: "<=75", isChecked: false },
    { id: "-80", value: "<=80", isChecked: false },
    { id: "-85", value: "<=85", isChecked: false },
    { id: "-90", value: "<=90", isChecked: false },
    { id: "-95", value: "<=95", isChecked: false },
    { id: "-100", value: "<=100", isChecked: false },
    { id: "5", value: ">=5", isChecked: false },
    { id: "10", value: ">=10", isChecked: false },
    { id: "15", value: ">=15", isChecked: false },
    { id: "20", value: ">=20", isChecked: false },
    { id: "25", value: ">=25", isChecked: false },
    { id: "30", value: ">=30", isChecked: false },
    { id: "35", value: ">=35", isChecked: false },
    { id: "40", value: ">=40", isChecked: false },
    { id: "45", value: ">=45", isChecked: false },
    { id: "50", value: ">=50", isChecked: false },
    { id: "55", value: ">=55", isChecked: false },
    { id: "60", value: ">=60", isChecked: false },
    { id: "65", value: ">=65", isChecked: false },
    { id: "70", value: ">=70", isChecked: false },
    { id: "75", value: ">=75", isChecked: false },
    { id: "80", value: ">=80", isChecked: false },
    { id: "85", value: ">=85", isChecked: false },
    { id: "90", value: ">=90", isChecked: false },
    { id: "95", value: ">=95", isChecked: false },
    { id: "100", value: ">=100", isChecked: false },
  ]);
  const [pc, setPc] = useState([
    { id: "-1", value: "<=1", isChecked: false },
    { id: "-2", value: "<=2", isChecked: false },
    { id: "-3", value: "<=3", isChecked: false },
    { id: "-4", value: "<=4", isChecked: false },
    { id: "-5", value: "<=5", isChecked: false },
    { id: "-6", value: "<=6", isChecked: false },
    { id: "-7", value: "<=7", isChecked: false },
    { id: "-8", value: "<=8", isChecked: false },
    { id: "-9", value: "<=9", isChecked: false },
    { id: "-10", value: "<=10", isChecked: false },
    { id: "1", value: ">=1", isChecked: false },
    { id: "2", value: ">=2", isChecked: false },
    { id: "3", value: ">=3", isChecked: false },
    { id: "4", value: ">=4", isChecked: false },
    { id: "5", value: ">=5", isChecked: false },
    { id: "6", value: ">=6", isChecked: false },
    { id: "7", value: ">=7", isChecked: false },
    { id: "8", value: ">=8", isChecked: false },
    { id: "9", value: ">=9", isChecked: false },
    { id: "10", value: ">=10", isChecked: false },
  ]);

  const [peg, setPeg] = useState([
    { id: "-1", value: "<=1", isChecked: false },
    { id: "-2", value: "<=2", isChecked: false },
    { id: "-3", value: "<=3", isChecked: false },
    { id: "1", value: ">=1", isChecked: false },
    { id: "2", value: ">=2", isChecked: false },
    { id: "3", value: ">=3", isChecked: false },
  ]);
  const [epspastyear, setepspastyear] = useState([
    { id: "-5", value: "<=5%", isChecked: false },
    { id: "-10", value: "<=10%", isChecked: false },
    { id: "-20", value: "<=20%", isChecked: false },
    { id: "-25", value: "<=25%", isChecked: false },
    { id: "-30", value: "<=30%", isChecked: false },
    { id: "5", value: ">=5%", isChecked: false },
    { id: "10", value: ">=10%", isChecked: false },
    { id: "15", value: ">=15%", isChecked: false },
    { id: "20", value: ">=20%", isChecked: false },
    { id: "25", value: ">=25%", isChecked: false },
    { id: "30", value: ">=30%", isChecked: false },
  ]);

  const [epspastfiveyear, setepspastfiveyear] = useState([
    { id: "-5", value: "<=5%", isChecked: false },
    { id: "-10", value: "<=10%", isChecked: false },
    { id: "-15", value: "<=15%", isChecked: false },
    { id: "-20", value: "<=20%", isChecked: false },
    { id: "-25", value: "<=25%", isChecked: false },
    { id: "-30", value: "<=30%", isChecked: false },
    { id: "5", value: ">=5%", isChecked: false },
    { id: "10", value: ">=10%", isChecked: false },
    { id: "15", value: ">=15%", isChecked: false },
    { id: "20", value: ">=20%", isChecked: false },
    { id: "25", value: ">=25%", isChecked: false },
    { id: "30", value: ">=30%", isChecked: false },
  ]);
  const [salespastfiveyear, setsalespastfiveyear] = useState([
    { id: "-5", value: "<=5%", isChecked: false },
    { id: "-10", value: "<=10%", isChecked: false },
    { id: "-15", value: "<=15%", isChecked: false },
    { id: "-20", value: "<=20%", isChecked: false },
    { id: "-25", value: "<=25%", isChecked: false },
    { id: "-30", value: "<=30%", isChecked: false },
    { id: "5", value: ">=5%", isChecked: false },
    { id: "10", value: ">=10%", isChecked: false },
    { id: "15", value: ">=15%", isChecked: false },
    { id: "20", value: ">=20%", isChecked: false },
    { id: "25", value: ">=25%", isChecked: false },
    { id: "30", value: ">=30%", isChecked: false },
  ]);

  const [roa, setRoa] = useState([
    { id: "-5", value: "<=5%", isChecked: false },
    { id: "-10", value: "<=10%", isChecked: false },
    { id: "-15", value: "<=15%", isChecked: false },
    { id: "-20", value: "<=20%", isChecked: false },
    { id: "-25", value: "<=25%", isChecked: false },
    { id: "-30", value: "<=30%", isChecked: false },
    { id: "-35", value: "<=15%", isChecked: false },
    { id: "-40", value: "<=20%", isChecked: false },
    { id: "-45", value: "<=25%", isChecked: false },
    { id: "-50", value: "<=30%", isChecked: false },
    { id: "5", value: ">=5%", isChecked: false },
    { id: "10", value: ">=10%", isChecked: false },
    { id: "15", value: ">=15%", isChecked: false },
    { id: "20", value: ">=20%", isChecked: false },
    { id: "25", value: ">=25%", isChecked: false },
    { id: "30", value: ">=30%", isChecked: false },
    { id: "35", value: ">=35%", isChecked: false },
    { id: "40", value: ">=40%", isChecked: false },
    { id: "45", value: ">=45%", isChecked: false },
    { id: "50", value: ">=50%", isChecked: false },
  ]);
  const [roe, setRoe] = useState([
    { id: "-5", value: "<=5%", isChecked: false },
    { id: "-10", value: "<=10%", isChecked: false },
    { id: "-15", value: "<=15%", isChecked: false },
    { id: "-20", value: "<=20%", isChecked: false },
    { id: "-25", value: "<=25%", isChecked: false },
    { id: "-30", value: "<=30%", isChecked: false },
    { id: "-35", value: "<=15%", isChecked: false },
    { id: "-40", value: "<=20%", isChecked: false },
    { id: "-45", value: "<=25%", isChecked: false },
    { id: "-50", value: "<=30%", isChecked: false },
    { id: "5", value: ">=5%", isChecked: false },
    { id: "10", value: ">=10%", isChecked: false },
    { id: "15", value: ">=15%", isChecked: false },
    { id: "20", value: ">=20%", isChecked: false },
    { id: "25", value: ">=25%", isChecked: false },
    { id: "30", value: ">=30%", isChecked: false },
    { id: "35", value: ">=35%", isChecked: false },
    { id: "40", value: ">=40%", isChecked: false },
    { id: "45", value: ">=45%", isChecked: false },
    { id: "50", value: ">=50%", isChecked: false },
  ]);

  const [roi, setRoi] = useState([
    { id: "-5", value: "<=5%", isChecked: false },
    { id: "-10", value: "<=10%", isChecked: false },
    { id: "-15", value: "<=15%", isChecked: false },
    { id: "-20", value: "<=20%", isChecked: false },
    { id: "-25", value: "<=25%", isChecked: false },
    { id: "-30", value: "<=30%", isChecked: false },
    { id: "-35", value: "<=15%", isChecked: false },
    { id: "-40", value: "<=20%", isChecked: false },
    { id: "-45", value: "<=25%", isChecked: false },
    { id: "-50", value: "<=30%", isChecked: false },
    { id: "5", value: ">=5%", isChecked: false },
    { id: "10", value: ">=10%", isChecked: false },
    { id: "15", value: ">=15%", isChecked: false },
    { id: "20", value: ">=20%", isChecked: false },
    { id: "25", value: ">=25%", isChecked: false },
    { id: "30", value: ">=30%", isChecked: false },
    { id: "35", value: ">=35%", isChecked: false },
    { id: "40", value: ">=40%", isChecked: false },
    { id: "45", value: ">=45%", isChecked: false },
    { id: "50", value: ">=50%", isChecked: false },
  ]);
  const [currentratio, setCurrentratio] = useState([
    { id: "-0.5", value: "<=0.5", isChecked: false },
    { id: "-1", value: "<=1", isChecked: false },
    { id: "0.5", value: ">=0.5", isChecked: false },
    { id: "1", value: ">=1", isChecked: false },
    { id: "1.5", value: ">=1.5", isChecked: false },
    { id: "2", value: ">=2", isChecked: false },
    { id: "2.5", value: ">=2.5", isChecked: false },
    { id: "3", value: ">=3", isChecked: false },
    { id: "3.5", value: ">=3.5", isChecked: false },
    { id: "4", value: ">=4", isChecked: false },
    { id: "4.5", value: ">=4.5", isChecked: false },
    { id: "5", value: ">=5", isChecked: false },
    { id: "5.5", value: ">=5.5", isChecked: false },
    { id: "6", value: ">=6", isChecked: false },
    { id: "6.5", value: ">=6.5", isChecked: false },
    { id: "7", value: ">=7", isChecked: false },
    { id: "7.5", value: ">=7.5", isChecked: false },
    { id: "8", value: ">=8", isChecked: false },
    { id: "8.5", value: ">=8.5", isChecked: false },
    { id: "9", value: ">=9", isChecked: false },
    { id: "9.5", value: ">=9.5", isChecked: false },
    { id: "10", value: ">=10", isChecked: false },
  ]);
  const [quickratio, setQuickratio] = useState([
    { id: "-0.5", value: "<=0.5", isChecked: false },
    { id: "-1", value: "<=1", isChecked: false },
    { id: "0.5", value: ">=0.5", isChecked: false },
    { id: "1", value: ">=1", isChecked: false },
    { id: "1.5", value: ">=1.5", isChecked: false },
    { id: "2", value: ">=2", isChecked: false },
    { id: "2.5", value: ">=2.5", isChecked: false },
    { id: "3", value: ">=3", isChecked: false },
    { id: "3.5", value: ">=3.5", isChecked: false },
    { id: "4", value: ">=4", isChecked: false },
    { id: "4.5", value: ">=4.5", isChecked: false },
    { id: "5", value: ">=5", isChecked: false },
    { id: "5.5", value: ">=5.5", isChecked: false },
    { id: "6", value: ">=6", isChecked: false },
    { id: "6.5", value: ">=6.5", isChecked: false },
    { id: "7", value: ">=7", isChecked: false },
    { id: "7.5", value: ">=7.5", isChecked: false },
    { id: "8", value: ">=8", isChecked: false },
    { id: "8.5", value: ">=8.5", isChecked: false },
    { id: "9", value: ">=9", isChecked: false },
    { id: "9.5", value: ">=9.5", isChecked: false },
    { id: "10", value: ">=10", isChecked: false },
  ]);
  const [ltdebt, setLtdebt] = useState([
    { id: "1", value: "1<=", isChecked: false },
    { id: "0.9", value: "0.9<=", isChecked: false },
    { id: "0.8", value: ">=0.8", isChecked: false },
    { id: "0.7", value: ">=0.7", isChecked: false },
    { id: "0.6", value: ">=0.6", isChecked: false },
    { id: "0.5", value: ">=0.5", isChecked: false },
    { id: "0.4", value: ">=0.4", isChecked: false },
    { id: "0.3", value: ">=0.3", isChecked: false },
    { id: "0.2", value: ">=0.2", isChecked: false },
    { id: "0.1", value: ">=0.1", isChecked: false },
    { id: "-0.1", value: "<=0.1", isChecked: false },
    { id: "-0.2", value: "<=0.2", isChecked: false },
    { id: "-0.3", value: "<=0.3", isChecked: false },
    { id: "-0.4", value: "<=0.4", isChecked: false },
    { id: "-0.5", value: "<=0.5", isChecked: false },
    { id: "-0.6", value: ">=0.6", isChecked: false },
    { id: "-0.7", value: "<=0.7", isChecked: false },
    { id: "-0.8", value: "<=0.8", isChecked: false },
    { id: "-0.9", value: "<=0.9", isChecked: false },
    { id: "-1", value: "<=1", isChecked: false },
  ]);
  const [debteq, setDebteq] = useState([
    { id: "1", value: "1<=", isChecked: false },
    { id: "0.9", value: "0.9<=", isChecked: false },
    { id: "0.8", value: ">=0.8", isChecked: false },
    { id: "0.7", value: ">=0.7", isChecked: false },
    { id: "0.6", value: ">=0.6", isChecked: false },
    { id: "0.5", value: ">=0.5", isChecked: false },
    { id: "0.4", value: ">=0.4", isChecked: false },
    { id: "0.3", value: ">=0.3", isChecked: false },
    { id: "0.2", value: ">=0.2", isChecked: false },
    { id: "0.1", value: ">=0.1", isChecked: false },
    { id: "-0.1", value: "<=0.1", isChecked: false },
    { id: "-0.2", value: "<=0.2", isChecked: false },
    { id: "-0.3", value: "<=0.3", isChecked: false },
    { id: "-0.4", value: "<=0.4", isChecked: false },
    { id: "-0.5", value: "<=0.5", isChecked: false },
    { id: "-0.6", value: ">=0.6", isChecked: false },
    { id: "-0.7", value: "<=0.7", isChecked: false },
    { id: "-0.8", value: "<=0.8", isChecked: false },
    { id: "-0.9", value: "<=0.9", isChecked: false },
    { id: "-1", value: "<=1", isChecked: false },
  ]);
  const [grossmargin, setGrossmargin] = useState([
    { id: "0", value: "<=0%", isChecked: false },
    { id: "5", value: "<=5%", isChecked: false },
    { id: "15", value: "<=15%", isChecked: false },
    { id: "20", value: "<=20%", isChecked: false },
    { id: "25", value: "<=25%", isChecked: false },
    { id: "30", value: "<=30%", isChecked: false },
    { id: "35", value: "<=35%", isChecked: false },
    { id: "40", value: "<=40%", isChecked: false },
    { id: "45", value: "<=45%", isChecked: false },
    { id: "50", value: "<=50%", isChecked: false },
    { id: "55", value: "<=55%", isChecked: false },
    { id: "60", value: "<=60%", isChecked: false },
    { id: "65", value: "<=65%", isChecked: false },
    { id: "70", value: "<=70%", isChecked: false },
    { id: "75", value: "<=75%", isChecked: false },
    { id: "80", value: "<=80%", isChecked: false },
    { id: "85", value: "<=85%", isChecked: false },
    { id: "90", value: "<=90%", isChecked: false },
    { id: "-10", value: "<=-10%", isChecked: false },
    { id: "-20", value: "<=-20%", isChecked: false },
    { id: "-30", value: "<=-30%", isChecked: false },
    { id: "-40", value: "<=-40%", isChecked: false },
    { id: "-50", value: "<=-50%", isChecked: false },
    { id: "-60", value: "<=-60%", isChecked: false },
    { id: "-70", value: "<=-70%", isChecked: false },
    { id: "-80", value: "<=-80%", isChecked: false },
    { id: "-90", value: "<=-90%", isChecked: false },
    { id: "-100", value: "<=-100%", isChecked: false },
    { id: "0", value: " >=0%", isChecked: false },
    { id: "5", value: " >=5%", isChecked: false },
    { id: "10", value: " >=10%", isChecked: false },
    { id: "15", value: " >=15%", isChecked: false },
    { id: "20", value: " >=20%", isChecked: false },
    { id: "25", value: " >=25%", isChecked: false },
    { id: "30", value: " >=30%", isChecked: false },
    { id: "35", value: " >=35%", isChecked: false },
    { id: "40", value: " >=40%", isChecked: false },
    { id: "45", value: " >=45%", isChecked: false },
    { id: "50", value: " >=50%", isChecked: false },
    { id: "55", value: " >=55%", isChecked: false },
    { id: "60", value: " >=60%", isChecked: false },
    { id: "65", value: " >=65%", isChecked: false },
    { id: "70", value: " >=70%", isChecked: false },
    { id: "75", value: " >=75%", isChecked: false },
    { id: "80", value: " >=80%", isChecked: false },
    { id: "85", value: " >=85%", isChecked: false },
    { id: "90", value: " >=90%", isChecked: false },
  ]);

  const [operatingmargin, setOperatingmargin] = useState([
    { id: "0", value: "<=0%", isChecked: false },
    { id: "5", value: "<=5%", isChecked: false },
    { id: "15", value: "<=15%", isChecked: false },
    { id: "20", value: "<=20%", isChecked: false },
    { id: "25", value: "<=25%", isChecked: false },
    { id: "30", value: "<=30%", isChecked: false },
    { id: "35", value: "<=35%", isChecked: false },
    { id: "40", value: "<=40%", isChecked: false },
    { id: "45", value: "<=45%", isChecked: false },
    { id: "50", value: "<=50%", isChecked: false },
    { id: "55", value: "<=55%", isChecked: false },
    { id: "60", value: "<=60%", isChecked: false },
    { id: "65", value: "<=65%", isChecked: false },
    { id: "70", value: "<=70%", isChecked: false },
    { id: "75", value: "<=75%", isChecked: false },
    { id: "80", value: "<=80%", isChecked: false },
    { id: "85", value: "<=85%", isChecked: false },
    { id: "90", value: "<=90%", isChecked: false },
    { id: "-10", value: "<=-10%", isChecked: false },
    { id: "-20", value: "<=-20%", isChecked: false },
    { id: "-30", value: "<=-30%", isChecked: false },
    { id: "-40", value: "<=-40%", isChecked: false },
    { id: "-50", value: "<=-50%", isChecked: false },
    { id: "-60", value: "<=-60%", isChecked: false },
    { id: "-70", value: "<=-70%", isChecked: false },
    { id: "-80", value: "<=-80%", isChecked: false },
    { id: "-90", value: "<=-90%", isChecked: false },
    { id: "-100", value: "<=-100%", isChecked: false },
    { id: "0", value: " >=0%", isChecked: false },
    { id: "5", value: " >=5%", isChecked: false },
    { id: "10", value: " >=10%", isChecked: false },
    { id: "15", value: " >=15%", isChecked: false },
    { id: "20", value: " >=20%", isChecked: false },
    { id: "25", value: " >=25%", isChecked: false },
    { id: "30", value: " >=30%", isChecked: false },
    { id: "35", value: " >=35%", isChecked: false },
    { id: "40", value: " >=40%", isChecked: false },
    { id: "45", value: " >=45%", isChecked: false },
    { id: "50", value: " >=50%", isChecked: false },
    { id: "55", value: " >=55%", isChecked: false },
    { id: "60", value: " >=60%", isChecked: false },
    { id: "65", value: " >=65%", isChecked: false },
    { id: "70", value: " >=70%", isChecked: false },
    { id: "75", value: " >=75%", isChecked: false },
    { id: "80", value: " >=80%", isChecked: false },
    { id: "85", value: " >=85%", isChecked: false },
    { id: "90", value: " >=90%", isChecked: false },
  ]);

  const [netprofilemargin, setNetprofilemargin] = useState([
    { id: "0", value: "<=0%", isChecked: false },
    { id: "5", value: "<=5%", isChecked: false },
    { id: "15", value: "<=15%", isChecked: false },
    { id: "20", value: "<=20%", isChecked: false },
    { id: "25", value: "<=25%", isChecked: false },
    { id: "30", value: "<=30%", isChecked: false },
    { id: "35", value: "<=35%", isChecked: false },
    { id: "40", value: "<=40%", isChecked: false },
    { id: "45", value: "<=45%", isChecked: false },
    { id: "50", value: "<=50%", isChecked: false },
    { id: "55", value: "<=55%", isChecked: false },
    { id: "60", value: "<=60%", isChecked: false },
    { id: "65", value: "<=65%", isChecked: false },
    { id: "70", value: "<=70%", isChecked: false },
    { id: "75", value: "<=75%", isChecked: false },
    { id: "80", value: "<=80%", isChecked: false },
    { id: "85", value: "<=85%", isChecked: false },
    { id: "90", value: "<=90%", isChecked: false },
    { id: "-10", value: "<=-10%", isChecked: false },
    { id: "-20", value: "<=-20%", isChecked: false },
    { id: "-30", value: "<=-30%", isChecked: false },
    { id: "-40", value: "<=-40%", isChecked: false },
    { id: "-50", value: "<=-50%", isChecked: false },
    { id: "-60", value: "<=-60%", isChecked: false },
    { id: "-70", value: "<=-70%", isChecked: false },
    { id: "-80", value: "<=-80%", isChecked: false },
    { id: "-90", value: "<=-90%", isChecked: false },
    { id: "-100", value: "<=-100%", isChecked: false },
    { id: "0", value: " >=0%", isChecked: false },
    { id: "5", value: " >=5%", isChecked: false },
    { id: "10", value: " >=10%", isChecked: false },
    { id: "15", value: " >=15%", isChecked: false },
    { id: "20", value: " >=20%", isChecked: false },
    { id: "25", value: " >=25%", isChecked: false },
    { id: "30", value: " >=30%", isChecked: false },
    { id: "35", value: " >=35%", isChecked: false },
    { id: "40", value: " >=40%", isChecked: false },
    { id: "45", value: " >=45%", isChecked: false },
    { id: "50", value: " >=50%", isChecked: false },
    { id: "55", value: " >=55%", isChecked: false },
    { id: "60", value: " >=60%", isChecked: false },
    { id: "65", value: " >=65%", isChecked: false },
    { id: "70", value: " >=70%", isChecked: false },
    { id: "75", value: " >=75%", isChecked: false },
    { id: "80", value: " >=80%", isChecked: false },
    { id: "85", value: " >=85%", isChecked: false },
    { id: "90", value: " >=90%", isChecked: false },
  ]);

  const [allsector, setAllsector] = useState<any>(["finance"]);
  const [allmarketcap, setAllmarketcap] = useState<any>(["mid"]);
  const [symbol, SetSymbol] = useState<any>("");
  const [quote, setquote] = useState("");
  const [list, setlist] = useState<any>([]);
  const [showFilter, setShowFilter] = useState(false);
  const signupSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
    password_confirmation: Yup.string().when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });
  const search = async () => {
    await marketsearch(input);
  };

  const handleMarketcap = (position: any) => {
    marketcap.forEach(
      (sector, index) =>
        (sector.isChecked =
          index == position ? !sector.isChecked : sector.isChecked)
    );
    setMarketcap(marketcap);

    let selectedMarket: any = [];
    marketcap.forEach((sector, index) => {
      if (sector.isChecked) {
        selectedMarket.push(sector.id);
      }
    });
    setAllmarketcap(selectedMarket);
  };

  const handleSector = (position: any) => {
    sector.forEach(
      (sector, index) =>
        (sector.isChecked =
          index == position ? !sector.isChecked : sector.isChecked)
    );
    setSector(sector);

    let selectedSector: any = [];
    sector.forEach((sector, index) => {
      if (sector.isChecked) {
        selectedSector.push(sector.id);
      }
    });
    setAllsector(selectedSector);
  };

  const getstocklists = async () => {
    //e.preventDefault();

    setShowFilter(false);
    await getStockList(allmarketcap, allsector, region);
  };

  useEffect(() => {
    getstocklists();
  }, []);
  useEffect(() => {
    addscreenerlist(stockId);
    setvaluationSuccess();
  }, []);

  return (
    <>
      <div className="table-responsive dash-table-div p-3 mt-0 stockListTable emptyStockProfileCont">
        <div className="input-relative mb-2">
          {/*<HiOutlineFilter className="filter-i green-text" onClick={() => setShowFilter(!showFilter)}></HiOutlineFilter> */}
          <BiSearch className="same-icons-rit green-text"></BiSearch>
          <input
            autoFocus
            className="search-dash mr-sm-2 gray-gradient gray-border"
            type="search"
            placeholder="Search for companies"
            value={input}
            onChange={(e) => {
              setinput(e.target.value);
              search();
            }}
          />
          {showFilter && (
            <div className="dropdownBox mt-1">
              <div className="p-4">
                <Row>
                  <Col sm={6} className="align-self-center">
                    <h3 className="bold_15_23">Descriptive</h3>
                  </Col>
                  <Col sm={6} className="text-right align-self-center">
                    <a href="" className="show_text">
                      Show Saved Screeners
                    </a>
                  </Col>
                </Row>

                <div className="market-scroll height_340 mt-2">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      name: "red",
                      sector: "",
                      industry: "",
                      price: "",
                      marketcap: "",
                      volume: "",
                      dividend: "",
                      earningdate: "",
                      pe: "",
                      ps: "",
                      pb: "",
                      pfcf: "",
                      pc: "",
                      peg: "",
                      epsgrowthpastyear: "",
                      epsgrowthpast5years: "",
                      salesgrowthpast5years: "",
                      roa: "",
                      roe: "",
                      roi: "",
                      currentratio: "",
                      quickratio: "",
                      ltdebteq: "",
                      debteq: "",
                      grossmargin: "",
                      operatingmargin: "",
                      netprofitmargin: "",
                    }}
                    // validationSchema={signupSchema}
                    onSubmit={(
                      {
                        name,
                        sector,
                        industry,
                        price,
                        marketcap,
                        volume,
                        dividend,
                        earningdate,
                        pe,
                        ps,
                        pb,
                        pfcf,
                        pc,
                        peg,
                        epsgrowthpastyear,
                        epsgrowthpast5years,
                        salesgrowthpast5years,
                        roa,
                        roe,
                        roi,
                        currentratio,
                        quickratio,
                        ltdebteq,
                        debteq,
                        grossmargin,
                        operatingmargin,
                        netprofitmargin,
                      },
                      { setStatus, setSubmitting }
                    ) => {
                      console.log(setSubmitting, "setSubmitting");
                      screenerselect(
                        JSON.stringify({
                          name,
                          sector,
                          industry,
                          price,
                          marketcap,
                          volume,
                          dividend,
                          earningdate,
                          pe,
                          ps,
                          pb,
                          pfcf,
                          pc,
                          peg,
                          epsgrowthpastyear,
                          epsgrowthpast5years,
                          salesgrowthpast5years,
                          roa,
                          roe,
                          roi,
                          currentratio,
                          quickratio,
                          ltdebteq,
                          debteq,
                          grossmargin,
                          operatingmargin,
                          netprofitmargin,
                        })
                      );
                    }}
                    render={({
                      errors,
                      status,
                      touched,
                      isSubmitting,
                      handleChange,
                      initialErrors,
                    }) => (
                      <Form>
                        <div>
                          <Row className="mt-3">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">Sector</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="sector"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option>Choose</option>
                                {sector.map((sec) => {
                                  return (
                                    <option value={`${sec.id}`}>
                                      {sec.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">Industry</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="industry"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option>Choose</option>
                                {industry.map((ind) => {
                                  return (
                                    <option value={`${ind.id}`}>
                                      {ind.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">Price</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="price"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option>Choose</option>
                                {price.map((prc) => {
                                  return (
                                    <option value={`${prc.id}`}>
                                      {prc.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">Market Cap</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="marketcap"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option>Choose</option>
                                {marketcap.map((mCap) => {
                                  return (
                                    <option value={`${mCap.id}`}>
                                      {mCap.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">Volume</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="volume"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option>Choose</option>
                                {volume.map((vol) => {
                                  return (
                                    <option value={`${vol.id}`}>
                                      {vol.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">Divident</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="dividend"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option>Choose</option>
                                {divident.map((div) => {
                                  return (
                                    <option value={`${div.id}`}>
                                      {div.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">Earning Date</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="earningdate"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option>Choose</option>
                                {earningdate.map((ear) => {
                                  return (
                                    <option value={`${ear.id}`}>
                                      {ear.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={6} className="align-self-center mt-3">
                              <h3 className="bold_15_23">Financial Filters</h3>
                            </Col>
                            <Col
                              sm={6}
                              className="text-right align-self-center"
                            ></Col>
                          </Row>
                          <Row className="mt-3">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">P/E</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="pe"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {pe.map((pagerear) => {
                                  return (
                                    <option value={`${pagerear.id}`}>
                                      {pagerear.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">P/S</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="ps"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {ps.map((psvalue) => {
                                  return (
                                    <option value={`${psvalue.id}`}>
                                      {psvalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">P/B (mrq)</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="pb"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {ps.map((pbvalue) => {
                                  return (
                                    <option value={`${pbvalue.id}`}>
                                      {pbvalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">P/FCF (TTM)</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="pfcf"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {pfcf.map((pfcfvalue) => {
                                  return (
                                    <option value={`${pfcfvalue.id}`}>
                                      {pfcfvalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">P/C (mrq)</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="pc"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {pc.map((pcvalue) => {
                                  return (
                                    <option value={`${pcvalue.id}`}>
                                      {pcvalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">PEG (TTM)</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="peg"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {peg.map((pegvalue) => {
                                  return (
                                    <option value={`${pegvalue.id}`}>
                                      {pegvalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">
                                EPS growth Past Year
                              </p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="epsgrowthpastyear"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {epspastyear.map((pastyear) => {
                                  return (
                                    <option value={`${pastyear.id}`}>
                                      {pastyear.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">
                                EPS growth Past 5 Years
                              </p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="epsgrowthpast5years"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {epspastfiveyear.map((pastfiveyear) => {
                                  return (
                                    <option value={`${pastfiveyear.id}`}>
                                      {pastfiveyear.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">
                                Sales growth Past 5 Years{" "}
                              </p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="salesgrowthpast5years"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {salespastfiveyear.map((sales) => {
                                  return (
                                    <option value={`${sales.id}`}>
                                      {sales.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">ROA (TTM)</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="roa"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {roa.map((roavalue) => {
                                  return (
                                    <option value={`${roavalue.id}`}>
                                      {roavalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">ROE (TTM)</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="roe"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {roe.map((roevalue) => {
                                  return (
                                    <option value={`${roevalue.id}`}>
                                      {roevalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">ROI </p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="roi"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {roi.map((roivalue) => {
                                  return (
                                    <option value={`${roivalue.id}`}>
                                      {roivalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">
                                Current Ratio (mrq)
                              </p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="currentratio"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {currentratio.map((currentvalue) => {
                                  return (
                                    <option value={`${currentvalue.id}`}>
                                      {currentvalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">Quick Ratio (mrq)</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="quickratio"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {quickratio.map((quickvalue) => {
                                  return (
                                    <option value={`${quickvalue.id}`}>
                                      {quickvalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">Lt Debt/Eq (mrq)</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="ltdebteq"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {ltdebt.map((debtvalue) => {
                                  return (
                                    <option value={`${debtvalue.id}`}>
                                      {debtvalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">Debt/Eq (mrq)</p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="debteq"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {debteq.map((eqvalue) => {
                                  return (
                                    <option value={`${eqvalue.id}`}>
                                      {eqvalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">
                                Gross Margin (TTM)
                              </p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="grossmargin"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {grossmargin.map((grossvalue) => {
                                  return (
                                    <option value={`${grossvalue.id}`}>
                                      {grossvalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">
                                Operating Margin (TTM)
                              </p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="operatingmargin"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {operatingmargin.map((operatingvalue) => {
                                  return (
                                    <option value={`${operatingvalue.id}`}>
                                      {operatingvalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={2} className="pr-0 align-self-center">
                              <p className="regular_15_97">
                                Net Profit Margin (TTM)
                              </p>
                            </Col>
                            <Col sm={4} className="pl-0 align-self-center">
                              <select
                                name="netprofitmargin"
                                className="form-select pop-clr-act"
                                aria-label="Default select example"
                                onChange={handleChange}
                              >
                                <option selected>Choose</option>
                                {netprofilemargin.map((marjinvalue) => {
                                  return (
                                    <option value={`${marjinvalue.id}`}>
                                      {marjinvalue.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={12} className="align-self-center">
                              <a href="" className="bold_15_d6">
                                <BiPlus></BiPlus> Add Another Filter
                              </a>
                              <a href="" className="bold_15_76 ml-3">
                                <FiMinus></FiMinus> Remove All Filters
                              </a>
                            </Col>
                          </Row>
                          <div className="mt-4 pb-3 pl-3">
                            <Link
                              to="/Screener"
                              className="btn-create mr-2"
                              onClick={getstocklists}
                            >
                              Screen for Stocks
                            </Link>
                            <button className="btn_save mr-2" type="submit">
                              Save Screener
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  />
                  <div className="">
                    <Row className="">
                      <Col sm={6} className="align-self-center">
                        <p className="regular_15_97 mb-0">
                          Show Saved Screeners
                        </p>
                      </Col>
                      <Col sm={6} className="align-self-center text-right">
                        <div>
                          <a href="" className="bold_15_76">
                            Back
                          </a>
                        </div>
                      </Col>
                    </Row>

                    {screenerlistview.data.map((typeinits: any) => {
                      return (
                        <div className="show_shave mt-2">
                          <h3 className="bold_15_2f">Screener 1</h3>
                          <Row className="">
                            <Col sm={6} className="align-self-center">
                              <p className="regular_15_2f mb-1">
                                Date Saved:
                                <span className="bold_15_2f">
                                  {typeinits.last_page_url}
                                </span>
                              </p>
                              <div className="">
                                <a href="" className="bold_15_76">
                                  Edit
                                </a>
                                <a href="" className="bold_15_d6 pl-3">
                                  Delete
                                </a>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                    <div className="show_shave mt-3">
                      <h3 className="bold_15_2f">Screener 1</h3>
                      <Row className="">
                        <Col sm={6} className="align-self-center">
                          <p className="regular_15_2f mb-1">
                            Date Saved:{" "}
                            <span className="bold_15_2f"> 18.06.2021</span>
                          </p>
                          <div className="">
                            <a href="" className="bold_15_76">
                              Edit
                            </a>
                            <a
                              className="bold_15_d6 pl-3"
                              onClick={() => {
                                screenerdeleteing("1");
                              }}
                            >
                              {" "}
                              Delete{" "}
                            </a>
                          </div>
                        </Col>
                        <Col sm={6} className="align-self-center">
                          <div className="text-right">
                            <a className="btn-edit pl-4 pr-4">Apply</a>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {/*   <div className="show_shave mt-2">
                  <h3 className="bold_15_2f">Screener 1</h3>
                    <Row className="">
                      <Col sm={6} className="align-self-center">
                          <p className="regular_15_2f mb-1">Date Saved: <span className="bold_15_2f"> {screenerlistget.data.current_page}</span></p>
                          <div className="">
                            <a href="" className="bold_15_76">Edit</a>
                            <a href="" className="bold_15_d6 pl-3">Delete</a>
                          </div>
                      </Col>
                      <Col sm={6} className="align-self-center">
                        <div className="text-right">
                        <a className="btn-edit pl-4 pr-4">Apply</a>
                        </div>  
                      </Col>
                    </Row>
                  </div>
                  
                    
                      
                  
                  
                       
                        <Col sm={6} className="align-self-center">
                          <p className="regular_15_2f mb-1">Date Saved: <span className="bold_15_2f"> 18.06.2021</span></p>
                          <div className="">
                            <a href="" className="bold_15_76">Edit</a>
                            <a href="" className="bold_15_d6 pl-3">Delete</a>
                          </div>
                      </Col>
                      <Col sm={6} className="align-self-center">
                        <div className="text-right">
                        <a className="btn-edit pl-4 pr-4">Apply</a>
                        </div>  
                      </Col>
                    </Row>
                  </div>
                  <div className="show_shave mt-2">
                  <h3 className="bold_15_2f">Screener 1</h3>
                    <Row className="">
                      <Col sm={6} className="align-self-center">
                          <p className="regular_15_2f mb-1">Date Saved: <span className="bold_15_2f"> 18.06.2021</span></p>
                          <div className="">
                            <a href="" className="bold_15_76">Edit</a>
                            <a href="" className="bold_15_d6 pl-3">Delete</a>
                          </div>
                      </Col>
                      <Col sm={6} className="align-self-center">
                        <div className="text-right">
                        <a className="btn-edit pl-4 pr-4">Apply</a>
                        </div>  
                      </Col> */}
                  </div>
                </div>
                {/*<p className="mb-2 color-97 regular-15">Region</p>
                    <label className="pop-clr-gr mb-3">United Status</label>
                    <p className="mb-2 color-97 regular-15">Market Cap (Intraday)</p>
                    {marketcap.map((sectors:any, index:any) => { 
                      return (<label key={index} htmlFor="formBasicCheckbox" className="pop-clr-act mr-2" onChange={() => handleMarketcap(index)}>
                                <input type="checkbox" value={sectors.id} defaultChecked={sectors.isChecked}  /> {sectors.value}  
                              </label>);
                        })} 
              
                   <p className="mb-2 color-97 regular-15">Sector</p>
                      {sector.map((market:any, index:any) => { 
                      return (<label key={index} htmlFor="formBasicCheckbox" className="pop-clr-act mr-2" onChange={() => handleSector(index)}>
                              <input type="checkbox" value={market.id} defaultChecked={market.isChecked} /> {market.value}  
                            </label>
                        );
                      })} */}
              </div>
            </div>
          )}
        </div>

        <table className="table dash-table scrolltable">
          <thead>
            <tr className="gradent-black">
              <th style={{ width: "70%" }}>Company</th>
              <th>Symbol</th>
            </tr>
          </thead>
          <tbody className="scroll market-scroll pointer">
            {getsearchmarket.map((market: any, index: any) => {
              return (
                <tr
                  key={index}
                  className={`${market.symbol == stockId ? "active" : ""}`}
                  onClick={() => {
                    SetSymbol(market.symbol);
                    setStockId(null);
                    setTimeout(()=>{
                      setStockId(market.symbol);
                    },500);
                    setquote(market.symbol);
                  }}
                >
                  <td width="70%">
                    <div className="row align-items-center">
                      <div className="col-sm-3 col-3">
                        <img
                          className="img-fluid"
                          width="30px"
                          src="/assets/images/3862.png"
                        />
                      </div>
                      <div className="col-sm-9 col-9 pl-0">
                        <p className="bold-16 m-0">{market.name}</p>
                      </div>
                    </div>
                  </td>
                  <td>{market.symbol}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isRegisterSuccess: state.user.isRegisterSuccess,
    stockList: state.stock.stockList,
    stockProfile: state.stock.stockProfile,
    datahistorical: state.stock.datahistorical,
    getsearchmarket: state.market.getsearchmarket,
    screenerget: state.portfolio.screenerget,
    screenerlistview: state.portfolio.screenerlistview,
  }),
  mapDispatchToProps: {
    getStockList,
    getmonthly,
    getStockProfile,
    marketsearch,
    screenerselect,
    addscreenerlist,
    screenerdeleteing,
    setvaluationSuccess
  },
  component: MyPortfolio,
});
