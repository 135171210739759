import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../App.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rangeslider/lib/index.css";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { FaTwitter } from "react-icons/fa";
import { getcashflow } from "../../data/market/market.actions";
import { AnyMessageParams } from "yup/lib/types";
import { RiArrowDownSLine } from "react-icons/ri";
import moment from "moment";
import NumberFormat from "react-number-format";
import ReactGA from "react-ga4";

interface OwnProps {
  stockId: any;
  cashflow: any;
  reportType: any;
  profileget: any;
}

interface StateProps {}

interface DispatchProps {
  getcashflow: typeof getcashflow;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface CashflowstatementProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const Cashflowstatement: React.FC<CashflowstatementProps> = ({
  stockId,
  getcashflow,
  cashflow,
  reportType,
  profileget,
}) => {
  const numberFormatMillon = (num: any) => {
    return +(num / 1000000).toFixed(2) + "";
  };
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  useEffect(() => {
    console.log(reportType, "reportType");
  }, [reportType]);
  return (
    <>
      <div className="outer outer_x">
        <div className="inner">
          <table className="table  grad_table table_harizontal">
            <tbody className="scroll market-scroll pr-0 pb-1">
              <tr className="clr">
                <th>Year</th>
                {cashflow.map((flow: any) => {
                  return (
                    <td>
                      {reportType == "annual"
                        ? "FY " + moment(flow.date).format("YYYY")
                        : flow.period + " " + moment(flow.date).format("YYYY")}
                    </td>
                  );
                })}
              </tr>

              <tr className="clr">
                <th>Operating Cash Flow</th>
                {cashflow.map((dep: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(
                            dep.netCashProvidedByOperatingActivities
                          )
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />{" "}
                    </td>
                  );
                })}
              </tr>

              <tr className="clr">
                <th>Investing Cash Flow</th>
                {cashflow.map((dep: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(
                            dep.netCashUsedForInvestingActivites
                          )
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>
              <tr className="clr">
                <th>Financing Cash Flow</th>
                {cashflow.map((dep: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(
                            dep.netCashUsedProvidedByFinancingActivities
                          )
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>
              <tr className="clr">
                <th><i>Effect Of Forex Changes On Cash</i></th>
                {cashflow.map((dep: any) => {
                  return (
                    <td><i>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(dep.effectOfForexChangesOnCash)
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      /></i>
                    </td>
                  );
                })}
              </tr>
              <tr className="clr">
                <th>Changes in Cash</th>
                {cashflow.map((dep: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(dep.netChangeInCash)
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>
              <tr className="clr">
                <th>Free Cash Flow</th>
                {cashflow.map((dep: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(dep.freeCashFlow)
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
{
  /*
 {getquotereturn.dayLow}
 {keymetrics.dividendYield} */
}
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { getcashflow },
  component: Cashflowstatement,
});
