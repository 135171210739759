import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Spinner,
  Carousel,
} from "react-bootstrap";
import { FaRegEye, FaRegUser, FaLinkedin } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import "./App.css";
import ReactGA from "react-ga4";
import { connect } from "./data/connect";
import * as selectors from "./data/selectors";
import { reset } from "./data/user/user.actions";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
interface OwnProps extends RouteComponentProps {}

interface StateProps {
  resetpasswordreload: boolean;
}

interface DispatchProps {
  reset: typeof reset;
}

type ResetProps = OwnProps & StateProps & DispatchProps;

const Aboutus: React.FC<ResetProps> = ({
  reset,
  resetpasswordreload,
  history,
  
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const ResetSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmpassword: Yup.string().when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const breakpoint = {
    // Small screen / phone
    sm: 576,
    // Medium screen / tablet
    md: 768,
    // Large screen / desktop
    lg: 992,
    // Extra large screen / wide desktop
    xl: 1200,
  };
  useEffect(() => {
    if (resetpasswordreload) history.push(`${"/Login"}`);
    return function cleanup() {};
  }, [resetpasswordreload]);
  
  var settings = {
    mobileFirst: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Formik
        initialValues={{
          password: "",
          confirmpassword: "",
        }}
        validationSchema={ResetSchema}
        onSubmit={(
          { password, confirmpassword },
          { setStatus, setSubmitting }
        ) => {
          console.log(setSubmitting, "setSubmitting");
          reset(password, confirmpassword);
        }}
        render={({ errors, status, touched, isSubmitting }) => (
          <div className="">
            <div className="login-back">
              <Header />
              <Container fluid className="about_101">
                <Row className="justify-content-center">
                  <Col sm={12} xl={6} className="align-self-top">
                    <div className="dash-table-div p-3 mh_25">
                      <Row className="justify-content-center">
                        <Col sm={3} className="align-self-center">
                          <img
                            src="/assets/images/icon.005ba8da.png"
                            className="w-100"
                          />
                        </Col>
                        <Col sm={9} className="align-self-center">
                          <h3 className="bold_12_76">About Mystockpitch</h3>
                          <h3 className="bold_20">
                            Search <MdKeyboardArrowRight></MdKeyboardArrowRight>{" "}
                            Pitch <MdKeyboardArrowRight></MdKeyboardArrowRight>{" "}
                            Track
                          </h3>
                          <p className="regular_14_a2">
                            API powered mystockpitch.com let's you search, pitch
                            (value) and track stocks listed on NYSE and NASDAQ
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col sm={6} xl={3} className="align-self-top">
                    <div className="dash-table-div p-3">
                      <h3 className="bold_12_76 mb-0">Our Objectives</h3>
                    </div>
                    <div className="dash-table-div p-3 about_ul mh_20">
                      <ul className="mb-0 pl-3">
                        <li>
                          Encourage the use of a private equity approach to
                          public markets conceptually
                        </li>
                        <li>
                          Raise awareness on the concept of compounding of
                          capital
                        </li>
                        <li>
                          Create awareness on the concept of giving to noble
                          causes
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col sm={6} xl={3} className="align-self-top">
                    <div className="dash-table-div p-3">
                      <h3 className="bold_12_76 mb-0">Our Principles</h3>
                    </div>
                    <div className="dash-table-div p-3 about_ul mh_20">
                      <ul className="mb-0 pl-3">
                        <li>Work to Give</li>
                        <li>Keep to Your Word</li>
                        <li>Act Local, Think Global</li>
                        <li>Strategic Partnerships for Expansion</li>
                        <li>Advance with Stability</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col sm={12} className="align-self-center">
                    <div className="dash-table-div text-center p-3">
                      <h3 className="mb-0 bold_15">MEET THE TEAM</h3>
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-center mt-4 mb-4">
                  <Col
                    sm={11}
                    className="align-self-center custom_slider text-white"
                  >
                    <Slider {...settings}>
                      <div>
                        <img
                          className="profile_slide"
                          src="/assets/images/picture.png"
                          alt="First slide"
                        />
                        <h4 className="bold_14_76 mt-2">Ravi Koghar</h4>
                        <p className="regular_14_ff">Co-Founder</p>
                        <p className="regular_12">
                          Ravi holds a BBA with a concentration in Finance from
                          Mahidol University (International College) and an MSc
                          in Real Estate Investment from Bayes Business School
                          (Formerly Cass Business School). He previously worked
                          at both Kotak Mahindra (UK) Limited (Singapore Branch)
                          and Keppel Capital. Ravi believes in taking a private
                          equity investment approach to public markets.
                        </p>
                      </div>
                      <div>
                        <img
                          className="profile_slide"
                          src="/assets/images/picture_2.png"
                          alt="First slide"
                        />
                        <h4 className="bold_14_76 mt-2">Antoine Vulcain</h4>
                        <p className="regular_14_ff">Chief Data Officer</p>
                        <p className="regular_12">
                          Antoine attained a Masters Degree in Finance from
                          Concordia University. He is passionate about finance
                          and excellent at handling data. He founded Financial
                          Modelling Prep, a well reputed API endpoint provider,
                          in early 2016. He is savvy with Node.js, PHP, Lavarel,
                          JAVA, Angular and more.
                        </p>
                      </div>
                      <div>
                        <img
                          className="profile_slide"
                          src="/assets/images/picture_2.png"
                          alt="First slide"
                        />
                        <h4 className="bold_14_76 mt-2">Arunraj Rajendran</h4>
                        <p className="regular_14_ff">Tech Mentor</p>
                        <p className="regular_12">
                          Arun completed both, a Bachelor Degree in Engineering
                          from Anna University and an MBA from the University of
                          Wales, Cardiff (UK). Arun is the current Chief
                          Information Officer of Supara Group and the former
                          Head of IT and Digital Operations at JCDecaux.
                        </p>
                      </div>
                      <div>
                        <img
                          className="profile_slide"
                          src="/assets/images/picture_2.png"
                          alt="First slide"
                        />
                        <h4 className="bold_14_76 mt-2">Saju Moni</h4>
                        <p className="regular_14_ff">Software Engineer</p>
                        <p className="regular_12">
                          Saju completed an MBA with a focus on Information
                          Management Systems from Annamalai University, India.
                          Saju is a seasoned software engineer, he is skilled in
                          RTB, REST, SEO, React Native, PHP, ReactJs and more.
                        </p>
                      </div>
                      <div>
                        <img
                          className="profile_slide"
                          src="/assets/images/picture_2.png"
                          alt="First slide"
                        />
                        <h4 className="bold_14_76 mt-2">Akshit Sharma</h4>
                        <p className="regular_14_ff">Project Enabler</p>
                        <p className="regular_12">
                          Akshit is an experienced business development
                          executive with a demonstrated history of working in
                          the marketing and advertising industry. Akshit
                          completed a Post Graduate Diploma in marketing from
                          FORE School of Management, New Dehli. He is currently
                          the Head of Business Development for Solstium (India),
                          a digital transformation company.
                        </p>
                      </div>
                      <div>
                        <img
                          className="profile_slide"
                          src="/assets/images/picture_2.png"
                          alt="First slide"
                        />
                        <h4 className="bold_14_76 mt-2">Sounak Sikdar</h4>
                        <p className="regular_14_ff">Lead Designer</p>
                        <p className="regular_12">
                          Sounak is a highly accomplished user experience
                          designer with a knack for designing experiences for
                          digital products that solve real-world problems. He is
                          particularly passionate about social impact driven
                          projects.
                        </p>
                      </div>
                    </Slider>
                  </Col>
                </Row>
              </Container>
            </div>
            <Footer />
          </div>
        )}
      />
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    schedule: selectors.getSearchedSchedule(state),
    favoritesSchedule: selectors.getGroupedFavorites(state),
    resetpasswordreload: state.user.resetpasswordreload,
  }),
  mapDispatchToProps: {
    reset,
  },
  component: Aboutus,
});
