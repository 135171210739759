
import React, { useState } from 'react';

import { Button, Container, Toast, Row, Col,Modal,Spinner,Field} from 'react-bootstrap';

import { TiCameraOutline } from "react-icons/ti"; 
import { HiOutlineMail } from "react-icons/hi"; 
import { FiLock } from "react-icons/fi";
import { FaRegEye,FaRegUser } from "react-icons/fa";
import { stringify } from 'querystring';
import { CSVLink, CSVDownload } from "react-csv";
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import moment from "moment";
import Accordion from 'react-bootstrap/Accordion';

import Form from 'react-bootstrap/Form';

import Card from 'react-bootstrap/Card';
import  { Redirect } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from 'react-dom';
import Header from './header';
import Footer from './footer';
import FileBase64 from 'react-file-base64';
import { IoIosNotificationsOutline, IoIosHelpCircle,IoMdCheckmark,IoIosCloseCircleOutline,IoIosArchive,IoIosBackspace,IoMdAdd, IoMdExit, IoIosHome,IoIosSwap,IoIosAdd,IoIosShare,IoIosArrowForward,IoIosArrowBack,IoIosCloudUpload,IoMdNotifications} from "react-icons/io";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTrue } from 'typescript';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
const config = { "apiUrl": "http://api.laacreators.com/" };

  class Market_details extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
     
      
    }; 
}
render(){
  return (
  <div className="">
    <div className="login-back vh-100">
    <Header />
        <Container fluid className="pb-5">
        <Row className="justify-content-center">
          <Col sm={5} className="">
          <div className="min-radius-7 bg-white p-4 relativec">
              <p className="bold-15 pt-3 color-00">Pitch Now</p>
                <img className="img-fluid grg-img" src="/assets/images/Group 294.png" />
                <Row className="pitch-para">
                  <Col sm={6} xs={6} className="color-97">
                  <p className="regular-15">Purchase Price:</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right color-97">
                  <p className="bold-15">$24.69</p>
                  </Col>
                </Row>
                <Row className="pitch-para">
                  <Col sm={6} xs={6} className="color-97">
                  <p className="regular-15">Purchase Price:</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right color-97">
                  <p className="bold-15">$24.69</p>
                  </Col>
                </Row>
                <Row className="pitch-para">
                  <Col sm={6} xs={6} className="color-97">
                  <p className="regular-15">Purchase Price:</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right color-97">
                  <p className="bold-15">$24.69</p>
                  </Col>
                </Row>
                <Row className="pitch-para">
                  <Col sm={6} xs={6} className="color-97">
                  <p className="regular-15">Purchase Price:</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right color-97">
                  <p className="bold-15">$24.69</p>
                  </Col>
                </Row>
                <Row className="pitch-para">
                  <Col sm={6} xs={6} className="color-97">
                  <p className="regular-15">Purchase Price:</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right color-97">
                    <p className="bold-15">$24.69</p>
                  </Col>
                </Row>
                  
                <div className="pt-2 pb-1">
                  <a href="" className="btn-buy mr-3 bold-15">Buy</a> 
                  <a href="" className="btn-later mr-3 bold-15">Save for Later</a> 
                  <a href="" className="text-red bold-15">Ignore</a> 
                </div>
          </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={3} className="text-center mt-4">
            <div className="min-radius-7 dash-table-div p-2">
              <p className="m-0 one-two">We are gathering the information, please wait...</p>
            </div>
          </Col>
        </Row>
        </Container>
        </div>
        <Footer />
    </div>
  );
}
};

export default Market_details;
