import { TokenClass } from "typescript";
import { config } from "../_config/config";
import { authHeader, handleResponse } from "../_helpers";

export const UserService = {
  register,
  login,
  otpCheck,
  forgetpassword,
  resetpassword,
  passwordOtpCheck,
  forget,
  reset,
  getprofile,
  updateprofile,
  updateimage,
  deleteuser,
  verifyUser,
  passwordReset,
  resetreset,
};

function register(user: any) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}api/register`,requestOptions).then(
    handleResponse
  );
}

function login(email: string, password: string) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      password: password,
      client_id: "3",
      client_secret: "u6GBMt1oVIj2jtZzZc4YHLlwEsM84wzTvAxfXjId",
    }),
  };

  return fetch(`${config.apiUrl}api/login`, requestOptions).then(
    handleResponse
  );
}

function otpCheck(username: any, otp: string) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username: username, code: otp }),
  };

  return fetch(`${config.apiUrl}login/loginotpcheck`, requestOptions).then(
    handleResponse
  );
}

function forgetpassword(username: any) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username: username }),
  };
  return fetch(`${config.apiUrl}login/forgetpassword`, requestOptions).then(
    handleResponse
  );
}

function resetpassword(email: any, newpassword: any, confirmpassword: any) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      newpassword: newpassword,
      confirmpassword: confirmpassword,
    }),
  };

  return fetch(`${config.apiUrl}login/reset_password`, requestOptions).then(
    handleResponse
  );
}

function passwordOtpCheck(username: any, code: any) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username: username, code: code }),
  };

  return fetch(`${config.apiUrl}login/passwordOtpCheck`, requestOptions).then(
    handleResponse
  );
}
function forget(email: any) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(email),
  };

  return fetch(`${config.apiUrl}api/forgot-password`, requestOptions).then(
    handleResponse
  );
}
function reset(password: any, confirmpassword: any) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      password: password,
      password_confirmation: confirmpassword,
    }),
  };

  return fetch(`${config.apiUrl}api/password,reset`, requestOptions).then(
    handleResponse
  );
}
function getprofile() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}api/user`, requestOptions).then(handleResponse);
}
function updateprofile(name: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ name: name }),
  };

  return fetch(`${config.apiUrl}api/updateProfile`, requestOptions).then(
    handleResponse
  );
}

function updateimage(profileimage: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ profile_image: profileimage }),
  };

  return fetch(`${config.apiUrl}api/updateProfileimage`, requestOptions).then(
    handleResponse
  );
}
function deleteuser() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    // body: JSON.stringify({ } )
  };
  return fetch(`${config.apiUrl}api/deleteAccount`, requestOptions).then(
    handleResponse
  );
}
function verifyUser(token: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    // body: JSON.stringify({ } )
  };
  return fetch(
    `${config.apiUrl}api/verify_account/${token}`,
    requestOptions
  ).then(handleResponse);
}
function passwordReset(user:any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(user),
  };
  return fetch(`${config.apiUrl}api/update_password`, requestOptions).then(
    handleResponse
    );
  }

  
    function resetreset(token:any,email:any,password: any, confirmpassword: any) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token:token,
          email:email,
          password: password,
          password_confirmation: confirmpassword,
        }),
      };
    
      return fetch(`${config.apiUrl}api/password/reset`, requestOptions).then(
        handleResponse
      );
    }


