import { GlobalActions } from './global.actions';
import { GlobalState } from './global.state';

export function globalReducer(state: GlobalState, action: GlobalActions): GlobalState {
  switch (action.type) {
    case 'show-loading':
      return { ...state, showLoading: action.showLoading };
    case 'show-toast':
      return { ...state, showToast: action.toast };
      case 'show-Drawer':
        return { ...state, showDrawer: action.showDrawer };
  
  }
}