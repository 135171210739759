import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import Slider from "react-rangeslider";
import { BiFilterAlt, BiBookmark } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";

import { RiCloseFill } from "react-icons/ri";
import "../../App.css";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rangeslider/lib/index.css";

import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { getStockProfile } from "../../data/stock/stock.actions";
import moment from "moment";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import NumberFormat from "react-number-format";
import { stockperportfolio } from "../../data/portfolio/portfolio.actions";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  getmonthly,
  getweekly,
  getdaily,
} from "../../data/portfolio/portfolio.actions";

interface OwnProps {
  stockId: any;
  portId: any;
}

interface StateProps {
  stockProfile: any;
  portfolioStocks: any;
  stockhistorical: any;
}

interface DispatchProps {
  getStockProfile: typeof getStockProfile;
  stockperportfolio: typeof stockperportfolio;
  getmonthly: typeof getmonthly;
  getweekly: typeof getweekly;
  getdaily: typeof getdaily;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface MyPortfolioProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const PortfolioStock: React.FC<MyPortfolioProps> = ({
  getdaily,
  getweekly,
  getmonthly,
  stockhistorical,
  getStockProfile,
  stockProfile,
  portfolioStocks,
  portId,
  stockperportfolio,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [newPortfolio, setNewPortfolio] = useMergeState({});
  const [stockId, setStockId] = useState("");
  const [portfolioId, setPortfolioId] = useState();
  const [reportType, setReportType] = useState("weekly");
  const [historicalData, setHistoricalData] = useState<any>([]);

  const m = moment();

  const portfolioSchema = Yup.object().shape({
    portfolio_name: Yup.string().required("Portfolio Name is required"),
  });
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  useEffect(() => {
    if (portfolioStocks.length > 0) {
      setStockId(portfolioStocks[0]);
    }
  }, [portfolioStocks]);

  useEffect(() => {
    if (stockId) {
      getStockProfile(stockId);
      switch (reportType) {
        case "daily":
          getdaily(stockId);
          break;
        case "weekly":
          getweekly(stockId);
          break;
        case "monthly":
          getmonthly(stockId, "yearly", 7);
          break;
      }
    }
  }, [stockId, reportType]);

  useEffect(() => {
    //console.log(stockhistorical,'stockhistorical');
    let hisData: any = [];

    if (stockhistorical["Monthly Time Series"]) {
      Object.keys(stockhistorical["Monthly Time Series"]).forEach(function (
        index: any
      ) {
        hisData.unshift({
          date: index,
          close: parseFloat(
            stockhistorical["Monthly Time Series"][index]["4. close"]
          ),
        });
      });
      setHistoricalData(hisData);
    }
  }, [stockhistorical]);

  useEffect(() => {
    stockperportfolio(portId);
  }, [portId]);

  return (
    <>
      <div className="dash-table-div p-3 mb-sm-0 mb-4">
        <Row className="row justify-content-center">
          <Col sm={6}>
            <span className="regular-14 color-97">
              Stocks added in portfolio
            </span>
            <p className="regular-15 green-text">{portfolioStocks.length}</p>
          </Col>
          <Col sm={6}>
            <span className="green-text">+ Add More Stocks</span>
          </Col>
          <Col sm={12} className="">
            <div className="form-group">
              <select
                className="form-control"
                id="sel1"
                onChange={(e) => setStockId(e.target.value)}
              >
                {portfolioStocks.map((stock: any, index: any) => {
                  return <option value={stock}>{stock}</option>;
                })}
              </select>
            </div>
            <ul className="mb-0 tab-ul">
              <li>
                <a
                  className={reportType == "daily" ? "active" : ""}
                  onClick={() => setReportType("daily")}
                >
                  Daily
                </a>
              </li>
              <li>
                <a
                  className={reportType == "weekly" ? "active" : ""}
                  onClick={() => setReportType("weekly")}
                >
                  Weekly
                </a>
              </li>
              <li>
                <a
                  className={reportType == "monthly" ? "active" : ""}
                  onClick={() => setReportType("monthly")}
                >
                  Monthly
                </a>
              </li>
            </ul>
            <ResponsiveContainer width="100%" height="300px">
              <AreaChart
                width={400}
                height={400}
                data={historicalData}
                margin={{
                  top: 10,
                  right: 20,
                  left: -35,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip label="date" />
                <Area
                  type="monotone"
                  dataKey="close"
                  stroke="#00CF76"
                  fill="#00CF76"
                />
              </AreaChart>
            </ResponsiveContainer>
            <Row className="mt-3">
              <Col sm={4}>
                <Row className="pb-2">
                  <Col sm={6} xs={6}>
                    <p className="bold-16 color-97 mt-0 mb-0">Open</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right">
                    <p className="bold-16 mt-0 mb-0">223.60</p>
                  </Col>
                </Row>
                <Row className="pb-2">
                  <Col sm={6} xs={6}>
                    <p className="bold-16 color-97 mt-0 mb-0">High</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right">
                    <p className="bold-16 mt-0 mb-0">223.60</p>
                  </Col>
                </Row>
                <Row className="pb-2">
                  <Col sm={6} xs={6}>
                    <p className="bold-16 color-97 mt-0 mb-0">Low</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right">
                    <p className="bold-16 mt-0 mb-0">223.60</p>
                  </Col>
                </Row>
              </Col>
              <Col sm={4}>
                <Row className="pb-2">
                  <Col sm={6} xs={6} className="pl-sm-0 pr-sm-0">
                    <p className="bold-16 color-97 mt-0 mb-0">Mkt cap</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right">
                    <p className="bold-16 mt-0 mb-0">223.60</p>
                  </Col>
                </Row>
                <Row className="pb-2">
                  <Col sm={6} xs={6} className="pl-sm-0 pr-sm-0">
                    <p className="bold-16 color-97 mt-0 mb-0">P/E ratio</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right">
                    <p className="bold-16 mt-0 mb-0">223.60</p>
                  </Col>
                </Row>
                <Row className="pb-2">
                  <Col sm={6} xs={6} className="pl-sm-0 pr-sm-0">
                    <p className="bold-16 color-97 mt-0 mb-0">Low</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right">
                    <p className="bold-16 mt-0 mb-0">-</p>
                  </Col>
                </Row>
              </Col>
              <Col sm={4}>
                <Row className="pb-2">
                  <Col sm={6} xs={6}>
                    <p className="bold-16 color-97 mt-0 mb-0">Open</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right">
                    <p className="bold-16 mt-0 mb-0">223.60</p>
                  </Col>
                </Row>
                <Row className="pb-2">
                  <Col sm={6} xs={6}>
                    <p className="bold-16 color-97 mt-0 mb-0">High</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right"></Col>
                </Row>
                <Row className="pb-2">
                  <Col sm={6} xs={6}>
                    <p className="bold-16 color-97 mt-0 mb-0">Low</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right"></Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    stockProfile: state.stock.stockList,
    portfolioStocks: state.portfolio.portfolioStocks,
    stockhistorical: state.portfolio.stockhistorical,
  }),
  mapDispatchToProps: {
    getStockProfile,
    stockperportfolio,
    getdaily,
    getmonthly,
    getweekly,
  },
  component: PortfolioStock,
});
