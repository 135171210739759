import { WatchlistActions } from './watchlist.actions';
import { WatchlistState } from './watchlist.state';

export function WatchlistReducer(state: WatchlistState, action: WatchlistActions): WatchlistState {
  switch (action.type) {
    case 'show-loading':
      return { ...state, showLoading: action.showLoading };
    case 'show-Drawer':
      return { ...state, showDrawer: action.showDrawer };
    case 'all-watchlist':
      return { ...state, watchlistget: action.getallwatchlist };
      case 'all-removelist':
        return { ...state, setremove: action.removelist };
        case 'all-addwatchlist':
          return { ...state, watchlistadd: action.addwatchlist };
    
  
  }
}