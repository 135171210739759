import { getUserToken } from '../dataApi';
import { useEffect } from 'react';

export const authHeader = () => {
    const userData : any = getUserToken();
    const token : any    = JSON.parse( userData );
    
    if ( token !== null ) { 
        return { 'Authorization': `Bearer ${token.token}`, 'Content-Type': 'application/json' };
    } else {
        return { 'Authorization': `Bearer`, 'Content-Type': 'application/json'};
    }
}

export const handleResponse = (response:any) => {
    return response.text().then((text:any) => {
        const data = text && JSON.parse(text);
       
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
               // window.location.href = '/Logout';
            }
            const error = (data && data.message) || data.errors || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}