import { config } from "../_config/config";
import { authHeader, handleResponse } from "../_helpers";

export const StockService = {
  getStockList,
  getStockProfile,
  getHistoricalData,
  addvaluation,
  getValuationTypes,
  getValuationOutput,
  addPitch,
  getvaluationbyid,
};

function getStockProfile(stock_symbol: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    body: JSON.stringify({ stock_symbol }),
  };
  return fetch(
    `${config.stockapiUrl}api/v3/profile/${stock_symbol}?apikey=e961820783a9fc9c4b575a56564509b8`,
    requestOptions
  ).then(handleResponse);
}

function getStockList(marketcap: any, sector: any, region: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      marketcap: marketcap.join("|"),
      sector: sector.join("|"),
      region: region,
    }),
  };

  return fetch(`${config.apiUrl}api/getStockList`, requestOptions).then(
    handleResponse
  );
}
function getHistoricalData(stocksymbol: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ stock_symbol: stocksymbol }),
  };

  return fetch(`${config.apiUrl}api/getHistoricalData`, requestOptions).then(
    handleResponse
  );
}
function addvaluation(valuation: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(valuation),
  };

  return fetch(`${config.apiUrl}api/addValuation`, requestOptions).then(
    handleResponse
  );
}

function getValuationTypes() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}api/getValuationTypes`, requestOptions).then(
    handleResponse
  );
}

function getValuationOutput(valuation: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(valuation),
  };

  return fetch(`${config.apiUrl}api/getValuationOutput`, requestOptions).then(
    handleResponse
  );
}

function addPitch(pitch: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(pitch),
  };

  return fetch(`${config.apiUrl}api/addPitch`, requestOptions).then(
    handleResponse
  );
}

function getvaluationbyid(vid: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ valuation_id: vid }),
  };
  return fetch(`${config.apiUrl}api/editValuation`, requestOptions).then(
    handleResponse
  );
}
