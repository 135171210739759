import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Col, Container, Row, Modal, Spinner } from "react-bootstrap";
import { FaRegEye, FaRegUser } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import "./App.css";
import { connect } from "./data/connect";
import * as selectors from "./data/selectors";
import { resetresetpassword } from "./data/user/user.actions";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import BarLoader from "react-spinners/ClipLoader";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";

interface OwnProps extends RouteComponentProps<Tparams> {
  resetcode: any;
  showLoading: any;
}

interface StateProps {
  resetuserpassword: boolean;
}

interface DispatchProps {
  resetresetpassword: typeof resetresetpassword;
}
type Tparams = { code: any };
type ResetProps = OwnProps & StateProps & DispatchProps;

const Reset: React.FC<ResetProps> = ({
  resetresetpassword,
  resetuserpassword,
  history,
  showLoading,
  match,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmpasswordShown, setconfirmPasswordShown] = useState(false);
  const [color, setColor] = useState("black");
  const ResetSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmpassword: Yup.string().when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleconfirmPasswordVisiblity = () => {
    setconfirmPasswordShown(confirmpasswordShown ? false : true);
  };
  useEffect(() => {
    if (resetuserpassword) history.push(`${"/ResetSucessmsg"}`);
    return function cleanup() {};
  }, [resetuserpassword]);

  return (
    <div>
      <Formik
        initialValues={{
          token: "",
          password: "",
          confirmpassword: "",
        }}
        validationSchema={ResetSchema}
        onSubmit={(
          { token, password, confirmpassword },
          { setStatus, setSubmitting }
        ) => {
          resetresetpassword(match.params.code, password, confirmpassword);
        }}
        render={({ errors, status, touched, isSubmitting, isValid }) => (
          <Form>
            <div className="login-back gradientlog">
              <Container fluid className="">
                <Row className="justify-content-center">
                  <Col sm={6} className="align-self-center">
                    <div className="">
                      <Row className="justify-content-center vh-100">
                        <Col md={9} lg={8} className="align-self-center">
                          <img
                            className="img-fluid login-img-top"
                            src="/assets/images/Mask Group 9.png"
                          />
                          <div className="pl-sm-5 pr-sm-5 pl-3 pr-3 bg-white log-bg-radius">
                            <div className="text-center">
                              <img
                                className="img-fluid img-small"
                                src="/assets/images/icon.005ba8da.png"
                              />
                            </div>
                            <h2 className="bold-19  text-center pt-3 pb-3">
                              RESET PASSWORD
                            </h2>

                            <div className="form-group">
                              {errors.password && touched.password ? (
                                <div className="errorCont">
                                  {errors.password}
                                </div>
                              ) : null}
                              <div className="input-relative">
                                <FiLock className="same-icons color-97"></FiLock>
                                {(() => {
                                  if (passwordShown == false) {
                                    return (
                                      <AiOutlineEyeInvisible
                                        className="same-icons-rit color-97"
                                        onClick={togglePasswordVisiblity}
                                      ></AiOutlineEyeInvisible>
                                    );
                                  }
                                })()}

                                {(() => {
                                  if (passwordShown == true) {
                                    return (
                                      <FaRegEye
                                        className="same-icons-rit color-97"
                                        onClick={togglePasswordVisiblity}
                                      ></FaRegEye>
                                    );
                                  }
                                })()}
                                <Field
                                  name="password"
                                  placeholder="Enter password"
                                  type={passwordShown ? "text" : "password"}
                                  className="form-control log-input"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              {errors.confirmpassword &&
                              touched.confirmpassword ? (
                                <div className="errorCont">
                                  {errors.confirmpassword}
                                </div>
                              ) : null}
                              <div className="input-relative">
                                <FiLock className="same-icons color-97"></FiLock>
                                {(() => {
                                  if (confirmpasswordShown == false) {
                                    return (
                                      <AiOutlineEyeInvisible
                                        className="same-icons-rit color-97"
                                        onClick={toggleconfirmPasswordVisiblity}
                                      ></AiOutlineEyeInvisible>
                                    );
                                  }
                                })()}

                                {(() => {
                                  if (confirmpasswordShown == true) {
                                    return (
                                      <FaRegEye
                                        className="same-icons-rit color-97"
                                        onClick={toggleconfirmPasswordVisiblity}
                                      ></FaRegEye>
                                    );
                                  }
                                })()}
                                <Field
                                  name="confirmpassword"
                                  placeholder="Confirm password"
                                  type={
                                    confirmpasswordShown ? "text" : "password"
                                  }
                                  className="form-control log-input"
                                />
                              </div>
                            </div>
                            <Row>
                              <Col sm={12} className="form-group mt-3 mb-5">
                                <Button
                                  className="green-ground w-100 btn-login"
                                  type="submit"
                                  disabled={!isValid}
                                >
                                  {" "}
                                  Submit{" "}
                                  <BarLoader
                                    color={color}
                                    loading={showLoading}
                                    size={18}
                                  />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    schedule: selectors.getSearchedSchedule(state),
    favoritesSchedule: selectors.getGroupedFavorites(state),
    resetuserpassword: state.user.resetuserpassword,
    showLoading: state.global.showLoading,
  }),
  mapDispatchToProps: {
    resetresetpassword,
  },
  component: Reset,
});
