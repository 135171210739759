import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { Button, Col, Container, Row, Modal, Spinner } from "react-bootstrap";
import { FaRegEye } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import BarLoader from "react-spinners/ClipLoader";
import "./App.css";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { setIsLoggedIn, setUsername, doLogin } from "./data/user/user.actions";
import { setShowLoading, setShowToast } from "./data/global/global.actions";
import { connect } from "./data/connect";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { stringify } from "querystring";
import { valuationTypes } from "./data/stock/stock.actions";
import { isValid } from "date-fns";

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  isLoggedin: boolean;
  userData: any;
  showLoading: any;
}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  doLogin: typeof doLogin;
  setShowToast: typeof setShowToast;
}

interface LoginProps extends OwnProps, StateProps, DispatchProps {}

const Login: React.FC<LoginProps> = ({
  setIsLoggedIn,
  history,
  doLogin,
  isLoggedin,
  setUsername: setUsernameAction,
  setShowToast,
  showLoading,
  userData,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState<any>({
    email: "",
    password: "",
    isChecked: false,
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [spinnerclose, setspinnerclose] = useState(false);
  const [color, setColor] = useState("black");
  const [isChecked, setisChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checked, setchecked] = useState(isChecked);

  const loginSchema = Yup.object().shape({
    email: Yup.string().required("Please enter an appropriate email address!"),
    password: Yup.string().required("Password is required"),
  });
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(foundUser);
    }
  }, []);

  useEffect(() => {
    if (
      (localStorage.getItem("isChecked"),
      localStorage.getItem("email"),
      localStorage.getItem("password"))
    ) {
      setUser({
        email: localStorage.getItem("email"),
        password: localStorage.getItem("password"),
        isChecked: localStorage.getItem("isChecked"),
      });
    }
  }, []);
  const checkedbox = () => {
    if (isChecked == true) {
      setchecked(true);
    } else {
      setchecked(false);
    }
  };
  useEffect(() => {
    checkedbox();
  }, [isChecked]);

  useEffect(() => {
    if (isLoggedin) {
      setspinnerclose(false);
      history.push("/");
    }
  }, [isLoggedin]);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          email: user.email,
          password: user.password,
          isChecked: user.isChecked,
        }}
        validationSchema={loginSchema}
        onSubmit={(
          { email, password, isChecked },
          { setStatus, setSubmitting }
        ) => {
          if (isChecked) {
            localStorage.setItem("email", email);
            localStorage.setItem("password", password);
            localStorage.setItem("isChecked", "true");
          }
          doLogin(email, password);
          setspinnerclose(true);
          console.log(isChecked, "ddddddddddddddddddddd");
        }}
        render={({
          errors,
          status,
          touched,
          values,
          isSubmitting,
          isValid,
        }) => (
          <Form>
            <div className="login-back login-back-tab vh-100 mb-5 mb-sm-0 gradientlog">
              <Container fluid className="vh-100 tab-top gradientlog-tap">
                <Row className="justify-content-center vh-100">
                  <Col lg={6} md={12} sm={9} className="align-self-center">
                    <div className="mt-2">
                      <Row className="justify-content-center">
                        <Col md={9} lg={8}>
                          <img
                            className="img-fluid login-img-top"
                            src="/assets/images/Mask Group 9.png"
                          />
                          <div className="text-center bg-white pl-sm-5 pr-sm-5 pl-3 pr-3 log-bg-radius">
                            <img
                              className="img-fluid img-small"
                              src="/assets/images/icon.005ba8da.png"
                            />

                            <h2 className="bold-19 text-center py-2">
                              USER LOGIN
                            </h2>
                            <div className="form-group">
                              {errors.email && touched.email ? (
                                <div className="errorCont">{errors.email}</div>
                              ) : null}

                              <div className="input-relative">
                                <HiOutlineMail className="same-icons color-97"></HiOutlineMail>
                                <Field
                                  name="email"
                                  placeholder="Enter your email "
                                  id="email"
                                  type="text"
                                  className={`form-control log-input ${
                                    errors.email && touched.email
                                      ? "errorInput"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              {errors.password && touched.password ? (
                                <div className="errorCont">
                                  {errors.password}
                                </div>
                              ) : null}
                              <div className="input-relative">
                                <FiLock className="same-icons color-97"></FiLock>
                                {(() => {
                                  if (passwordShown == false) {
                                    return (
                                      <AiOutlineEyeInvisible
                                        className="same-icons-rit color-97"
                                        onClick={togglePasswordVisiblity}
                                      ></AiOutlineEyeInvisible>
                                    );
                                  }
                                })()}

                                {(() => {
                                  if (passwordShown == true) {
                                    return (
                                      <FaRegEye
                                        className="same-icons-rit color-97"
                                        onClick={togglePasswordVisiblity}
                                      ></FaRegEye>
                                    );
                                  }
                                })()}
                                <Field
                                  name="password"
                                  placeholder="Enter password"
                                  type={passwordShown ? "text" : "password"}
                                  className="form-control log-input"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <Row>
                                <Col sm={6} xs={6} className="text-left mb-2">
                                  <label
                                    htmlFor="formBasicCheckbox"
                                    className="regular-14 color-97"
                                  >
                                    <Field
                                      type="checkbox"
                                      defaultChecked={values.isChecked}
                                      name="isChecked"
                                      className="mr-1 v_b"
                                    />
                                    Remember Me
                                  </label>
                                </Col>
                                <Col
                                  sm={6}
                                  xs={6}
                                  className=" text-right pl-0 mb-2"
                                >
                                  <Link
                                    to="/forget"
                                    className="green-text regular-14"
                                  >
                                    Forgot Password?
                                  </Link>
                                </Col>
                                {/* <Col sm={12} xs={6} className="text-left">
                                        <label htmlFor="formBasicCheckbox" className="regular-14 color-97">
                                            <Field type="checkbox" id="Check me out" name='check_box' color="primary" className="mr-1 v_b"/>I have read and agree to both, the <a href='/Termsofuse'>Terms of Use </a>and <a href="/Privacypolicy">Privacy Policy</a>
                                        </label>
                                            </Col>*/}
                                <Col sm={12}>
                                  <Button
                                    className="green-ground w-100 btn-login"
                                    type="submit"
                                    disabled={!isValid}
                                  >
                                    {" "}
                                    Login{" "}
                                    <BarLoader
                                      color={color}
                                      loading={showLoading}
                                      size={13}
                                    />
                                  </Button>
                                </Col>

                                <Col
                                  sm={12}
                                  className="center-home form-group  link_signup"
                                >
                                  <p className="text-center regular-14 color-97">
                                    Do not have an account?{" "}
                                    <Link
                                      to="/signup"
                                      className="regular-14 green-text"
                                    >
                                      Create Now
                                    </Link>{" "}
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    isLoggedin: state.user.isLoggedin,
    userData: state.user.userData,
    showLoading: state.global.showLoading,
  }),
  mapDispatchToProps: {
    setIsLoggedIn,
    setUsername,
    doLogin,
    setShowToast,
  },
  component: Login,
});
