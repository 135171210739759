import { config } from "../_config/config";
import { authHeader, handleResponse } from "../_helpers";

export const PitchesService = {
  mypitches,
  buypitch,
  updatepitch,
  deletetrackedpitches,
  trackedpitchupdate,
};
function mypitches() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}api/myValuation`, requestOptions).then(
    handleResponse
  );
}

function buypitch(
  stock_symbol: any,
  portfolio_id: any,
  valuation_type_id: any,
  valuation_id: any,
  valuation_output_id: any
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      stock_symbol,
      portfolio_id,
      valuation_type_id,
      valuation_id,
      valuation_output_id,
    }),
  };
  return fetch(`${config.apiUrl}api/buyPitch`, requestOptions).then(
    handleResponse
  );
} 

function updatepitch(valuationid: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ valuation_id: valuationid }),
  };
  return fetch(`${config.apiUrl}api/updateValuation`, requestOptions).then(
    handleResponse
  );
}

function deletetrackedpitches(valuationid: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ valuation_id: valuationid }),
  };
  return fetch(`${config.apiUrl}api/deletePitch`, requestOptions).then(
    handleResponse
  );
}

function trackedpitchupdate(inputtrackedpitches: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(inputtrackedpitches),
  };

  return fetch(`${config.apiUrl}api/update-pitch`, requestOptions).then(
    handleResponse
  );
}
