import React, { useEffect,useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {Button, Col, Container, Row, Modal,Spinner } from 'react-bootstrap';
import { FaRegEye,FaRegUser } from "react-icons/fa"; 
import { FiLock } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { ToastContainer, toast } from 'react-toastify';
import './App.css';
import { connect } from './data/connect';
import * as selectors from './data/selectors';
import { doRegister } from './data/user/user.actions';
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { isValid } from 'date-fns';
interface OwnProps extends RouteComponentProps {}

interface StateProps {
    isRegisterSuccess: boolean
}

interface DispatchProps {
    doRegister: typeof doRegister;
}

type RegisterProps = OwnProps & StateProps & DispatchProps;

const Signup: React.FC<RegisterProps> = ({doRegister,isRegisterSuccess, history }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [passwordShown, setPasswordShown] = useState(false);
    const [name, setname] = useState('');
    const [confirmpasswordShown, setconfirmPasswordShown] = useState(false);
    const signupSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().required('Please enter an appropriate e-mail address'),
        password: Yup.string().required('Password is required'),
        password_confirmation:Yup.string().when("password", {
            is: (val:any) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              "Both passwords need to be the same"
            )
          }),
          //terms_and_conditions: Yup.boolean().required('You must accept the policy terms and conditions'),
          terms_and_conditions: Yup.boolean()
            .oneOf([true], "You must accept the Terms of Use and Privacy Policy")
    });

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
      };
      const toggleconfirmPasswordVisiblity = () => {
        setconfirmPasswordShown(confirmpasswordShown ? false : true);
      };

    useEffect(() => {
        if( isRegisterSuccess ) history.push(`${'/sucess' }`);
        return function cleanup() {}
      }, [isRegisterSuccess]); 
    
        return (
            <div>
                <Formik
                    initialValues={{
                        name: '',   
                        password: '',
                        email:'',
                        password_confirmation:'',
                        terms_and_conditions: false,
                    }}
                    validationSchema={signupSchema}
                    onSubmit={({ name, password, email ,password_confirmation,terms_and_conditions}, { setStatus, setSubmitting }) => {
                        console.log(setSubmitting,'setSubmitting');
                        doRegister({email,name,password,password_confirmation,terms_and_conditions:"1"});

                    }}
                    render={({ errors, status, touched, isSubmitting ,isValid}) => (
    <Form>
    <div className="login-back login-back-tab gradientlog">
        <Container fluid className=" tab-top gradientlog-tap align-self-center">
            <Row className="justify-content-center vh-100">
                <Col xl={6} md={12} className="align-self-center">
                <div className="mt-2 pb-2">
                    <Row className="justify-content-center">
                <Col md={9} lg={8}>
                <img className="img-fluid img sign-img-top" src="/assets/images/Mask Group 9.png" />
                <div className="pl-sm-5 pr-sm-5 pl-3 pr-3 bg-white log-bg-radius">
                <div className="text-center">
                <img className="img-fluid img-small" src="/assets/images/icon.005ba8da.png" />
                </div>
                <h2 className="bold-19 text-center py-2">CREATE AN ACCOUNT</h2>
                    <div className="form-group">
                        {errors.name && touched.name ? (
                            <div className="errorCont">{errors.name}</div>
                        ) : null}
                        <div className="input-relative">
                            <FaRegUser className="same-icons color-97"></FaRegUser>
                            <Field 
                            name="name" 
                            placeholder="Enter your full name" 
                            type="text" 
                            className="form-control log-input"  
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        {errors.email && touched.email ? (
                            <div className="errorCont">{errors.email}</div>
                        ) : null}
                        <div className="input-relative">
                            <HiOutlineMail className="same-icons color-97"></HiOutlineMail>
                            <Field name="email" placeholder="Enter your email " type="text" className="form-control log-input"  />
                        </div>
                    </div>
                        <div className="form-group">
                            {errors.password && touched.password ? (
                            <div className="errorCont">{errors.password}</div>
                            ) : null}
                            <div className="input-relative">
                                <FiLock className="same-icons color-97"></FiLock>
                                        {(() => { 
                if (passwordShown == false){
                return(
                    <AiOutlineEyeInvisible className="same-icons-rit color-97"   onClick={togglePasswordVisiblity} ></ AiOutlineEyeInvisible>
                    )
                }  })()}

                {(() => { 
                if (passwordShown == true){
                return(
                    <FaRegEye className="same-icons-rit color-97"   onClick={togglePasswordVisiblity} ></ FaRegEye>
                    )
                }  })()}
                                <Field 
                                name="password" 
                                placeholder="Enter password"  
                                type={passwordShown ? "text" : "password" } 
                                className="form-control log-input" />
                            </div>
                        </div>
                        <div className="form-group">
                            {errors.password_confirmation && touched.password_confirmation ? (
                            <div className="errorCont">{errors.password_confirmation}</div>
                            ) : null}
                            <div className="input-relative">
                            <FiLock className="same-icons color-97"></FiLock>
                                        {(() => { 
                if (confirmpasswordShown == false){
                return(
                    <AiOutlineEyeInvisible className="same-icons-rit color-97"   onClick={toggleconfirmPasswordVisiblity} ></ AiOutlineEyeInvisible>
                    )
                }  })()}

                {(() => { 
                if (confirmpasswordShown == true){
                return(
                    <FaRegEye className="same-icons-rit color-97"   onClick={toggleconfirmPasswordVisiblity} ></ FaRegEye>
                    )
                }  })()}
                                <Field
                                 name="password_confirmation" 
                                 placeholder="Confirm password" 
                                type={confirmpasswordShown ? "text" : "password" } 
                                className="form-control log-input" 
                                />
                            </div>
                        </div>
                        <Row>
                        <Col sm={12} xs={6} className="text-left">
                        {errors.terms_and_conditions && touched.terms_and_conditions ? (
                            <div className="errorCont">{errors.terms_and_conditions}</div>
                            ) : null}
                             <div className="input-relative">
                        <label htmlFor="formBasicCheckbox" className="regular-14 color-97">
                            <Field 
                            type="checkbox" 
                            id="Check me out" 
                            name='terms_and_conditions' 
                            color="primary"

                            /> I have read and agree to both, the <a href='/Termsofuse'>Terms of Use </a>and <a href="/Privacypolicy">Privacy Policy</a>
                        </label>
                        </div>
                        </Col>
                        </Row>
                        <Row>
                    <Col sm={12} className="form-group">
                    <Button 
                    className="green-ground w-100 btn-login" 
                    type="submit" 
                    disabled={!isValid}
                    > 
                    Sign Up  
                    </Button>     
                    </Col>
                    <Col sm={12} className="center-home form-group">
                        <p className="text-center regular-14 color-97">Already have an account? <Link to="/login" className="regular-14 green-text">Login</Link></p> 
                        <p className="text-center regular-14 color-97 pointer">
                            <a className="regular-14 green-text" onClick={handleShow}>Learn More</a>   
                        </p>     
                    </Col> 
                </Row>
                </div>
                </Col>
                    
                </Row>
                </div>
                </Col>
            </Row>
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className="brd_none">
                  <Modal.Title className="px-3 pt-3">
                      
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <Row className="pb-4"> 
                      <Col>
                      <h3 className="pop_title">Mystockpitch let's you do the following:</h3>
                      <p className="text_background mt-0"><span className="shape_model">Access fundamental data on<br/> NYSE and NASDAQ listed stocks</span></p>
                      <p className="text_background"><span className="shape_model">Value stocks with our P/E, P/B,<br/>  P/S and EV/EBITDA models</span></p>
                      <p className="text_background"><span className="shape_model pt-2">Track your pitched stocks</span></p>
                      </Col>
                    </Row>
                </Modal.Body>
                
              </Modal>
        </Container>
        </div>
        
    </Form>
                    )}
                />
            </div>
        );
    
    }

export default connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state) => ({
      schedule: selectors.getSearchedSchedule(state),
      favoritesSchedule: selectors.getGroupedFavorites(state),
      isRegisterSuccess: state.user.isRegisterSuccess
    }),
    mapDispatchToProps: {
      doRegister
    },
    component: Signup  
});
