import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../App.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rangeslider/lib/index.css";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { FaTwitter } from "react-icons/fa";
import {
  getbalancestatement,
  marketratio,
} from "../../data/market/market.actions";
import { AnyMessageParams } from "yup/lib/types";
import { RiArrowDownSLine } from "react-icons/ri";
import ReactGA from "react-ga4";
interface OwnProps {
  stockId: any;
  profileget: any;
}

interface StateProps {
  getmarketratio: any;
}

interface DispatchProps {
  marketratio: typeof marketratio;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface FinancialRatioProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const FinancialRatio: React.FC<FinancialRatioProps> = ({
  stockId,
  profileget,
  marketratio,
  getmarketratio,
}) => {
  useEffect(() => {
    marketratio(stockId);
  }, [stockId]);
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  return (
    <>
      <table className=" scrolltable  grad_table ratio_para market-scroll hight_list">
        <h3 className="mt-3">Liquidity Ratios</h3>
        <div className="wh_ratio">
          <Row>
            <Col sm={12}>
              <p className=" ">
                Current Ratio{" "}
                <span className="float-right">
                  {getmarketratio.currentRatioTTM
                    ? getmarketratio.currentRatioTTM.toFixed(2) + "X"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p className=" ">
                Quick Ratio{" "}
                <span className="float-right">
                  {getmarketratio.quickRatioTTM
                    ? getmarketratio.quickRatioTTM.toFixed(2) + "X"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p className=" ">
                Cash Ratio{" "}
                <span className="float-right">
                  {getmarketratio.cashRatioTTM
                    ? getmarketratio.cashRatioTTM.toFixed(2) + "X"
                    : "-"}
                </span>
              </p>{" "}
            </Col>
            <Col sm={12}>
              <p className=" ">
                Days Sales Outstanding{" "}
                <span className="float-right">
                  {getmarketratio.daysOfSalesOutstandingTTM
                    ? getmarketratio.daysOfSalesOutstandingTTM.toFixed(2)
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p className=" ">
                Days Inventory Outstanding{" "}
                <span className="float-right">
                  {getmarketratio.daysOfInventoryOutstandingTTM
                    ? getmarketratio.daysOfInventoryOutstandingTTM.toFixed(2)
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p className=" ">
                Operating Cycle{" "}
                <span className="float-right">
                  {getmarketratio.operatingCycleTTM
                    ? getmarketratio.operatingCycleTTM.toFixed(2)
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p className=" ">
                Days Payable Outstanding{" "}
                <span className="float-right">
                  {getmarketratio.daysOfPayablesOutstandingTTM
                    ? getmarketratio.daysOfPayablesOutstandingTTM.toFixed(2)
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p className=" ">
                Cash Conversion Cycle{" "}
                <span className="float-right">
                  {getmarketratio.cashConversionCycleTTM
                    ? getmarketratio.cashConversionCycleTTM.toFixed(2)
                    : "-"}
                </span>
              </p>
            </Col>
          </Row>
        </div>
        <div>
          <h3>Profitability Ratios</h3>
        </div>
        <div className="wh_ratio">
          <Row>
            <Col sm={12}>
              <p>
                Gross Profit Margin{" "}
                <span className="float-right">
                  {getmarketratio.grossProfitMarginTTM
                    ? (getmarketratio.grossProfitMarginTTM * 100).toFixed(2) +
                      "%"
                    : "-"}
                </span>
              </p>{" "}
            </Col>
            <Col sm={12}>
              <p>
                Operating Profit Margin{" "}
                <span className="float-right">
                  {getmarketratio.operatingProfitMarginTTM
                    ? (getmarketratio.operatingProfitMarginTTM * 100).toFixed(
                        2
                      ) + "%"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Pretax Profit Margin{" "}
                <span className="float-right">
                  {getmarketratio.pretaxProfitMarginTTM
                    ? (getmarketratio.pretaxProfitMarginTTM * 100).toFixed(2) +
                      "%"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Net Profit Margin{" "}
                <span className="float-right">
                  {getmarketratio.netProfitMarginTTM
                    ? (getmarketratio.netProfitMarginTTM * 100).toFixed(2) + "%"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Effective Tax Rate{" "}
                <span className="float-right">
                  {getmarketratio.effectiveTaxRateTTM
                    ? (getmarketratio.effectiveTaxRateTTM * 100).toFixed(2) +
                      "%"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Return on Assets{" "}
                <span className="float-right">
                  {getmarketratio.returnOnAssetsTTM
                    ? (getmarketratio.returnOnAssetsTTM * 100).toFixed(2) + "%"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Return on Equity{" "}
                <span className="float-right">
                  {getmarketratio.returnOnEquityTTM
                    ? (getmarketratio.returnOnEquityTTM * 100).toFixed(2) + "%"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Return on Capital Employed{" "}
                <span className="float-right">
                  {getmarketratio.returnOnCapitalEmployedTTM
                    ? (getmarketratio.returnOnCapitalEmployedTTM * 100).toFixed(
                        2
                      ) + "%"
                    : "-"}
                </span>
              </p>
            </Col>
          </Row>
        </div>
        <div>
          <h3>Debt Ratios </h3>
        </div>
        <div className="wh_ratio">
          <Row>
            <Col sm={12}>
              <p>
                Debt ratio{" "}
                <span className="float-right">
                  {getmarketratio.debtRatioTTM
                    ? getmarketratio.debtRatioTTM.toFixed(2) + "X"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Debt to Equity Ratio{" "}
                <span className="float-right">
                  {getmarketratio.debtEquityRatioTTM
                    ? getmarketratio.debtEquityRatioTTM.toFixed(2) + "X"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Long-term Debt to Capitalization{" "}
                <span className="float-right">
                  {getmarketratio.longTermDebtToCapitalizationTTM
                    ? getmarketratio.longTermDebtToCapitalizationTTM.toFixed(
                        2
                      ) + "X"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Interest Coverage Ratio{" "}
                <span className="float-right">
                  {getmarketratio.interestCoverageTTM
                    ? getmarketratio.interestCoverageTTM.toFixed(2) + "X"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Cash Flow to Debt Ratio{" "}
                <span className="float-right">
                  {getmarketratio.cashFlowToDebtRatioTTM
                    ? getmarketratio.cashFlowToDebtRatioTTM.toFixed(2) + "X"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Equity Multiplier{" "}
                <span className="float-right">
                  {getmarketratio.companyEquityMultiplierTTM
                    ? getmarketratio.companyEquityMultiplierTTM.toFixed(2) + "X"
                    : "-"}
                </span>
              </p>
            </Col>
          </Row>
        </div>
        <div>
          <h3>Operating Performance Ratios </h3>
        </div>
        <div className="wh_ratio">
          <Row>
            <Col sm={12}>
              <p>
                Fixed Asset Turnover{" "}
                <span className="float-right">
                  {getmarketratio.fixedAssetTurnoverTTM
                    ? getmarketratio.fixedAssetTurnoverTTM.toFixed(2)
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Asset Turnover{" "}
                <span className="float-right">
                  {getmarketratio.assetTurnoverTTM
                    ? getmarketratio.assetTurnoverTTM.toFixed(2)
                    : "-"}
                </span>
              </p>
            </Col>
          </Row>
        </div>
        <div>
          <h3>Cash Flow Indicator Ratios </h3>
        </div>
        <div className="wh_ratio">
          <Row>
            <Col sm={12}>
              <p>
                Operating Cash Flow Sales Ratio{" "}
                <span className="float-right">
                  {getmarketratio.operatingCashFlowSalesRatioTTM
                    ? (
                        getmarketratio.operatingCashFlowSalesRatioTTM * 100
                      ).toFixed(2) + "%"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Free Cash Flow Operating Cash Flow{" "}
                <span className="float-right">
                  {getmarketratio.freeCashFlowOperatingCashFlowRatioTTM
                    ? (
                        getmarketratio.freeCashFlowOperatingCashFlowRatioTTM *
                        100
                      ).toFixed(2) + "%"
                    : "-"}
                </span>
              </p>
            </Col>
          </Row>
        </div>
        <div>
          <h3>Valuation Metrics</h3>
        </div>
        <div className="wh_ratio">
          <Row>
            <Col sm={12}>
              <p>
                Price to Book Value (P/B){" "}
                <span className="float-right">
                  {getmarketratio.priceBookValueRatioTTM
                    ? getmarketratio.priceBookValueRatioTTM.toFixed(2) + "X"
                    : "-"}
                </span>
              </p>{" "}
            </Col>
            <Col sm={12}>
              <p>
                Price to Earnings (P/E){" "}
                <span className="float-right">
                  {getmarketratio.priceEarningsRatioTTM
                    ? getmarketratio.priceEarningsRatioTTM.toFixed(2) + "X"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Price to Sales (P/S){" "}
                <span className="float-right">
                  {getmarketratio.priceToSalesRatioTTM
                    ? getmarketratio.priceToSalesRatioTTM.toFixed(2) + "X"
                    : "-"}
                </span>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Enterprise Multiple (EV/EBITDA){" "}
                <span className="float-right">
                  {getmarketratio.enterpriseValueMultipleTTM
                    ? getmarketratio.enterpriseValueMultipleTTM.toFixed(2) + "X"
                    : "-"}
                </span>
              </p>
            </Col>
          </Row>
        </div>
      </table>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    getmarketratio: state.market.getmarketratio,
  }),
  mapDispatchToProps: { marketratio },
  component: FinancialRatio,
});
