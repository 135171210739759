import { UserActions } from "./user.actions";
import { UserState } from "./user.state";

export function userReducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case "set-user-loading":
      return { ...state, loading: action.isLoading };
    case "set-user-data":
      return { ...state, ...action.data };
    case "set-username":
      return { ...state, username: action.username };
    case "set-has-seen-tutorial":
      return { ...state, hasSeenTutorial: action.hasSeenTutorial };
    case "set-dark-mode":
      return { ...state, darkMode: action.darkMode };
    case "set-is-loggedin":
      return { ...state, isLoggedin: action.loggedIn };
    case "register-success":
      return { ...state, isRegisterSuccess: action.registerSuccess };
    case "emailregister-success":
      return { ...state, emailreset: action.emailsuccess };
    case "passwordreset-success":
      return { ...state, confpassword: action.repassword };
    case "set-login-success":
      return { ...state, isLoginSuccess: action.loginSuccess };
    case "get-current-location":
      return { ...state, currentLocation: action.currentLocation };
    case "get-load-filter":
      return { ...state, loadFilter: action.loadFilter };
    case "register-forgetSuccess":
      return { ...state, resetforget: action.forgetSuccess };
    case "register-resetSuccess":
      return { ...state, resetpasswordreload: action.resetSuccess };
    case "register-resetdata":
      return { ...state, resetuserpassword: action.resetresetSuccess };
    case "profile-get":
      return { ...state, getuserprofile: action.profileget };
    case "profile-update":
      return { ...state, reloadprofileupdate: action.profileupdate };
    case "profile-profileimage":
      return { ...state, setprofimage: action.profileimage };
    case "profile-delete":
      return { ...state, setprofdelete: action.profiledelete };
    case "verify-user":
      return { ...state, setuserVerify: action.VerifyUSer };
    case "password-update":
        return { ...state, setupdatePass: action.passwordUpdate };
  }
}
