import { MarketActions } from "./market.actions";
import { MarketState } from "./market.state";

export function MarketReducer(
  state: MarketState,
  action: MarketActions
): MarketState {
  switch (action.type) {
    case "show-loading":
      return { ...state, showLoading: action.showLoading };
    case "show-controlstate":
      return { ...state, showcontrolstate: action.controlstate };
    case "show-Drawer":
      return { ...state, showDrawer: action.showDrawer };
    case "alldevice-list":
      return { ...state, showalldevice: action.alldevice };
    case "deviceon-list":
      return { ...state, showondevice: action.deviceon };
    case "home-reset":
      return { ...state, homereset: action.homereset };
    case "home-list":
      return { ...state, gethome: action.homelist };
    case "single-home":
      return { ...state, gethomename: action.singlehome };
    case "room-reset":
      return { ...state, resetroom: action.roomreset };
    case "room-list":
      return { ...state, getroom: action.roomlist };
    case "room-editreset":
      return { ...state, editroomreset: action.roomeditreset };
    case "room-name":
      return { ...state, singleroomname: action.roomname };
    case "set-ignore":
      return { ...state, ignoreset: action.ignore };
    case "set-searchmarket":
      return { ...state, getsearchmarket: action.searchmarket };
    case "set-marketgetprofile":
      return { ...state, profileget: action.marketgetprofile };
    case "set-marketratioget":
      return { ...state, getmarketratio: action.marketratioget };
    case "set-getliveprice":
      return { ...state, getliveprices: action.liveprices };
    case "set-cashflow":
      return { ...state, cashflow: action.cashflows };
    case "set-balancestatement":
      return { ...state, balancestatement: action.balancestatements };
    case "set-incomestatement":
      return { ...state, incomestatementget: action.incomestatement };
    case "set-instituteowner":
      return { ...state, instituteowner: action.instituteowner };
    case "set-income":
      return { ...state, getincome: action.income };
    case "set-calendarYear":
      return {...state, getcalendarYear : action.calendarYear}
  }
}
