import { ActionType } from '../../util/types';
import { GlobalState } from './global.state';
import { Toast } from '../../models/OtrModel';
import { GlobalService } from './global.service';


export const showToast = (toast: Partial<Toast>) => ({
  type: 'show-toast',
  toast
} as const );

export const setShowLoading = (showLoading: boolean) => ({
  type: 'show-loading',
  showLoading
} as const);

export const setShowDrawer = (showDrawer: boolean) => ({
  type: 'show-Drawer',
  showDrawer
} as const);

export const setShowToast = (toast:any) => async (dispatch: React.Dispatch<any>) => {
  dispatch(showToast(toast));
}

export type GlobalActions =

  | ActionType<typeof showToast>
  | ActionType<typeof setShowLoading>
  | ActionType<typeof setShowDrawer>