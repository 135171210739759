import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./App.css";
import { connect } from "./data/connect";
import * as selectors from "./data/selectors";
import { reset } from "./data/user/user.actions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RouteComponentProps } from "react-router";
import Header from "./header";
import Footer from "./footer";
interface OwnProps extends RouteComponentProps {}

interface StateProps {
  resetpasswordreload: boolean;
}

interface DispatchProps {
  reset: typeof reset;
}

type ResetProps = OwnProps & StateProps & DispatchProps;

const Sucess: React.FC<ResetProps> = ({
  reset,
  resetpasswordreload,
  history,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const ResetSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmpassword: Yup.string().when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const breakpoint = {
    // Small screen / phone
    sm: 576,
    // Medium screen / tablet
    md: 768,
    // Large screen / desktop
    lg: 992,
    // Extra large screen / wide desktop
    xl: 1200,
  };
  useEffect(() => {
    if (resetpasswordreload) history.push(`${"/ResetSucessmsg"}`);
    return function cleanup() {};
  }, [resetpasswordreload]);

  return (
    <div>
      <Formik
        initialValues={{
          password: "",

          confirmpassword: "",
        }}
        validationSchema={ResetSchema}
        onSubmit={(
          { password, confirmpassword },
          { setStatus, setSubmitting }
        ) => {
          console.log(setSubmitting, "setSubmitting");
          reset(password, confirmpassword);
        }}
        render={({ errors, status, touched, isSubmitting }) => (
          <div className="">
            <div className="login-back">
              <Header />
              <Container fluid className="contact_101">
                <Row className="justify-content-center vh_100">
                  <Col sm={12} xl={8} className="align-self-center">
                    <div className="dash-table-div p-3 mh_25">
                      <Row className="justify-content-center">
                        <Col sm={9} className="align-self-center text-center">
                          <img
                            src="/assets/images/icon.005ba8da.png"
                            className="w-25"
                          />
                          <h3 className="bold_30_76 mt-3">
                            {" "}
                            Please Check Your Email for Password Reset!
                          </h3>

                          {/* <Link 
                            to="/login"
                            className="btn-value mr-sm-2 text-center mobi-block my-4 d-block"
                          >
                            Login{" "}
                          </Link> */}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Footer />
          </div>
        )}
      />
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    schedule: selectors.getSearchedSchedule(state),
    favoritesSchedule: selectors.getGroupedFavorites(state),
    resetpasswordreload: state.user.resetpasswordreload,
  }),
  mapDispatchToProps: {
    reset,
  },
  component: Sucess,
});
