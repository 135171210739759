import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Spinner,
  Carousel,
} from "react-bootstrap";
import ReactGA from "react-ga4";
import { FaRegEye, FaRegUser, FaLinkedin } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import "./App.css";
import { connect } from "./data/connect";
import * as selectors from "./data/selectors";
import { reset } from "./data/user/user.actions";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
interface OwnProps extends RouteComponentProps {}

interface StateProps {
  resetpasswordreload: boolean;
}

interface DispatchProps {
  reset: typeof reset;
}

type ResetProps = OwnProps & StateProps & DispatchProps;

const Compliance: React.FC<ResetProps> = ({
  reset,
  resetpasswordreload,
  history,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const ResetSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmpassword: Yup.string().when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const breakpoint = {
    // Small screen / phone
    sm: 576,
    // Medium screen / tablet
    md: 768,
    // Large screen / desktop
    lg: 992,
    // Extra large screen / wide desktop
    xl: 1200,
  };
  useEffect(() => {
    if (resetpasswordreload) history.push(`${"/Login"}`);
    return function cleanup() {};
  }, [resetpasswordreload]);

  return (
    <div>
      <div className="">
        <div className="login-back">
          <Header/>
          <Container fluid className="contact_101">
            <Row className="justify-content-center terms">
              <Col sm={12} className="align-self-top">
                <div className="dash-table-div p-3 mh_25 vh_80 mb-3 scroll-ball">
                  <Row className="justify-content-center">
                    <div className="page-content d-flex flex pl-0 pr-0">
                      <div className="container">
                        <div className="mt-3">
                          <div className="entry-content">
                            <h2 className="text-info fs16">Compliance Note</h2>
                            <h5 className="text-info fs16">
                              {" "}
                              Regulation and licencing requirements (not)
                              applicable to mystockpitch.com
                            </h5>
                            <p>
                              Mystockpitch is an internet content provider, or
                              ICP, under the class licence scheme of the
                              Infocomm Media Development Authority of Singapore.
                              As such, Mystockpitch needs no registration to
                              provide internet content in Singapore. It is
                              required, though, to comply with IMDA’s class
                              licence conditions and Internet code of practice.
                            </p>
                            <p>
                              Mystockpitch is not a financial adviser under
                              Singapore law, as it does not carry out a business
                              of providing a financial advisory service. In
                              other words, as Mystockpitch does not advise
                              anyone concerning investment products (for
                              example, capital markets products such as
                              securities, units in a collective investment
                              scheme, derivatives contracts, or spot foreign
                              exchange contracts for the purposes of leveraged
                              foreign exchange trading). As such, Mystockpitch
                              needs no financial adviser’s licence. The
                              Financial Advisers Act 2001 of Singapore excludes
                              information service providers from the statutory
                              definition of ‘financial adviser’. The content on
                              our platform is offered only for general
                              information purposes.{" "}
                            </p>
                            <p>
                              In no event will we or our directors, employees or
                              agents be liable to you or any third party for any
                              direct, indirect, consequential, exemplary,
                              incidental, special, or punitive damages,
                              including lost profit, lost revenue, loss of data,
                              or other damages arising from your use of our
                              site, even if we have been advised of the
                              possibility of such damages.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    schedule: selectors.getSearchedSchedule(state),
    favoritesSchedule: selectors.getGroupedFavorites(state),
    resetpasswordreload: state.user.resetpasswordreload,
  }),
  mapDispatchToProps: {
    reset,
  },
  component: Compliance,
});
