import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Spinner,
  Carousel,
} from "react-bootstrap";
import { FaRegEye, FaRegUser, FaLinkedin } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import "./App.css";
import { connect } from "./data/connect";
import * as selectors from "./data/selectors";
import { reset } from "./data/user/user.actions";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import ReactGA from "react-ga4";
interface OwnProps extends RouteComponentProps {}

interface StateProps {
  resetpasswordreload: boolean;
}

interface DispatchProps {
  reset: typeof reset;
}

type ResetProps = OwnProps & StateProps & DispatchProps;

const Privacypolicy: React.FC<ResetProps> = ({
  reset,
  resetpasswordreload,
  history,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const ResetSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmpassword: Yup.string().when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const breakpoint = {
    // Small screen / phone
    sm: 576,
    // Medium screen / tablet
    md: 768,
    // Large screen / desktop
    lg: 992,
    // Extra large screen / wide desktop
    xl: 1200,
  };
  useEffect(() => {
    if (resetpasswordreload) history.push(`${"/Login"}`);
    return function cleanup() {};
  }, [resetpasswordreload]);

  return (
    <div>
      <div className="">
        <div className="login-back">
          <Header />
          <Container fluid className="contact_101">
            <Row className="justify-content-center">
              <Col sm={12} className="align-self-top">
                <div className="dash-table-div p-3 mh_25 vh_80 mb-3 scroll-ball">
                  <Row className="justify-content-center">
                    <div className="col-12 px-sm-0">
                      <div className="container">
                        <div className="mt-3">
                          <div className="entry-content">
                            <h2 className="text-info fs18 ">
                              DATA PROTECTION NOTICE FOR USERS
                            </h2>
                            <p>
                            This Data Protection Notice (‘Notice’) sets out the basis which <b className="green-text ">
                                <i>www.mystockpitch.com</i>
                              </b> (‘we’, ‘us’, or ‘our’) may collect, use, disclose or otherwise process personal data of our users in accordance with the Personal Data Protection Act 2012 (‘PDPA’), the law on data protection in Singapore. This Notice applies to personal data in our possession or under our control, including personal data in the possession of organisations which we have engaged to collect, use, disclose or process personal data for our purposes.
                            </p>

                            <h2 className="text-info fs16"> PERSONAL DATA </h2>
                            <div className="pl-0">
                              <p><span className="Number_t">1.</span> <span className="text_le">As used in this Notice:</span></p>
                              <p>
                                <b>‘personal data’</b> means data, whether true
                                or not, about a user who can be identified: (a)
                                from that data; or (b) from that data and other
                                information to which we have or are likely to
                                have access; and
                              </p>
                              <p>
                                <b>‘user’</b> means an individual who (a) has contacted us through any means to find out more about any goods or services we provide, or (b) may, or has, entered into a contract with us for the supply of any goods or services by us.
                              </p>
                              
                              <p><span className="Number_t">2.</span> <span className="text_le"> Depending on the nature of your interaction with us, some examples of personal data which we may collect from you include name, e-mail address and photograph.</span>
                              </p>
                              <p><span className="Number_t">3.</span> <span className="text_le"> Other terms used in this Notice shall have the meanings given to them in the PDPA (where the context so permits).</span>
                              </p>

                              <h2 className="text-info fs16">
                                COLLECTION, USE AND DISCLOSURE OF PERSONAL DATA
                              </h2>
                                <p><span className="Number_t">4.</span> <span className="text_le"> We generally do not collect your personal data unless (a) it is provided to us voluntarily by you directly or via a third party who has been duly authorised by you to disclose your personal data to us (your ‘authorised representative’) after (i) you (or your authorised representative) have been notified of the purposes for which the data is collected, and (ii) you (or your authorised representative) have provided written consent to the collection and usage of your personal data for those purposes, or (b) collection and use of personal data without consent is permitted or required by the PDPA or other laws. We shall seek your consent before collecting any additional personal data and before using your personal data for a purpose which has not been notified to you (except where permitted or authorised by law).</span>
                                </p>
                              
                              <p><span className="Number_t">5.</span> <span className="text_le"> We may collect and use your personal data for any of the following purposes:</span></p>
                                <div className="pl-4">
                                <p className="mt-3">
                                  <span className="pr-2">(a)</span> <span className="text_smt">performing obligations during or in connection with our provision of the goods or services requested by you;</span>
                                </p>
                                <p>
                                  <span className="pr-2">(b)</span> <span className="text_smt">responding to, handling, and processing queries, requests, applications, complaints, and feedback from you;</span>
                                </p>
                                <p><span className="pr-2">(c)</span> <span className="text_smt">managing your relationship with us;</span></p>
                                <p>
                                  <span className="pr-2">(d)</span> <span className="text_smt">complying with any applicable laws, regulations, codes of practice, guidelines, or rules, or to assist in law enforcement and investigations conducted by any governmental or regulatory authority; and</span>
                                </p>
                                <p>
                                  <span className="pr-2">(e)</span> <span className="text_smt">any other purposes for which you have provided the information.</span>
                              </p>
                              </div>
                              <p><span className="Number_t">6.</span> <span className="text_le"> We may disclose your personal data where such disclosure is required for performing obligations during or in connection with our provision of the goods and services requested by you.</span>
                              </p>
                              <h3 className="text-info fs16">
                                WITHDRAWING YOUR CONSENT
                              </h3>
                              <p><span className="Number_t">7.</span> <span className="text_le"> The consent that you provide for the collection, use and disclosure of your personal data will remain valid until such time it is being withdrawn by you in writing. You may withdraw consent and request us to stop collecting, using, or disclosing your personal data for any of the purposes listed above by submitting your request in writing or via e-mail to our Data Protection Officer at the contact details provided below.</span>
                              </p>
                              <p><span className="Number_t">8.</span> <span className="text_le"> Upon receipt of your written request to withdraw your consent, we may require reasonable time (depending on the complexity of the request and its impact on our relationship with you) for your request to be processed and for us to notify you of the consequences of us acceding to the same, including any legal consequences which may affect your rights and liabilities to us. In general, we shall seek to process your request within five business days of receiving it.</span>
                              </p>
                              <p><span className="Number_t">9.</span> <span className="text_le"> Whilst we respect your decision to withdraw your consent, please note that depending on the nature and scope of your request, we may not be able to continue providing our goods or services to you and we shall, in such circumstances, notify you before completing the processing of your request. Should you decide to cancel your withdrawal of consent, please inform us in writing in the manner described in clause 7.</span>
                              </p>
                              <p><span className="Number_t">10.</span> <span className="text_le"> Please note that withdrawing consent does not affect our right to continue to collect, use and disclose personal data where such collection, use and disclose without consent is permitted or required under applicable laws.</span>
                              </p>
                              <h3 className="text-info fs16">
                                ACCESS TO AND CORRECTION OF PERSONAL DATA
                              </h3>
                              <p><span className="Number_t">11.</span> <span className="text_le"> If you wish to make (a) an access request for access to a copy of the personal data which we hold about you or information about the ways in which we use or disclose your personal data, or (b) a correction request to correct or update any of your personal data which we hold about you, you may submit your request in writing or via e-mail to our Data Protection Officer at the contact details provided below.</span> 
                              </p>
                              <p><span className="Number_t">12.</span> <span className="text_le"> Please note that a reasonable fee may be charged for an access request. If so, we will inform you of the fee before processing your request. </span>
                              </p>
                              <p><span className="Number_t">13.</span> <span className="text_le"> We will respond to your request as soon as reasonably possible. In general, our response will be within five business days. Should we not be able to respond to your request within thirty days after receiving your request, we will inform you in writing within thirty days of the time by which we will be able to respond to your request. If we are unable to provide you with any personal data or to make a correction requested by you, we shall generally inform you of the reasons why we are unable to do so (except where we are not required to do so under the PDPA).</span>
                              </p>

                              <h3 className="text-info fs16">
                                PROTECTION OF PERSONAL DATA
                              </h3>

                              <p><span className="Number_t">14.</span> <span className="text_le"> To safeguard your personal data from unauthorised access, collection, use, disclosure, copying, modification, disposal or similar risks, we have introduced appropriate administrative, physical and technical measures such as minimised collection of personal data, authentication and access controls (such as good password practices, need-to-basis for data disclosure, etc.), encryption of data, data anonymisation, up-to-date antivirus protection, regular patching of operating system and other software, securely erase storage media in devices before disposal, web security measures against risks, and security review and testing performed regularly.</span>
                              </p>
                              <p><span className="Number_t">15.</span> <span className="text_le"> You should be aware, however, that no method of transmission over the Internet or method of electronic storage is completely secure. While security cannot be guaranteed, we strive to protect the security of your information and are constantly reviewing and enhancing our information security measures. </span>
                              </p>

                              <h3 className="text-info fs16">
                                ACCURACY OF PERSONAL DATA
                              </h3>

                              <p><span className="Number_t">16.</span> <span className="text_le"> We generally rely on personal data provided by you (or your authorised representative). To ensure that your personal data is current, complete and accurate, please keep your user profile up to date, or update us if there are changes to your personal data by informing our Data Protection Officer in writing or via e-mail at the contact details provided below.</span>
                              </p>

                              <h3 className="text-info fs16">
                                RETENTION OF PERSONAL DATA
                              </h3>

                              <p><span className="Number_t">17.</span> <span className="text_le"> We may retain your personal data for as long as it is necessary to fulfil the purpose for which it was collected, or as required or permitted by applicable laws.</span>
                              </p>
                              <p><span className="Number_t">18.</span> <span className="text_le"> We will cease to retain your personal data or remove how the data can be associated with you as soon as it is reasonable to assume that such retention no longer serves the purpose for which the personal data was collected and is no longer necessary for legal or business purposes.</span>
                              </p>

                              <h3 className="text-info fs16">
                                TRANSFERS OF PERSONAL DATA OUTSIDE OF SINGAPORE
                              </h3>

                              <p><span className="Number_t">19.</span> <span className="text_le"> We generally do not transfer your personal data to countries outside of Singapore. However, if we do so, we will obtain your consent for the transfer to be made and we will take steps to ensure that your personal data continues to receive a standard of protection that is at least comparable to that provided under the PDPA.</span></p>

                              <h3 className="text-info fs16">
                                DATA PROTECTION OFFICER
                              </h3>

                              <p><span className="Number_t">20.</span> <span className="text_le"> You may contact our Data Protection Officer if you have any enquiries or feedback on our personal data protection policies and procedures, or if you wish to make any request, in the following manner:</span>
                              </p>

                              <p className="mt-3"><span className="Number_t"></span> <span className="text_le">
                                <i>Name of DPO: Ravkaran Singh Koghar</i><br/>
                                <i>Contact no: +6594658110</i><br/>
                                <i>E-mail address: support@mystockpitch.com</i></span>
                              </p>

                              <h3 className="text-info fs16">
                                EFFECT OF NOTICE AND CHANGES TO NOTICE
                              </h3>

                              <p><span className="Number_t">21.</span> <span className="text_le"> This Notice applies in conjunction with any other notices, contractual clauses and consent clauses that apply in relation to the collection, use and disclosure of your personal data by us.</span>
                              </p>
                              <p><span className="Number_t">22.</span> <span className="text_le"> We may revise this Notice from time to time without any prior notice. You may determine if any such revision has taken place by referring to the date on which this Notice was last updated. Your continued use of our services constitutes your acknowledgement and acceptance of such changes.</span>
                              </p>
                              
                            </div>
                            <p className="mt-3"><span className="Number_t"> </span> <span className="text_le">
                                Effective date:  18/05/2022<br/>
                                Last updated:  18/05/2022</span>
                              </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    schedule: selectors.getSearchedSchedule(state),
    favoritesSchedule: selectors.getGroupedFavorites(state),
    resetpasswordreload: state.user.resetpasswordreload,
  }),
  mapDispatchToProps: {
    reset,
  },
  component: Privacypolicy,
});
