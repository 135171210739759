import { combineReducers } from "./combineReducers";
import { sessionsReducer } from "./sessions/sessions.reducer";
import { userReducer } from "./user/user.reducer";
import { globalReducer } from "./global/global.reducer";
import { MarketReducer } from "./market/market.reducer";
import { PortfolioReducer } from "./portfolio/portfolio.reducer";
import { StockReducer } from "./stock/stock.reducer";
import { PitchesReducer } from "./Pitches/pitches.reducer";

import { FaAngry, FaLastfmSquare, FaLessThanEqual } from "react-icons/fa";
import { WatchlistReducer } from "./watchlist/watchlist.reducer";
import moment from "moment";

export const initialState: AppState = {
  data: {
    schedule: { groups: [] } as any,
    sessions: [],
    speakers: [],
    favorites: [],
    locations: [],
    allTracks: [],
    filteredTracks: [],
    mapCenterId: 0,
    loading: false,
    menuEnabled: true,
  },
  user: {
    hasSeenTutorial: false,
    darkMode: false,
    isLoggedin: false,
    loading: false,
    isRegisterSuccess: false,
    confpassword: false,
    emailreset: false,
    isLoginSuccess: {},
    currentLocation: { lat: 40.745255, long: -74.034775 },
    resetforget: false,
    resetpasswordreload: false,
    resetuserpassword:false,
    getuserprofile: [],
    setupdatePass:[],
    reloadprofileupdate: false,
    setprofimage: false,
    setprofdelete: false,
    setuserVerify: false,
  },
  global: {
    showLoading: false,
    showToast: { isOpen: false, message: "", color: "danger" },
    showDrawer: false,
  },
  market: {
    getroomlist: [],
    showLoading: false,
    showcontrolstate: "USD",
    showDrawer: false,
    showalldevice: [],
    showondevice: [],
    homereset: false,
    gethome: [],
    gethomename: [],
    resetroom: false,
    getroom: [],
    editroomreset: false,
    singleroomname: [],
    ignoreset: false,
    getsearchmarket: [],
    profileget: [],
    getmarketratio: [],
    getliveprices: [],
    cashflow: [],
    balancestatement: [],
    incomestatementget: [],
    instituteowner: [],
    getincome: [],
    calendarYear: moment().format("YYYY"),
  },
  watchlist: {
    showLoading: false,
    showDrawer: false,
    watchlistget: [],
    setremove: false,
    watchlistadd: false,
  },
  portfolio: {
    portfolioList: [],
    portfolioCreate: false,
    delreload: false,
    editreload: { portfolio_name: "" },
    portfoliosearch: [],
    updateportfolio: false,
    getportfoliovalue: 0,
    portfolioStocks: [],
    stockhistorical: [],
    keymetrics: [],
    getquotereturn: [],
    getquotereturnpitch: [],
    screenerget: false,
    screenerlistview: { data: [] },
    fbmarketget: [],
    ttmvalue: [],
    keyttmvalue: [],
    keyexecutives: [],
    institutionalholder: [],
    getfin: [],
    gethis: [],
    getrs: [],
    getrsquar: [],
    getevincomes: [],
    getbalancesheetev: [],
    getshareoustanding: [],
    setchange: [],
    hidevaluation: [],
    reloadpitch: [],
    reloadpitchupdate: [],
    gettrack: false,
    gettrackview: [],
    getstockProfile: [],
    viewnews: [],
    afterpitchreload: false,
  },
  stock: {
    stockList: [],
    stockProfile: { Name: "", LatestQuarter: "" },
    datahistorical: {},
    reloadvaluation: false,
    valuationTypes: [],
    valuationDetail: {},
    viewvaluationbyid: [],
  },
  pitches: {
    allpitcheslist: [],
    valuationdetails: [],
    pitchadd: false,
    updatepitchsuccess: false,
    trackedpitchesdeletereload: false,
    gettrakedpitchesupdate: [],
    trackupdatereload: false,
  },
};

export const reducers = combineReducers({
  data: sessionsReducer,
  user: userReducer,
  global: globalReducer,
  market: MarketReducer,
  portfolio: PortfolioReducer,
  stock: StockReducer,
  pitches: PitchesReducer,
  watchlist: WatchlistReducer,
});

export type AppState = ReturnType<typeof reducers>;
