import { Pentagon } from "react-bootstrap-icons";
import { RiOilFill, RiOpenArmFill } from "react-icons/ri";
import { config } from "../_config/config";
import { authHeader, handleResponse } from "../_helpers";

export const PortfolioService = {
  addPortfolio,
  getPortfolioList,
  getPortfolioDetails,
  updatePortfolioDetails,
  deleteportfolio,
  editportfolio,
  searchportfolio,
  portfolioupdate,
  stockperportfolio,
  getdaily,
  getweekly,
  getmonthly,
  getmonthlys,
  getincome,
  getbalance,
  getmetirials,
  getquote,
  getscreenerinputs,
  getscreenerlist,
  getfbmarket,
  getincomes,
  getfbmarketonemonth,
  getfbmarketfiveday,
  screenerdelete,
  getannualratios,
  getkeymetricsttm,
  getkeyexecutives,
  getinstitutionalholder,
  getrevenuepershare,
  getrevenuepersharequar,
  incomeforev,
  getbalanceev,
  getincomequ,
  addpitch,
  updatepitch,
  track,
  trackview,
  viewStockProfile,
  getebidquar,
  getebidanu,
  getnews,
};

function addPortfolio(portfolio: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(portfolio),
  };

  return fetch(`${config.apiUrl}api/addPortfolio`, requestOptions).then(
    handleResponse
  );
}

function getPortfolioList() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}api/getPortfolioList`, requestOptions).then(
    handleResponse
  );
}

function getPortfolioDetails(portfolio: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ portfolio_id: portfolio }),
  };

  return fetch(`${config.apiUrl}api/getPortfolioDetails`, requestOptions).then(
    handleResponse
  );
}

function updatePortfolioDetails(portfolio: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(portfolio),
  };

  return fetch(
    `${config.apiUrl}api/updatePortfolioDetails`,
    requestOptions
  ).then(handleResponse);
}
function deleteportfolio(portfolioid: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ portfolio_id: portfolioid }),
  };

  return fetch(`${config.apiUrl}api/deletePortfolio`, requestOptions).then(
    handleResponse
  );
}

function editportfolio(portfolioid: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ portfolio_id: portfolioid }),
  };

  return fetch(`${config.apiUrl}api/getPortfolioDetails`, requestOptions).then(
    handleResponse
  );
}
function searchportfolio(portfolioname: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ portfolio_name: portfolioname }),
  };

  return fetch(`${config.apiUrl}api/searchPortfolioList`, requestOptions).then(
    handleResponse
  );
}

function portfolioupdate(portfolio: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(portfolio),
  };

  return fetch(
    `${config.apiUrl}api/updatePortfolioDetails`,
    requestOptions
  ).then(handleResponse);
}

function stockperportfolio(portfolioid: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ portfolio_id: portfolioid }),
  };

  return fetch(`${config.apiUrl}api/stock_per_portfolio`, requestOptions).then(
    handleResponse
  );
}
function getdaily(stocksymbol: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ stock_symbol: stocksymbol }),
  };

  return fetch(`${config.apiUrl}api/getDailyData`, requestOptions).then(
    handleResponse
  );
}
function getweekly(stocksymbol: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ stock_symbol: stocksymbol }),
  };

  return fetch(`${config.apiUrl}api/getWeeklyData`, requestOptions).then(
    handleResponse
  );
}
function getmonthly(stocksymbol: any, reportType: any, dataPoint: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers: myHeaders,
    body: JSON.stringify({ url: `api/v3/ratios/${stocksymbol}?period=${reportType}&limit=${dataPoint}&apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}

function getmonthlys(stocksymbol: any, reportType: any, dataPoint: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers : myHeaders,
    body: JSON.stringify({ url: `api/v3/ratios/${stocksymbol}?period=${reportType}&limit=${dataPoint}&apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}

function getincome(stocksymbol: any, limit: any, reportType: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers : myHeaders,
    body: JSON.stringify({ url: `api/v3/income-statement/${stocksymbol}?period=${reportType}&limit=${limit}&apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}

function getincomes(stocksymbol: any, limit: any, reportType: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers : myHeaders,
    body: JSON.stringify({ url: `api/v3/income-statement/${stocksymbol}?period=${reportType}&limit=${limit}&apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}

function getincomequ(stocksymbol: any, reportType: any, limit: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers: myHeaders,
    body: JSON.stringify({ url: `api/v3/income-statement/${stocksymbol}?period=${reportType}&limit=${limit}&apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}

function getbalance(stocksymbol: any, limit: any, reportType: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers : myHeaders,
    body: JSON.stringify({ url: `api/v3/balance-sheet-statement/${stocksymbol}?period=${reportType}&limit=${limit}&apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}

function getmetirials(stocksymbol: any, limit: any, reportType: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers : myHeaders,
    body: JSON.stringify({ url: `api/v3/key-metrics/${stocksymbol}?period=${reportType}&limit=${limit}&apikey=`, method:"GET", parameters:{} })
  };

return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}

function getquote(stocksymbol: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers : myHeaders,
    body: JSON.stringify({ url: `api/v3/quote/${stocksymbol}?apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function getscreenerinputs(screenerinput: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(screenerinput),
  };

  return fetch(`${config.apiUrl}api/screener/add`, requestOptions).then(
    handleResponse
  );
}
function getscreenerlist(stocksymbol: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}api/screener/list`, requestOptions).then(
    handleResponse
  );
}
function getfbmarket(stocksymbol: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers: myHeaders,
    body: JSON.stringify({ url: `api/v3/historical-chart/30min/${stocksymbol}?apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function getfbmarketonemonth(stocksymbol: any, fromdate: any, todate: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers : myHeaders,
    body: JSON.stringify({ url: `api/v3/historical-price-full/${stocksymbol}?from=${fromdate}&to=${todate}&apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function getfbmarketfiveday(
  duration: any,
  stocksymbol: any,
  fromdate: any,
  todate: any
) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers : myHeaders,
    body: JSON.stringify({ url: `api/v3/historical-chart/${duration}/${stocksymbol}?from=${fromdate}&apikey=`, method:"GET", parameters:{} })
  };
  return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function screenerdelete(screenerid: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(screenerid),
  };

  return fetch(`${config.apiUrl}api/screener/delete`, requestOptions).then(
    handleResponse
  );
}
function getannualratios(stocksymbol: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers : myHeaders,
    body: JSON.stringify({ url: `api/v3/ratios-ttm/${stocksymbol}?apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function getkeymetricsttm(stocksymbol: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers : myHeaders,
    body: JSON.stringify({ url: `api/v3/key-metrics-ttm/${stocksymbol}?limit=40&apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function getkeyexecutives(stocksymbol: any) {

  const requestOptions = {
    method  : 'POST',
    headers:{},
    body: JSON.stringify({ url: `api/v3/key-executives/${stocksymbol}?apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function getinstitutionalholder(stocksymbol: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers: myHeaders,
    body: JSON.stringify({ url: `api/v3/institutional-holder/${stocksymbol}?apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function getrevenuepershare(stocksymbol: any, limit: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers: myHeaders,
    body: JSON.stringify({ url: `api/v3/key-metrics/${stocksymbol}?limit=${limit}&apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function getrevenuepersharequar(stocksymbol: any, limit: any, reportType: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method  : 'POST',
    headers: myHeaders,
    body: JSON.stringify({ url: `api/v3/key-metrics/${stocksymbol}?limit=${limit}&period=${reportType}&apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function incomeforev(stocksymbol: any, limit: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method  : 'POST',
    headers: myHeaders,
    body: JSON.stringify({ url: `api/v3/income-statement/${stocksymbol}?limit=${limit}&apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
function getbalanceev(stocksymbol: any, limit: any, reportType: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers: myHeaders,
    body: JSON.stringify({ url: `api/v3/balance-sheet-statement/${stocksymbol}?period=${reportType}&limit=${limit}&apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}

async function addpitch(inputpitch: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(inputpitch),
  };
  return await fetch(`${config.apiUrl}api/addValuation`, requestOptions).then(
    handleResponse
  );
}

function updatepitch(inputpitch: any) {
  if(inputpitch.pitch_status == 1 ){
    inputpitch.tracked_market_price = inputpitch.market_price
  }
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(inputpitch),
  };
  return fetch(`${config.apiUrl}api/updateValuation`, requestOptions).then(
    handleResponse
  );
}

function track(vid: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ valuation_id: vid }),
  };
  return fetch(`${config.apiUrl}api/update-pitch`, requestOptions).then(
    handleResponse
  );
}

function trackview() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}api/trackedPitches`, requestOptions).then(
    handleResponse
  );
}

function viewStockProfile(stock_symbol: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers: myHeaders,
    body: JSON.stringify({ url: `api/v3/profile/${stock_symbol}?apikey=`, method:"GET", parameters:{} })
  };
  return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}

function getebidquar(stocksymbol: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers: myHeaders,
    body: JSON.stringify({ url: `api/v3/ratios/${stocksymbol}?apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}

function getebidanu(stocksymbol: any, limit: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers: myHeaders,
    body: JSON.stringify({ url: `api/v3/ratios/${stocksymbol}?limit=${limit}&apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}

function getnews(stocksymbol: any, limit: any) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method  : 'POST',
    headers: myHeaders,
    body: JSON.stringify({ url: `api/v3/stock_news?tickers=${stocksymbol}&limit=${limit}&apikey=`, method:"GET", parameters:{} })
};
return fetch(`${config.commonapiUrl}`, requestOptions).then(handleResponse);
}
