import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Form,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import Slider from "react-rangeslider";
import GaugeChart from "react-gauge-chart";
import ReactSpeedometer, {
  CustomSegmentLabelPosition,
} from "react-d3-speedometer";
import { BiSearch } from "react-icons/bi";
import ReactGA from "react-ga4";
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { FaInfoCircle, FaMixer, FaTimes } from "react-icons/fa";
import "../../App.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rangeslider/lib/index.css";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import {
  getStockList,
  addvaluation,
  setvaluationSuccess,
} from "../../data/stock/stock.actions";
import { marketprofile } from "../../data/market/market.actions";
import moment from "moment";
// import Speedometer, {
//   Background,
//   Arc,
//   Needle,
//   Progress,
//   Marks,
//   Indicator,
// } from 'react-speedometer';
import NumberFormat from "react-number-format";
import { getincomestatements } from "../../data/market/market.actions";
import {
  getchange,
  getearnings,
  getincome,
  getmonthlys,
  getrevenuepersharequar,
  updatepitch,
} from "../../data/portfolio/portfolio.actions";

interface OwnProps {
  update: any;
  size: any;
  valuation_type: any;
  valuation_text: any;
  stockId: any;
  setViewBusiness: any;
  showModal: any;
  setShowModal: any;
  found: any;
  invalue: any;
  setinvalue: any;
  pitchvalue: any;
  setpitchvalue: any;
  displayType: any;
  historys: any;
  Setpitchedited: any;
  hideset: any;
  viewvaluationbyid: any;
  sr: any;
  usd: any;
}

interface StateProps {
  stockList: any;
  valuationDetail: any;
  profileget: any;
  getincome: any;
  getuserprofile: any;
  getquotereturn: any;
  stockhistorical: any;
  getfin: any;
  getcalendarYear: any;
  showcontrolstate: any;
  //NEW
  getrsquar: any;
}

interface DispatchProps {
  getStockList: typeof getStockList;
  addvaluation: typeof addvaluation;
  getincomestatements: typeof getincomestatements;
  marketprofile: typeof marketprofile;
  getearnings: typeof getearnings;
  getmonthlys: typeof getmonthlys;
  //NEW
  getrevenuepersharequar: typeof getrevenuepersharequar;
  getchange: typeof getchange;
  updatepitch: typeof updatepitch;
  setvaluationSuccess: typeof setvaluationSuccess;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);

  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface MyValuationpbProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const Myvaluationpb: React.FC<MyValuationpbProps> = ({
  displayType,
  viewvaluationbyid,
  hideset,
  sr,
  Setpitchedited,
  getuserprofile,
  getcalendarYear,
  showcontrolstate,
  pitchvalue,
  updatepitch,
  update,
  size,
  profileget,
  setpitchvalue,
  stockId,
  setViewBusiness,
  showModal,
  setinvalue,
  invalue,
  setShowModal,
  getchange,
  getrsquar,
  getrevenuepersharequar,
  stockhistorical,
  getfin,
  valuationDetail,
  getquotereturn,
  valuation_type,
  getincome,
  marketprofile,
  getincomestatements,
  getStockList,
  getearnings,
  stockList,
  addvaluation,
  setvaluationSuccess,
  getmonthlys,
  found,
  usd,
}) => {
  const [year, setyear] = useMergeState({
    threeyear: "",
    fiveyear: "",
    sevenyear: "",
    tenyear: "",
  });
  const [yearforward, setyearforward] = useMergeState({
    yeardef: "",
    threeyears: "",
    fiveyears: "",
    sevenyears: "",
    tenyears: "",
  });
  const [previousyear, setpreviousyear] = useState("");
  const [ifchange, setifchange] = useState(0);

  // const [ep, setEp] = useState(1);
  // const [growthrate, setgrowthrate] = useState<any>(1);
  // const [forwardmultiple, setforwardmultiple] = useState<any>(1);
  // const [holdingperiod, setholdingperiod] = useState<any>(1);
  // const [caleraningpershare, setcaleraningpershare] = useState<any>(1);
  // const [calintrinsic, setcalintrinsic] = useState<any>(1);
  // const [calmargin, setcalmargin] = useState<any>(1);
  // const [calexpectedgain, setcalexpectedgain] = useState<any>(1);
  const [changedValuation, setChangedValuation] = useState(false);
  const [moatsList, setMoatsList] = useState([
    "Brand(s)",
    "Patents",
    "Licenses",
    "Trade Secret(s)",
    "High Switching Costs",
    "Network Effects",
    "Cost Advantages",
  ]);
  const [pop, setPop] = useState("");
  const [meter, setmeter] = useState(0);
  const [showModals1, setShowModals1] = useState(false);
  const [calirr, setcalirr] = useState<any>(1);
  const [moic, setmoic] = useState<any>(1);
  const [dialogModal, setDialogModal] = useState(false);
  const [namechange, setnamechange] = useState("");
  const [textedit, settextedit] = useState("");

  const [texteditprev, settexteditprev] = useState("");
  const [facechange, setfacechange] = useState("");
  const [facechangein, setfacechangein] = useState("");
  const [valuatuionchanged, setvaluatuionchanged] = useState(false);
  var controller = new AbortController();
  var signal: any = "";
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  const [getpitch, setgetpitch] = useMergeState({
    valuation_type_id: "2",
    stock_symbol: "",
    stock_name: "",
    book_value_per_share: 1,
    growth_in_bookvalue: 1,
    bookvalue_per_share_at_endof_hold: 1,
    forward_multiple: 1,
    market_price: 1,
    tracked_market_price: null,
    holding_period: 1,
    intrinsic_value_per_share: 1,
    premium: 1,
    cumulative_loss: 1,
    irr: 1,
    moic: 1,
    moats: [],
    comment: "",
  });

  useEffect(() => {
    Setpitchedited(getpitch);
  }, [getpitch, getpitch.intrinsic_value_per_share, sr]);

  useEffect(() => {
    let percentage = getpitch.premium;
    if (
      percentage >= 0 &&
      percentage < 10 &&
      profileget.price <= getpitch.intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/S1.png");
      setfacechangein("/assets/images/H1.png");
    } else if (
      percentage >= 10 &&
      percentage < 25 &&
      profileget.price <= getpitch.intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/S2.png");
      setfacechangein("/assets/images/H2.png");
    } else if (
      percentage >= 25 &&
      percentage < 35 &&
      profileget.price <= getpitch.intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/S3.png");
      setfacechangein("/assets/images/H3.png");
    } else if (
      percentage >= 35 &&
      profileget.price <= getpitch.intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/S4.png");
      setfacechangein("/assets/images/H4.png");
    } else if (
      percentage >= 0 &&
      percentage < 10 &&
      profileget.price >= getpitch.intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/H1.png");
      setfacechangein("/assets/images/S1.png");
    } else if (
      percentage >= 10 &&
      percentage < 25 &&
      profileget.price >= getpitch.intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/H2.png");
      setfacechangein("/assets/images/S2.png");
    } else if (
      percentage >= 25 &&
      percentage < 35 &&
      profileget.price >= getpitch.intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/H3.png");
      setfacechangein("/assets/images/S3.png");
    } else if (
      percentage >= 35 &&
      profileget.price >= getpitch.intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/H4.png");
      setfacechangein("/assets/images/S4.png");
    }
  }, [
    profileget?.price,
    getpitch.intrinsic_value_per_share,
    getpitch.premium,
    getpitch.cumulative_loss,
    getpitch.book_value_per_share,
    getpitch.growth_in_bookvalue,
    getpitch.forward_multiple,
  ]);

  useEffect(() => {
    setgetpitch({
      stock_symbol: stockId,
      stock_name: profileget?.companyName,
      market_price: found?.market_price
        ? found?.market_price
        : profileget?.price,
      //tracked_market_price: profileget?.price,
    });
  }, [stockId, profileget, sr, found]);

  useEffect(() => {
    if (viewvaluationbyid.stock_symbol) {
      setgetpitch({
        stock_symbol: stockId,
        stock_name: viewvaluationbyid?.companyName,
        book_value_per_share: viewvaluationbyid.book_value_per_share,
        growth_in_bookvalue: viewvaluationbyid.growth_in_bookvalue
          ? viewvaluationbyid.growth_in_bookvalue
          : 1,
        bookvalue_per_share_at_endof_hold:
          viewvaluationbyid.bookvalue_per_share_at_endof_hold,
        forward_multiple: viewvaluationbyid.forward_multiple,
        market_price: profileget?.price,
        holding_period: viewvaluationbyid.holding_period,
        intrinsic_value_per_share: viewvaluationbyid.intrinsic_value_per_share,
        premium: viewvaluationbyid.premium,
        cumulative_loss: viewvaluationbyid.cumulative_loss * 100,
        irr: viewvaluationbyid.irr * 100,
        moic: viewvaluationbyid.moic,
        valuation_id: viewvaluationbyid.id,
        pitch_status: viewvaluationbyid.pitch_status,
        moats: viewvaluationbyid.moats,
        comment: viewvaluationbyid.comment,
        tracked_market_price: viewvaluationbyid?.tracked_market_price,
      });
    }
  }, [viewvaluationbyid]);

  useEffect(() => {
    return () => {
      if (displayType != "text" && changedValuation == true) {
        setvaluationSuccess();
      }
      setChangedValuation(false);
    };
  }, []);
  const usdPopover = (
    <Popover id="popover-basic" className="insp">
      <table className="table small_table  ">
        <tbody>
          <p className="sugg pl-1">{showcontrolstate}</p>
        </tbody>
      </table>
    </Popover>
  );
  useEffect(() => {
    console.log(sr, "9090909090909090");
  }, [valuatuionchanged]);

  useEffect(() => {
    if (showModal) {
      setChangedValuation(true);
    }
  }, [showModal]);
  const chartStyle = {
    height: 50,
    width: 210,
  };
  const growthRatePopover = (
    <Popover id="popover-basic" className="ins">
      <table className="table small_table">
        <tbody>
          <p className="sugg pl-1">Suggestions</p>
          <tr>
            <td width="25%">3 Yr - Avg</td>
            <td width="25%">5 Yr - Avg</td>
            <td width="25%">7 Yr - Avg</td>
            <td width="25%">10 Yr - Avg</td>
          </tr>
          <tr>
            <td> {year.threeyear}</td>
            <td> {year.fiveyear}</td>
            <td> {year.sevenyear}</td>
            <td> {year.tenyear}</td>
          </tr>
        </tbody>
      </table>
    </Popover>
  );

  const forwardPopover = (
    <Popover id="popover-basic" className="ins">
      <table className="table small_table">
        <tbody>
          <p className="sugg pl-1">Suggestions</p>
          <tr>
            <td width="25%">3 Yr - Avg</td>
            <td width="25%">5 Yr - Avg</td>
            <td width="25%">7 Yr - Avg</td>
            <td width="25%">10 Yr - Avg</td>
          </tr>
          <tr>
            <td> {yearforward.threeyears}</td>
            <td> {yearforward.fiveyears}</td>
            <td> {yearforward.sevenyears}</td>
            <td> {yearforward.tenyears}</td>
          </tr>
        </tbody>
      </table>
    </Popover>
  );

  const holdingPopover = (
    <Popover id="popover-basic" className="ins">
      <table className="table small_table">
        <tbody>
          <tr>
            <td width="25%">3 Yrs</td>
            <td width="25%">5 Yrs</td>
            <td width="25%">7 Yrs</td>
            <td width="25%">10 Yrs</td>
          </tr>
        </tbody>
      </table>
    </Popover>
  );

  useEffect(() => {
    getearnings(stockId, "11", "annual");
    getmonthlys(stockId, "annual", "11");
    getincomestatements(stockId, "2", "120");
    marketprofile(stockId);
    //NEW

    getrevenuepersharequar(stockId, "11", "annual");
  }, []);

  useEffect(() => {
    setinvalue(getpitch.intrinsic_value_per_share);
  }, [stockId, showModal, valuation_type]);

  useEffect(() => {
    if (getpitch.intrinsic_value_per_share < 0) {
      setDialogModal(true);
    }
  }, [getpitch.cumulative_loss]);

  useEffect(() => {
    setpitchvalue(getpitch);
  }, [getpitch, getpitch.intrinsic_value_per_share]);

  useEffect(() => {
    console.log(getrsquar, "iooooo");
    getrsquar.map((data: any, index: any) => {
      if (index == 0) {
        setpreviousyear(data.date);
      }
    });
    if (
      (!viewvaluationbyid.stock_symbol ||
        viewvaluationbyid.valuation_type_id != valuation_type) &&
      displayType != "text"
    ) {
      setgetpitch({
        book_value_per_share: getrsquar[0]
          ? getrsquar[0].bookValuePerShare.toFixed(2)
          : 1,
        comment: "",
      });
    }
    setifchange(getrsquar[0] ? getrsquar[0].bookValuePerShare.toFixed(2) : 0);
    settextedit(
      `${"Book Value Per Share "}  (${
        getcalendarYear
          ? getcalendarYear
          : getrsquar[0]
          ? moment(getrsquar[0].date).format("YYYY")
          : moment().subtract(3, "month").format("YYYY")
      }A)`
    );
    settexteditprev(`${"Expected Book Value Per Share "}`);
  }, [getrsquar, viewvaluationbyid, getcalendarYear]);

  useEffect(() => {
    let recentyear: any;
    let year3rd: any = "N/A";
    let year5th: any = "N/A";
    let year7th: any = "N/A";
    let year9th: any = "N/A";
    getrsquar.map((data: any, index: any) => {
      if (index == 0) {
        recentyear = data.bookValuePerShare;
      }
      if (index == 3) {
        if (data.bookValuePerShare >= 0) {
          year3rd = data.bookValuePerShare;
        } else {
          year3rd = "N/A";
        }
      }
      if (index == 5) {
        if (data.bookValuePerShare >= 0) {
          year5th = data.bookValuePerShare;
        } else {
          year5th = "N/A";
        }
      }
      if (index == 7) {
        if (data.bookValuePerShare >= 0) {
          year7th = data.bookValuePerShare;
        } else {
          year7th = "N/A";
        }
      }
      if (index == 10) {
        if (data.bookValuePerShare >= 0) {
          year9th = data.bookValuePerShare;
        } else {
          year9th = "N/A";
        }
      }
    });

    setyear({
      threeyear:
        year3rd != "N/A"
          ? ((Math.pow(recentyear / year3rd, 1 / 3) - 1) * 100).toFixed(2) + "%"
          : year3rd,
    });
    setyear({
      fiveyear:
        year5th != "N/A"
          ? ((Math.pow(recentyear / year5th, 1 / 5) - 1) * 100).toFixed(2) + "%"
          : year5th,
    });
    setyear({
      sevenyear:
        year7th != "N/A"
          ? ((Math.pow(recentyear / year7th, 1 / 7) - 1) * 100).toFixed(2) + "%"
          : year7th,
    });
    setyear({
      tenyear:
        year9th != "N/A"
          ? ((Math.pow(recentyear / year9th, 1 / 10) - 1) * 100).toFixed(2) +
            "%"
          : year9th,
    });
  }, [getrsquar]);

  useEffect(() => {
    console.log(year.threeyear, "yr");
  }, [year]);
  //For Forward Multiple Suggestions
  useEffect(() => {
    let ThirdYear = getfin.slice(0, 3);
    console.log(ThirdYear, "3");
    let FifthYear = getfin.slice(0, 5);
    console.log(FifthYear, "2");
    let SeventhYear = getfin.slice(0, 7);
    console.log(SeventhYear, "1");
    let TenthYear = getfin.slice(0, 10);
    console.log(TenthYear, "0");

    let ThirdYearFilter = ThirdYear.filter(
      (data: any) => data.priceToBookRatio >= 0
    );

    if (ThirdYear.length == ThirdYearFilter.length) {
      let ThirdYearValue = 0;
      if (ThirdYear.length > 0) {
        let startPoint = parseInt(moment(ThirdYear[0].date).format("YYYY")) + 1;

        ThirdYear.map((data: any, index: any) => {
          if (parseInt(moment(data.date).format("YYYY")) == startPoint - 1) {
            ThirdYearValue = data.priceToBookRatio + ThirdYearValue;
            startPoint = parseInt(moment(data.date).format("YYYY"));
          } else {
            ThirdYearValue = 0;
          }
        });
      }
      if (ThirdYearValue > 0) {
        setyearforward({ threeyears: (ThirdYearValue / 3).toFixed(2) });
      } else {
        setyearforward({ threeyears: "N/A" });
      }
    } else {
      setyearforward({ threeyears: "N/A" });
    }

    let FifthYearFilter = FifthYear.filter(
      (data: any) => data.priceToBookRatio >= 0
    );

    if (FifthYear.length == FifthYearFilter.length) {
      let FifthYearValue = 0;
      if (FifthYear.length > 0) {
        let startPoint = parseInt(moment(FifthYear[0].date).format("YYYY")) + 1;

        FifthYear.map((data: any, index: any) => {
          if (parseInt(moment(data.date).format("YYYY")) == startPoint - 1) {
            FifthYearValue = data.priceToBookRatio + FifthYearValue;
            startPoint = parseInt(moment(data.date).format("YYYY"));
          } else {
            FifthYearValue = 0;
          }
        });
        if (FifthYearValue > 0) {
          setyearforward({ fiveyears: (FifthYearValue / 5).toFixed(2) });
        } else {
          setyearforward({ fiveyears: "N/A" });
        }
      }
    } else {
    }

    let SeventhYearFilter = SeventhYear.filter(
      (data: any) => data.priceToBookRatio >= 0
    );

    if (SeventhYear.length == SeventhYearFilter.length) {
      let SeventhYearValue = 0;
      if (SeventhYear.length > 0) {
        let startPoint =
          parseInt(moment(SeventhYear[0].date).format("YYYY")) + 1;

        SeventhYear.map((data: any, index: any) => {
          if (parseInt(moment(data.date).format("YYYY")) == startPoint - 1) {
            SeventhYearValue = data.priceToBookRatio + SeventhYearValue;
            startPoint = parseInt(moment(data.date).format("YYYY"));
          } else {
            SeventhYearValue = 0;
          }
        });

        if (SeventhYearValue > 0) {
          setyearforward({ sevenyears: (SeventhYearValue / 7).toFixed(2) });
        } else {
          setyearforward({ sevenyears: "N/A" });
        }
      }
    } else {
    }

    let TenthYearFilter = TenthYear.filter(
      (data: any) => data.priceToBookRatio >= 0
    );

    if (TenthYear.length == TenthYearFilter.length) {
      let TenthYearValue = 0;
      if (TenthYear.length > 0) {
        let startPoint = parseInt(moment(TenthYear[0].date).format("YYYY")) + 1;
        TenthYear.map((data: any, index: any) => {
          if (parseInt(moment(data.date).format("YYYY")) == startPoint - 1) {
            TenthYearValue = data.priceToBookRatio + TenthYearValue;
            startPoint = parseInt(moment(data.date).format("YYYY"));
          } else {
            TenthYearValue = 0;
          }
        });

        if (TenthYearValue > 0) {
          setyearforward({ tenyears: (TenthYearValue / 10).toFixed(2) });
        } else {
          setyearforward({ tenyears: "N/A" });
        }
      }
    } else {
      setyearforward({ tenyears: "N/A" });
    }
  }, [getfin]);

  useEffect(() => {
    //setcaleraningpershare(20.51);
    let previousYear = getrsquar[0] ? getrsquar[0].revenuePerShare : 0;
    //console.log( Math.pow( ( 1 + (growthrate / 100)),holdingperiod ) * previousYear);
    setgetpitch({
      bookvalue_per_share_at_endof_hold:
        Math.pow(
          1 + getpitch.growth_in_bookvalue / 100,
          getpitch.holding_period
        ) * getpitch.book_value_per_share,
    });

    setgetpitch({
      cumulative_loss:
        getpitch.intrinsic_value_per_share / getpitch.market_price - 1,
    });
    setgetpitch({
      irr:
        Math.pow(
          getpitch.intrinsic_value_per_share / profileget.price,
          1 / getpitch.holding_period
        ) - 1,
    });

    setgetpitch({
      cumulative_loss:
        Math.pow(
          getpitch.intrinsic_value_per_share / getpitch.market_price,
          1 / getpitch.holding_period
        ) - 1,
    });
    setgetpitch({
      moic: getpitch.intrinsic_value_per_share / getpitch.market_price,
    });
    setinvalue(getpitch.intrinsic_value_per_share);
  }, [
    getincome,
    getpitch.forward_multiple,
    getpitch.growth_in_bookvalue,
    getpitch.holding_period,
    getpitch.bookvalue_per_share_at_endof_hold,
    getpitch.intrinsic_value_per_share,
    getpitch.premium,
    getpitch.cumulative_loss,
    getpitch.irr,
    getpitch.moic,
    getpitch.book_value_per_share,
  ]);
  const handleSelectOneEvent = (ev: any, eventId: any) => {
    if (!getpitch.moats.includes(eventId)) {
      setgetpitch({ moats: [...getpitch.moats, eventId] });
    } else {
      setgetpitch({
        moats: getpitch.moats.filter((id: any) => id !== eventId),
      });
    }
  };
  useEffect(() => {
    setgetpitch({
      intrinsic_value_per_share:
        getpitch.bookvalue_per_share_at_endof_hold * getpitch.forward_multiple,
    });
  }, [getpitch.bookvalue_per_share_at_endof_hold, getpitch.forward_multiple]);

  useEffect(() => {
    setgetpitch({
      premium:
        Math.abs(
          getpitch.market_price / getpitch.intrinsic_value_per_share - 1
        ) * 100,
    });
    if (getpitch.book_value_per_share <= 0) setDialogModal(true);
  }, [
    getpitch.market_price,
    getpitch.intrinsic_value_per_share,
    getpitch.book_value_per_share,
  ]);
  useEffect(() => {
    var intervalPB = setInterval(() => {
      marketprofile(stockId);
    }, 30000);
    return () => {
      clearInterval(intervalPB);
    };
  }, []);
  return (
    <>
      <Modal
        show={dialogModal}
        cssClass="modalhome"
        onHide={() => setDialogModal(false)}
      >
        <div className="modalselect pl-sm-5 pr-sm-5 pt-4 pb-sm-4 p-2">
          <Row>
            <Col sm={11}></Col>
            <Col sm={1}>
              <FaTimes
                className="text-right cursor-pointer"
                onClick={() => {
                  setDialogModal(false);
                }}
              ></FaTimes>
            </Col>
          </Row>

          <h3 className="mt-3 mb-2 bold-15 color-23 mb-4 text-center">
            {" "}
            {getpitch.book_value_per_share > 0
              ? `Try another model`
              : `Enter positive number or try another model`}
          </h3>
        </div>
      </Modal>
      <Col md={12} xl={invalue == "" ? size : size} className="mt-3">
        {hideset == "1" && (
          <>
            <div className="min-radius-7 table-value pl-4 pr-4 pt-2 pb-2 mb-3 mt-0">
              <Row>
                <Col sm={6} className="align-self-center">
                  <p className="mb-1 regular_14_a2">Valuation Methodology</p>
                  <h3 className="one-five mb-0">P/B Valuation</h3>
                </Col>
                {update != "1" && (
                  <Col sm={6} className="text-sm-right align-self-center">
                    <a
                      className="red_change"
                      onClick={() => {
                        setShowModal(true);
                        setvaluatuionchanged(true);
                      }}
                    >
                      Change
                    </a>
                  </Col>
                )}
                {update == "1" && (
                  <Col sm={6} className="text-right align-self-center">
                    <a
                      className="red_change"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      update
                    </a>
                  </Col>
                )}
              </Row>
            </div>
          </>
        )}
        <Row>
          <Col md={6}>
            <div
              className="min-radius-7 table-value pl-4 pr-4 pt-2 pb-2 mt-0"
              style={{ height: "100%" }}
            >
              <Row className="mb-3">
                <Col sm={5} className="pr-0">
                  <p className="m-0 bold-15">My Assumptions</p>
                </Col>
                {/* <Col sm={7} className="px-0 text-right align-self-center">
                <p className="mb-0 regular_12_a2">In millions where appropriate</p>
                </Col> */}
              </Row>

              {/* <Form> */}
              <div className="no-boreder model-table small_table_in">
                <div className="tip_tool">
                  <Row className="mb-2">
                    <Col sm={displayType == "input" ? 8 : 9} className="pr-0">
                      {getpitch.comment && (
                        <>
                          <input
                            value={getpitch.comment}
                            placeholder="Your input here"
                            type="any"
                            size={getpitch.comment.length}
                            className={`form-control table-left-input inline_field ${
                              displayType == "input" ? "" : "inputAsDisply"
                            }`}
                            onChange={(e) => {
                              settextedit(e.target.value);
                              settexteditprev(e.target.value);
                              setnamechange(e.target.value);
                              setgetpitch({ comment: e.target.value });
                            }}
                          />
                          <span className="">
                            {" "}
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              placement="right"
                              overlay={usdPopover}
                            >
                              <button className="btn">
                                <FaInfoCircle className="clrtol"></FaInfoCircle>
                              </button>
                            </OverlayTrigger>
                          </span>
                        </>
                      )}
                      {!getpitch.comment && (
                        <div className="psre">
                          <input
                            value={
                              getpitch.book_value_per_share == ifchange
                                ? textedit
                                : texteditprev
                            }
                            size={
                              (getpitch.book_value_per_share == ifchange
                                ? textedit
                                : texteditprev
                              ).length
                            }
                            placeholder="Your input here"
                            type="any"
                            className={`form-control table-left-input auto_input ${
                              displayType == "input" ? "" : "inputAsDisply"
                            }`}
                            onChange={(e) => {
                              settextedit(e.target.value);
                              settexteditprev(e.target.value);
                              setnamechange(e.target.value);
                              setgetpitch({ comment: e.target.value });
                            }}
                          />
                          <span className="">
                            {" "}
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              placement="right"
                              overlay={usdPopover}
                            >
                              <button className="btn">
                                <FaInfoCircle className="clrtol"></FaInfoCircle>
                              </button>
                            </OverlayTrigger>
                          </span>
                        </div>
                      )}

                      {/*  <p className="align-self-center mb-0">{ep == ifchange ? `Book Value per share (${moment(getrsquar[0].date).format("YYYY")} ${getrsquar[0].period})` : 'Normalised Book Value per share' }</p>  */}
                      {/*{moment(getincome[0].date).format("YYYY")} */}
                    </Col>
                    <Col sm={displayType == "input" ? 4 : 3}>
                      <NumberFormat
                        className={`${
                          displayType == "input"
                            ? `form-control table-right-input`
                            : "green-text text-right valueText"
                        }`}
                        displayType={displayType == "input" ? `input` : `text`}
                        value={getpitch.book_value_per_share}
                        onValueChange={(values) => {
                          const { formattedValue, value } = values;
                          setgetpitch({
                            book_value_per_share: parseFloat(value),
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm={displayType == "input" ? 8 : 9} className="pr-0">
                      <p className="align-self-center mb-0">
                        Book Value Per Share Growth Rate{" "}
                        {/* <span>
                          {" "}
                          <OverlayTrigger
                            trigger="hover"
                            placement="right"
                            overlay={growthRatePopover}
                          >
                            <button className="btn">
                              <FaInfoCircle className="clrtol"></FaInfoCircle>
                            </button>
                          </OverlayTrigger>
                        </span> */}
                      </p>
                    </Col>
                    <Col sm={displayType == "input" ? 4 : 3}>
                      <NumberFormat
                        className={`${
                          displayType == "input"
                            ? `form-control table-right-input`
                            : "green-text text-right valueText"
                        }`}
                        displayType={displayType == "input" ? `input` : `text`}
                        value={parseFloat(getpitch.growth_in_bookvalue).toFixed(
                          2
                        )}
                        onValueChange={(values) => {
                          const { formattedValue, value } = values;
                          setgetpitch({
                            growth_in_bookvalue: parseFloat(value),
                          });
                        }}
                        suffix={"%"}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm={displayType == "input" ? 8 : 9} className="pr-0">
                      <p className="align-self-center mb-0">
                        Forward P/B Multiple{" "}
                        {/* <span>
                          {" "}
                          <OverlayTrigger
                            trigger="hover"
                            placement="right"
                            overlay={forwardPopover}
                          >
                            <button className="btn">
                              <FaInfoCircle className="clrtol"></FaInfoCircle>
                            </button>
                          </OverlayTrigger>
                        </span> */}
                      </p>
                    </Col>

                    <Col sm={displayType == "input" ? 4 : 3}>
                      <NumberFormat
                        className={`${
                          displayType == "input"
                            ? `form-control table-right-input`
                            : "green-text text-right valueText"
                        }`}
                        displayType={displayType == "input" ? `input` : `text`}
                        value={parseFloat(getpitch.forward_multiple).toFixed(2)}
                        decimalSeparator="."
                        onValueChange={(values) => {
                          const { formattedValue, value, floatValue } = values;
                          setgetpitch({
                            forward_multiple: floatValue?.toFixed(2),
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm={displayType == "input" ? 8 : 9}>
                      <p className="align-self-center mb-0">Market Price </p>
                    </Col>
                    {displayType != "text" && (
                      <Col sm={displayType == "input" ? 4 : 3}>
                        <input
                          value={
                            profileget?.price
                              ? profileget?.price.toFixed(2)
                              : ""
                          }
                          placeholder="Your input here"
                          type="text"
                          className="form-control table-right-input"
                        />
                      </Col>
                    )}

                    {displayType == "text" && (
                      <Col sm={displayType == "input" ? 4 : 3}>
                        <NumberFormat
                          className={`${
                            displayType == "input"
                              ? `form-control table-right-input`
                              : "green-text text-right valueText"
                          }`}
                          displayType={
                            displayType == "input" ? `input` : `text`
                          }
                          value={parseFloat(getpitch.market_price).toFixed(2)}
                        />
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col
                      sm={displayType == "input" ? 8 : 9}
                      className="align-self-center pr-0"
                    >
                      <p className="mb-0">
                        Hold Period{" "}
                        <span>
                          <OverlayTrigger
                            trigger="hover"
                            placement="right"
                            overlay={holdingPopover}
                          >
                            <button className="btn">
                              <FaInfoCircle className="clrtol"></FaInfoCircle>
                            </button>
                          </OverlayTrigger>
                        </span>
                      </p>
                    </Col>
                    <Col sm={displayType == "input" ? 4 : 3}>
                      <NumberFormat
                        className={`${
                          displayType == "input"
                            ? `form-control table-right-input`
                            : "green-text text-right valueText"
                        }`}
                        displayType={displayType == "input" ? `input` : `text`}
                        value={getpitch.holding_period}
                        onValueChange={(values) => {
                          const { formattedValue, value } = values;
                          setgetpitch({ holding_period: parseFloat(value) });
                        }}
                        suffix={
                          getpitch.holding_period > 1 ? " years" : " year"
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              {/* </Form> */}
            </div>
          </Col>

          <Col xl={6} md={6}>
            <div className="min-radius-7 table-value pl-4 pr-4 pt-2 pb-2 market-cd-hs mt-0">
              <p className="m-0 bold-15 pb-3">My Valuation</p>
              <table className="no-boreder model-table">
                <tbody>
                  <tr>
                    <td width="80%">Book Value Per Share At End Of Hold</td>
                    <td>
                      {" "}
                      <NumberFormat
                        className="green-text text-right"
                        value={parseFloat(
                          getpitch.bookvalue_per_share_at_endof_hold
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td width="80%">Intrinsic Value</td>
                    <td>
                      {" "}
                      <NumberFormat
                        className="green-text text-right"
                        value={parseFloat(
                          getpitch.intrinsic_value_per_share
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td width="80%" colSpan={2}>
                      Moats
                      <br />
                      {getpitch.moats.map((list: any) => (
                        <span className="moats-text">{list}</span>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    {getpitch.market_price <=
                      getpitch.intrinsic_value_per_share && (
                      <td width="80%">Margin Of Safety</td>
                    )}
                    {getpitch.market_price >=
                      getpitch.intrinsic_value_per_share && (
                      <td width="80%">Premium</td>
                    )}
                    <td>
                      {" "}
                      <NumberFormat
                        className="green-text text-right"
                        value={parseFloat(getpitch.premium).toFixed(2)}
                        suffix={`%`}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <td width="80%">Meets M.O.S. Criterion</td>
                    <td>
                      {" "}
                      {meter > getpitch.premium && <span> No</span>}
                      {meter <= getpitch.premium && <span> Yes</span>}
                    </td>
                  </tr> */}
                  <tr>
                    {getpitch.intrinsic_value_per_share >=
                      getpitch.market_price && (
                      <td width="80%">Expected Gain</td>
                    )}
                    {getpitch.intrinsic_value_per_share <=
                      getpitch.market_price && (
                      <td width="80%">Expected Loss</td>
                    )}
                    <td>
                      {" "}
                      <NumberFormat
                        className="green-text text-right"
                        //value={(getpitch.cumulative_loss * 100).toFixed(2)}
                        value={(
                          (getpitch.intrinsic_value_per_share /
                            getpitch.market_price -
                            1) *
                          100
                        ).toFixed(2)}
                        suffix={`%`}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td width="80%">Expected IRR</td>
                    <td>
                      {" "}
                      <NumberFormat
                        className="green-text text-right"
                        value={(getpitch.irr * 100).toFixed(2)}
                        suffix={`%`}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td width="80%">Expected MOIC</td>
                    <td>
                      {" "}
                      <NumberFormat
                        className="green-text text-right"
                        value={parseFloat(getpitch.moic).toFixed(2)}
                        suffix={`X`}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          {hideset == "1" && (
            <Col md={12}>
              <div className="min-radius-7 table-value pl-4 pr-4 py-1">
                <Row>
                  <Col sm={4} className="end-img align-self-center">
                    {/* <GaugeChart id="gauge-chart1" style={chartStyle} 
                  nrOfLevels={30} 
                  colors={["#006400", "#00FF00	"]} 
                  arcWidth={0.3} 
                   percent={meter/100}
                   needleColor="#FFFFFF " 
                  /> */}
                    {/* <Speedometer
  value={54}
  max={80}
  angle={160}
  fontFamily='squada-one'
>
  <Background angle={180} />
  <Arc/>
  <Needle/>
  <Progress/>
  <Marks/>
  <Indicator>
    {(value:any, textProps:any) => (
      <p>
        {value}k/m
      </p>
    )}
  </Indicator>
</Speedometer> */}
                    <ReactSpeedometer
                      maxValue={100}
                      value={
                        profileget.price <= getpitch.intrinsic_value_per_share
                          ? getpitch.premium.toFixed(2)
                          : 0
                      }
                      startColor="#32CD32"
                      segments={10}
                      endColor="#006400"
                      width={230}
                      height={125}
                      ringWidth={30}
                      textColor="white"
                      needleHeightRatio={0.5}
                      needleColor="#FFFFFF"
                      labelFontSize="10"
                      customSegmentLabels={[
                        {
                          text: "10 %",
                          position: CustomSegmentLabelPosition.Outside,
                        },
                        {
                          text: "20 %",
                          position: CustomSegmentLabelPosition.Outside,
                        },
                        {
                          text: "30 %",
                          position: CustomSegmentLabelPosition.Outside,
                        },
                        {
                          text: "40 %",
                          position: CustomSegmentLabelPosition.Outside,
                        },
                        {
                          text: "50 %",
                          position: CustomSegmentLabelPosition.Outside,
                        },
                        {
                          text: "60 %",
                          position: CustomSegmentLabelPosition.Outside,
                        },
                        {
                          text: "70 %",
                          position: CustomSegmentLabelPosition.Outside,
                        },
                        {
                          text: "80 %",
                          position: CustomSegmentLabelPosition.Outside,
                        },
                        {
                          text: "90 %",
                          position: CustomSegmentLabelPosition.Outside,
                        },
                        {
                          text: "100 %",
                          position: CustomSegmentLabelPosition.Outside,
                        },
                      ]}
                    />
                    <div className="meter_style">
                      <NumberFormat
                        className="ReactSpeedometer-input"
                        displayType={displayType == "input" ? `input` : `text`}
                        value={
                          profileget.price <= getpitch.intrinsic_value_per_share
                            ? parseFloat(getpitch.premium).toFixed(0)
                            : 0
                        }
                        suffix={`%`}
                        decimalSeparator="."
                        onValueChange={(values) => {
                          const { formattedValue, value, floatValue } = values;
                          setmeter(parseInt(value));
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm={4} className="moats-img pl-5 mt-2">
                    <a
                      onClick={() => {
                        setShowModals1(true);
                      }}
                    >
                      <img
                        src="/assets/images/Moats Icon.png"
                        className="w-100 tyu"
                      />
                    </a>
                  </Col>
                  <Col sm={2} className="end-img">
                    <img src={facechange} className="w-100" />
                    <h5 className="img-text">Mr. Market</h5>
                  </Col>
                  <Col sm={2} className="end-img">
                    <img src={facechangein} className="w-100" />
                    {getuserprofile?.name && (
                      <div className="img-text">
                        <h5 className="clrbut">{getuserprofile.name}</h5>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          )}
        </Row>
      </Col>
      <Modal
        show={showModals1}
        cssClass="modalhome"
        onHide={() => setShowModals1(false)}
      >
        <div className="modalselect">
          <Popover id="popover-positioned-top">
            <Row className="">
              <Col className="px-4 py-2">
                <div className="table_value scroll-ball">
                  <table className="">
                    <thead className="moats-header">
                      {moatsList.map((list) => (
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              name="status"
                              value={moatsList}
                              defaultChecked={
                                getpitch.moats.indexOf(list) != -1
                              }
                              onChange={(ev) => handleSelectOneEvent(ev, list)}
                            />{" "}
                            {list}
                          </td>
                        </tr>
                      ))}
                    </thead>
                  </table>
                </div>
              </Col>
            </Row>
          </Popover>
        </div>
      </Modal>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isRegisterSuccess: state.user.isRegisterSuccess,
    stockList: state.stock.stockList,
    valuationDetail: state.stock.valuationDetail,
    getincome: state.market.getincome,
    getquotereturn: state.portfolio.getquotereturn,
    profileget: state.market.profileget,
    stockhistorical: state.portfolio.stockhistorical,
    getfin: state.portfolio.getfin,
    getuserprofile: state.user.getuserprofile,
    getcalendarYear: state.market.getcalendarYear,
    showcontrolstate: state.market.showcontrolstate,
    //NEW
    getrsquar: state.portfolio.getrsquar,
  }),
  mapDispatchToProps: {
    getrevenuepersharequar,
    updatepitch,
    getStockList,
    addvaluation,
    getincomestatements,
    getchange,
    marketprofile,
    getearnings,
    setvaluationSuccess,
    getmonthlys,
  },
  component: Myvaluationpb,
});

function reportType(stockId: any, arg1: string, reportType: any) {
  throw new Error("Function not implemented.");
}
