import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../App.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rangeslider/lib/index.css";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { FaTwitter } from "react-icons/fa";
import { getbalancestatement } from "../../data/market/market.actions";
import { AnyMessageParams } from "yup/lib/types";
import { RiArrowDownSLine } from "react-icons/ri";
import moment from "moment";
import NumberFormat from "react-number-format";
import ReactGA from "react-ga4";

interface OwnProps {
  stockId: any;
  balancestatement: any;
  reportType: any;
  profileget: any;
}

interface StateProps {}

interface DispatchProps {}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);

  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface BalancesheetProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const Balancesheet: React.FC<BalancesheetProps> = ({
  stockId,
  reportType,
  balancestatement,
  profileget,
}) => {
  const numberFormatMillon = (num: any) => {
    return +(num / 1000000).toFixed(2) + "";
  };
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  useEffect(() => {
    console.log(reportType, "reportType");
  }, [reportType]);

  return (
    <>
      <div className="outer outer_x">
        <div className="inner">
          <table className="table  grad_table table_harizontal">
            <tbody className="scroll market-scroll pr-0 pb-1">
              <tr className="clr">
                <th>Year</th>
                {balancestatement.map((year: any) => {
                  return (
                    <td>
                      {reportType == "annual"
                        ? "FY " + moment(year.date).format("YYYY")
                        : year.period + " " + moment(year.date).format("YYYY")}
                    </td>
                  );
                })}
              </tr>
              <tr className="clr">
                <th>Total Current Assets</th>
                {balancestatement.map((year: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(year.totalCurrentAssets)
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>

              <tr className="clr">
                <th>Total Non-Current Assets</th>
                {balancestatement.map((year: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(year.totalNonCurrentAssets)
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>

              <tr className="clr">
                <th>Total Assets</th>
                {balancestatement.map((year: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(year.totalAssets)
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>
              <tr className="clr">
                <th>Total Current Liabilities</th>
                {balancestatement.map((year: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(year.totalCurrentLiabilities)
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>
              <tr className="clr">
                <th>Total Non-Current Liabilites</th>
                {balancestatement.map((year: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(year.totalNonCurrentLiabilities)
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>
              <tr className="clr">
                <th>Total Liabilites</th>
                {balancestatement.map((year: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(year.totalLiabilities)
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>
              <tr className="clr">
                <th>Total Equity</th>
                {balancestatement.map((year: any) => {
                  return (
                    <td>
                      <NumberFormat
                        value={parseFloat(
                          numberFormatMillon(year.totalEquity)
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: Balancesheet,
});

//<NumberFormat  value={portfolio.investment_value} displayType={'text'} thousandSeparator={true} prefix={'$'} />//
