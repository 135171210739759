import React, { useState } from "react";

import { Button, Container, Toast, Row, Col, Modal } from "react-bootstrap";

import "./App.css";

import { FaFacebookF, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Marquee from "react-fast-marquee";

import "react-toastify/dist/ReactToastify.css";

var Loader = require("react-loader");

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="bg-footer">
        <Container fluid>
          <Row className="pt-sm-1 pb-sm-1 pt-2 pb-2">
            <Col sm={4} className="text-white text-center text-sm-left">
              <NavLink activeClassName="active-menu" to="/Contactus">
                <span className="green-text regular-12 pr-2">Contact us</span>
              </NavLink>
              {/* <span className="regular-12">033 5687466</span> */}
              <span className="regular-14"> |</span>
              <NavLink activeClassName="active-menu" to="/Privacypolicy">
                <span className="green-text regular-12"> Privacy Policy</span>{" "}
              </NavLink>
              <span className="regular-14"> |</span>
              <NavLink activeClassName="active-menu" to="/Termsofuse">
                <span className="green-text regular-12"> Terms Of Use</span>{" "}
              </NavLink>
              <span className="regular-14"> |</span>
              <NavLink activeClassName="active-menu" to="/Compliance">
                <span className="green-text regular-12"> Compliance Note</span>{" "}
              </NavLink>
            </Col>
            <Col sm={8} className="text-white text-sm-right text-center">
              <span className="regular-12">
                ©Copyright 2022 <span className="green-text"> Mystockpitch</span> All Rights
                Reserved.Powered by <span className="green-text">Solstium</span>{" "}
              </span>
            </Col>
            {/* <Col
                sm={1}
                className="align-self-center text-center text-sm-left pl-0"
              >
                <p className="m-0 one-two">
                  <a href="" className="green-text pr-2 footer-i">
                    <FaFacebookF></FaFacebookF>
                  </a>
                  <a href="" className="green-text pr-2 footer-i">
                    <FaYoutube className=""></FaYoutube>
                  </a>
                  <a href="" className="green-text">
                    <FaLinkedinIn></FaLinkedinIn>
                  </a>
                </p>
              </Col> */}
          </Row>
        </Container>
      </div>
    );
  }
}

export default Footer;
