import React, { useEffect, useState } from "react";

import { Button, Container, Toast, Row, Col, Modal } from "react-bootstrap";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import { stringify } from "querystring";
import { NavLink } from "react-router-dom";
import Marquee from "react-fast-marquee";
import "./App.css";
import { BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi";
import { BiFoodTag, BiHomeHeart } from "react-icons/bi";
import { FaGraduationCap, FaTimes } from "react-icons/fa";
import NumberFormat from "react-number-format";
import { ToastContainer, toast } from "react-toastify";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import moment from "moment";
import { FaTimesCircle } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { Navbar, Nav, NavDropdown, FormControl } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { FaHandHoldingHeart, FaRegBell } from "react-icons/fa";
import {
  IoIosNotificationsOutline,
  IoIosHelpCircle,
  IoMdExit,
  IoIosHome,
  IoIosSwap,
  IoIosAdd,
  IoIosShare,
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosCloudUpload,
  IoMdNotifications,
} from "react-icons/io";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "./data/connect";
import { getprofile } from "./data/user/user.actions";
import { addliveprice } from "./data/market/market.actions";
interface OwnProps {}

interface StateProps {
  getuserprofile: any;
  getliveprices: any;
}

interface DispatchProps {
  getprofile: typeof getprofile;
  addliveprice: typeof addliveprice;
}

interface HeaderProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const Header: React.FC<HeaderProps> = ({
  getprofile,
  getuserprofile,
  getliveprices,
  addliveprice,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    getprofile();
  }, []);

  useEffect(() => {
    addliveprice();
  }, []);

  //  const NavToast=()=>{
  //   toast.success("Coming Soon!")
  //  };

  return (
    <>
      <div className="green-ground color-top">
        <Container fluid className="">
          <div className="green-ground">
            <Row className="pt-2 pb-2 pt-sm-1 pb-sm-1">
              <Col md={3} xl={3} className="align-self-center">
                {/* <button type="button" className="dash_top_btn">
                  Leaderboard
                </button> */}
                {/* <button type="button" className="btn  btn_blog ml-2">
                  Blog
                </button> */}
              </Col>
              <Col md={12} xl={12} className="align-self-center">
                <div>
                  <Marquee>
                    {getliveprices.map((cngerepeat: any) => {
                      var name;
                      return (
                        <ul className="top-ul mb-0">
                          {(() => {
                            name = cngerepeat.symbol;
                            if (cngerepeat.symbol == "^GSPC") {
                              name = "S&P 500";
                            } else if (cngerepeat.symbol == "^NYA") {
                              name = "NYSE";
                            } else if (cngerepeat.symbol == "^DJI") {
                              name = "DJIA";
                            } else {
                              name = "NASDAQ";
                            }
                          })()}
                          <li>
                            {name}
                            <span className="bold-15 ml-2">
                              <NumberFormat
                                value={cngerepeat.price.toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </span>

                            {cngerepeat.change >= 0 && (
                              <span className="top-icon-up ml-2">
                                <BiUpArrowAlt className=""></BiUpArrowAlt>
                                {cngerepeat.change.toFixed(2)}(
                                {cngerepeat.changesPercentage.toFixed(2) + "%"})
                              </span>
                            )}
                            {cngerepeat.change < 0 && (
                              <span className="top-icon-end ml-2">
                                <BiDownArrowAlt className=""></BiDownArrowAlt>
                                {cngerepeat.change.toFixed(2)}(
                                {cngerepeat.changesPercentage.toFixed(2) + "%"})
                              </span>
                            )}
                          </li>
                        </ul>
                      );
                    })}
                  </Marquee>
                </div>
              </Col>

              {/*}    <ul className="top-ul mb-0">
            
              <li>S&P 500
                <span  className="bold-15 ml-2">43,356.23</span>
               
                <span className="top-icon-end ml-2"><BiUpArrowAlt className=""></ BiUpArrowAlt>{cngerepeat.price}</span>
              </li>
              <li>NYSE
                <span className="bold-15 ml-2">43,356.23</span>
                <span className="top-icon-end ml-2"><BiUpArrowAlt className=""></ BiUpArrowAlt>{cngerepeat.price}</span>
              </li>
              <li>DJIA 
                <span className="bold-15 ml-2">43,356.23</span>
                <span className="top-icon-end ml-2"><BiUpArrowAlt className=""></ BiUpArrowAlt>{cngerepeat.change}</span>
              </li>
              <li>Nasdaq 
                <span className="bold-15 ml-2">43,356.23</span>
                
                <span className="top-icon-end-gree ml-2"><BiUpArrowAlt className=""></ BiUpArrowAlt>{cngerepeat.change}</span>
               
              </li>
                      </ul>*/}
            </Row>
          </div>
        </Container>
      </div>
      <div className="gradent-black">
        <Container fluid className="">
          <Row className="hide_m_menu">
            <Col sm={5} xs={4} className="">
              <a className="ml-0 mt-3 mt-sm-0 mr-3">
                <img
                  className="img-fluid logo"
                  src="/assets/images/icon.005ba8da.png"
                />
              </a>
              <Navbar className="px-0 d-inline-block" expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="headerlink">
                    <NavLink
                      activeClassName="active-menu selected"
                      exact
                      to="/"
                    >
                      Mr. Market
                    </NavLink>

                    {getuserprofile.name && (
                      <>
                        <NavLink activeClassName="active-menu" to="/Pitches">
                          My Pitches
                        </NavLink>
                      </>
                    )}

                    {!getuserprofile.name && (
                      <>
                        <a
                          className="k"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          My Pitches
                        </a>
                      </>
                    )}

                    {getuserprofile.name && (
                      <>
                        <NavLink
                          activeClassName="active-menu"
                          to="/Mytrackedpitches"
                        >
                          My Tracked Pitches
                        </NavLink>
                      </>
                    )}

                    {!getuserprofile.name && (
                      <>
                        <a
                          className="k"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          My Tracked Pitches
                        </a>
                      </>
                    )}
                    <NavLink activeClassName="active-menu" to="/Aboutus">
                      About us
                    </NavLink>
                    {/*<NavLink
                      className="btn_selfi"
                      activeClassName="active-menu"
                      to="/selfless service"
                    >
                      <FaHandHoldingHeart></FaHandHoldingHeart> Selfless Service
                    </NavLink>*/}

                    {/*<NavLink  activeClassName="active-menu" to="/Portfolio"> My Portfolio</NavLink>*/}
                    {/* <NavLink  activeClassName="active-menu" to="/watchlist">My Watchlist</NavLink>*/}
                    {/*<NavLink  activeClassName="active-menu" to="/Dashboard">Managed Portfolios</NavLink> */}
                    {/* <NavLink  className="text_25_76" activeClassName="active-menu" to="#"><FaRegBell></FaRegBell></NavLink>*/}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>

            <Col sm={7} xs={6} className="align-self-center text-right">
              <div className="headerlink d-inline-block">
                <a className="btn_menus">
                  <button
                    className="btn_Toast"
                    onClick={() => {
                      setShowPopup(true);
                    }}
                  >
                    <span className="btn_sol"></span> Solve Hunger
                  </button>
                </a>
                <a className="btn_menus">
                  <FaGraduationCap className="btn_18"></FaGraduationCap>{" "}
                  <button
                    className="btn_Toast"
                    onClick={() => {
                      setShowPopup(true);
                    }}
                  >
                    Educate and Empower
                  </button>
                </a>
                <a className="btn_menus pr-4">
                  <BiHomeHeart className="btn_18"></BiHomeHeart>
                  <button
                    className="btn_Toast"
                    onClick={() => {
                      setShowPopup(true);
                    }}
                  >
                    Provide Shelter
                  </button>
                </a>
              </div>
              {getuserprofile.name && (
                <Dropdown as={ButtonGroup}>
                  {!getuserprofile.profile && (
                    <img
                      className="img-fluid d-inline-block profile"
                      src="/assets/images/juju.png"
                    />
                  )}
                  {getuserprofile.profile && (
                    <img
                      className="img-fluid d-inline-block profile"
                      // src="/assets/images/juju.png"

                      src={`https://api.mystockpitch.com/storage/images/profile/${getuserprofile.profile}`}
                    />
                  )}
                  <Button className="clrbut" variant="success">
                    {getuserprofile.name}
                  </Button>
                  <Dropdown.Toggle
                    className="clrbut"
                    split
                    variant="success"
                    id="dropdown-split-basic"
                  />
                  <Dropdown.Menu>
                    <Dropdown.Item className="clrbut">
                      <Link className="cpin" to="/Myaccount">
                        {" "}
                        Edit Profile
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="clrbut" href="/Logout">
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {!getuserprofile.name && (
                <div className="align-self-center float-sm-right">
                  <Link to="/signup">
                    <button type="button" className="btn_selfis mt-1">
                      Sign Up
                    </button>
                  </Link>
                  <Link to="/login">
                    <button type="button" className="log_top_btn mt-1 ml-3">
                      Login
                    </button>
                  </Link>
                </div>
              )}
            </Col>
          </Row>
          <div className="hide_w_menu">
            <Row className="">
              <Col sm={9} xs={10} className="text-center px-0">
                <Navbar className="px-0" expand="lg">
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="headerlink pl-2">
                      <NavLink
                        activeClassName="active-menu selected"
                        exact
                        to="/"
                      >
                        Mr. Market
                      </NavLink>

                      {getuserprofile.name && (
                        <>
                          <NavLink activeClassName="active-menu" to="/Pitches">
                            My Pitches
                          </NavLink>
                        </>
                      )}

                      {!getuserprofile.name && (
                        <>
                          <a
                            className="k"
                            onClick={() => {
                              setShowModal(true);
                            }}
                          >
                            My Pitches
                          </a>
                        </>
                      )}

                      {getuserprofile.name && (
                        <>
                          <NavLink
                            activeClassName="active-menu"
                            to="/Mytrackedpitches"
                          >
                            My Tracked Pitches
                          </NavLink>
                        </>
                      )}

                      {!getuserprofile.name && (
                        <>
                          <a
                            className="k"
                            onClick={() => {
                              setShowModal(true);
                            }}
                          >
                            My Tracked Pitches
                          </a>
                        </>
                      )}
                      <NavLink activeClassName="active-menu" to="/Aboutus">
                        About us
                      </NavLink>
                      {/*<NavLink
                      className="btn_selfi"
                      activeClassName="active-menu"
                      to="/selfless service"
                    >
                      <FaHandHoldingHeart></FaHandHoldingHeart> Selfless Service
                    </NavLink>*/}
                      <NavLink className="btn_menus" activeClassName="" to="/">
                        <span className="btn_sol"></span> Solve Hunger
                      </NavLink>
                      <NavLink
                        className="btn_menus"
                        activeClassName=""
                        to="/EmptyPage"
                      >
                        <FaGraduationCap className="btn_18"></FaGraduationCap>{" "}
                        Educate and Empower
                      </NavLink>
                      <NavLink
                        className="btn_menus"
                        activeClassName=""
                        to="/EmptyPage"
                      >
                        <BiHomeHeart className="btn_18"></BiHomeHeart> Provide
                        Shelter
                      </NavLink>
                      {/*<NavLink  activeClassName="active-menu" to="/Portfolio"> My Portfolio</NavLink>*/}
                      {/* <NavLink  activeClassName="active-menu" to="/watchlist">My Watchlist</NavLink>*/}
                      {/*<NavLink  activeClassName="active-menu" to="/Dashboard">Managed Portfolios</NavLink> */}
                      {/* <NavLink  className="text_25_76" activeClassName="active-menu" to="#"><FaRegBell></FaRegBell></NavLink>*/}
                      {getuserprofile.name && (
                        <div className="text-left">
                          <Dropdown as={ButtonGroup}>
                            {!getuserprofile.profile && (
                              <img
                                className="img-fluid d-inline-block profile"
                                src="/assets/images/juju.png"
                              />
                            )}

                            {getuserprofile.profile && (
                              <img
                                className="img-fluid mt-1 relativec upload-img"
                                // src="/assets/images/juju.png"

                                src={`https://api.mystockpitch.com/storage/images/profile/${getuserprofile.profile}`}
                              />
                            )}
                            <Button className="clrbut" variant="success">
                              {getuserprofile.name}
                            </Button>
                            <Dropdown.Toggle
                              className="clrbut"
                              split
                              variant="success"
                              id="dropdown-split-basic"
                            />
                            <Dropdown.Menu>
                              <Dropdown.Item className="clrbut">
                                <Link className="cpin" to="/Myaccount">
                                  Edit Profile
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item className="clrbut" href="/Logout">
                                Logout
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
                      {!getuserprofile.name && (
                        <Col className="align-self-center">
                          <Link to="/signup">
                            <button type="button" className="btn_selfis mt-1">
                              Sign Up
                            </button>
                          </Link>
                          <Link to="/login">
                            <button
                              type="button"
                              className="log_top_btn mt-1 ml-3"
                            >
                              Login
                            </button>
                          </Link>
                        </Col>
                      )}
                      <Modal
                        show={showModal}
                        cssClass="modalhome"
                        onHide={() => setShowModal(false)}
                      >
                        <div className="modalselect pl-sm-5 pr-sm-5 pt-4 pb-sm-4 p-2">
                          <h3 className="mt-3 mb-2 bold-15 color-23 mb-4">
                            {" "}
                            Please <a href="/login">Login</a> or{" "}
                            <a href="/signup"> Sign Up </a> to Continue!
                          </h3>
                          <div className="mt-4 mb-3">
                            <Link to="/login">
                              <Button className="green-ground w-100 btn-login">
                                Login
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </Modal>

                      <Modal
                        show={showPopup}
                        cssClass="modalhome"
                        onHide={() => setShowPopup(false)}
                      >
                        <div className="close-pop">
                          <FaTimesCircle
                            onClick={() => {
                              setShowPopup(false);
                            }}
                          />
                        </div>
                        <div className="modalselect pl-sm-5 pr-sm-5 pt-4 pb-sm-4 p-2">
                          <h3 className="mt-3 mb-2 bold-15 color-23 mb-4 pop-up">
                            Coming Soon !
                          </h3>
                        </div>
                      </Modal>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Col>
              <Col sm={1} xs={2} className="align-self-top pt-3">
                <a className="ml-0 mt-3 mt-sm-0 pt-3">
                  <img
                    className="img-fluid logo"
                    src="/assets/images/icon.005ba8da.png"
                  />
                </a>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    getuserprofile: state.user.getuserprofile,
    getliveprices: state.market.getliveprices,
  }),
  mapDispatchToProps: { getprofile, addliveprice },
  component: Header,
});
