import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import { BiFilterAlt, BiBookmark, BiLinkExternal } from "react-icons/bi";
import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header";
import Footer from "../footer";
import ReactGA from "react-ga4";
//import MyPitch from './MyPitch';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "../App.css";

import { connect } from "../data/connect";
import * as selectors from "../data/selectors";
import { RouteComponentProps } from "react-router";

import { AiOutlineDelete } from "react-icons/ai";
import { getquotespitch, trackview } from "../data/portfolio/portfolio.actions";
import {
  deletetrackedpitches,
  trackedpitchupdate,
} from "../data/Pitches/pitches.actions";
import { transpileModule } from "typescript";
import { GiTrumpetFlag } from "react-icons/gi";
import NumberFormat from "react-number-format";

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  gettrackview: any;
  trackedpitchesdeletereload: any;
  getquotereturnpitch: any;
}

interface DispatchProps {
  trackview: typeof trackview;
  deletetrackedpitches: typeof deletetrackedpitches;
  trackedpitchupdate: typeof trackedpitchupdate;
  getquotespitch: typeof getquotespitch;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface MytrackedpitchesProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const Mytrackedpitches: React.FC<MytrackedpitchesProps> = ({
  trackview,
  gettrackview,
  deletetrackedpitches,
  trackedpitchesdeletereload,
  trackedpitchupdate,
  history,
  getquotereturnpitch,
  getquotespitch,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [pitchId, setPitchId] = useState(0);
  const [invesname, setinvesname] = useState("");
  const [displayType, setdisplayType] = useState("text");
  const [pitchsymbol, SetPitchsymbol] = useState([""]);
  
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  const deletepitches = () => {
    deletetrackedpitches(gettrackview.id);
  };

  const [trackupdate, settrackupdate] = useMergeState({
    valuation_id: "",
    tracked_intrinsic_value_per_share: "",
  });

  useEffect(() => {
    settrackupdate({
      valuation_id: gettrackview?.id,

      tracked_intrinsic_value_per_share:
        gettrackview?.intrinsic_value_per_share,
    });
  }, [gettrackview]);

  useEffect(() => {
    trackview();
  }, []);

  useEffect(() => {
    if (trackedpitchesdeletereload == true) {
      trackview();
    }
  }, [trackedpitchesdeletereload]);

  useEffect(() => {
    let mySymbol: any = [];
    gettrackview?.map((pitches: any, index: any) => {
      mySymbol.push(pitches.stock_symbol);
    });

    SetPitchsymbol(mySymbol);
  }, [gettrackview]);

  useEffect(() => {
    var pitchSymbolTimeOut = setInterval(() => {
      getquotespitch(pitchsymbol);
    }, 30000);
    return () => {
      clearInterval(pitchSymbolTimeOut);
    };
  }, [pitchsymbol]);
  console.log(pitchsymbol, "zzzzzzzzzzzzzzzzzzzz");
  useEffect(() => {
    if (gettrackview?.market_price >= gettrackview?.intrinsic_value_per_share) {
      setinvesname("Revisit Investment Idea");
    }
    if (gettrackview?.market_price <= gettrackview?.intrinsic_value_per_share) {
      setinvesname("On Track");
    }
  }, [gettrackview]);

  return (
    <div className="bg_strike">
      <Header />
      <Container fluid className="contact_101">
        <Row>
          <Col sm={12} className="mt-3">
            <h3 className="bold_15 text-white text-left mb-4">
              My Tracked Pitches
            </h3>
            <div className="table-responsive dash-table-div tracked-table-div p-3 mt-0 track_table track_pitch_h scroll-ball">
              <Table className="table">
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Cost Per Share</th>
                    <th>Market Price</th>
                    <th>Intrinsic Value Per Share</th>
                    <th>Margin of Safety/Premium</th>
                    <th>Actual Gain/Loss</th>
                    <th>Expected Cumulative Gain/Loss</th>
                    <th>Actual MOIC</th>
                    <th>Expected MOIC</th>
                    {/* <th>Investment Status</th> */}
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {gettrackview?.map((type: any, index: any) => {
                    const found = getquotereturnpitch?.find(
                      (element: any) => element?.symbol == type?.stock_symbol
                    );

                    let marketPrice = found?.price
                      ? found?.price
                      : type.market_price;
                    // let premiumcalculation = Math.abs(
                    //   parseFloat(marketPrice) /
                    //     parseFloat(type.intrinsic_value_per_share) -
                    //     1
                    // );
                    const marginOfSafety = Math.abs(
                      parseFloat(marketPrice) /
                        parseFloat(type.intrinsic_value_per_share) -
                        1
                    );
                    const actualGain =
                      parseFloat(marketPrice) /
                        parseFloat(type.tracked_market_price) -
                      1;
                    const cumloss =
                      parseFloat(type.intrinsic_value_per_share) /
                        parseFloat(type.tracked_market_price) -
                      1;

                    // const actualmoic = type.market_price / type.tracked_market_price;
                    // const exmoic = type.tracked_intrinsic_value_per_share / type.tracked_market_price;
                    return (
                      <tr>
                        <td>{type.stock_name}</td>
                        <td>
                          {parseFloat(type.tracked_market_price).toFixed(2)}
                        </td>
                        <td>{parseFloat(marketPrice).toFixed(2)}</td>
                        <td>
                          <NumberFormat
                            className="green-text text-right"
                            defaultValue={parseFloat(
                              type.intrinsic_value_per_share
                            ).toFixed(2)}
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              type.intrinsic_value_per_share = value;
                            }}
                            displayType={
                              displayType == "input" ? `input` : `text`
                            }
                            thousandSeparator={true}
                          />
                        </td>
                        {parseFloat(marketPrice) <=
                          parseFloat(type.intrinsic_value_per_share) && (
                          <td>
                            <span> Margin of Safety </span>
                            <NumberFormat
                              className="green-text text-right"
                              value={(marginOfSafety * 100).toFixed(2)}
                              suffix={`%`}
                              displayType={"text"}
                              thousandSeparator={true}
                            />{" "}
                          </td>
                        )}
                        {parseFloat(marketPrice) >
                          parseFloat(type.intrinsic_value_per_share) && (
                          <td>
                            <span> Premium </span>
                            <NumberFormat
                              className="green-text text-right"
                              value={(marginOfSafety * 100).toFixed(2)}
                              suffix={`%`}
                              displayType={"text"}
                              thousandSeparator={true}
                            />{" "}
                          </td>
                        )}

                        {parseFloat(marketPrice) >=
                          parseFloat(type.tracked_market_price) && (
                          <td>
                            <span> Gain </span>
                            <NumberFormat
                              className="green-text text-right"
                              value={(actualGain * 100).toFixed(2)}
                              suffix={`%`}
                              displayType={"text"}
                              thousandSeparator={true}
                            />{" "}
                          </td>
                        )}
                        {parseFloat(marketPrice) <
                          parseFloat(type.tracked_market_price) && (
                          <td>
                            <span> Loss </span>
                            <NumberFormat
                              className="green-text text-right"
                              value={(actualGain * 100).toFixed(2)}
                              suffix={`%`}
                              displayType={"text"}
                              thousandSeparator={true}
                            />{" "}
                          </td>
                        )}
                        {parseFloat(type.intrinsic_value_per_share) >=
                          parseFloat(type.tracked_market_price) && (
                          <td>
                            {" "}
                            <span> Gain </span>{" "}
                            <NumberFormat
                              className="green-text text-right"
                              value={(cumloss * 100).toFixed(2)}
                              suffix={`%`}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                        )}
                        {parseFloat(type.intrinsic_value_per_share) <
                          parseFloat(type.tracked_market_price) && (
                          <td>
                            <span> Loss </span>
                            <NumberFormat
                              className="green-text text-right"
                              value={(cumloss * 100).toFixed(2)}
                              suffix={`%`}
                              displayType={"text"}
                              thousandSeparator={true}
                            />{" "}
                          </td>
                        )}

                        <td>
                          {(
                            parseFloat(marketPrice) /
                            parseFloat(type.tracked_market_price)
                          ).toFixed(2)}
                          {`x`}
                        </td>
                        <td>
                          {(
                            parseFloat(type.intrinsic_value_per_share) /
                            parseFloat(type.tracked_market_price)
                          ).toFixed(2)}
                          {`x`}
                        </td>
                        {/* {parseFloat(marketPrice) >=
                          parseFloat(type?.tracked_market_price) &&
                          parseFloat(marketPrice) <
                            parseFloat(type?.intrinsic_value_per_share) && (
                            <td>On Track</td>
                          )}
                        {parseFloat(marketPrice) <
                          parseFloat(type?.tracked_market_price) && (
                          <td>Revisit Investment Idea</td>
                        )}
                        {parseFloat(marketPrice) >=
                          parseFloat(type?.intrinsic_value_per_share) && (
                          <td>Homerun</td>
                        )} */}
                        <td>{invesname}</td>
                        <td>
                          <span
                            className="ai_delete"
                            style={{ cursor: "pointer" }}
                          >
                            <AiOutlineDelete
                              onClick={() => {
                                deletetrackedpitches(type.id);
                              }}
                            ></AiOutlineDelete>
                          </span>
                        </td>
                        {displayType == "text" && (
                          <>
                            <td>
                              <button
                                type="button"
                                className="btn btn-danger mr-3 editBtn"
                                onClick={() => {
                                  history.push(`${`/Market/${type?.id}`}`);
                                }}
                              >
                                Edit
                              </button>
                            </td>
                          </>
                        )}
                        {displayType == "input" && (
                          <>
                            <td>
                              <button
                                type="button"
                                className="btn btn-danger mr-3 editBtn"
                                onClick={() => {
                                  trackedpitchupdate({
                                    valuation_id: type?.id,
                                    intrinsic_value_per_share:
                                      type?.intrinsic_value_per_share,
                                  });
                                  setdisplayType("text");
                                }}
                              >
                                Update
                              </button>
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    gettrackview: state.portfolio.gettrackview,
    trackedpitchesdeletereload: state.pitches.trackedpitchesdeletereload,
    getquotereturnpitch: state.portfolio.getquotereturnpitch,
  }),
  mapDispatchToProps: {
    trackview,
    deletetrackedpitches,
    trackedpitchupdate,
    getquotespitch,
  },
  component: Mytrackedpitches,
});
