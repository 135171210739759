import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";
import { BiFilterAlt, BiBookmark, BiLinkExternal } from "react-icons/bi";
import "../../App.css";
import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../header";
import Footer from "../../footer";
import ReactGA from "react-ga4";
import MyPortfolio from "./MyPortfolio";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { RouteComponentProps } from "react-router";
import { getPortfolioList } from "../../data/portfolio/portfolio.actions";

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  isRegisterSuccess: any;
  portfolioList: any;
}

interface DispatchProps {
  getPortfolioList: typeof getPortfolioList;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface PortfolioProps extends OwnProps, StateProps, DispatchProps {}

const Portfolio: React.FC<PortfolioProps> = ({
  isRegisterSuccess,
  getPortfolioList,
  portfolioList,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [portfolioId, setPortfolioId] = useState(0);
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  return (
    <div className="">
      <div className="login-back">
        <Header />
        <Container fluid>
          <MyPortfolio />
        </Container>
        <Footer />
      </div>
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isRegisterSuccess: state.user.isRegisterSuccess,
    portfolioList: state.portfolio.portfolioList,
  }),
  mapDispatchToProps: { getPortfolioList },
  component: Portfolio,
});
