import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  Spinner,
  Tab,
  Tabs,
  Accordion,
  Card,
  Dropdown,
  useAccordionToggle,
  Nav,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  BiFilterAlt,
  BiBookmark,
  BiUpArrowAlt,
  BiDownArrowAlt,
} from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
import { FaAngleDown, FaTimes } from "react-icons/fa";
import ReactGA from "react-ga4";
import { RiArrowDownSLine, RiCloseFill } from "react-icons/ri";
import "../../App.css";

import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rangeslider/lib/index.css";

import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import {
  gethistoricaldata,
  getStockProfile,
  getValuationTypes,
  addvaluation,
  addPitch,
  setvaluationSuccess,
} from "../../data/stock/stock.actions";
import moment from "moment";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

import { addwatchlist } from "../../data/watchlist/watchlist.actions";
import {
  getbalancestatement,
  getcashflow,
  getincomestatement,
  ignorevaluation,
  marketprofile,
  marketratio,
} from "../../data/market/market.actions";
import {
  getmonthly,
  getincome,
  getbalance,
  updatepitch,
  addpitch,
  getmetirials,
  getweekly,
  getdaily,
  getquotes,
  addfbmarket,
  addfbmarketfiveday,
  addfbmarketonemonth,
  gettm,
  updatettm,
  getebidquar,
  getebidanu,
} from "../../data/portfolio/portfolio.actions";

import InvesmentDropDown from "./Invesmentdropdown";
import News from "./News";
import Balancesheet from "./Balancesheet";
import Cashflowstatement from "./Cashflowstatement";
import FinancialRatio from "./FinancialRatio";
import Incomestatement from "./Incomestatement";
import { AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";
import { getprofile } from "../../data/user/user.actions";

interface OwnProps {
  stockId: any;
  viewbusiness: any;
  SetValuation_type: any;
  SetValuation_text: any;
  showModal: any;
  setShowModal: any;
  setViewBusiness: any;
  viewBusiness: any;
  invalue: any;
  setinvalue: any;
  pitchvalue: any;
  setpitchvalue: any;
  historys: any;
  viewvaluationbyid: any;
  pitchedited: any;
  history: any;
  setusd: any;
}

interface StateProps {
  stockProfile: any;
  datahistorical: any;
  stockList: any;
  valuationTypes: any;
  valuationDetail: any;
  portfolioList: any;
  stockhistorical: any;
  profileget: any;
  getmarketratio: any;
  getquotereturn: any;
  fbmarketget: any;
  ttmvalue: any;
  cashflow: any;
  balancestatement: any;
  incomestatementget: any;
  setchange: any;
  hidevaluation: any;
  getuserprofile: any;
  afterpitchreload: any;
  showcontrolstate: any;
}

interface DispatchProps {
  getStockProfile: typeof getStockProfile;
  gethistoricaldata: typeof gethistoricaldata;
  getValuationTypes: typeof getValuationTypes;
  addvaluation: typeof addvaluation;
  addPitch: typeof addPitch;
  addwatchlist: typeof addwatchlist;
  ignorevaluation: typeof ignorevaluation;
  getmonthly: typeof getmonthly;
  getweekly: typeof getweekly;
  getdaily: typeof getdaily;
  marketprofile: typeof marketprofile;
  marketratio: typeof marketratio;
  getincome: typeof getincome;
  getbalance: typeof getbalance;
  getmetirials: typeof getmetirials;
  getquotes: typeof getquotes;
  addfbmarket: typeof addfbmarket;
  addfbmarketfiveday: typeof addfbmarketfiveday;
  addfbmarketonemonth: typeof addfbmarketonemonth;
  gettm: typeof gettm;
  updatettm: typeof updatettm;
  getcashflow: typeof getcashflow;
  getbalancestatement: typeof getbalancestatement;
  getincomestatement: typeof getincomestatement;
  addpitch: typeof addpitch;
  updatepitch: typeof updatepitch;
  getebidquar: typeof getebidquar;
  getebidanu: typeof getebidanu;
  getprofile: typeof getprofile;
  setvaluationSuccess: typeof setvaluationSuccess;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);

  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

const AxisLabel: React.FC<any> = ({
  axisType,
  x,
  y,
  width,
  height,
  stroke,
  children,
}) => {
  const isVert = axisType === "yAxis";
  const cx = isVert ? x : parseInt(x) + parseInt(width) / 2;
  const cy = isVert
    ? parseInt(height) / 2 + parseInt(y)
    : parseInt(y) + parseInt(height) + 10;
  const rot = isVert ? `270 ${cx} ${cy}` : 0;
  return (
    <text
      x={cx}
      y={cy}
      style={{
        opacity: 1,
        display: "block",
        fontSize: "12px",
        fill: "#00CF76",
      }}
      transform={`rotate(${rot})`}
      textAnchor="middle"
      stroke={stroke}
    >
      {children}
    </text>
  );
};

interface MyPortfolioProps extends OwnProps, StateProps, DispatchProps {}

const StockProfile: React.FC<MyPortfolioProps> = ({
  getprofile,
  getuserprofile,
  setvaluationSuccess,
  updatepitch,
  historys,
  pitchedited,
  viewvaluationbyid,
  getebidquar,
  getebidanu,
  setViewBusiness,
  setpitchvalue,
  addpitch,
  pitchvalue,
  setinvalue,
  viewBusiness,
  portfolioList,
  hidevaluation,
  showModal,
  setShowModal,
  setchange,
  valuationTypes,
  getStockProfile,
  stockId,
  stockProfile,
  ignorevaluation,
  viewbusiness,
  gethistoricaldata,
  stockList,
  datahistorical,
  getValuationTypes,
  addvaluation,
  SetValuation_type,
  SetValuation_text,
  valuationDetail,
  addPitch,
  addwatchlist,
  stockhistorical,
  getmonthly,
  getdaily,
  getweekly,
  profileget,
  marketprofile,
  getmarketratio,
  marketratio,
  getincome,
  getbalance,
  getmetirials,
  getquotereturn,
  invalue,
  balancestatement,
  getincomestatement,
  incomestatementget,
  getquotes,
  fbmarketget,
  addfbmarket,
  addfbmarketfiveday,
  addfbmarketonemonth,
  gettm,
  updatettm,
  ttmvalue,
  getcashflow,
  cashflow,
  getbalancestatement,
  history,
  afterpitchreload,
  showcontrolstate,
  setusd,
}) => {
  const [showModals, setShowModals] = useState(false);

  const [input, setinput] = useMergeState("");
  const [insvalue, setInsvalue] = useState(100000000);
  const [chartFilter, setChartFilter] = useState("close");
  const [charttype, setcharttype] = useState("AreaChart");
  const [chartfrequency, setchartfrequency] = useState<any>([]);
  const [chartendpoint, setchartendpoint] = useState("historical");
  const [chartname, setchartname] = useState("");
  const [portfolioId, setPortfolioId] = useState("");
  const [valuation, setValuation] = useState("");
  const [getpoint, setgetpoint] = useState("21");
  const [getpoints, setgetpoints] = useState("");
  const [getformat, setgetformat] = useState("h:mm a");
  const [getinterval, setgetinterval] = useState(12);
  const [getnull, setgetnull] = useState(0);
  const [getttm, setgetttm] = useState("");
  const [viewvaluebus, setviewvaluebus] = useState(false);
  const [gettittle, setgettitittle] = useState("Price ");
  const [getfrequencyformat, setgetfrequencyformat] = useState("h:mm a");
  const [getvalue, setgetvalue] = useState("");
  const [historicalData, setHistoricalData] = useState<any>([]);
  const m = moment();
  const [reportType, setReportType] = useState("5min");
  const [reportTypeVal, setReportTypeVal] = useState("1D");
  const [extraData, setExtraData] = useState<any>({
    fromDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    id: "5min",
  });
  const [chartMin, setChartMin] = useState<any>(0);
  const [chartMax, setChartMax] = useState<any>(0);
  const [statementset, setstatementset] = useState("true");
  const [currency, setcurrency] = useState("false");
  const [datesplit, setdatesplit] = useState<any>("");
  const [reportedCurrency, setReportedCurrency] = useState<any>("");
  const [setintcurrency, setsetintcurrency] = useState("");
  const [acc, setacc] = useState(false);
  const [defname, setdefname] = useState("Price Chart");
  const [panelIndex, setPanelIndex] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [calenderval, setcalenderval] = useState(false);
  var controller = new AbortController();
  var signal: any = "";
  const [graph, setGraph] = useState([
    {
      id: "close",
      isstatement: "true",
      currency: "false",
      points: "63",
      tittle: "Price",
      value: "Price",
      type: "AreaChart",
      extra: {
        fromDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        id: "5min",
      },
      frequency: [
        {
          value: "1D",
          id: "quarter",
          point: "21",
          interval: 12,
          format: "h:mm a",
          extra: {
            fromDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
            toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
            id: "5min",
          },
        },
        {
          value: "5D",
          id: "quarter",
          point: "50",
          interval: 12,
          format: "DD-MMM",
          extra: {
            fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
            toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
            id: "30min",
          },
        },
        {
          value: "1M",
          id: "quarter",
          point: "13",
          interval: 5,
          format: "DD-MMM",
          extra: {
            fromDate: moment().subtract(32, "days").format("YYYY-MM-DD"),
            toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
            id: "1min",
          },
        },
        {
          value: "6M",
          id: "quarter",
          point: "9",
          interval: 39,
          format: "MMM-YYYY",
          extra: {
            fromDate: moment()
              .subtract(6, "months")
              .startOf("month")
              .format("YYYY-MM-DD"),
            toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
            id: "1min",
          },
        },
        {
          value: "YTD",
          id: "quarter",
          point: "5",
          interval: 34,
          format: "MMM-YYYY",
          extra: {
            fromDate: moment().startOf("year").format("YYYY-MM-DD"),
            toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
            id: "1min",
          },
        },
        {
          value: "1Y",
          id: "quarter",
          point: "5",
          interval: 36,
          format: "MMM-YYYY",
          extra: {
            fromDate: moment()
              .subtract(12, "months")
              .startOf("month")
              .format("YYYY-MM-DD"),
            toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
            id: "1min",
          },
        },
        {
          value: "5Y",
          id: "quarter",
          point: "5",
          interval: 210,
          format: "YYYY",
          extra: {
            fromDate: moment()
              .subtract(5, "years")
              .startOf("month")
              .format("YYYY-MM-DD"),
            toDate: moment()
              .subtract(1, "days")
              .endOf("month")
              .format("YYYY-MM-DD"),
            id: "1min",
          },
        },
        {
          value: "MAX",
          id: "quarter",
          point: "5",
          interval: 300,
          format: "YYYY",
          extra: {
            fromDate: moment()
              .subtract(100, "years")
              .startOf("month")
              .format("YYYY-MM-DD"),
            toDate: moment()
              .subtract(1, "days")
              .endOf("month")
              .format("YYYY-MM-DD"),
            id: "1min",
          },
        },
      ],
      endpoint: "historical",
      format: "S",
      dataFormat: "quarter",
    },
    {
      id: "totalAssets",
      isstatement: "true",
      currency: "true",
      points: "63",
      tittle: "Total Assets ",
      value: "Total Assets - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "16",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "balance",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "totalLiabilities",
      isstatement: "true",
      currency: "true",
      points: "63",
      tittle: "Total Liabilities ",
      value: "Total Liabilities - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "balance",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "totalStockholdersEquity",
      isstatement: "true",
      currency: "true",
      points: "63",
      tittle: "Shareholder Equity ",
      value: "Shareholder Equity - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "balance",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "currentRatio",
      isstatement: "true",
      points: "63",
      currency: "false",
      tittle: "Current Ratio",
      value: "Current Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "metrics",
      format: "A",
      dataFormat: "quarter",
    },
    {
      id: "quickRatio",
      isstatement: "true",
      points: "63",
      currency: "false",
      tittle: "Quick Ratio",
      value: "Quick Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "A",
      dataFormat: "quarter",
    },
    {
      id: "debtEquityRatio",
      isstatement: "true",
      currency: "false",
      points: "63",
      tittle: "Debt to Equity Ratio",
      value: "Debt to Equity Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "A",
      dataFormat: "quarter",
    },
    {
      id: "returnOnEquity",
      isstatement: "true",
      currency: "false",
      points: "63",
      tittle: "Return on Equity (%)",
      value: "Return on Equity - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "returnOnEquity",
      isstatement: "true",
      currency: "false",
      points: "16",
      tittle: "Return on Equity (%)",
      value: "Return on Equity - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "annual",
    },
    {
      id: "returnOnAssets",
      isstatement: "true",
      currency: "false",
      points: "63",
      tittle: "Return on Assets (%)",
      value: "Return on Assets - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 7,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "returnOnAssets",
      isstatement: "true",
      currency: "false",
      points: "16",
      tittle: "Return on Assets (%)",
      value: "Return on Assets - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "annual",
    },
    {
      id: "roic",
      isstatement: "true",
      currency: "false",
      points: "63",
      tittle: "Return on Invested Capital (%)",
      value: "ROIC - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "metrics",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "roic",
      isstatement: "true",
      currency: "false",
      points: "16",
      tittle: "Return on Invested Capital (%)",
      value: "ROIC - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "metrics",
      format: "P",
      dataFormat: "annual",
    },
    {
      id: "priceEarningsRatio",
      isstatement: "true",
      currency: "false",
      points: "63",
      tittle: "P/E ",
      value: "PE Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "quarter",
      null: 0,
    },
    {
      id: "priceEarningsRatio",
      isstatement: "true",
      currency: "false",
      points: "16",
      tittle: "P/E ",
      value: "PE Ratio - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "annual",
      null: 0,
      data: "ttm",
    },
    {
      id: "priceToBookRatio",
      isstatement: "true",
      currency: "false",
      points: "63",
      tittle: "P/B",
      value: "PB Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "quarter",
      null: 0,
    },
    {
      id: "priceToBookRatio",
      isstatement: "true",
      currency: "false",
      points: "16",
      tittle: "P/B",
      value: "PB Ratio - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "annual",
      null: 0,
      data: "ttm",
    },
    {
      id: "priceToFreeCashFlowsRatio",
      isstatement: "true",
      currency: "false",
      points: "63",
      tittle: "P/FCF",
      value: "P/FCF Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "quarter",
      null: 0,
    },
    {
      id: "priceToFreeCashFlowsRatio",
      isstatement: "true",
      currency: "false",
      points: "16",
      tittle: "P/FCF",
      value: "P/FCF Ratio - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "annual",
      null: 0,
      data: "ttm",
    },
    {
      id: "priceToSalesRatio",
      isstatement: "true",
      currency: "false",
      points: "63",
      tittle: "P/S",
      value: "P/S Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "quarter",
      null: 0,
    },
    {
      id: "priceToSalesRatio",
      isstatement: "true",
      currency: "false",
      points: "16",
      tittle: "P/S",
      value: "P/S Ratio - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "quarter",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "annual",
      null: 0,
      data: "ttm",
    },
    {
      id: "grossProfitMargin",
      isstatement: "true",
      currency: "false",
      points: "63",
      tittle: "Gross Margin (%)",
      value: "Gross Margin - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "grossProfitMargin",
      isstatement: "true",
      currency: "false",
      points: "16",
      tittle: "Gross Margin (%)",
      value: "Gross Margin - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "annual",
    },
    {
      id: "operatingProfitMargin",
      isstatement: "true",
      currency: "false",
      points: "63",
      tittle: "Operating Margin (%)",
      value: "Operating Margin - Quarterlyyy",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "operatingProfitMargin",
      isstatement: "true",
      currency: "false",
      points: "16",
      tittle: "Operating Margin (%)",
      value: "Operating Margin - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratios",
      format: "P",
    },
    {
      id: "ebitdaratio",
      isstatement: "true",
      currency: "false",
      points: "63",
      tittle: "EBITDA Margin (%)",
      value: "EBITDA Margin - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "income",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "ebitdaratio",
      isstatement: "true",
      currency: "false",
      points: "16",
      tittle: "EBITDA Margin (%)",
      value: "EBITDA Margin - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "income",
      format: "P",
      dataFormat: "annual",
    },
    {
      id: "pretaxProfitMargin",
      isstatement: "true",
      currency: "false",
      points: "63",
      tittle: "Pre-Tax Margin (%)",
      value: "Pre-tax Margin - Quaterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "pretaxProfitMargin",
      isstatement: "true",
      currency: "false",
      points: "16",
      tittle: "Pre-Tax Margin (%)",
      value: "Pre-tax Margin - Anually",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "annual",
    },
    {
      id: "netProfitMargin",
      isstatement: "true",
      currency: "false",
      points: "63",
      tittle: "Net Margin (%)",
      value: "Net Margin - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "netProfitMargin",
      isstatement: "true",
      currency: "false",
      points: "16",
      tittle: "Net Margin (%)",
      value: "Net Margin - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "annual",
    },
    {
      id: "revenue",
      isstatement: "true",
      currency: "true",
      points: "63",
      tittle: "Revenue ",
      value: "Revenue - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "revenue",
      isstatement: "true",
      currency: "true",
      points: "16",
      tittle: "Revenue ",
      value: "Revenue - Annual",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "quarter",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "annual",
    },
    {
      id: "grossProfit",
      isstatement: "true",
      currency: "true",
      points: "63",
      tittle: "Gross Profit ",
      value: "Gross Profit - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "grossProfit",
      isstatement: "true",
      currency: "true",
      points: "16",
      tittle: "Gross Profit ",
      value: "Gross Profit - Annual",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "annual",
    },
    {
      id: "operatingIncome",
      isstatement: "true",
      currency: "true",
      points: "63",
      tittle: "Operating Income ",
      value: "Operating Income - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "operatingIncome",
      isstatement: "true",
      currency: "true",
      points: "16",
      tittle: "Operating Income ",
      value: "Operating Income - Annual",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "annual",
    },
    {
      id: "ebitda",
      isstatement: "true",
      currency: "true",
      points: "63",
      tittle: "EBITDA ",
      value: "EBITDA - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "ebitda",
      isstatement: "true",
      currency: "true",
      points: "16",
      tittle: "EBITDA ",
      value: "EBITDA - Annual",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "annual",
    },
    {
      id: "netIncome",
      isstatement: "true",
      currency: "true",
      points: "63",
      tittle: "Net Income - Annual",
      value: "Net Income - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "netIncome",
      isstatement: "true",
      currency: "true",
      points: "16",
      tittle: "Net Income - Annual",
      value: "Net Income - Annual",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "annual",
    },
    {
      id: "eps",
      isstatement: "true",
      currency: "true",
      points: "63",
      tittle: "EPS ",
      value: "EPS - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "eps",
      isstatement: "true",
      currency: "true",
      points: "16",
      tittle: "EPS ",
      value: "EPS - Annual",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "annual",
    },
    {
      id: "inversment",
      isstatement: "false",
      currency: "false",
      points: "16",
      tittle: "EPS ($)",
      value: "Investment Highlights",
      type: "inves",
      frequency: [],
      endpoint: "inves",
      format: "S",
      dataFormat: "annual",
    },
    {
      id: "balancesheetannual",
      isstatement: "false",
      currency: "false",
      points: "16",
      tittle: "EPS ($)",
      value: "Balance Sheet Annual",
      type: "balancesheetannual",
      frequency: [],
      endpoint: "balancesheetannual",
      format: "S",
      dataFormat: "annual",
    },
    {
      id: "balancesheetquarter",
      isstatement: "false",
      currency: "false",
      points: "16",
      tittle: "EPS ($)",
      value: "Balance Sheet Quarter",
      type: "balancesheetquarter",
      frequency: [],
      endpoint: "balancesheetquarter",
      format: "S",
      dataFormat: "quarter",
    },
    {
      id: "Cashflowstatementannual",
      isstatement: "false",
      currency: "false",
      points: "16",
      tittle: "EPS ($)",
      value: "Cash Flow Statement - Annual",
      type: "Cashflowstatementannual",
      frequency: [],
      endpoint: "Cashflowstatementannual",
      format: "S",
      dataFormat: "annual",
    },
    {
      id: "Cashflowstatementquarter",
      isstatement: "false",
      currency: "false",
      points: "16",
      tittle: "EPS ($)",
      value: "Cash Flow Statement - Quarter",
      type: "Cashflowstatementquarter",
      frequency: [],
      endpoint: "Cashflowstatementquarter",
      format: "S",
      dataFormat: "quarter",
    },
    {
      id: "incomestatementannual",
      isstatement: "false",
      currency: "false",
      points: "16",
      tittle: "EPS ($)",
      value: " - Annual",
      type: "incomestatementannual",
      frequency: [],
      endpoint: "incomestatementannual",
      format: "S",
      dataFormat: "annual",
    },
    {
      id: "incomestatementquarter",
      isstatement: "false",
      currency: "false",
      points: "16",
      tittle: "EPS ($)",
      value: "Income Statement - Quarter",
      type: "incomestatementquarter",
      frequency: [],
      endpoint: "incomestatementquarter",
      format: "S",
      dataFormat: "quarter",
    },
    {
      id: "financialratio",
      isstatement: "false",
      currency: "false",
      points: "16",
      tittle: "EPS ($)",
      value: "Financial Ratios",
      type: "financialratio",
      frequency: [],
      endpoint: "income",
      format: "S",
      dataFormat: "annual",
    },
  ]);

  const [fina, setFina] = useState([
    {
      id: "incomestatementannual",
      isstatement: "false",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "EPS ($)",
      value: "Income Statement - Annual",
      type: "incomestatementannual",
      frequency: [],
      endpoint: "incomestatementannual",
      format: "S",
      dataFormat: "annual",
    },
    {
      id: "incomestatementquarter",
      isstatement: "false",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "EPS ($)",
      value: "Income Statement - Quarterly",
      type: "incomestatementquarter",
      frequency: [],
      endpoint: "incomestatementquarter",
      format: "S",
      dataFormat: "quarter",
    },
    {
      id: "Cashflowstatementannual",
      isstatement: "false",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "EPS ($)",
      value: "Cash Flow Statement - Annual",
      type: "Cashflowstatementannual",
      frequency: [],
      endpoint: "Cashflowstatementannual",
      format: "S",
      dataFormat: "annual",
    },
    {
      id: "Cashflowstatementquarter",
      isstatement: "false",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "EPS ($)",
      value: "Cash Flow Statement - Quarterly",
      type: "Cashflowstatementquarter",
      frequency: [],
      endpoint: "Cashflowstatementquarter",
      format: "S",
      dataFormat: "quarter",
    },
    {
      id: "balancesheetannual",
      isstatement: "false",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "EPS ($)",
      value: "Balance Sheet - Annual",
      type: "balancesheetannual",
      frequency: [],
      endpoint: "balancesheetannual",
      format: "S",
      dataFormat: "annual",
    },
    {
      id: "balancesheetquarter",
      isstatement: "false",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "EPS ($)",
      value: "Balance Sheet - Quarterly",
      type: "balancesheetquarter",
      frequency: [],
      endpoint: "balancesheetquarter",
      format: "S",
      dataFormat: "quarter",
    },

    {
      id: "financialratio",
      isstatement: "false",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "EPS ($)",
      value: "Financial Ratios",
      type: "financialratio",
      frequency: [],
      endpoint: "income",
      format: "S",
      dataFormat: "annual",
    },
  ]);

  const [revpro, setrevpro] = useState([
    {
      id: "revenue",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "63",
      tittle: "Revenue ",
      value: "Revenue - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "revenue",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "16",
      tittle: "Revenue ",
      value: "Revenue - Annual",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "annual",
    },
    {
      id: "grossProfit",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "63",
      tittle: "Gross Profit ",
      value: "Gross Profit - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "grossProfit",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "16",
      tittle: "Gross Profit ",
      value: "Gross Profit - Annual",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "annual",
    },
    {
      id: "ebitda",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "63",
      tittle: "EBITDA ",
      value: "EBITDA - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "ebitda",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "16",
      tittle: "EBITDA ",
      value: "EBITDA - Annual",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "annual",
    },
    {
      id: "operatingIncome",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "63",
      tittle: "Operating Income ",
      value: "Operating Income - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "operatingIncome",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "16",
      tittle: "Operating Income ",
      value: "Operating Income - Annual",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "annual",
    },
    {
      id: "netIncome",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "63",
      tittle: "Net Income - Annual",
      value: "Net Income - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "netIncome",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "16",
      tittle: "Net Income - Annual",
      value: "Net Income - Annual",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "annual",
    },
    {
      id: "eps",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "63",
      tittle: "EPS ",
      value: "EPS - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "eps",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "16",
      tittle: "EPS ",
      value: "EPS - Annual",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "income",
      format: "C",
      dataFormat: "annual",
    },
  ]);

  const [margins, setmargins] = useState([
    {
      id: "grossProfitMargin",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "63",
      tittle: "Gross Margin (%)",
      value: "Gross Margin - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "grossProfitMargin",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "Gross Margin (%)",
      value: "Gross Margin - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "annual",
    },
    {
      id: "operatingProfitMargin",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "63",
      tittle: "Operating Margin (%)",
      value: "Operating Margin - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "operatingProfitMargin",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "Operating Margin (%)",
      value: "Operating Margin - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "annual",
    },
    {
      id: "pretaxProfitMargin",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "63",
      tittle: "Pre-Tax Margin (%)",
      value: "Pre-tax Margin - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "pretaxProfitMargin",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "Pre-Tax Margin (%)",
      value: "Pre-tax Margin - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "annual",
    },
    {
      id: "netProfitMargin",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "63",
      tittle: "Net Margin (%)",
      value: "Net Margin - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "netProfitMargin",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "Net Margin (%)",
      value: "Net Margin - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "annual",
    },
  ]);

  const [valmetric, setvalmetric] = useState([
    {
      id: "priceEarningsRatio",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "63",
      tittle: "P/E ",
      value: "P/E Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "quarter",
      null: 0,
    },
    {
      id: "priceEarningsRatio",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "P/E",
      value: "P/E Ratio - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "annual",
      null: 0,
      data: "ttm",
    },
    {
      id: "priceToBookRatio",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "63",
      tittle: "P/B",
      value: "P/B Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "quarter",
      null: 0,
    },
    {
      id: "priceToBookRatio",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "P/B",
      value: "P/B Ratio - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "annual",
      null: 0,
      data: "ttm",
    },
    {
      id: "priceToSalesRatio",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "63",
      tittle: "P/S",
      value: "P/S Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "quarter",
      null: 0,
    },
    {
      id: "priceToSalesRatio",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "P/S",
      value: "P/S Ratio - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "quarter",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "annual",
      null: 0,
      data: "ttm",
    },
    {
      id: "enterpriseValueMultiple",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "63",
      tittle: " EV/EBITDA",
      value: "EV/EBITDA Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "quarter",
    },
    {
      id: "enterpriseValueMultiple",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: " EV/EBITDA",
      value: "EV/EBITDA Ratio - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "annual",
      null: 0,
      data: "ttm",
    },
    {
      id: "priceToFreeCashFlowsRatio",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "63",
      tittle: "P/FCF",
      value: "P/FCF Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "quarter",
      null: 0,
    },
    {
      id: "priceToFreeCashFlowsRatio",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "P/FCF",
      value: "P/FCF Ratio - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratiostest",
      format: "A",
      dataFormat: "annual",
      null: 0,
      data: "ttm",
    },
  ]);

  const [bsheet, setbsheet] = useState([
    {
      id: "totalAssets",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "63",
      tittle: "Total Assets ",
      value: "Total Assets - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "16",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "balance",
      format: "C",
      dataFormat: "quarter",
    },

    {
      id: "totalLiabilities",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "63",
      tittle: "Total Liabilities ",
      value: "Total Liabilities - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "balance",
      format: "C",
      dataFormat: "quarter",
    },
    {
      id: "totalStockholdersEquity",
      isstatement: "true",
      currency: "true",
      calenderyear: true,
      points: "63",
      tittle: "Shareholder Equity ",
      value: "Shareholder Equity - Quarterly",
      type: "BarChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "balance",
      format: "C",
      dataFormat: "quarter",
    },
  ]);

  const [keyratio, setkeyratio] = useState([
    {
      id: "currentRatio",
      isstatement: "true",
      points: "63",
      currency: "false",
      calenderyear: false,
      tittle: "Current Ratio",
      value: "Current Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "metrics",
      format: "A",
      dataFormat: "quarter",
    },
    {
      id: "quickRatio",
      isstatement: "true",
      points: "63",
      currency: "false",
      calenderyear: false,
      tittle: "Quick Ratio",
      value: "Quick Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "A",
      dataFormat: "quarter",
    },
    {
      id: "debtEquityRatio",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "63",
      tittle: "Debt to Equity Ratio",
      value: "Debt to Equity Ratio - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "A",
      dataFormat: "quarter",
    },
    {
      id: "returnOnEquity",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "63",
      tittle: "Return on Equity (%)",
      value: "Return on Equity - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "returnOnEquity",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "Return on Equity (%)",
      value: "Return on Equity - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "annual",
    },
    {
      id: "returnOnAssets",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "63",
      tittle: "Return on Assets (%)",
      value: "Return on Assets - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 7,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "returnOnAssets",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "Return on Assets (%)",
      value: "Return on Assets - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "ratios",
      format: "P",
      dataFormat: "annual",
    },
    {
      id: "roic",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "63",
      tittle: "Return on Invested Capital (%)",
      value: "Return On Invested Capital - Quarterly",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          id: "quarter",
          point: "63",
          interval: 7,
          format: "YYYY",
        },
        {
          value: "5Y",
          id: "quarter",
          point: "21",
          interval: 3,
          format: "YYYY",
        },
        { value: "3Y", id: "quarter", point: "13", interval: 1, format: "MMM" },
        { value: "2Y", id: "quarter", point: "9", interval: 0, format: "MMM" },
        { value: "1Y", id: "quarter", point: "5", interval: 0, format: "MMM" },
      ],
      endpoint: "metrics",
      format: "P",
      dataFormat: "quarter",
    },
    {
      id: "roic",
      isstatement: "true",
      currency: "false",
      calenderyear: false,
      points: "16",
      tittle: "Return on Invested Capital (%)",
      value: "Return On Invested Capital - Annual",
      type: "ScatterChart",
      frequency: [
        {
          value: "All",
          point: "16",
          id: "annual",
          interval: 1,
          format: "YYYY",
        },
        { value: "5Y", point: "5", id: "annual", interval: 0, format: "YYYY" },
      ],
      endpoint: "metrics",
      format: "P",
      dataFormat: "annual",
    },
  ]);

  const [pric, setpric] = useState({
    id: "close",
    isstatement: "true",
    currency: "false",
    calenderyear: false,
    points: "63",
    tittle: "Price ",
    value: "Price Chart",
    type: "AreaChart",
    extra: {
      fromDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      id: "5min",
    },
    frequency: [
      {
        value: "1D",
        id: "quarter",
        point: "21",
        interval: 12,
        format: "h:mm a",
        extra: {
          fromDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
          toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
          id: "5min",
        },
      },
      {
        value: "5D",
        id: "quarter",
        point: "21",
        interval: 12,
        format: "DD-MMM",
        extra: {
          fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
          toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
          id: "30min",
        },
      },
      {
        value: "1M",
        id: "quarter",
        point: "13",
        interval: 5,
        format: "DD-MMM",
        extra: {
          fromDate: moment().subtract(32, "days").format("YYYY-MM-DD"),
          toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
          id: "1min",
        },
      },
      {
        value: "6M",
        id: "quarter",
        point: "9",
        interval: 39,
        format: "MMM-YYYY",
        extra: {
          fromDate: moment()
            .subtract(6, "months")
            .startOf("month")
            .format("YYYY-MM-DD"),
          toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
          id: "1min",
        },
      },
      {
        value: "YTD",
        id: "quarter",
        point: "5",
        interval: 34,
        format: "MMM-YYYY",
        extra: {
          fromDate: moment().startOf("year").format("YYYY-MM-DD"),
          toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
          id: "1min",
        },
      },
      {
        value: "1Y",
        id: "quarter",
        point: "5",
        interval: 36,
        format: "MMM-YYYY",
        extra: {
          fromDate: moment()
            .subtract(12, "months")
            .startOf("month")
            .format("YYYY-MM-DD"),
          toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
          id: "1min",
        },
      },
      {
        value: "5Y",
        id: "quarter",
        point: "5",
        interval: 210,
        format: "YYYY",
        extra: {
          fromDate: moment()
            .subtract(5, "years")
            .startOf("month")
            .format("YYYY-MM-DD"),
          toDate: moment()
            .subtract(1, "days")
            .endOf("month")
            .format("YYYY-MM-DD"),
          id: "1min",
        },
      },
      {
        value: "MAX",
        id: "quarter",
        point: "5",
        interval: 300,
        format: "YYYY",
        extra: {
          fromDate: moment()
            .subtract(100, "years")
            .startOf("month")
            .format("YYYY-MM-DD"),
          toDate: moment()
            .subtract(1, "days")
            .endOf("month")
            .format("YYYY-MM-DD"),
          id: "1min",
        },
      },
    ],
    endpoint: "historical",
    format: "S",
    dataFormat: "quarter",
  });

  const [inves, setinves] = useState({
    id: "inversment",
    isstatement: "false",
    currency: "false",
    calenderyear: false,
    points: "16",
    tittle: "EPS ($)",
    value: "Investment Highlights",
    type: "inves",
    frequency: [],
    endpoint: "inves",
    format: "S",
    dataFormat: "annual",
  });
  const [news, setnews] = useState({
    id: "news",
    isstatement: "false",
    calenderyear: false,
    currency: "false",
    points: "63",
    tittle: "News 1 ",
    value: "News",
    type: "news",
    frequency: [],
    endpoint: "balance",
    format: "C",
    dataFormat: "quarter",
  });

  const visible = () => {
    setacc(acc ? false : true);
  };
  //   function marketprofile(inputprofile:any ) {

  //     if(signal){
  //         signal='';
  //         controller.abort();
  //     }else{
  //         signal = controller.signal;
  //     }
  //     const requestOptions = {
  //         method  : 'GET',
  //         headers: {}
  //     };
  //     return fetch(`${config.stockapiUrl}api/v3/profile/${inputprofile}?apikey=e961820783a9fc9c4b575a56564509b8`, requestOptions).then(handleResponse);
  // }
  const CustomToggle = ({
    children,
    eventKey,
    iconClassName,
    onClick,
  }: any) => {
    //console.log(className,'Prospss')
    const customOnClick = useAccordionToggle(eventKey, () => {
      setPanelIndex(eventKey === panelIndex ? null : eventKey);
      onClick();
    });
useEffect(() => {
    ReactGA.send("pageview");
  }, []);
    const customClass = eventKey === panelIndex ? "open-class" : "closed-class";

    return (
      <Nav.Link
        className={`sympol ${customClass} ${iconClassName}`}
        onClick={customOnClick}
      >
        {children}
      </Nav.Link>
    );
  };

  let initialGraph = graph[0];
  const defaultChart = JSON.stringify(graph[0]);

  const numberFormatBillon = (num: any, cur: any = "$") => {
    var isNagative = num > 0 ? false : true;
    num = Math.abs(num);
    if (num > 1000000000000) {
      return (
        cur + (isNagative ? "-" : "") + (num / 1000000000000).toFixed(2) + "T"
      ); // convert to M for number from > 1 million
    } else if (num > 1000000000) {
      return (
        cur + (isNagative ? "-" : "") + (num / 1000000000).toFixed(2) + "B"
      ); // convert to M for number from > 1 million
    } else if (num > 1000000) {
      return cur + (isNagative ? "-" : "") + (num / 1000000).toFixed(2) + "M"; // convert to M for number from > 1 million
    } else {
      return cur + (isNagative ? "-" : "") + num.toFixed(2); // if value < 1000, nothing to do
    }
  };
  const numberFormatBillonAxis = (num: any, cur: any = "$") => {
    var isNagative = num > 0 ? false : true;
    num = Math.abs(num);
    if (num > 1000000000000) {
      return (
        cur + (isNagative ? "-" : "") + (num / 1000000000000).toFixed(2) + "T"
      ); // convert to M for number from > 1 million
    } else if (num > 1000000000) {
      return (
        cur + (isNagative ? "-" : "") + (num / 1000000000).toFixed(2) + "B"
      ); // convert to M for number from > 1 million
    } else if (num > 1000000) {
      return cur + (isNagative ? "-" : "") + (num / 1000000).toFixed(2) + "M"; // convert to M for number from > 1 million
    } else {
      return num == 0 ? 0 : cur + (isNagative ? "-" : "") + num.toFixed(2); // if value < 1000, nothing to do
    }
  };
  const portfolioSchema = Yup.object().shape({
    portfolio_name: Yup.string().required("Portfolio Name is required"),
  });

  useEffect(() => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    getprofile();
  }, []);

  useEffect(() => {
    console.log(defname, "jujjjjjjjjjjjjjjjjjjjjjjjjjjj");
  }, [defname]);

  const pitchcreate = async () => {
    await addpitch(pitchedited);
    setTimeout(() => {
      history.push("/Pitches");
    }, 1000);
  };

  useEffect(() => {
    if (afterpitchreload == true) {
      setpitchvalue("");
    }
  }, []);

  const pitchupdates = () => {
    console.log(pitchedited, "222222222222222");
    updatepitch(pitchedited);
    if (pitchedited.pitch_status == 2) {
      setTimeout(() => {
        history.push("/Mytrackedpitches");
      }, 1000);
    } else {
      setTimeout(() => {
        history.push("/Pitches");
      }, 1000);
    }
  };

  useEffect(() => {
    var interval23 = setInterval(() => {
      marketprofile(stockId);
    }, 30000);
    return () => {
      clearInterval(interval23);
    };
  }, []);

  useEffect(() => {
    if (stockId) {
      marketprofile(stockId);
      //marketratio(stockId);
      getquotes(stockId);
      //gettm(stockId);
      //getebidquar(stockId);
    }
    console.log(historicalData, "ololo");
  }, [stockId]);

  useEffect(() => {
    console.log(stockId, getpoint, reportType, chartendpoint);
    //alert('Ratio');
    if (stockId) {
      // getStockProfile(stockId);
      if (chartendpoint == "ratiostest") {
        getmonthly(stockId, reportType, getpoint);
      } else if (chartendpoint == "ratios") {
        getmonthly(stockId, reportType, getpoint);
      } else if (chartendpoint == "income") {
        getincome(stockId, getpoint, reportType);
      } else if (chartendpoint == "balance") {
        getbalance(stockId, getpoint, reportType);
      } else if (chartendpoint == "Cashflowstatementannual") {
        getcashflow(stockId, 10, "annual");
      } else if (chartendpoint == "Cashflowstatementquarter") {
        getcashflow(stockId, 42, "quarter");
      } else if (chartendpoint == "balancesheetannual") {
        getbalancestatement(stockId, "annual", 10);
      } else if (chartendpoint == "balancesheetquarter") {
        getbalancestatement(stockId, "quarter", 42);
      } else if (chartendpoint == "incomestatementannual") {
        getincomestatement(stockId, "annual", 10);
      } else if (chartendpoint == "incomestatementquarter") {
        getincomestatement(stockId, "quarter", 42);
      } else if (chartendpoint == "historical") {
        if (reportTypeVal == "5D" || reportTypeVal == "1D") {
          addfbmarketfiveday(
            extraData.id,
            stockId,
            extraData.fromDate,
            extraData.toDate
          );
        } else {
          addfbmarketonemonth(stockId, extraData.fromDate, extraData.toDate);
        }
      } else {
        getmetirials(stockId, getpoint, reportType);
      }
    }
  }, [stockId, reportType, chartendpoint, getpoint, reportTypeVal]);

  useEffect(() => {
    getValuationTypes();
  }, []);

  useEffect(() => {
    {
      cashflow.map((dep: any) => {
        setsetintcurrency(dep.reportedCurrency);
      });
    }
    // console.log(setintcurrency,"frtyuytretyuuuuuuuuuuuuuuuuuuuuuuuu")
  }, [cashflow]);

  useEffect(() => {
    console.log(cashflow, "kokokokoko => cashflow");
  }, [cashflow]);

  useEffect(() => {
    setusd(showcontrolstate);
  }, [showcontrolstate]);

  const addwlist = async () => {
    //e.preventDefault();
    await addwatchlist(stockId);
  };

  useEffect(() => {
    //console.log(stockhistorical,chartFilter,'stockhistorical');
    let hisData: any = [];
    let minC: any, maxC: any;
    if (stockhistorical) {
      let stockhistoricalCopy = stockhistorical;
      if (reportTypeVal == "1D") {
        stockhistoricalCopy = stockhistoricalCopy.filter(
          (sHis: any) =>
            moment(sHis.date).format("YYYY-MM-DD") ==
            moment(stockhistoricalCopy[0].date).format("YYYY-MM-DD")
        );
      }
      stockhistoricalCopy.map((stockHis: any, index: any) => {
        if (index == 0) {
          minC = stockHis[chartFilter];
          maxC = stockHis[chartFilter];
        }
        var ChartVal = stockHis[chartFilter];
        if (stockHis[chartFilter] <= 0 && chartendpoint == "ratiostest") {
          ChartVal = 0;
        }
        if (minC > stockHis[chartFilter]) {
          minC = stockHis[chartFilter];
        }
        if (maxC < stockHis[chartFilter]) {
          maxC = stockHis[chartFilter];
        }

        if (calenderval == false) {
          hisData.unshift({ date: stockHis.date, value: ChartVal });
        }
        if (calenderval == true) {
          hisData.unshift({
            date:
              reportType == "annual"
                ? `${stockHis.period} ${stockHis.calendarYear}`
                : `${stockHis.period} ${stockHis.calendarYear}`,
            value: ChartVal,
          });
        }

        {
          /*{ reportType == 'annual' ? moment(year.date).format('YYYY') : year.period+' '+moment(year.date).format('YYYY') } */
        }
        if (stockHis.reportedCurrency) {
          //console.log(stockHis.reportedCurrency, "stockHis.reportedCurrency");
          setReportedCurrency(stockHis.reportedCurrency);
        } else {
          //setReportedCurrency("");
        }
      });

      if (ttmvalue.length > 0 && chartendpoint == "ratiostest") {
        if (minC > ttmvalue[0][chartFilter + "TTM"]) {
          minC = ttmvalue[0][chartFilter + "TTM"];
        }
        if (maxC < ttmvalue[0][chartFilter + "TTM"]) {
          maxC = ttmvalue[0][chartFilter + "TTM"];
        }
        hisData.push({
          date: moment().format("YYYY-MM-DD"),
          value:
            ttmvalue[0][chartFilter + "TTM"] > 0
              ? ttmvalue[0][chartFilter + "TTM"]
              : 0,
        });
      }

      if (minC > 0) {
        var ChartInt = (maxC - minC) / 5;
        var newMin = minC - ChartInt;
        setChartMin(newMin < 0 ? 0 : newMin);
        setChartMax(maxC);
      } else {
        setChartMin(minC);
        setChartMax(maxC);
      }
      if (chartendpoint == "ratiostest") {
        setChartMin(0);
      }
      //console.log(hisData,'stockhistorical');
      if (reportTypeVal == "MAX") {
        let firstvalueget = hisData.shift();
        let lastvalueget = hisData.pop();
        let first: any = moment(firstvalueget.date).format("dd");
        let last: any = moment(lastvalueget.date).format("dd");
        let minus: any = ((last - first) * 365) / 41;
        let plus9 = minus;
        setgetinterval(plus9);
      }
      setHistoricalData(hisData);
    }
  }, [stockhistorical, chartFilter, ttmvalue]);

  useEffect(() => {
    let initialGraph = graph[0];

    if (initialGraph.extra) {
      //setExtraData(initialGraph.extra);
    }
    updatettm();
    setgetttm("");

    setchartfrequency(initialGraph.frequency);
    setgetformat(initialGraph.format);

    // setReportTypeVal(initialGraph.frequency[0].value);
    // setgetpoint(initialGraph.frequency[0].point);
    // setgetfrequencyformat(initialGraph.frequency[0].format);
    // setReportType(initialGraph.frequency[0].id);
    // setgetinterval(initialGraph.frequency[0].interval);    // setchartendpoint(initialGraph.endpoint);
    // setChartFilter(initialGraph.id);
    // setcharttype(initialGraph.type);
    // setstatementset(initialGraph.isstatement);
    // setgettitittle(initialGraph.tittle);
  }, []);

  if (!profileget?.companyName) return <p>Loading...</p>;

  const getPopup = () => {
    if (!valuation) {
      toast.error(" ");
    }
  };

  return (
    <>
      {/*  <div className="gradent-black text-white pt-2 pb-2 min-radius-7">
           <Row className="row justify-content-center">
              <Col sm={1} className="align-self-center text-center">
              <BiFilterAlt className="bold-16"></BiFilterAlt>
              </Col>
              <Col sm={5} className="align-self-center">
              <p className="m-0 bold-16">Filter Applied</p>
              </Col>
              <Col sm={5} className="align-self-center text-right">
              <p className="m-0 bold-16 green-text">Edit</p>
              </Col>
              <Col sm={1} className="align-self-center text-right">
              <RiCloseFill className="three-zero"></RiCloseFill>
              </Col>
  </Row>  
          </div>*/}
      {/* <Row className="">
            <Col sm={4} className="">
              <div className="black_grad p-2">
              <Row>
                <Col sm={6} className="align-self-center">
                  <p className="mb-0 onefive"><FiFilter className="text-white two-zero mr-2"></FiFilter> Screener Results</p>
                </Col>
                <Col sm={6} className="align-self-center text-right">
                  <div className="">
                    <a href="" className="one-five bold_15_76">Edit <RiCloseFill className="text-white two-zero ml-2"></RiCloseFill></a>
                  </div>
                </Col>
              </Row>
              </div>
            </Col>
          </Row> */}

      <div className="dash-table-div p-3 mt-0">
        <Row className=" justify-content-center stockProfileCont">
          <Col sm={12} className="">
            <Row className=" justify-content-center">
              <Col
                sm={statementset == "true" ? (viewBusiness ? 12 : 8) : 12}
                className=""
              >
                {statementset == "true" && (
                  <Row className=" justify-content-center">
                    <Col sm={8} xs={12} className="text-left">
                      <p className=" m-0 regular-15">
                        {profileget?.companyName}
                      </p>
                    </Col>
                    <Col
                      sm={4}
                      xs={12}
                      className="align-self-center text-sm-right"
                    >
                      <p className="m-0 text-white regular-15">
                        <span className="color-97">
                          {profileget?.exchangeShortName}:
                        </span>{" "}
                        {profileget?.symbol}
                      </p>
                    </Col>
                  </Row>
                )}

                {/*<p className="regular-14 color-97 mb-2">{stockProfile.LatestQuarter}</p>*/}

                {statementset == "true" && (
                  <Row>
                    <Col sm={5}>
                      <p className="bold-15 mb-0">
                        <p className="mb-0 mt-1 regular_12_a3">Market Price</p>
                        <h4 className="regular_30 mb-0">
                          {profileget.price ? profileget.price.toFixed(2) : ""}{" "}
                          <span className="color-97 bold-18">
                            {profileget.currency}
                          </span>
                        </h4>

                        {profileget.changes >= 0 && (
                          <span className="top-icon-up">
                            <BiUpArrowAlt className=""></BiUpArrowAlt>
                            {profileget.changes.toFixed(2)}(
                            {(
                              (profileget.changes /
                                (profileget.price - profileget.changes)) *
                              100
                            ).toFixed(2) + "%"}
                            )
                          </span>
                        )}
                        {profileget.changes < 0 && (
                          <span className="top-icon-end">
                            <BiDownArrowAlt className=""></BiDownArrowAlt>
                            {profileget.changes.toFixed(2)}(
                            {(
                              (profileget.changes /
                                (profileget.price - profileget.changes)) *
                              100
                            ).toFixed(2) + "%"}
                            )
                          </span>
                        )}
                        <span className="mr-2"> Today</span>
                      </p>
                    </Col>
                    {viewBusiness && (
                      <>
                        <Col sm={2} className="align-self-center text-left">
                          <h2>VS</h2>
                        </Col>
                        <Col sm={5}>
                          <p className="bold-15 m-0">
                            <p className="mb-0 mt-1 regular_12_a3">
                              Intrinsic Value
                            </p>
                            <h4 className="regular_30 mb-0">
                              {
                                (viewBusiness = parseFloat(invalue)
                                  ? parseFloat(invalue).toFixed(2)
                                  : "-")
                              }{" "}
                              {viewBusiness ==
                                parseFloat(invalue).toFixed(2) && (
                                <span className="color-97 bold-18">
                                  {profileget.currency}
                                  {console.log(
                                    profileget,
                                    "cccccccccccccccccccc"
                                  )}
                                </span>
                              )}
                            </h4>
                          </p>
                        </Col>
                      </>
                    )}
                  </Row>
                )}

                {statementset == "false" && (
                  <div className="row mb-2">
                    <div className="align-self-center col-sm-6 col-12">
                      <p className="m-0">
                        <span className="bold_14">
                          {profileget.companyName}
                        </span>
                        .{" "}
                        <span className="regular_14_a2">
                          {profileget.exchangeShortName}
                        </span>
                        :{" "}
                        <span className="regular_14">{profileget.symbol}</span>
                      </p>
                    </div>
                    <div className="align-self-center text-sm-right col-sm-6 col-12 pl-2 ">
                      <p className="m-0 text-white regular-15 pl-2">
                        <span className="">
                          {parseFloat(profileget.price).toFixed(2)}{" "}
                          <small className="color-97">
                            {profileget.currency}
                          </small>
                        </span>
                        {profileget.changes >= 0 && (
                          <span className="top-icon-up ml-2">
                            <BiUpArrowAlt className=""></BiUpArrowAlt>
                            {profileget.changes.toFixed(2)}(
                            {(
                              (profileget.changes /
                                (profileget.price - profileget.changes)) *
                              100
                            ).toFixed(2) + "%"}
                            )
                          </span>
                        )}
                        {profileget.changes < 0 && (
                          <span className="top-icon-end ml-2">
                            <BiDownArrowAlt className=""></BiDownArrowAlt>
                            {profileget.changes.toFixed(2)}(
                            {(
                              (profileget.changes /
                                (profileget.price - profileget.changes)) *
                              100
                            ).toFixed(2) + "%"}
                            )
                          </span>
                        )}
                        {defname != "News" &&
                          defname != "Investment Highlights" &&
                          defname != "Financial Ratios" && (
                            <div className="text-financial">
                              <p>
                                All numbers are in millions,Currency in{" "}
                                {showcontrolstate ? showcontrolstate : "USD"}
                              </p>
                            </div>
                          )}
                      </p>
                    </div>
                    {/*<Row>
      <Col sm={12}>
    <div>
         <p className="mb-2 mt-2 regular_12_a2 pl-2">Closed: 24 Jun am GMT-4 Disclaimer Pre-market 324.43+1.87(0.55%) </p>
          </div>
          </Col>
                  </Row>*/}
                  </div>
                )}
                <Row>
                  <Col sm={6} className="mb-2 mt-2">
                    <Dropdown
                      show={showDropdown}
                      onToggle={() => setShowDropdown(!showDropdown)}
                    >
                      <Dropdown.Toggle
                        variant="link"
                        className="lop p-0"
                        id="dropdown-basic"
                      >
                        {defname}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="accord_position pr-5 ju  cnsr">
                          <Accordion className="bg_d2f accordion_ul_scroll">
                            <Card.Header className="p-0 empty-color">
                              <CustomToggle
                                iconClassName="noicon"
                                className="w-100 text-left"
                                as={Button}
                                variant="link"
                                eventKey="10"
                                onClick={() => {
                                  setShowDropdown(false);
                                  let graph: any = pric;
                                  setdefname(pric.value);
                                  setcalenderval(pric.calenderyear);

                                  if (graph.extra) {
                                    setExtraData(graph.extra);
                                    setReportTypeVal("1D");
                                    setgetpoint("21");
                                    setgetfrequencyformat("h:mm a");
                                    setReportType("5min");
                                    setgetinterval(12);
                                  }
                                  updatettm();
                                  setgetttm("");
                                  if (graph.data == "ttm") {
                                    setgetttm(graph.data);
                                    gettm(stockId);
                                  }
                                  if (graph.value == "Investment Highlights")
                                    setReportTypeVal(graph.value);
                                  if (graph.value == "Balancesheet")
                                    setReportTypeVal(graph.value);
                                  if (graph.value == "Cashflowstatement")
                                    setReportTypeVal(graph.value);

                                  if (graph.dataFormat == "annual") {
                                    setgetpoint("16");
                                    setgetinterval(1);
                                    setReportType("annual");
                                  } else {
                                    setgetpoint("63");
                                    setgetinterval(7);
                                    setReportType("quarter");
                                  }
                                  setchartendpoint(graph.endpoint);
                                  setChartFilter(graph.id);
                                  setcharttype(graph.type);
                                  setstatementset(graph.isstatement);
                                  setcurrency(graph.currency);
                                  setchartfrequency(graph.frequency);
                                  setReportTypeVal("All");
                                  setgetformat(graph.format);
                                  setgetfrequencyformat("YYYY");
                                  setgettitittle(graph.tittle);
                                  if (graph.extra) {
                                    setExtraData(graph.extra);
                                    setReportTypeVal("1D");
                                    setgetpoint("21");
                                    setgetfrequencyformat("h:mm a");
                                    setReportType("5min");
                                    setgetinterval(12);
                                  }
                                  setacc(false);
                                }}
                              >
                                Price Chart
                              </CustomToggle>
                            </Card.Header>
                            <Card.Header className="p-0 empty-color">
                              <CustomToggle
                                iconClassName="noicon"
                                className="w-100 text-left"
                                as={Button}
                                variant="link"
                                eventKey="18"
                                onClick={() => {
                                  setShowDropdown(false);
                                  let graph: any = inves;

                                  setdefname(inves.value);
                                  setcalenderval(inves.calenderyear);
                                  if (graph.extra) {
                                    setExtraData(graph.extra);
                                    setReportTypeVal("1D");
                                    setgetpoint("21");
                                    setgetfrequencyformat("h:mm a");
                                    setReportType("5min");
                                    setgetinterval(12);
                                  }
                                  updatettm();
                                  setgetttm("");
                                  if (graph.data == "ttm") {
                                    setgetttm(graph.data);
                                    gettm(stockId);
                                  }
                                  if (graph.value == "Investment Highlights")
                                    setReportTypeVal(graph.value);
                                  if (graph.value == "Balancesheet")
                                    setReportTypeVal(graph.value);
                                  if (graph.value == "Cashflowstatement")
                                    setReportTypeVal(graph.value);

                                  if (graph.dataFormat == "annual") {
                                    setgetpoint("16");
                                    setgetinterval(1);
                                    setReportType("annual");
                                  } else {
                                    setgetpoint("63");
                                    setgetinterval(7);
                                    setReportType("quarter");
                                  }
                                  setchartendpoint(graph.endpoint);
                                  setChartFilter(graph.id);
                                  setcharttype(graph.type);
                                  setstatementset(graph.isstatement);
                                  setcurrency(graph.currency);
                                  setchartfrequency(graph.frequency);
                                  setReportTypeVal("All");
                                  setgetformat(graph.format);
                                  setgetfrequencyformat("YYYY");
                                  setgettitittle(graph.tittle);
                                  if (graph.extra) {
                                    setExtraData(graph.extra);
                                    setReportTypeVal("1D");
                                    setgetpoint("21");
                                    setgetfrequencyformat("h:mm a");
                                    setReportType("5min");
                                    setgetinterval(12);
                                  }
                                  setacc(false);
                                }}
                              >
                                Investment Highlights
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse className="" eventKey="10">
                              <Card.Body className="p-0"></Card.Body>
                            </Accordion.Collapse>

                            <CustomToggle
                              onClick={() => {}}
                              className="w-100 text-left sympol p-0 empty-color"
                              as={Button}
                              variant="link"
                              eventKey="11"
                            >
                              Financials
                            </CustomToggle>
                            <Accordion.Collapse className="" eventKey="11">
                              <Card.Body className="p-0">
                                <ul className="center_ul mb-0">
                                  {fina.map((fina) => {
                                    return (
                                      <li
                                        style={{ cursor: "pointer" }}
                                        value={JSON.stringify(fina)}
                                        onClick={() => {
                                          setShowDropdown(false);
                                          let graph: any = fina;
                                          setdefname(fina.value);
                                          setcalenderval(fina.calenderyear);

                                          if (graph.extra) {
                                            setExtraData(graph.extra);
                                            setReportTypeVal("1D");
                                            setgetpoint("21");
                                            setgetfrequencyformat("h:mm a");
                                            setReportType("5min");
                                            setgetinterval(12);
                                          }
                                          updatettm();
                                          setgetttm("");
                                          if (graph.data == "ttm") {
                                            setgetttm(graph.data);
                                            gettm(stockId);
                                          }
                                          if (
                                            graph.value ==
                                            "Investment Highlights"
                                          )
                                            setReportTypeVal(graph.value);
                                          if (graph.value == "Balancesheet")
                                            setReportTypeVal(graph.value);
                                          if (
                                            graph.value == "Cashflowstatement"
                                          )
                                            setReportTypeVal(graph.value);

                                          if (graph.dataFormat == "annual") {
                                            setgetpoint("16");
                                            setgetinterval(1);
                                            setReportType("annual");
                                          } else {
                                            setgetpoint("63");
                                            setgetinterval(7);
                                            setReportType("quarter");
                                          }
                                          setchartendpoint(graph.endpoint);
                                          setChartFilter(graph.id);
                                          setcharttype(graph.type);
                                          setstatementset(graph.isstatement);
                                          setcurrency(graph.currency);
                                          setchartfrequency(graph.frequency);
                                          setReportTypeVal("All");
                                          setgetformat(graph.format);
                                          setgetfrequencyformat("YYYY");
                                          setgettitittle(graph.tittle);
                                          if (graph.extra) {
                                            setExtraData(graph.extra);
                                            setReportTypeVal("1D");
                                            setgetpoint("21");
                                            setgetfrequencyformat("h:mm a");
                                            setReportType("5min");
                                            setgetinterval(12);
                                          }
                                          setacc(false);
                                        }}
                                      >
                                        {fina.value}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                            <CustomToggle eventKey="2" onClick={() => {}}>
                              Revenue & Profit
                            </CustomToggle>
                            <Accordion.Collapse className="" eventKey="2">
                              <Card.Body className="p-1">
                                <ul className="center_ul mb-0">
                                  {revpro.map((revpro) => {
                                    return (
                                      <li
                                        style={{ cursor: "pointer" }}
                                        value={JSON.stringify(revpro)}
                                        onClick={() => {
                                          setShowDropdown(false);
                                          let graph: any = revpro;
                                          setdefname(revpro.value);
                                          setcalenderval(revpro.calenderyear);

                                          if (graph.extra) {
                                            setExtraData(graph.extra);
                                            setReportTypeVal("1D");
                                            setgetpoint("21");
                                            setgetfrequencyformat("h:mm a");
                                            setReportType("5min");
                                            setgetinterval(12);
                                          }
                                          updatettm();
                                          setgetttm("");
                                          if (graph.data == "ttm") {
                                            setgetttm(graph.data);
                                            gettm(stockId);
                                          }
                                          if (
                                            graph.value ==
                                            "Investment Highlights"
                                          )
                                            setReportTypeVal(graph.value);
                                          if (graph.value == "Balancesheet")
                                            setReportTypeVal(graph.value);
                                          if (
                                            graph.value == "Cashflowstatement"
                                          )
                                            setReportTypeVal(graph.value);

                                          if (graph.dataFormat == "annual") {
                                            setgetpoint("16");
                                            setgetinterval(1);
                                            setReportType("annual");
                                          } else {
                                            setgetpoint("63");
                                            setgetinterval(7);
                                            setReportType("quarter");
                                          }
                                          setchartendpoint(graph.endpoint);
                                          setChartFilter(graph.id);
                                          setcharttype(graph.type);
                                          setstatementset(graph.isstatement);
                                          setcurrency(graph.currency);
                                          setchartfrequency(graph.frequency);
                                          setReportTypeVal("All");
                                          setgetformat(graph.format);
                                          setgetfrequencyformat("YYYY");
                                          setgettitittle(graph.tittle);
                                          if (graph.extra) {
                                            setExtraData(graph.extra);
                                            setReportTypeVal("1D");
                                            setgetpoint("21");
                                            setgetfrequencyformat("h:mm a");
                                            setReportType("5min");
                                            setgetinterval(12);
                                          }
                                          setacc(false);
                                        }}
                                      >
                                        {revpro.value}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                            <CustomToggle eventKey="4" onClick={() => {}}>
                              Balance Sheet Items
                            </CustomToggle>
                            <Accordion.Collapse className="" eventKey="4">
                              <Card.Body className="p-0">
                                <ul className="center_ul mb-0">
                                  {bsheet.map((bsheet) => {
                                    return (
                                      <li
                                        style={{ cursor: "pointer" }}
                                        value={JSON.stringify(bsheet)}
                                        onClick={() => {
                                          let graph: any = bsheet;
                                          setdefname(bsheet.value);
                                          setcalenderval(bsheet.calenderyear);
                                          setShowDropdown(false);
                                          if (graph.extra) {
                                            setExtraData(graph.extra);
                                            setReportTypeVal("1D");
                                            setgetpoint("21");
                                            setgetfrequencyformat("h:mm a");
                                            setReportType("5min");
                                            setgetinterval(12);
                                          }
                                          updatettm();
                                          setgetttm("");
                                          if (graph.data == "ttm") {
                                            setgetttm(graph.data);
                                            gettm(stockId);
                                          }
                                          if (
                                            graph.value ==
                                            "Investment Highlights"
                                          )
                                            setReportTypeVal(graph.value);
                                          if (graph.value == "Balancesheet")
                                            setReportTypeVal(graph.value);
                                          if (
                                            graph.value == "Cashflowstatement"
                                          )
                                            setReportTypeVal(graph.value);

                                          if (graph.dataFormat == "annual") {
                                            setgetpoint("16");
                                            setgetinterval(1);
                                            setReportType("annual");
                                          } else {
                                            setgetpoint("63");
                                            setgetinterval(7);
                                            setReportType("quarter");
                                          }
                                          setchartendpoint(graph.endpoint);
                                          setChartFilter(graph.id);
                                          setcharttype(graph.type);
                                          setstatementset(graph.isstatement);
                                          setcurrency(graph.currency);
                                          setchartfrequency(graph.frequency);
                                          setReportTypeVal("All");
                                          setgetformat(graph.format);
                                          setgetfrequencyformat("YYYY");
                                          setgettitittle(graph.tittle);
                                          if (graph.extra) {
                                            setExtraData(graph.extra);
                                            setReportTypeVal("1D");
                                            setgetpoint("21");
                                            setgetfrequencyformat("h:mm a");
                                            setReportType("5min");
                                            setgetinterval(12);
                                          }
                                          setacc(false);
                                        }}
                                      >
                                        {bsheet.value}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                            <CustomToggle eventKey="1" onClick={() => {}}>
                              Margins
                            </CustomToggle>
                            <Accordion.Collapse className="" eventKey="1">
                              <Card.Body className="p-1">
                                <ul className="center_ul mb-0">
                                  {margins.map((margins) => {
                                    return (
                                      <li
                                        style={{ cursor: "pointer" }}
                                        value={JSON.stringify(margins)}
                                        onClick={() => {
                                          let graph: any = margins;
                                          setdefname(margins.value);
                                          setShowDropdown(false);
                                          setcalenderval(margins.calenderyear);
                                          if (graph.extra) {
                                            setExtraData(graph.extra);
                                            setReportTypeVal("1D");
                                            setgetpoint("21");
                                            setgetfrequencyformat("h:mm a");
                                            setReportType("5min");
                                            setgetinterval(12);
                                          }
                                          updatettm();
                                          setgetttm("");
                                          if (graph.data == "ttm") {
                                            setgetttm(graph.data);
                                            gettm(stockId);
                                          }
                                          if (
                                            graph.value ==
                                            "Investment Highlights"
                                          )
                                            setReportTypeVal(graph.value);
                                          if (graph.value == "Balancesheet")
                                            setReportTypeVal(graph.value);
                                          if (
                                            graph.value == "Cashflowstatement"
                                          )
                                            setReportTypeVal(graph.value);

                                          if (graph.dataFormat == "annual") {
                                            setgetpoint("16");
                                            setgetinterval(1);
                                            setReportType("annual");
                                          } else {
                                            setgetpoint("63");
                                            setgetinterval(7);
                                            setReportType("quarter");
                                          }
                                          setchartendpoint(graph.endpoint);
                                          setChartFilter(graph.id);
                                          setcharttype(graph.type);
                                          setstatementset(graph.isstatement);
                                          setcurrency(graph.currency);
                                          setchartfrequency(graph.frequency);
                                          setReportTypeVal("All");
                                          setgetformat(graph.format);
                                          setgetfrequencyformat("YYYY");
                                          setgettitittle(graph.tittle);
                                          if (graph.extra) {
                                            setExtraData(graph.extra);
                                            setReportTypeVal("1D");
                                            setgetpoint("21");
                                            setgetfrequencyformat("h:mm a");
                                            setReportType("5min");
                                            setgetinterval(12);
                                          }
                                          setacc(false);
                                        }}
                                      >
                                        {margins.value}
                                        {console.log(
                                          margins.value,
                                          "ddddddddddddddddddd"
                                        )}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>

                            <CustomToggle eventKey="3" onClick={() => {}}>
                              Valuation Metrics
                            </CustomToggle>
                            <Accordion.Collapse className="" eventKey="3">
                              <Card.Body className="p-1">
                                <ul className="center_ul mb-0">
                                  {valmetric.map((valmetric) => {
                                    return (
                                      <li
                                        style={{ cursor: "pointer" }}
                                        value={JSON.stringify(valmetric)}
                                        onClick={() => {
                                          let graph: any = valmetric;
                                          setdefname(valmetric.value);
                                          setShowDropdown(false);
                                          setcalenderval(
                                            valmetric.calenderyear
                                          );
                                          if (graph.extra) {
                                            setExtraData(graph.extra);
                                            setReportTypeVal("1D");
                                            setgetpoint("21");
                                            setgetfrequencyformat("h:mm a");
                                            setReportType("5min");
                                            setgetinterval(12);
                                          }
                                          updatettm();
                                          setgetttm("");
                                          if (graph.data == "ttm") {
                                            setgetttm(graph.data);
                                            gettm(stockId);
                                          }
                                          if (
                                            graph.value ==
                                            "Investment Highlights"
                                          )
                                            setReportTypeVal(graph.value);
                                          if (graph.value == "Balancesheet")
                                            setReportTypeVal(graph.value);
                                          if (
                                            graph.value == "Cashflowstatement"
                                          )
                                            setReportTypeVal(graph.value);

                                          if (graph.dataFormat == "annual") {
                                            setgetpoint("16");
                                            setgetinterval(1);
                                            setReportType("annual");
                                          } else {
                                            setgetpoint("63");
                                            setgetinterval(7);
                                            setReportType("quarter");
                                          }
                                          setchartendpoint(graph.endpoint);
                                          setChartFilter(graph.id);
                                          setcharttype(graph.type);
                                          setstatementset(graph.isstatement);
                                          setcurrency(graph.currency);
                                          setchartfrequency(graph.frequency);
                                          setReportTypeVal("All");
                                          setgetformat(graph.format);
                                          setgetfrequencyformat("YYYY");
                                          setgettitittle(graph.tittle);
                                          if (graph.extra) {
                                            setExtraData(graph.extra);
                                            setReportTypeVal("1D");
                                            setgetpoint("21");
                                            setgetfrequencyformat("h:mm a");
                                            setReportType("5min");
                                            setgetinterval(12);
                                          }
                                          setacc(false);
                                        }}
                                      >
                                        {valmetric.value}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>

                            <CustomToggle eventKey="5" onClick={() => {}}>
                              Key Ratios
                            </CustomToggle>
                            <Accordion.Collapse className="" eventKey="5">
                              <Card.Body className="p-0">
                                <ul className="center_ul mb-0">
                                  {keyratio.map((keyratio) => {
                                    return (
                                      <li
                                        style={{ cursor: "pointer" }}
                                        value={JSON.stringify(keyratio)}
                                        onClick={() => {
                                          let graph: any = keyratio;
                                          setdefname(keyratio.value);
                                          setShowDropdown(false);
                                          setcalenderval(keyratio.calenderyear);
                                          if (graph.extra) {
                                            setExtraData(graph.extra);
                                            setReportTypeVal("1D");
                                            setgetpoint("21");
                                            setgetfrequencyformat("h:mm a");
                                            setReportType("5min");
                                            setgetinterval(12);
                                          }
                                          updatettm();
                                          setgetttm("");
                                          if (graph.data == "ttm") {
                                            setgetttm(graph.data);
                                            gettm(stockId);
                                          }
                                          if (
                                            graph.value ==
                                            "Investment Highlights"
                                          )
                                            setReportTypeVal(graph.value);
                                          if (graph.value == "Balancesheet")
                                            setReportTypeVal(graph.value);
                                          if (
                                            graph.value == "Cashflowstatement"
                                          )
                                            setReportTypeVal(graph.value);

                                          if (graph.dataFormat == "annual") {
                                            setgetpoint("16");
                                            setgetinterval(1);
                                            setReportType("annual");
                                          } else {
                                            setgetpoint("63");
                                            setgetinterval(7);
                                            setReportType("quarter");
                                          }
                                          setchartendpoint(graph.endpoint);
                                          setChartFilter(graph.id);
                                          setcharttype(graph.type);
                                          setstatementset(graph.isstatement);
                                          setcurrency(graph.currency);
                                          setchartfrequency(graph.frequency);
                                          setReportTypeVal("All");
                                          setgetformat(graph.format);
                                          setgetfrequencyformat("YYYY");
                                          setgettitittle(graph.tittle);
                                          if (graph.extra) {
                                            setExtraData(graph.extra);
                                            setReportTypeVal("1D");
                                            setgetpoint("21");
                                            setgetfrequencyformat("h:mm a");
                                            setReportType("5min");
                                            setgetinterval(12);
                                          }
                                          setacc(false);
                                        }}
                                      >
                                        {keyratio.value}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>

                            <Card.Header className="p-0 empty-color">
                              <CustomToggle
                                iconClassName="noicon"
                                className="w-100 text-left"
                                as={Button}
                                variant="link"
                                eventKey="19"
                                onClick={() => {
                                  setShowDropdown(false);
                                  let graph: any = news;
                                  setdefname(news.value);
                                  console.log(
                                    news,
                                    "kkkkkkkkkkkkkshdasjfhdfjkadf"
                                  );

                                  if (graph.extra) {
                                    setExtraData(graph.extra);
                                    setReportTypeVal("1D");
                                    setgetpoint("21");
                                    setgetfrequencyformat("h:mm a");
                                    setReportType("5min");
                                    setgetinterval(12);
                                  }
                                  updatettm();
                                  setgetttm("");
                                  if (graph.data == "ttm") {
                                    setgetttm(graph.data);
                                    gettm(stockId);
                                  }

                                  setReportTypeVal("News");

                                  if (graph.dataFormat == "annual") {
                                    setgetpoint("16");
                                    setgetinterval(1);
                                    setReportType("annual");
                                  } else {
                                    setgetpoint("63");
                                    setgetinterval(7);
                                    setReportType("quarter");
                                  }
                                  setchartendpoint(graph.endpoint);
                                  setChartFilter(graph.id);
                                  setcharttype(graph.type);
                                  setstatementset(graph.isstatement);
                                  setcurrency(graph.currency);
                                  setchartfrequency(graph.frequency);
                                  setReportTypeVal("All");
                                  setgetformat(graph.format);
                                  setgetfrequencyformat("YYYY");
                                  setgettitittle(graph.tittle);
                                  if (graph.extra) {
                                    setExtraData(graph.extra);
                                    setReportTypeVal("1D");
                                    setgetpoint("21");
                                    setgetfrequencyformat("h:mm a");
                                    setReportType("5min");
                                    setgetinterval(12);
                                  }
                                  setacc(false);
                                }}
                              >
                                News
                              </CustomToggle>
                            </Card.Header>
                          </Accordion>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  {statementset == "true" && (
                    <Col>
                      {chartFilter == "inversment" && (
                        <p className="mb-2 mt-2 regular_12_a2 pl-2">
                          All numbers are in millions. Currency in{" "}
                          {setintcurrency}
                        </p>
                      )}{" "}
                    </Col>
                  )}
                </Row>

                <ul className="mb-0 tab-ul max_ul">
                  {chartfrequency?.map((types: any) => {
                    return (
                      <li>
                        <a
                          className={
                            reportTypeVal == types.value ? "active" : ""
                          }
                          onClick={() => {
                            if (types.extra) {
                              setExtraData(types.extra);
                            }
                            setReportTypeVal(types.value);
                            setgetpoint(types.point);
                            setgetfrequencyformat(types.format);
                            setReportType(types.id);
                            setgetinterval(types.interval);
                          }}
                        >
                          {types.value}
                        </a>
                      </li>
                    );
                  })}
                </ul>

                {charttype == "AreaChart" && (
                  <ResponsiveContainer width="100%" height="48%">
                    <AreaChart
                      height={250}
                      width={400}
                      data={historicalData}
                      margin={{
                        top: 10,
                        right: 20,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="date"
                        stroke="white"
                        tick={{ fontSize: 10 }}
                        name="Date"
                        interval={getinterval}
                        tickFormatter={(tickItem: any) =>
                          moment(tickItem).format(getfrequencyformat)
                        }
                      />
                      <YAxis
                        domain={[chartMin, chartMax]}
                        stroke="white"
                        label={
                          <AxisLabel
                            axisType="yAxis"
                            x="10"
                            y="10"
                            width="100"
                            height="250"
                          >
                            {gettittle}
                            {currency == "true" && reportedCurrency
                              ? "(" + reportedCurrency + ")"
                              : ""}
                          </AxisLabel>
                        }
                        tick={{ fontSize: 10 }}
                        tickFormatter={(tickItem: any) => {
                          if (getformat == "S") {
                            return numberFormatBillonAxis(tickItem.toFixed(2));
                          } else if (getformat == "C") {
                            return numberFormatBillonAxis(
                              tickItem.toFixed(2),
                              ""
                            );
                          } else if (getformat == "A") {
                            return tickItem.toFixed(2);
                          } else {
                            return (tickItem * 100).toFixed(0) + "%";
                          }
                        }}
                      />
                      <Tooltip
                        wrapperStyle={{ backgroundColor: "black" }}
                        labelStyle={{ color: "green" }}
                        itemStyle={{ color: "black" }}
                        label="Date"
                        formatter={(value: any) => {
                          if (getformat == "S") {
                            return numberFormatBillon(value);
                          } else if (getformat == "C") {
                            return numberFormatBillon(
                              value.toFixed(2),
                              reportedCurrency
                            );
                          } else if (getformat == "A") {
                            return value.toFixed(2);
                          } else {
                            return (value * 100).toFixed(0) + "%";
                          }
                        }}
                      />
                      <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#00CF76"
                        fill="#00CF76"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                )}
                {/*<NumberFormat value={parseInt(numberFormatMillon(dep.revenue)).toFixed(2)} displayType={'text'} thousandSeparator={true}/> 
                  {/* Bar */}
                {charttype == "BarChart" && (
                  <ResponsiveContainer width="100%" height="48%">
                    <BarChart
                      height={600}
                      width={350}
                      margin={{
                        top: 10,
                        right: 20,
                        left: 0,
                        bottom: 0,
                      }}
                      data={historicalData}
                    >
                      <Tooltip
                        cursor={{ fill: "rgba 0 0 0 -4" }}
                        wrapperStyle={{ backgroundColor: "black" }}
                        labelStyle={{ color: "green" }}
                        itemStyle={{ color: "black" }}
                        separator={" "}
                        label="data"
                        formatter={(value: any) => {
                          if (getformat == "S") {
                            return numberFormatBillon(
                              value,
                              reportedCurrency + " "
                            );
                          } else if (getformat == "C") {
                            return numberFormatBillon(
                              value.toFixed(2),
                              reportedCurrency + " "
                            );
                          } else if (getformat == "A") {
                            return value.toFixed(2);
                          } else {
                            return (value * 100).toFixed(2) + "%";
                          }
                        }}
                      />
                      <Bar dataKey="value" fill="#00CF76" name=" ">
                        {historicalData.map((entry: any, index: any) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={entry.value > 0 ? "#00CF76" : "#d62728"}
                          />
                        ))}
                      </Bar>
                      <XAxis
                        dataKey="date"
                        stroke="white"
                        tick={{ fontSize: 10 }}
                        name="Date"
                        interval={getinterval}
                        tickFormatter={(tickItem: any) => tickItem}
                      />
                      <YAxis
                        tickCount={5}
                        domain={[chartMin, chartMax]}
                        stroke="white"
                        label={
                          <AxisLabel
                            axisType="yAxis"
                            x="10"
                            y="10"
                            width="100"
                            height="250"
                          >
                            {gettittle}
                            {currency == "true" && reportedCurrency
                              ? "(" + reportedCurrency + ")"
                              : ""}
                          </AxisLabel>
                        }
                        tick={{ fontSize: 10 }}
                        tickFormatter={(tickItem: any) => {
                          if (getformat == "S") {
                            return numberFormatBillonAxis(tickItem.toFixed(2));
                          } else if (getformat == "C") {
                            return numberFormatBillonAxis(
                              tickItem.toFixed(2),
                              ""
                            );
                          } else if (getformat == "A") {
                            return tickItem.toFixed(2);
                          } else {
                            return (tickItem * 100).toFixed(0) + "%";
                          }
                        }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                )}
                {/*scaterchat*/}
                {charttype == "ScatterChart" && (
                  <ResponsiveContainer width="100%" height="48%">
                    <LineChart
                      width={350}
                      height={600}
                      data={historicalData}
                      margin={{
                        top: 10,
                        right: 20,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        stroke="white"
                        tick={{ fontSize: 10 }}
                        dataKey="date"
                        interval={getinterval}
                        tickFormatter={(tickItem: any) =>
                          moment(tickItem).format(getfrequencyformat)
                        }
                      />
                      <YAxis
                        domain={[chartMin, chartMax]}
                        stroke="white"
                        label={
                          <AxisLabel
                            axisType="yAxis"
                            x="10"
                            y="10"
                            width="100"
                            height="250"
                          >
                            {gettittle}
                            {currency == "true" && reportedCurrency
                              ? "(" + reportedCurrency + ")"
                              : ""}
                          </AxisLabel>
                        }
                        tick={{ fontSize: 10 }}
                        tickFormatter={(tickItem: any) => {
                          if (getformat == "S") {
                            return numberFormatBillonAxis(tickItem.toFixed(2));
                          } else if (getformat == "C") {
                            return numberFormatBillonAxis(
                              tickItem.toFixed(2),
                              ""
                            );
                          } else if (getformat == "A") {
                            return tickItem.toFixed(2);
                          } else {
                            return (tickItem * 100).toFixed(2) + "%";
                          }
                        }}
                      />

                      <Tooltip
                        wrapperStyle={{ backgroundColor: "black" }}
                        labelStyle={{ color: "green" }}
                        itemStyle={{ color: "black" }}
                        formatter={(value: any) => {
                          if (getformat == "S") {
                            return numberFormatBillon(value);
                          } else if (getformat == "C") {
                            return numberFormatBillon(
                              value.toFixed(2),
                              reportedCurrency
                            );
                          } else if (getformat == "A") {
                            return value.toFixed(2);
                          } else {
                            return (value * 100).toFixed(2) + "%";
                          }
                        }}
                      />
                      <Line
                        type="monotone"
                        dataKey="value"
                        stroke="#00CF76"
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                )}

                {statementset == "true" && (
                  <Row className="mt-2">
                    <Col sm={4}>
                      <Row>
                        <Col sm={6} xs={6}>
                          <p className="bold-16 color-97 mb-2">Open</p>
                          <p className="bold-16 color-97 mb-2">High</p>
                          <p className="bold-16 color-97 mb-2">Low</p>
                        </Col>
                        <Col sm={6} xs={6} className="text-right">
                          <p className="bold-16 mb-2">
                            {getquotereturn?.open
                              ? getquotereturn?.open.toFixed(2)
                              : ""}
                          </p>
                          <p className="bold-16 mb-2">
                            {getquotereturn?.dayHigh
                              ? getquotereturn?.dayHigh.toFixed(2)
                              : ""}
                          </p>
                          <p className="bold-16 mb-2">
                            {getquotereturn?.dayLow
                              ? getquotereturn?.dayLow.toFixed(2)
                              : ""}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={4}>
                      <Row>
                        <Col sm={6} xs={6} className="pl-sm-0 pr-sm-0">
                          <p className="bold-16 color-97 mb-2">Mkt cap</p>
                          <p className="bold-16 color-97 mb-2">P/E ratio</p>
                          <p className="bold-16 color-97 mb-2">Vol</p>
                        </Col>
                        <Col sm={6} xs={6} className="text-left">
                          <p className="bold-16 mb-2">
                            {getquotereturn?.marketCap
                              ? numberFormatBillon(getquotereturn?.marketCap)
                              : "-"}
                          </p>
                          <p className="bold-16 mb-2">
                            {getquotereturn?.pe
                              ? getquotereturn?.pe.toFixed(2)
                              : "-"}
                          </p>
                          <p className="bold-16 mb-2">
                            <NumberFormat
                              thousandSeparator={true}
                              value={getquotereturn?.volume}
                              displayType={"text"}
                              prefix=""
                            />
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={4}>
                      <Row>
                        <Col sm={6} xs={6} className="pr-0 pl-0">
                          <p className="bold-16 color-97 mb-2">Prev close</p>
                          <p className="bold-16 color-97 mb-2">52-wk high</p>
                          <p className="bold-16 color-97 mb-2">52-wk low</p>
                        </Col>
                        <Col sm={6} xs={6} className="text-right">
                          <p className="bold-16 mb-2">
                            {" "}
                            {getquotereturn?.previousClose
                              ? getquotereturn?.previousClose.toFixed(2)
                              : ""}{" "}
                          </p>
                          <p className="bold-16 mb-2">
                            {getquotereturn?.yearHigh
                              ? getquotereturn?.yearHigh.toFixed(2)
                              : ""}{" "}
                          </p>
                          <p className="bold-16 mb-2">
                            {getquotereturn?.yearLow
                              ? getquotereturn?.yearLow.toFixed(2)
                              : ""}{" "}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Col>

              {statementset == "true" && (
                <>
                  {!viewBusiness && (
                    <Col sm={4} className="align-self-center">
                      <div className="gradent-black text-white pt-2 pl-2 pb-2 min-radius-7">
                        <h4 className="business_let mb-3">Business</h4>
                        <div className="stock-p-h scroll-ball">
                          {/*<p className="bold-16 mt-0"> {stockProfile.Description}*/}
                          <p className="business_let_para mt-0">
                            {profileget.description}
                          </p>
                        </div>
                      </div>
                    </Col>
                  )}
                </>
              )}
              <Col sm={12}>
                {charttype == "inves" && (
                  <InvesmentDropDown
                    stockId={stockId}
                    profileget={profileget}
                  />
                )}
                {charttype == "financialratio" && (
                  <FinancialRatio stockId={stockId} profileget={profileget} />
                )}
                {charttype == "balancesheetannual" && (
                  <Balancesheet
                    stockId={stockId}
                    profileget={profileget}
                    reportType={reportType}
                    balancestatement={balancestatement}
                  />
                )}
                {charttype == "balancesheetquarter" && (
                  <Balancesheet
                    stockId={stockId}
                    profileget={profileget}
                    reportType={reportType}
                    balancestatement={balancestatement}
                  />
                )}
                {charttype == "Cashflowstatementannual" && (
                  <Cashflowstatement
                    stockId={stockId}
                    profileget={profileget}
                    reportType={reportType}
                    cashflow={cashflow}
                  />
                )}
                {charttype == "Cashflowstatementquarter" && (
                  <Cashflowstatement
                    stockId={stockId}
                    profileget={profileget}
                    reportType={reportType}
                    cashflow={cashflow}
                  />
                )}
                {charttype == "incomestatementannual" && (
                  <Incomestatement
                    stockId={stockId}
                    profileget={profileget}
                    reportType={reportType}
                    incomestatementget={incomestatementget}
                  />
                )}
                {charttype == "incomestatementquarter" && (
                  <Incomestatement
                    stockId={stockId}
                    profileget={profileget}
                    reportType={reportType}
                    incomestatementget={incomestatementget}
                  />
                )}
                {charttype == "news" && (
                  <News stockId={stockId} profileget={profileget} />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {!viewBusiness && (
            <Col sm={6}>
              <a
                className="btn-value mr-sm-2 text-center mobi-block mt-2 d-block"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Value Business
              </a>
            </Col>
          )}

          {(() => {
            if (viewBusiness) {
              return (
                <>
                  {!viewvaluationbyid?.stock_symbol && (
                    <Col sm={6}>
                      <a
                        className="btn-value text-center mobi-block d-block"
                        onClick={() => {
                          pitchcreate();
                        }}
                      >
                        Pitch{" "}
                      </a>

                      {/*addPitch({"valuation_id": valuationDetail.valuation_id});  */}
                    </Col>
                  )}

                  {viewvaluationbyid?.stock_symbol && (
                    <Col sm={6}>
                      <a
                        className="btn-value text-center mobi-block d-block"
                        type="button"
                        onClick={() => {
                          pitchupdates();
                          setvaluationSuccess();
                        }}
                      >
                        {" "}
                        Re-Pitch{" "}
                      </a>

                      {/*addPitch({"valuation_id": valuationDetail.valuation_id});  */}
                    </Col>
                  )}

                  <Col sm={6}>
                    <a
                      className="btn-find mt-sm-0 text-center mobi-block d-block"
                      onClick={() => {
                        setViewBusiness(false);
                        setinvalue("");
                        history.push("/");
                      }}
                    >
                      Find Another Business
                    </a>
                    {/*onClick={ () => { ignorevaluation({"valuation_id":valuationDetail.valuation_id}) } } */}
                  </Col>
                </>
              );
            }
          })()}

          {/*  <a className="bold-15 text-white mt-3 mt-sm-0 text-center mobi-block" onClick={addwlist}><BiBookmark></BiBookmark>Add to Watchlist</a>  */}
        </Row>
      </div>

      <div className="custom_pop_wid">
        <Modal
          show={showModal}
          cssClass="modalhome"
          onHide={() => setShowModal(false)}
        >
          <div className="modalselect pl-sm-5 pr-sm-5 pt-4 pb-sm-4 p-2">
            <Row>
              <Col sm={11}></Col>
              <Col sm={1}>
                <FaTimes
                  className="text-right cursor-pointer"
                  onClick={() => {
                    setShowModal(false);
                  }}
                ></FaTimes>
              </Col>
            </Row>
            {getuserprofile.name && (
              <>
                <h3 className="mt-3 mb-2 bold-15 color-23 mb-4">
                  Choose Valuation Methodology
                </h3>
                <div className="valuationCont">
                  {valuationTypes.map((type: any, index: any) => {
                    return (
                      <div
                        className="valuationCheck"
                        key={index}
                        onClick={() => {
                          setValuation(type.id);
                          SetValuation_type(type.id);
                          SetValuation_text(type.valuation_name);
                        }}
                      >
                        <label className="form-check-label bold-15 container_radio">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="radio"
                            value={type.id}
                            defaultChecked={type.id == valuation}
                          />

                          <span className="checktitle">
                            {type.valuation_name}
                          </span>
                          <span className="checkmark"></span>
                          <span className="checkborder"></span>
                        </label>
                      </div>
                    );
                  })}
                </div>

                <div className="mt-4 mb-3">
                  {!valuation && viewvaluebus == true && (
                    <p className="value_bus">
                      Please choose valuation methodology!
                    </p>
                  )}
                  <Button
                    className="green-ground w-100 btn-login"
                    onClick={() => {
                      if (valuation) {
                        viewbusiness(true);
                        setShowModal(false);
                      } else {
                        setviewvaluebus(true);
                      }
                    }}
                  >
                    Value Business
                  </Button>
                </div>
              </>
            )}

            {!getuserprofile.name && (
              <>
                <h3 className="mt-3 mb-2 bold-15 color-23 mb-4">
                  {" "}
                  Please <a href="/login">Login</a> or{" "}
                  <a href="/signup">Sign Up </a> to Continue!
                </h3>
                <div className="mt-4 mb-3">
                  <Link to="/login">
                    <Button className="green-ground w-100 btn-login">
                      Login
                    </Button>
                  </Link>
                </div>
              </>
            )}
            {/* <Col onClick={() => {setValuation('4'); SetValuation_type('4'); SetValuation_text( "P/S Valuation");}}>   
                   <div className="card w-100 card-click">  
                      <div className="card-body p-2">                     
                        <div className="form-check" >
                            <div className="w-100">
                            <label className="form-check-label bold-15 green-text container_radio" >P/S
                            <input className="form-check-input " type="radio" name="radio"  />
                            <span className="checkmark"></span>
                            </label>
                            </div>
                        </div>
                      </div>
                  </div>
        </Col> */}
          </div>
        </Modal>
      </div>
      <Modal
        show={showModals}
        cssClass="modalhome"
        onHide={() => setShowModals(false)}
      >
        <div className="modalselect">
          <Row className="">
            <Col className="">
              <div className="min-radius-7 bg-white p-4 relativec">
                <p className="bold-15 pt-3 color-00"></p>
                <img
                  className="img-fluid grg-img"
                  src="/assets/images/Group 294.png"
                />
                <Row className="pitch-para">
                  <Col sm={6} xs={6} className="color-97">
                    <p className="regular-15">Intrinsic Value Per Share</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right color-97">
                    <p className="bold-15">
                      {valuationDetail.intrinsic_value_per_share}%
                    </p>
                  </Col>
                </Row>
                <Row className="pitch-para">
                  <Col sm={6} xs={6} className="color-97">
                    <p className="regular-15">Margin of Safety</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right color-97">
                    <p className="bold-15">
                      {valuationDetail.margin_of_safety}%
                    </p>
                  </Col>
                </Row>
                <Row className="pitch-para">
                  <Col sm={6} xs={6} className="color-97">
                    <p className="regular-15">Cumulative Gain/Loss</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right color-97">
                    <p className="bold-15">
                      {valuationDetail.cumulative_gain_or_loss}
                    </p>
                  </Col>
                </Row>
                <Row className="pitch-para">
                  <Col sm={6} xs={6} className="color-97">
                    <p className="regular-15">IRR</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right color-97">
                    <p className="bold-15">{valuationDetail.irr}%</p>
                  </Col>
                </Row>
                <Row className="pitch-para">
                  <Col sm={6} xs={6} className="color-97">
                    <p className="regular-15">MOIC</p>
                  </Col>
                  <Col sm={6} xs={6} className="text-right color-97">
                    <p className="bold-15">{valuationDetail.moic}%</p>
                  </Col>
                </Row>
                <div className="pt-2 pb-1">
                  <select
                    onChange={(e) => {
                      setPortfolioId(e.target.value);
                    }}
                  >
                    {portfolioList.map((portfolio: any, index: any) => {
                      return (
                        <option value={portfolio.id}>
                          {portfolio.portfolio_name}
                        </option>
                      );
                    })}
                  </select>
                  <a
                    onClick={() => {
                        addPitch({
                          stock_symbol: stockId,
                          portfolio_id: portfolioId,
                          valuation_type_id: valuationDetail.valuation_type_id,
                          valuation_id: valuationDetail.valuation_id,
                        });
                    }}
                    className="btn-buy mr-3 bold-15"
                  >
                    Buy.
                  </a>
                  <a href="" className="btn-later mr-3 bold-15">
                    Save for Later
                  </a>
                  <a href="" className="text-red bold-15">
                    Ignore
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    stockProfile: state.stock.stockProfile,
    stockList: state.stock.stockList,
    datahistorical: state.stock.datahistorical,
    valuationTypes: state.stock.valuationTypes,
    valuationDetail: state.stock.valuationDetail,
    portfolioList: state.portfolio.portfolioList,
    stockhistorical: state.portfolio.stockhistorical,
    profileget: state.market.profileget,
    getmarketratio: state.market.getmarketratio,
    getquotereturn: state.portfolio.getquotereturn,
    fbmarketget: state.portfolio.fbmarketget,
    ttmvalue: state.portfolio.ttmvalue,
    cashflow: state.market.cashflow,
    balancestatement: state.market.balancestatement,
    incomestatementget: state.market.incomestatementget,
    setchange: state.portfolio.setchange,
    hidevaluation: state.portfolio.hidevaluation,
    getuserprofile: state.user.getuserprofile,
    afterpitchreload: state.portfolio.afterpitchreload,
    showcontrolstate: state.market.showcontrolstate,
  }),
  mapDispatchToProps: {
    getmonthly,
    setvaluationSuccess,
    getprofile,
    addpitch,
    updatepitch,
    getebidquar,
    getebidanu,
    getdaily,
    getincomestatement,
    getweekly,
    getStockProfile,
    gethistoricaldata,
    getValuationTypes,
    addvaluation,
    addPitch,
    addwatchlist,
    ignorevaluation,
    marketprofile,
    marketratio,
    getincome,
    getbalance,
    getmetirials,
    getquotes,
    addfbmarket,
    addfbmarketfiveday,
    addfbmarketonemonth,
    gettm,
    updatettm,
    getcashflow,
    getbalancestatement,
  },
  component: StockProfile,
});
