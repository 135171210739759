import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import Slider from "react-rangeslider";
import { Link } from "react-router-dom";
import { FiEdit, FiArrowRight } from "react-icons/fi";

import { BiSearch } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";

import "../../App.css";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rangeslider/lib/index.css";
import PortfolioStock from "./PortfolioStock";
import EmptyPortfolio from "./EmptyPortfolio";
import AddPortfolio from "./AddPortfolio";

import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import {
  deleteportfolio,
  editportfolio,
  getdaily,
  getmonthly,
  getPortfolioList,
  getweekly,
  Searchportfolio,
  stockperportfolio,
} from "../../data/portfolio/portfolio.actions";
import moment from "moment";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import NumberFormat from "react-number-format";

interface OwnProps {}

interface StateProps {
  isRegisterSuccess: any;
  portfolioCreate: boolean;
  portfolioList: any;
  delreload: any;
  portfoliosearch: any;
  getportfoliovalue: any;
}

interface DispatchProps {
  getPortfolioList: typeof getPortfolioList;
  deleteportfolio: typeof deleteportfolio;
  Searchportfolio: typeof Searchportfolio;
  stockperportfolio: typeof stockperportfolio;
  getdaily: typeof getdaily;
  getweekly: typeof getweekly;
  getmonthly: typeof getmonthly;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface MyPortfolioProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const MyPortfolio: React.FC<MyPortfolioProps> = ({
  isRegisterSuccess,
  history,
  getPortfolioList,
  portfolioCreate,
  deleteportfolio,
  portfolioList,
  delreload,
  stockperportfolio,
  Searchportfolio,
  portfoliosearch,
  getdaily,
  getweekly,
  getmonthly,
  getportfoliovalue,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [newPortfolio, setNewPortfolio] = useMergeState({});
  const [insvalue, setInsvalue] = useState(100000000);
  const [portfolioname, setPortfolioname] = useState("");
  const [portfolioId, setPortfolioId] = useState(0);
  const [portfolio_id, setPortfolio_id] = useState("");
  const [use, setuse] = useState("");

  const m = moment();

  const portfolioSchema = Yup.object().shape({
    portfolio_name: Yup.string().required("Portfolio Name is required"),
  });
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  useEffect(() => {
    if (delreload) {
      getPortfolioList();
    }
    return function cleanup() {};
  }, [delreload, getPortfolioList]);

  const search = async () => {
    //e.preventDefault();
    await Searchportfolio(portfolioname);
  };

  useEffect(() => {
    getPortfolioList();
  }, []);

  {
    /* const portidpass = async () => {
    //e.preventDefault();
  
      await stockperportfolio(portfolio_id);
    
 */
  }
  useEffect(() => {
    getdaily(portfolioId);
  }, []);
  useEffect(() => {
    getweekly(portfolioId);
  }, []);

  useEffect(() => {
    getmonthly(portfolioId, "yarly", use);
  }, []);

  if (portfolioList.length == 0) {
    return <EmptyPortfolio />;
  } else {
    return (
      <>
        <Row>
          <Col xl={8} md={8} className="mt-4 mb-sm-2">
            <div className="input-relative">
              <input
                className="search-dash mr-sm-2"
                type="search"
                placeholder="Search portfolio "
                value={portfolioname}
                onChange={(e) => {
                  setPortfolioname(e.target.value);
                  search();
                }}
              />
            </div>
          </Col>
          <Col xl={4} md={4} className="mt-4 mb-sm-2">
            <div className="text-right mb-4 mb-sm-0">
              <a className="btn-create" onClick={() => setShowModal(true)}>
                Create Portfolio
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={8} md={12} className="mt-4 mb-sm-5">
            <div className="table-responsive dash-table-div p-3">
              <table className="table dash-table scrolltable">
                <thead>
                  <tr>
                    <th>Portfolio Name</th>
                    <th className="pl-0">Inception Date </th>
                    <th className="pl-0">Net Asset Value</th>
                    <th className="pl-0">IRR since inception</th>
                    <th className="pl-0">Actions</th>
                  </tr>
                </thead>
                <tbody className="scroll-ball">
                  {portfolioList.map((portfolio: any, index: any) => {
                    return (
                      <tr>
                        <td>
                          <a
                            onClick={() => {
                              setPortfolio_id(portfolio.id);
                            }}
                          >
                            {" "}
                            {portfolio.portfolio_name}
                          </a>
                        </td>
                        <td>
                          <a
                            onClick={() => {
                              setPortfolio_id(portfolio.id);
                            }}
                          >
                            {" "}
                            {moment(portfolio.created_at).format(
                              "YYYY-MM-DD hh:mm:ss"
                            )}
                          </a>
                        </td>
                        <td>
                          <a
                            onClick={() => {
                              setPortfolio_id(portfolio.id);
                            }}
                          >
                            {" "}
                            {moment(portfolio.updated_at).format(
                              "YYYY-MM-DD hh:mm:ss"
                            )}
                          </a>
                        </td>
                        <td>
                          <a
                            onClick={() => {
                              setPortfolio_id(portfolio.id);
                            }}
                          >
                            {" "}
                            <NumberFormat
                              value={portfolio.investment_value}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </a>
                        </td>
                        <td>
                          <a
                            className="btn-edit mr-3"
                            onClick={() => {
                              setPortfolioId(portfolio.id);
                              setShowModal(true);
                            }}
                          >
                            <FiEdit className="mr-2"></FiEdit>Adjust
                          </a>
                          <a
                            className="btn-delete"
                            onClick={() => {
                              deleteportfolio(portfolio.id);
                            }}
                          >
                            {" "}
                            <BsTrash className="mr-1"></BsTrash> Delete
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Col>

          <Col xl={4} md={12} className="mt-4">
            <div className="Portfolio-bk mt-2 mb-4 mb-sm-0">
              <h3 className="bold-16">Total Portfolio Value</h3>
              <h4 className="bold-45">
                <NumberFormat
                  value={getportfoliovalue}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </h4>
              <Link to="/Portfolio" className="green-text underline one-four">
                Go to Portfolio <FiArrowRight className="ml-1"></FiArrowRight>
              </Link>
            </div>
            {portfolio_id && (
              <PortfolioStock stockId={0} portId={portfolio_id} />
            )}
          </Col>
        </Row>
        <Modal
          show={showModal}
          cssClass="modalhome"
          onHide={() => setShowModal(false)}
        >
          <div className="modalselect">
            <AddPortfolio
              portfolioId={portfolioId}
              setShowModal={setShowModal}
            />
          </div>
        </Modal>
      </>
    );
  }
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isRegisterSuccess: state.user.isRegisterSuccess,
    portfolioCreate: state.portfolio.portfolioCreate,
    portfolioList: state.portfolio.portfolioList,
    delreload: state.portfolio.delreload,
    portfoliosearch: state.portfolio.portfoliosearch,
    getportfoliovalue: state.portfolio.getportfoliovalue,
  }),
  mapDispatchToProps: {
    getPortfolioList,
    deleteportfolio,
    Searchportfolio,
    stockperportfolio,
    getdaily,
    getweekly,
    getmonthly,
  },
  component: MyPortfolio,
});
