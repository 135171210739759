import { ActionType } from "../../util/types";
import { MarketState } from "./market.state";
import { showToast } from "../global/global.actions";
import { MarketService } from "./market.service";
import moment from "moment";

export const Allschedule = () => async (dispatch: React.Dispatch<any>) => {
  await MarketService.allschedule().then(
    (getalldevice) => {
      dispatch(setAlldevice(getalldevice));
    },
    (error) => {}
  );
};

export const DeviceOn = () => async (dispatch: React.Dispatch<any>) => {
  await MarketService.deviceon().then(
    (getdeviceon) => {
      dispatch(setDeviceon(getdeviceon));
    },
    (error) => {}
  );
};

export const getcreatehome =
  (home_name: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await MarketService.inserthome(home_name).then(
      (homeinsert) => {
        dispatch(setShowLoading(false));
        dispatch(
          showToast({
            isOpen: true,
            color: "success",
            message: "Created Successfully",
          })
        );
        dispatch(setHomeReset(true));
      },
      (error) => {
        dispatch(showToast({ isOpen: true, color: "danger", message: error }));
      }
    );
  };

export const gethomes = () => async (dispatch: React.Dispatch<any>) => {
  await MarketService.gethomes().then(
    (homelist) => {
      dispatch(setHomelist(homelist));
    },
    (error) => {}
  );
};
export const getsinglehome =
  (id: any) => async (dispatch: React.Dispatch<any>) => {
    await MarketService.gethomename(id).then(
      (singlehome) => {
        dispatch(setSinglehome(singlehome));
      },
      (error) => {}
    );
  };

export const getinsertroom =
  (room_name: any, home_id: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await MarketService.insertroom(room_name, home_id).then(
      (roominsert) => {
        dispatch(setShowLoading(false));
        dispatch(
          showToast({
            isOpen: true,
            color: "success",
            message: "Created Successfully",
          })
        );
        dispatch(setRoomReset(true));
      },
      (error) => {
        dispatch(showToast({ isOpen: true, color: "danger", message: error }));
      }
    );
  };

export const getroomlist =
  (id: any) => async (dispatch: React.Dispatch<any>) => {
    await MarketService.getroomlist(id).then(
      (roomlist) => {
        dispatch(setRoomlist(roomlist));
      },
      (error) => {}
    );
  };
export const getedithome =
  (home_id: any, room_name: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await MarketService.edithome(home_id, room_name).then(
      (roominsert) => {
        dispatch(setShowLoading(false));
        dispatch(
          showToast({
            isOpen: true,
            color: "success",
            message: "Name Edited Successfully",
          })
        );
        dispatch(setEditRoomReset(true));
      },
      (error) => {
        dispatch(showToast({ isOpen: true, color: "danger", message: error }));
      }
    );
  };
export const getsingleroom =
  (id: any) => async (dispatch: React.Dispatch<any>) => {
    await MarketService.getsingleroomname(id).then(
      (singleroomname) => {
        dispatch(setSingleroomname(singleroomname));
      },
      (error) => {}
    );
  };

export const ignorevaluation =
  (valuation_id: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await MarketService.ignorevaluation(valuation_id).then(
      (resetignore) => {
        dispatch(setShowLoading(false));
        dispatch(
          showToast({
            isOpen: true,
            color: "success",
            message: "Valuation Ignored Successfully",
          })
        );
        dispatch(setIgnore(true));
      },
      (error) => {
        dispatch(showToast({ isOpen: true, color: "danger", message: error }));
      }
    );
  };
export const marketsearch =
  (input: any) => async (dispatch: React.Dispatch<any>) => {
    await MarketService.mrmarketsearch(input).then(
      (searchmarket) => {
        //console.log(searchmarket.length,'searchmarketsearchmarket')
        dispatch(setsearchmarket(searchmarket.length ? searchmarket : []));
      },
      (error) => {}
    );
  };
export const marketprofile =
  (inputprofile: any) => async (dispatch: React.Dispatch<any>) => {
    await MarketService.marketprofile(inputprofile).then(
      (marketgetprofile) => {
        dispatch(setmarketgetprofile(marketgetprofile[0]));
      },
      (error) => {}
    );
  };
export const marketratio =
  (inputprofile: any) => async (dispatch: React.Dispatch<any>) => {
    await MarketService.marketratio(inputprofile).then(
      (marketratioget) => {
        dispatch(setmarketratioget(marketratioget[0]));
      },
      (error) => {}
    );
  };

export const addliveprice = () => async (dispatch: React.Dispatch<any>) => {
  await MarketService.getliveprice().then(
    (liveprices) => {
      dispatch(setgetliveprice(liveprices));
    },
    (error) => {}
  );
};
export const getcashflow =
  (stocksymbol: any, limit: any, period: any) =>
  async (dispatch: React.Dispatch<any>) => {
    await MarketService.getcashflow(stocksymbol, limit, period).then(
      (cashflow) => {
        dispatch(setcashflow(cashflow));
      },
      (error) => {}
    );
  };

export const getbalancestatement =
  (stocksymbol: any, periodbalance: any, limit: any) =>
  async (dispatch: React.Dispatch<any>) => {
    await MarketService.getbalancestatement(
      stocksymbol,
      periodbalance,
      limit
    ).then(
      (balancestatement) => {
        dispatch(setbalancestatement(balancestatement));
      },
      (error) => {}
    );
  };
export const getincomestatement =
  (stocksymbol: any, periodincome: any, limit: any) =>
  async (dispatch: React.Dispatch<any>) => {
    await MarketService.getincomestatement(
      stocksymbol,
      periodincome,
      limit
    ).then(
      (incomestatement) => {
        dispatch(setincomestatement(incomestatement));
        if (incomestatement.length > 0) {
          dispatch(setcontrolstate(incomestatement[0]["reportedCurrency"]));
          dispatch(setcalendarYear(incomestatement[0]["calendarYear"]));
        } else {
          dispatch(setcontrolstate("USD"));
        }
      },
      (error) => {}
    );
  };

export const getincomestatements =
  (stocksymbol: any, periodincome: any, limit: any) =>
  async (dispatch: React.Dispatch<any>) => {
    await MarketService.getincomestatement(
      stocksymbol,
      periodincome,
      limit
    ).then(
      (income) => { 
        dispatch(setincome(income));
        if (income.length > 0) {
          dispatch(setcontrolstate(income[0]["reportedCurrency"]));
          dispatch(setcalendarYear(income[0]["calendarYear"]));
        }
      },
      (error) => {}
    );
  };
export const getinstitutionalowner =
  (stocksymbol: any) => async (dispatch: React.Dispatch<any>) => {
    await MarketService.getinstitutionalowner(stocksymbol).then(
      (instituteowner) => {
        if (instituteowner) {
          const result = instituteowner.filter(
            (owner: any) =>
              owner.dateReported ==
              moment(instituteowner[0].dateReported)
                .subtract(1, "Q")
                .endOf('quarter')
                .format("YYYY-MM-DD")
          );
          dispatch(setinstituteowner(result));
        }
      },
      (error) => {}
    );
  };

export const setShowLoading = (showLoading: boolean) =>
  ({
    type: "show-loading",
    showLoading,
  } as const);
export const setcontrolstate = (controlstate: string) =>
  ({
    type: "show-controlstate",
    controlstate,
  } as const);
export const setShowDrawer = (showDrawer: boolean) =>
  ({
    type: "show-Drawer",
    showDrawer,
  } as const);
export const setAlldevice = (alldevice: any) =>
  ({
    type: "alldevice-list",
    alldevice,
  } as const);
export const setDeviceon = (deviceon: any) =>
  ({
    type: "deviceon-list",
    deviceon,
  } as const);
export const setHomeReset = (homereset: boolean) =>
  ({
    type: "home-reset",
    homereset,
  } as const);
export const setHomelist = (homelist: any) =>
  ({
    type: "home-list",
    homelist,
  } as const);
export const setSinglehome = (singlehome: any) =>
  ({
    type: "single-home",
    singlehome,
  } as const);

export const setRoomReset = (roomreset: boolean) =>
  ({
    type: "room-reset",
    roomreset,
  } as const);
export const setRoomlist = (roomlist: any) =>
  ({
    type: "room-list",
    roomlist,
  } as const);
export const setEditRoomReset = (roomeditreset: boolean) =>
  ({
    type: "room-editreset",
    roomeditreset,
  } as const);
export const setSingleroomname = (roomname: any) =>
  ({
    type: "room-name",
    roomname,
  } as const);
export const setIgnore = (ignore: boolean) =>
  ({
    type: "set-ignore",
    ignore,
  } as const);
export const setsearchmarket = (searchmarket: any) =>
  ({
    type: "set-searchmarket",
    searchmarket,
  } as const);
export const setmarketgetprofile = (marketgetprofile: any) =>
  ({
    type: "set-marketgetprofile",
    marketgetprofile,
  } as const);
export const setmarketratioget = (marketratioget: any) =>
  ({
    type: "set-marketratioget",
    marketratioget,
  } as const);

export const setgetliveprice = (liveprices: any) =>
  ({
    type: "set-getliveprice",
    liveprices,
  } as const);
export const setcashflow = (cashflows: any) =>
  ({
    type: "set-cashflow",
    cashflows,
  } as const);
export const setbalancestatement = (balancestatements: any) =>
  ({
    type: "set-balancestatement",
    balancestatements,
  } as const);
export const setincomestatement = (incomestatement: any) =>
  ({
    type: "set-incomestatement",
    incomestatement,
  } as const);
export const setinstituteowner = (instituteowner: any) =>
  ({
    type: "set-instituteowner",
    instituteowner,
  } as const);
export const setincome = (income: any) =>
  ({
    type: "set-income",
    income,
  } as const);
export const setcalendarYear = (calendarYear: any) =>
  ({
    type: "set-calendarYear",
    calendarYear,
  } as const);

  
export type MarketActions =
  | ActionType<typeof setShowLoading>
  | ActionType<typeof setcontrolstate>
  | ActionType<typeof setShowDrawer>
  | ActionType<typeof setAlldevice>
  | ActionType<typeof setDeviceon>
  | ActionType<typeof setHomeReset>
  | ActionType<typeof setHomelist>
  | ActionType<typeof setSinglehome>
  | ActionType<typeof setRoomReset>
  | ActionType<typeof setRoomlist>
  | ActionType<typeof setEditRoomReset>
  | ActionType<typeof setSingleroomname>
  | ActionType<typeof setIgnore>
  | ActionType<typeof setsearchmarket>
  | ActionType<typeof setmarketgetprofile>
  | ActionType<typeof setmarketratioget>
  | ActionType<typeof setgetliveprice>
  | ActionType<typeof setcashflow>
  | ActionType<typeof setbalancestatement>
  | ActionType<typeof setincomestatement>
  | ActionType<typeof setinstituteowner>
  | ActionType<typeof setincome>
  | ActionType<typeof setcalendarYear>
