import React, { useEffect } from "react";
import { Router, Route, Link, Switch } from "react-router-dom";

import ReactGA from "react-ga4";
import { history } from "../data/_helpers";

import { PrivateRoute } from "../_components";
import { HomePage } from "../HomePage/HomePage";
import Login from "../Login";
import Signup from "../Signup";
import Forget from "../Forget";
import Reset from "../Reset";
import Aboutus from "../Aboutus";
import EmptyPage from "../EmptyPage";
import Sucess from "../Sucess";
import Verify_account from "../Verify_account";
import Contactus from "../Contactus";
import Compliance from "../Compliance";
import ResetSucess from "../ResetSucess";
import ResetSucessmsg from "../ResetSucessmsg";
import Termsofuse from "../Termsofuse";
import Privacypolicy from "../Privacypolicy";
import Dashboard from "../Dashboard";
import Header from "../header";
import Myaccount from "../Myaccount";
import Portfolio from "../Pages/Portfolio/Portfolio";
import Pitches from "../Pitches";
import Market_details from "../Market_details";
import Watchlist from "../Watchlist";
import InvesmentDropDown from "../Pages/Market/Invesmentdropdown";
import News from "../Pages/Market/News";
import Balancesheet from "../Pages/Market/Balancesheet";
import Cashflowstatement from "../Pages/Market/Cashflowstatement";
import Market from "../Pages/Market/Market";
import Loader from "react-loader-spinner";
import { connect } from "../data/connect";
import { AppContextProvider } from "../data/AppContext";
import { loadConfData } from "../data/sessions/sessions.actions";
import {
  setIsLoggedIn,
  setUsername,
  loadUserData,
  logoutUser,
} from "../data/user/user.actions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { showToast } from "../data/global/global.actions";
import { ToastContainer } from "react-toastify";
import RedirectToLogin from "../_components/RedirectToLogin";
import Screener from "../Pages/Market/Screener";
import Mytrackedpitches from "../Pages/Mytrackedpitches";

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <AppConnected />
    </AppContextProvider>
  );
};

interface StateProps {
  darkMode: boolean;
  showDrawer: boolean;
  isLoggedin: boolean;
  showLoading: boolean;
}

interface DispatchProps {
  loadConfData: typeof loadConfData;
  loadUserData: typeof loadUserData;
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  logoutUser: typeof logoutUser;
}
interface WebAppProps extends StateProps, DispatchProps {}

const WebApp: React.FC<WebAppProps> = ({
  darkMode,
  isLoggedin,
  setIsLoggedIn,
  setUsername,
  showDrawer,
  loadConfData,
  loadUserData,
  showLoading,
  logoutUser,
}) => {
  useEffect(() => {
    ReactGA.initialize('G-GJ7EPC1100');
  }, []);
  return (
    <Router history={history}>
      <div>
        <div className="sloader">
          {showLoading}
          <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={2000} //3 secs
            visible={showLoading}
          />
        </div>

        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/signup" exact component={Signup} />
          <Route path="/forget" exact component={Forget} />
          <Route path="/reset_password/:code" exact component={Reset} />
          <Route path="/Aboutus" exact component={Aboutus} />
          <Route path="/EmptyPage" exact component={EmptyPage} />
          <Route path="/Contactus" exact component={Contactus} />
          <Route path="/Sucess" exact component={Sucess} />
          <Route path="/Compliance" exact component={Compliance} />
          <Route path="/Termsofuse" exact component={Termsofuse} />
          <Route path="/Privacypolicy" exact component={Privacypolicy} />
          <Route
            path="/Verify_account/:code"
            exact
            component={Verify_account}
          />
          <Route path="/ResetSucess" exact component={ResetSucess} />
          <Route path="/ResetSucessmsg" exact component={ResetSucessmsg} />
          <PrivateRoute
            isAuthenticated={isLoggedin}
            path="/Header"
            exact
            component={Header}
          />
          <PrivateRoute
            isAuthenticated={isLoggedin}
            path="/Dashboard"
            exact
            component={Dashboard}
          />
          <PrivateRoute
            isAuthenticated={isLoggedin}
            path="/Myaccount"
            exact
            component={Myaccount}
          />
          <PrivateRoute
            isAuthenticated={isLoggedin}
            path="/Portfolio"
            exact
            component={Portfolio}
          />
          <PrivateRoute
            isAuthenticated={isLoggedin}
            path="/Screener"
            exact
            component={Screener}
          />

          <PrivateRoute
            isAuthenticated={isLoggedin}
            path="/Mytrackedpitches"
            exact
            component={Mytrackedpitches}
          />
          <PrivateRoute
            isAuthenticated={isLoggedin}
            path="/Pitches"
            exact
            component={Pitches}
          />
          <PrivateRoute
            isAuthenticated={isLoggedin}
            path="/Market_details"
            exact
            component={Market_details}
          />
          <PrivateRoute
            isAuthenticated={isLoggedin}
            path="/Watchlist"
            exact
            component={Watchlist}
          />
          <Route path="/Market" exact component={Market} />
          <Route path="/" exact component={Market} />
          <Route path="/Market/:id" exact component={Market} />

          <PrivateRoute
            isAuthenticated={isLoggedin}
            path="/InvesmentDropDowns"
            exact
            component={InvesmentDropDown}
          />
          <PrivateRoute
            isAuthenticated={isLoggedin}
            path="/BalanceSheet"
            exact
            component={Balancesheet}
          />
          <PrivateRoute
            isAuthenticated={isLoggedin}
            path="/Cashflowstatement"
            exact
            component={Cashflowstatement}
          />
          <PrivateRoute
            isAuthenticated={isLoggedin}
            path="/news"
            exact
            component={News}
          />
          <Route
            path="/logout"
            render={() => {
              return (
                <RedirectToLogin
                  setIsLoggedIn={setIsLoggedIn}
                  setUsername={setUsername}
                  logoutUser={logoutUser}
                />
              );
            }}
          />

          {/*<PrivateRoute path="/csv" exact component={Csv} /><PrivateRoute path="/passwordedit/:edituserid" exact component={PasswordEdit} />*/}
        </Switch>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Router>
  );
};

export { App };

const AppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    schedule: state.data.schedule,
    showDrawer: state.global.showDrawer,
    showLoading: state.global.showLoading,
    isLoggedin: state.user.isLoggedin,
    showToast: state.global.showToast,
  }),
  mapDispatchToProps: {
    loadConfData,
    loadUserData,
    setIsLoggedIn,
    setUsername,
    logoutUser,
  },
  component: WebApp,
});
