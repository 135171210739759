import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import Slider from "react-rangeslider";
import ReactGA from "react-ga4";
import { BiSearch } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";

import "../../App.css";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rangeslider/lib/index.css";

import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import {
  deleteportfolio,
  getPortfolioList,
} from "../../data/portfolio/portfolio.actions";
import moment from "moment";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface EmptyStockProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const EmptyStock: React.FC<EmptyStockProps> = ({}) => {
  const [showModal, setShowModal] = useState(false);
  const [newPortfolio, setNewPortfolio] = useMergeState({});
  const [insvalue, setInsvalue] = useState(100000000);
  const [portfolioId, setPortfolioId] = useState();
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  return (
    <>
      <div className="gradent-black text-white min-radius-7 text-center align-self-center emptyStockProfileCont">
        <Row className="empty-h">
          <Col className="align-self-center">
            <img
              className="img-fluid "
              src="/assets/images/undraw_revenue.png"
            />
            <p className="inf">Select a company to see relevant information</p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: EmptyStock,
});
