import React, { useState, useEffect } from "react";

import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";

import { BiSearch } from "react-icons/bi";
import { RiCloseFill } from "react-icons/ri";
import { BiFilterAlt, BiBookmark } from "react-icons/bi";
import { BsTrash, BsFillBookmarkFill } from "react-icons/bs";
import { stringify } from "querystring";

import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import moment from "moment";
import Accordion from "react-bootstrap/Accordion";

import Form from "react-bootstrap/Form";

import Card from "react-bootstrap/Card";
import { Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom";
import Header from "./header";
import Footer from "./footer";
import EmptyWatchlist from "./Pages/Watchlist/EmptyWatchlist";

import {
  IoIosNotificationsOutline,
  IoIosHelpCircle,
  IoMdCheckmark,
  IoIosCloseCircleOutline,
  IoIosArchive,
  IoIosBackspace,
  IoMdAdd,
  IoMdExit,
  IoIosHome,
  IoIosSwap,
  IoIosAdd,
  IoIosShare,
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosCloudUpload,
  IoMdNotifications,
} from "react-icons/io";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTrue } from "typescript";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { RouteComponentProps } from "react-router";
import { connect } from "./data/connect";
import ReactGA from "react-ga4";
import {
  allwatchlistget,
  removewatchlist,
} from "./data/watchlist/watchlist.actions";

interface OwnProps {}

interface StateProps {
  watchlistget: any;
}

interface DispatchProps {
  allwatchlistget: typeof allwatchlistget;
  removewatchlist: typeof removewatchlist;
}

interface WatchlistProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const Watchlist: React.FC<WatchlistProps> = ({
  allwatchlistget,
  removewatchlist,
  watchlistget,
}) => {
  const [symbol, setSymbol] = useState("");
  const [viewhistorical, setViewhistorical] = useState(false);
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  useEffect(() => {
    allwatchlistget();
  }, [allwatchlistget]);

  const removewatch = async () => {
    //e.preventDefault();

    await removewatchlist(symbol);
  };

  return (
    <div className="">
      <div className="login-back">
        <Header />
        <Container fluid className="pb-5">
          <Row>
            {watchlistget.length == 0 && <EmptyWatchlist />}
            {watchlistget.length > 0 && (
              <Col xl={6} md={12} className="mt-4">
                <h3 className="list-hd ml-3 bold-15">My Watchlist</h3>
                <div className="dash-table-div p-3 w-list-h mt-0">
                  <div className="input-relative mb-2">
                    <BiSearch className="same-icons-rit green-text"></BiSearch>
                    <input
                      className="search-dash mr-sm-2 gray-gradient gray-border"
                      type="search"
                      placeholder="Search stocks and shares here"
                    />
                  </div>
                  <div className="table-responsive">
                    <table className="table dash-table scrolltable">
                      <thead>
                        <tr className="gradent-black">
                          <th className="bold-14 w-50">Name</th>
                          <th className="bold-14 pl-0">LTP</th>
                          <th className="bold-14 pl-0">CHG</th>
                          <th className="bold-14 pl-0">CHG %</th>
                          <th className="bold-14 pl-0">Action</th>
                        </tr>
                      </thead>
                      <tbody className="scroll">
                        {watchlistget.map((watchlist: any, index: any) => {
                          return (
                            <>
                              <tr>
                                <td width="50%">
                                  <div className="row">
                                    <div className="col-sm-2 col-3">
                                      <img
                                        className="img-fluid mt-1"
                                        src="/assets/images/3862.png"
                                      />
                                    </div>
                                    <div className="col-sm-8 pl-0 col-9">
                                      <a
                                        onClick={() => {
                                          setViewhistorical(true);
                                        }}
                                      >
                                        {" "}
                                        <p className="bold-14 m-0">
                                          BAJFINANCE{" "}
                                          <small className="ten">NSE</small>
                                        </p>{" "}
                                      </a>
                                      <p className="regular-14 m-0">
                                        BAJAJ FINANCE LIMITED
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <a
                                    onClick={() => {
                                      setViewhistorical(true);
                                    }}
                                  >
                                    {" "}
                                    $20,000
                                  </a>
                                </td>
                                <td className="text-red">
                                  <a
                                    onClick={() => {
                                      setViewhistorical(true);
                                    }}
                                  >
                                    4.80{" "}
                                  </a>
                                </td>
                                <td className="green-text">
                                  <a
                                    onClick={() => {
                                      setViewhistorical(true);
                                    }}
                                  >
                                    {" "}
                                    0.15{" "}
                                  </a>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary pr-2"
                                    onClick={() => {
                                      removewatch();
                                      setSymbol(watchlist.stock_symbol);
                                    }}
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            )}

            {(() => {
              if (viewhistorical == true) {
                return (
                  <div>
                    <Col xl={6} md={12} className="mt-5 pb-md-5">
                      <div className="dash-table-div p-3 mt-4">
                        <Row className="row justify-content-center mt-2 pt-3">
                          <Col sm={12} className="">
                            <Row className="row justify-content-center">
                              <Col sm={8} className="">
                                <Row className="row justify-content-center">
                                  <Col sm={8} xs={12} className="text-left">
                                    <p className="m-0 regular-15">
                                      Alibaba Group Holding Ltd
                                    </p>
                                  </Col>
                                  <Col
                                    sm={4}
                                    xs={12}
                                    className="align-self-center text-sm-right"
                                  >
                                    <p className="m-0 text-white regular-15">
                                      <span className="color-97">HKG:</span>{" "}
                                      9988
                                    </p>
                                  </Col>
                                </Row>
                                <h4 className="regular-45">
                                  224.40{" "}
                                  <span className="color-97 bold-18">HKD</span>
                                </h4>
                                <p className="regular-15 green-text">
                                  +3.00 (1.36%)
                                </p>
                                <p className="regular-14 color-97">
                                  23 Apr, 4:08 pm GMT+8
                                </p>
                                <ul className="mb-0 tab-ul">
                                  <li>
                                    <a href="">1 Day</a>
                                  </li>
                                  <li>
                                    <a href="">5 Day</a>
                                  </li>
                                  <li>
                                    <a href="">1 Months</a>
                                  </li>
                                  <li>
                                    <a href="">3 Months</a>
                                  </li>
                                  <li>
                                    <a href="">5 Months</a>
                                  </li>
                                  <li>
                                    <a href="">1 Year</a>
                                  </li>
                                  <li>
                                    <a href="">3 Year</a>
                                  </li>
                                  <li>
                                    <a href="">Max</a>
                                  </li>
                                </ul>
                                <img
                                  className="img-fluid mt-3"
                                  src="/assets/images/Group3.png"
                                />
                                <Row className="mt-2">
                                  <Col sm={4}>
                                    <Row>
                                      <Col sm={6} xs={6}>
                                        <p className="bold-16 color-97 mt-0">
                                          Open
                                        </p>
                                        <p className="bold-16 color-97 mt-0">
                                          High
                                        </p>
                                        <p className="bold-16 color-97 mt-0">
                                          Low
                                        </p>
                                      </Col>
                                      <Col sm={6} xs={6} className="text-right">
                                        <p className="bold-16 mt-0">223.60</p>
                                        <p className="bold-16 mt-0">223.60</p>
                                        <p className="bold-16 mt-0">223.60</p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col sm={4}>
                                    <Row>
                                      <Col
                                        sm={6}
                                        xs={6}
                                        className="pl-sm-0 pr-sm-0"
                                      >
                                        <p className="bold-16 color-97 mt-0">
                                          Mkt cap
                                        </p>
                                        <p className="bold-16 color-97 mt-0">
                                          P/E ratio
                                        </p>
                                        <p className="bold-16 color-97 mt-0">
                                          Low
                                        </p>
                                      </Col>
                                      <Col sm={6} xs={6} className="text-right">
                                        <p className="bold-16 mt-0">223.60</p>
                                        <p className="bold-16 mt-0">223.60</p>
                                        <p className="bold-16 mt-0">-</p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col sm={4}>
                                    <Row>
                                      <Col sm={6} xs={6}>
                                        <p className="bold-16 color-97 mt-0">
                                          Open
                                        </p>
                                        <p className="bold-16 color-97 mt-0">
                                          High
                                        </p>
                                        <p className="bold-16 color-97 mt-0">
                                          Low
                                        </p>
                                      </Col>
                                      <Col sm={6} xs={6} className="text-right">
                                        <p className="bold-16 mt-0">223.60</p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm={4} className="align-self-center">
                                <div className="gradent-black text-white pl-3 min-radius-7">
                                  <h4 className="bold-15 color-97 pt-3 pt-sm-0">
                                    Company Info
                                  </h4>
                                  <div className="para-c-h scroll-ball">
                                    <p className="regular-13 mt-0">
                                      Alibaba Group Holding Limited, also known
                                      as Alibaba Group and Alibaba.com, is a
                                      Chinese multinational technology company
                                      specializing in e-commerce, retail,
                                      Internet, and technology. Founded on 28
                                      June 1999[1] in Hangzhou, Zhejiang, the
                                      company provides consumer-to-consumer
                                      (C2C), and business-to-business (B2B)
                                      sales services via web portals, shopping
                                      search engines and cloud computing
                                      services.
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          {/*   <Col sm={8} className="align-self-center text-center">
            <img className="img-fluid" src="/assets/images/undraw_revenue.png" />
            <p className="m-0 one-six text-center color-97">Select shares to see information's.</p>
  </Col> */}
                        </Row>
                      </div>
                      <div className="text-right pt-4 pb-3 pb-sm-5">
                        <a
                          href=""
                          className="btn-value mr-sm-2 text-center mobi-block"
                        >
                          Value Business
                        </a>
                        <a
                          href=""
                          className="btn-ignore mt-3 mt-sm-0 text-center mobi-block mr-2"
                        >
                          Ignore
                        </a>
                        <a className="bold-15 mt-3 mt-sm-0 text-center mobi-block text-white">
                          <BsFillBookmarkFill></BsFillBookmarkFill> Remove From
                          Watchlist
                        </a>
                      </div>
                    </Col>
                  </div>
                );
              }
            })()}
          </Row>
        </Container>
        <Footer />
      </div>
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    watchlistget: state.watchlist.watchlistget,
  }),
  mapDispatchToProps: {
    allwatchlistget,
    removewatchlist,
  },
  component: Watchlist,
});
