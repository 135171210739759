import { ActionType } from "../../util/types";
import { PortfolioState } from "./portfolio.state";
import { Toast } from "../../models/OtrModel";
import { PortfolioService } from "./portfolio.service";
import { toast } from "react-toastify";
import { setShowLoading, showToast } from "../global/global.actions";

export const getPortfolioList = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setShowLoading(true));
  await PortfolioService.getPortfolioList().then(
    (portfolioList) => {
      if (portfolioList.data) {
        dispatch(setPortfolio(portfolioList.data.data));
        dispatch(
          setPortfoliovalue(parseInt(portfolioList.total_porfolio_value))
        );
      } else {
        dispatch(setPortfolio([]));
      }
      dispatch(setShowLoading(false));
    },
    (error) => {
      dispatch(setShowLoading(false));
    }
  );
};

export const addPortfolio =
  (portFolio: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    dispatch(portfolioCreate(false));
    await PortfolioService.addPortfolio(portFolio).then(
      (portfolioList) => {
        dispatch(portfolioCreate(true));
        getPortfolioList();
        dispatch(setShowLoading(false));
        dispatch(toast.success(" Portfolio Created sucessfully"));
      },
      (error) => {
        dispatch(toast.error("Error with Portfolio creation"));
        dispatch(setShowLoading(false));
      }
    );
  };

export const deleteportfolio =
  (portfoliioid: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.deleteportfolio(portfoliioid).then(
      (portfolioreload) => {
        dispatch(toast.success(" Deleted sucessfully!"));
        dispatch(setportfoliodelreload(true));
      },
      (error) => {
        dispatch(toast.success(" Deleted un sucessfully!"));
      }
    );
  };

export const editportfolio =
  (portfoliioid: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    dispatch(portfolioCreate(false));
    await PortfolioService.editportfolio(portfoliioid).then(
      (portfolioeditreload) => {
        dispatch(setShowLoading(true));

        if (portfolioeditreload.data) {
          dispatch(setPortfolioeditreload(portfolioeditreload.data[0]));
        }
      },
      (error) => {
        dispatch(toast.success(" Deleted un sucessfully !"));
      }
    );
  };

export const Searchportfolio =
  (portfolioname: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.searchportfolio(portfolioname).then(
      (searchportfolio) => {
        if (searchportfolio.data) {
          dispatch(setPortfolio(searchportfolio.data.data));
        } else {
          dispatch(setPortfolio([]));
        }
      }
    );
  };
export const portfolioupdate =
  (portfolio: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await PortfolioService.portfolioupdate(portfolio).then(
      (portfolioupdate) => {
        dispatch(setShowLoading(true));
        dispatch(toast.success(" Updated sucessfully"));
        dispatch(setPortfolioupdate(true));
        dispatch(portfolioCreate(true));
      },
      (error) => {
        dispatch(toast.success(" Deleted un sucessfully"));
      }
    );
  };

export const stockperportfolio =
  (portfoliioid: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.stockperportfolio(portfoliioid).then(
      (stockperid) => {
        if (stockperid.data) dispatch(setStockperid(stockperid.data));
        else dispatch(setStockperid([]));
      },
      (error) => {}
    );
  };

export const getdaily =
  (stocksymbol: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await PortfolioService.getdaily(stocksymbol).then(
      (daily) => {
        dispatch(setShowLoading(true));
        //dispatch(setStockHistorical(daily));
      },
      (error) => {
        //dispatch(toast.success(' Error'));
      }
    );
  };

export const getweekly =
  (stocksymbol: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await PortfolioService.getweekly(stocksymbol).then(
      (weekly) => {
        dispatch(setShowLoading(true));
        // dispatch(setStockHistorical(weekly));
      },
      (error) => {
        //dispatch(toast.success(' Error'));
      }
    );
  };

export const getmonthly =
  (stocksymbol: any, reportType: any, dataPoint: any) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await PortfolioService.getmonthly(stocksymbol, reportType, dataPoint).then(
      (monthly) => {
        dispatch(setShowLoading(true));
        dispatch(setStockHistorical(monthly));
        dispatch(setfin(monthly));
      },
      (error) => {
        //dispatch(toast.success(' Error'));
      }
    );
  };
export const getmonthlys =
  (stocksymbol: any, reportType: any, dataPoint: any) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await PortfolioService.getmonthlys(stocksymbol, reportType, dataPoint).then(
      (datas) => {
        dispatch(setShowLoading(true));
        dispatch(setfin(datas));
      },
      (error) => {
        //dispatch(toast.success(' Error'));
      }
    );
  };
export const getincome =
  (stocksymbol: any, limit: any, reportType: any) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await PortfolioService.getincome(stocksymbol, limit, reportType).then(
      (monthly) => {
        dispatch(setStockHistorical(monthly));
      },
      (error) => {
        //dispatch(toast.success(' Error'));
      }
    );
  };
export const getearnings =
  (stocksymbol: any, limit: any, reportType: any) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await PortfolioService.getincomes(stocksymbol, limit, reportType).then(
      (daily) => {
        dispatch(sethis(daily));
      },
      (error) => {
        dispatch(toast.error(" Error"));
      }
    );
  };
export const getbalance =
  (stocksymbol: any, limit: any, reportType: any) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await PortfolioService.getbalance(stocksymbol, limit, reportType).then(
      (monthly: any) => {
        //console.log(monthly,'monthlymonthlymonthlymonthly')
        dispatch(setStockHistorical(monthly));
      },
      (error) => {
        //dispatch(toast.success(' Error'));
      }
    );
  };
export const getmetirials =
  (stocksymbol: any, limit: any, reportType: any) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await PortfolioService.getmetirials(stocksymbol, limit, reportType).then(
      (monthly: any) => {
        dispatch(setStockHistorical(monthly));
        dispatch(setkeymetrics(monthly[0]));
      },
      (error) => {
        //dispatch(toast.success(' Error'));
      }
    );
  };
export const getquotes =
  (stocksymbol: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.getquote(stocksymbol).then(
      (quotereturn: any) => {
        dispatch(setquotereturn(quotereturn[0]));
      },
      (error) => {}
    );
  };
export const getquotespitch =
  (stocksymbol: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.getquote(stocksymbol).then(
      (quotereturnpitch: any) => {
        dispatch(setquotereturnpitch(quotereturnpitch));
      },
      (error) => {}
    );
  };
export const screenerselect =
  (screenerinput: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.getscreenerinputs(screenerinput).then(
      (insertscreener) => {
        dispatch(setShowLoading(false));
      },
      (error) => {}
    );
  };
export const addscreenerlist =
  (stocksymbol: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.getscreenerlist(stocksymbol).then(
      (screenerlistget) => {
        dispatch(setscreenerlistget(screenerlistget.data));
      },
      (error) => {}
    );
  };
export const addfbmarket =
  (stocksymbol: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.getfbmarket(stocksymbol).then(
      (monthly: any) => {
        dispatch(setStockHistorical(monthly));
      },
      (error) => {}
    );
  };
export const addfbmarketonemonth =
  (stocksymbol: any, fromdate: any, todate: any) =>
  async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.getfbmarketonemonth(
      stocksymbol,
      fromdate,
      todate
    ).then(
      (monthly: any) => {
        dispatch(
          setStockHistorical(monthly.historical ? monthly.historical : [])
        );
      },
      (error) => {}
    );
  };
export const addfbmarketfiveday =
  (duration: any, stocksymbol: any, fromdate: any, todate: any) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setStockHistorical([]));
    
    await PortfolioService.getfbmarketfiveday(
      duration,
      stocksymbol,
      fromdate,
      todate
    ).then(
      (monthly: any) => {
        dispatch(setStockHistorical(monthly));
      },
      (error) => {}
    );
  };
export const screenerdeleteing =
  (screenerid: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.screenerdelete(screenerid).then(
      (monthly) => {},
      (error) => {}
    );
  };
export const gettm =
  (stocksymbol: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.getannualratios(stocksymbol).then(
      (monthly: any) => {
        dispatch(setTtmValue(monthly));
      },
      (error) => {}
    );
  };

export const getkeymetricsttmvalue =
  (stocksymbol: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.getkeymetricsttm(stocksymbol).then(
      (monthly: any) => {
        dispatch(setkeymetricsttm(monthly[0]));
      },
      (error) => {}
    );
  };
export const getkeyexecutives =
  (stocksymbol: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.getkeyexecutives(stocksymbol).then(
      (monthly: any) => {
        dispatch(setgetkeyexecutives(monthly[0]));
      },
      (error) => {}
    );
  };
export const getinstitutionalholder =
  (stocksymbol: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.getinstitutionalholder(stocksymbol).then(
      (monthly: any) => {
        dispatch(setinstitutionalholder(monthly[0]));
      },
      (error) => {}
    );
  };
export const getrevenuepershare =
  (stocksymbol: any, limit: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await PortfolioService.getrevenuepershare(stocksymbol, limit).then(
      (rs) => {
        dispatch(setrs(rs));
      },
      (error) => {
        // //dispatch(toast.success(' Error'));
      }
    );
  };

export const getrevenuepersharequar =
  (stocksymbol: any, limit: any, reportType: any) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await PortfolioService.getrevenuepersharequar(
      stocksymbol,
      limit,
      reportType
    ).then(
      (rsquar) => {
        dispatch(setrsquar(rsquar));
      },
      (error) => {
        // //dispatch(toast.success(' Error'));
      }
    );
  };

export const incomeforev =
  (stocksymbol: any, limit: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await PortfolioService.incomeforev(stocksymbol, limit).then(
      (evincome) => {
        dispatch(setevincome(evincome));
        console.log(evincome,'yyyyyyyyyyyyyyyyyyy')
      },
      (error) => {
        // //dispatch(toast.success(' Error'));
      }
    );
  };

export const getbalanceev =
  (stocksymbol: any, limit: any, reportType: any) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await PortfolioService.getbalanceev(stocksymbol, limit, reportType).then(
      (balancesheetev) => {
        //console.log(monthly,'monthlymonthlymonthlymonthly')
        dispatch(setBalancesheetev(balancesheetev));
      },
      (error) => {
        // //dispatch(toast.success(' Error'));
      }
    );
  };

export const getearningsforquarter =
  (stocksymbol: any, reportType: any, limit: any) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await PortfolioService.getincomequ(stocksymbol, reportType, limit).then(
      (shareoustanding) => {
        dispatch(setshareoustanding(shareoustanding));
      },
      (error) => {
        //dispatch(toast.success(' Error'));
      }
    );
  };

export const addpitch =
  (inputpitch: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    dispatch(setafterpitch(false));
    await PortfolioService.addpitch(inputpitch).then(
      (pitch) => {
        dispatch(setpitch(pitch));
        dispatch(setafterpitch(true));
        dispatch(setShowLoading(false));
        dispatch(toast.success("Stock Pitch Successful!"));
      },
      (error) => {
        console.log(error, "lllllll");
        dispatch(toast.error(error));
        dispatch(setShowLoading(false));
      }
    );
  };

export const updatepitch =
  (inputpitch: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    dispatch(setafterpitch(false));
    dispatch(settrack(false));
    await PortfolioService.updatepitch(inputpitch).then(
      (pitchupdate) => {
        dispatch(setpitchupdate(pitchupdate));
        dispatch(setafterpitch(true));
        dispatch(setShowLoading(false));
        dispatch(settrack(true));
        dispatch(toast.success(" Pitch Updated Successfully!"));
      },
      (error) => {
        dispatch(toast.error(error));

        dispatch(setShowLoading(false));
      }
    );
  };
export const track = (vid: any) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setShowLoading(true));
  await PortfolioService.track(vid).then(
    (track) => {
      dispatch(settrack(true));
      dispatch(setShowLoading(false));
      dispatch(toast.success("Pitch Updated Successfully!"));
    },
    (error) => {
      dispatch(toast.error(" Error"));
      dispatch(setShowLoading(false));
    }
  );
};

export const trackview = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setShowLoading(true));
  dispatch(settrackview([]));
  await PortfolioService.trackview().then(
    (trackview) => {
      dispatch(settrackview(trackview.data));
      dispatch(setShowLoading(false));
    },
    (error) => {
      dispatch(toast.error(" Error"));
      dispatch(setShowLoading(false));
    }
  );
};

export const viewStockProfile =
  (stock: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.viewStockProfile(stock).then(
      (stockProfile: any) => {
        dispatch(setStockProfile(stockProfile));
      },
      (error: any) => {}
    );
  };

export const getebidquar =
  (stocksymbol: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.getebidquar(stocksymbol).then(
      (monthly: any) => {
        dispatch(setStockHistorical(monthly));
      },
      (error) => {}
    );
  };

export const getebidanu =
  (stocksymbol: any, limit: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.getebidanu(stocksymbol, limit).then(
      (monthly: any) => {
        dispatch(setStockHistorical(monthly));
      },
      (error) => {}
    );
  };

export const getnews =
  (stocksymbol: any, limit: any) => async (dispatch: React.Dispatch<any>) => {
    await PortfolioService.getnews(stocksymbol, limit).then(
      (news) => {
        dispatch(setnews(news));
      },
      (error) => {}
    );
  };
export const getchange = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setchange([]));
};
export const getvaluationhide = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setvaluationhide([]));
};
export const updatettm = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setTtmValue([]));
};

export const setPortfolio = (portfolioList: any) =>
  ({
    type: "portfolio-list",
    portfolioList,
  } as const);

export const portfolioCreate = (portfolioSuccess: any) =>
  ({
    type: "portfolio-create",
    portfolioSuccess,
  } as const);
export const setportfoliodelreload = (portfolioreload: boolean) =>
  ({
    type: "portfolio-portfolioreload",
    portfolioreload,
  } as const);
export const setPortfolioeditreload = (portfolioeditreload: any) =>
  ({
    type: "portfolio-portfolioeditreload",
    portfolioeditreload,
  } as const);
export const setSearchportfolio = (searchportfolio: any) =>
  ({
    type: "portfolio-searchportfolio",
    searchportfolio,
  } as const);
export const setPortfolioupdate = (portfolioupdate: any) =>
  ({
    type: "portfolio-portfolioupdate",
    portfolioupdate,
  } as const);
export const setPortfoliovalue = (portfoliovalue: any) =>
  ({
    type: "portfolio-portfoliovalue",
    portfoliovalue,
  } as const);
export const setStockperid = (stockperid: any) =>
  ({
    type: "portfolio-stockperid",
    stockperid,
  } as const);


export const setStockHistorical = (historical: any) =>
  ({
    type: "set-stock-historical",
    historical,
  } as const);

  
export const setkeymetrics = (metrics: any) =>
  ({
    type: "set-key-metrics",
    metrics,
  } as const);
export const setquotereturn = (quotereturn: any) =>
  ({
    type: "set-quotereturn",
    quotereturn,
  } as const);
export const setquotereturnpitch = (quotereturnpitch: any) =>
  ({
    type: "set-quotereturnpitch",
    quotereturnpitch,
  } as const);

export const getscreenerinputs = (screenerinput: any) =>
  ({
    type: "set-screenerset",
    screenerinput,
  } as const);
export const setscreenerlistget = (screenerlistget: any) =>
  ({
    type: "set-screenerlistget",
    screenerlistget,
  } as const);
export const setaddfbmarket = (fbmarket: any) =>
  ({
    type: "set-addfbmarket",
    fbmarket,
  } as const);

export const setTtmValue = (ttmValue: any) =>
  ({
    type: "set-ttmvalue",
    ttmValue,
  } as const);
export const setkeymetricsttm = (metricsttmValue: any) =>
  ({
    type: "set-keyttmvalue",
    metricsttmValue,
  } as const);
export const setgetkeyexecutives = (executives: any) =>
  ({
    type: "set-keyexecutives",
    executives,
  } as const);
export const setinstitutionalholder = (institutional: any) =>
  ({
    type: "set-institutionalholder",
    institutional,
  } as const);

export const setfin = (fin: any) =>
  ({
    type: "set-fin",
    fin,
  } as const);

export const sethis = (his: any) =>
  ({
    type: "set-his",
    his,
  } as const);

export const setrs = (rs: any) =>
  ({
    type: "set-rs",
    rs,
  } as const);

export const setrsquar = (rsquar: any) =>
  ({
    type: "set-rsquar",
    rsquar,
  } as const);

export const setevincome = (evincome: any) =>
  ({  
    type: "set-evincome",
    evincome,
  } as const);  

export const setBalancesheetev = (balancesheetev: any) =>
  ({
    type: "set-balancesheetev",
    balancesheetev,
  } as const);

export const setshareoustanding = (shareoustanding: any) =>
  ({
    type: "set-shareoustanding",
    shareoustanding,
  } as const);
export const setchange = (change: any) =>
  ({
    type: "set-change",
    change,
  } as const);
export const setvaluationhide = (valuationhide: any) =>
  ({
    type: "set-valuationhide",
    valuationhide,
  } as const);

export const setpitch = (pitch: any) =>
  ({
    type: "set-pitch",
    pitch,
  } as const);

export const setpitchupdate = (pitchupdate: any) =>
  ({
    type: "set-pitchupdate",
    pitchupdate,
  } as const);

export const settrack = (track: boolean) =>
  ({
    type: "set-track",
    track,
  } as const);

export const settrackview = (trackview: any) =>
  ({
    type: "set-trackview",
    trackview,
  } as const);

export const setStockProfile = (stockProfile: any) =>
  ({
    type: "stock-profile",
    stockProfile,
  } as const);

export const setnews = (news: any) =>
  ({
    type: "set-news",
    news,
  } as const);

export const setafterpitch = (afterpitch: boolean) =>
  ({
    type: "portfolio-afterpitch",
    afterpitch,
  } as const);

export type PortfolioActions =
  | ActionType<typeof setPortfolio>
  | ActionType<typeof portfolioCreate>
  | ActionType<typeof setportfoliodelreload>
  | ActionType<typeof setPortfolioeditreload>
  | ActionType<typeof setSearchportfolio>
  | ActionType<typeof setPortfolioupdate>
  | ActionType<typeof setPortfoliovalue>
  | ActionType<typeof setStockperid>
  | ActionType<typeof setStockHistorical>
  | ActionType<typeof setkeymetrics>
  | ActionType<typeof setquotereturn>
  | ActionType<typeof setquotereturnpitch>
  | ActionType<typeof getscreenerinputs>
  | ActionType<typeof setscreenerlistget>
  | ActionType<typeof setaddfbmarket>
  | ActionType<typeof setTtmValue>
  | ActionType<typeof setkeymetricsttm>
  | ActionType<typeof setgetkeyexecutives>
  | ActionType<typeof setinstitutionalholder>
  | ActionType<typeof setfin>
  | ActionType<typeof sethis>
  | ActionType<typeof setrs>
  | ActionType<typeof setrsquar>
  | ActionType<typeof setevincome>
  | ActionType<typeof setBalancesheetev>
  | ActionType<typeof setshareoustanding>
  | ActionType<typeof setchange>
  | ActionType<typeof setvaluationhide>
  | ActionType<typeof setpitch>
  | ActionType<typeof setpitchupdate>
  | ActionType<typeof settrack>
  | ActionType<typeof settrackview>
  | ActionType<typeof setStockProfile>
  | ActionType<typeof setnews>
  | ActionType<typeof setafterpitch>;
