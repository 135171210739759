import React, { useEffect, useState } from 'react';
import { connect } from './data/connect';

interface OwnProps  {}

interface StateProps {

}

interface DispatchProps {
}

interface PerformanceProps extends OwnProps, StateProps, DispatchProps { }

const Performance: React.FC<PerformanceProps> = ({  }) => {

        return (
            <table className="table dash-table scrolltable grad_table">
            <thead>
              <tr className="clr">
              <th>No</th>
              <th>Ticker</th>
              <th>Company</th>
              <th>Price</th>
              <th>Change</th>
              <th>Volume</th>
              <th>Week</th>
              <th>Month</th>
              <th>3 Months</th>          
              <th>6 Months</th>
              <th>1 Year</th>
              <th>YTD</th>
              <th>Avg Volume</th>
              </tr>
            </thead>
            <tbody className="scroll market-scroll pr-0">
            <tr className="clr">
            <td>1</td>
            <td>3.12</td>              
            <td >-1.3</td>
            <td>3.12</td>              
            <td >-1.3</td>             
            <td>3.12</td>              
            <td >-1.3</td>
            <td>3.12</td>              
            <td >-1.3</td>              
            <td>3.12</td>              
            <td >-1.3</td>
            <td>3.12</td>              
            <td >-1.3</td>  
            </tr>
            <tr className="clr">
            <td>1</td>
            <td>3.12</td>              
            <td >-1.3</td>
            <td>3.12</td>              
            <td >-1.3</td>             
            <td>3.12</td>              
            <td >-1.3</td>
            <td>3.12</td>              
            <td >-1.3</td>              
            <td>3.12</td>              
            <td >-1.3</td>
            <td>3.12</td>              
            <td >-1.3</td>  
            </tr>
            
            

              
            </tbody>
          </table>
         );
};

export default connect<OwnProps, {}, DispatchProps>({
    mapStateToProps: (state) => ({
   
    }),
    mapDispatchToProps: {
   
       
    },
    component: Performance
    });