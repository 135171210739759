import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import Slider from "react-rangeslider";

import "../../App.css";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rangeslider/lib/index.css";

import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import {
  getPortfolioList,
  addPortfolio,
  editportfolio,
  portfolioupdate,
} from "../../data/portfolio/portfolio.actions";
import moment from "moment";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

interface OwnProps {
  portfolioId: any;
  setShowModal: any;
}

interface StateProps {
  isRegisterSuccess: any;
  portfolioCreate: boolean;
  editreload: any;
}

interface DispatchProps {
  getPortfolioList: typeof getPortfolioList;
  addPortfolio: typeof addPortfolio;
  editportfolio: typeof editportfolio;
  portfolioupdate: typeof portfolioupdate;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface AddPortfolioProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const AddPortfolio: React.FC<AddPortfolioProps> = ({
  isRegisterSuccess,
  getPortfolioList,
  addPortfolio,
  portfolioCreate,
  portfolioId,
  editportfolio,
  editreload,
  portfolioupdate,
  setShowModal,
}) => {
  const [newPortfolio, setNewPortfolio] = useMergeState({});
  const [insvalue, setInsvalue] = useState(100000000);
  const m = moment();
  const portfolioSchema = Yup.object().shape({
    portfolio_name: Yup.string().required("Portfolio Name is required"),
  });

  useEffect(() => {
    if (portfolioCreate) {
      setInsvalue(100000000);
      getPortfolioList();
      setShowModal(false);
    }
  }, [portfolioCreate]);

  useEffect(() => {
    if (portfolioId) {
      editportfolio(portfolioId);
    }
  }, [portfolioId]);

  useEffect(() => {
    if (editreload) {
      setInsvalue(editreload.investment_value);
    }
  }, [editreload]);

  return (
    <Row>
      <Col sm={12}>
        <Formik
          initialValues={{
            portfolio_name: editreload.portfolio_name,
          }}
          validationSchema={portfolioSchema}
          onSubmit={(
            { portfolio_name },
            { setStatus, setSubmitting, resetForm }
          ) => {
            if (portfolioId) {
              portfolioupdate({
                portfolio_id: portfolioId,
                portfolio_name: portfolio_name,
                date_time: m.format("YYYY-MM-DD hh:mm:ss"),
                investment_value: insvalue,
              });
            } else {
              addPortfolio({
                portfolio_name,
                investment_value: insvalue,
                date_time: m.format("YYYY-MM-DD hh:mm:ss"),
              });
            }

            resetForm({ values: { portfolio_name: "" } });
          }}
          enableReinitialize
          render={({ errors, status, touched, isSubmitting }) => (
            <Form>
              <div className="p-3 mt-4">
                <label className="bold-15 green-text">
                  Creating New Portfolio
                </label>
                <Row className="mt-3">
                  <Col sm={4}>
                    <p className="regular-15 mb-0 color-97">Inception Date</p>
                    <p className="bold-15 m-0 color-97">
                      {m.format("YYYY-MM-DD")}
                    </p>
                  </Col>
                  {/*<Col sm={3}>
                              <p className="regular-15 mb-0 color-97">Time</p> 
                              <p className="bold-15 m-0 color-97">{m.format("hh:mm:ss")}</p> 
                    </Col>*/}
                </Row>
                <p className="eight text-red mt-2">
                  *date and time is detected automatocally
                </p>
                <hr />
                <label className="regular-15 color-00">Portfolio Name</label>
                <Field
                  name="portfolio_name"
                  placeholder="Enter Portfolio Name"
                  type="text"
                  className={`form-control pop-border ${
                    errors.portfolio_name && touched.portfolio_name
                      ? "errorInput"
                      : ""
                  }`}
                />
                <label className="regular-15 color-00 mt-4">Seed Capital</label>
                <Slider
                  value={insvalue}
                  min={0}
                  max={200000000}
                  onChange={(value: any) => setInsvalue(value)}
                />
                <Row>
                  <Col>$0</Col>
                  <Col
                    className="text-center"
                    style={{ fontWeight: "bold", color: "#00CF76" }}
                  >
                    <NumberFormat
                      value={insvalue}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Col>
                  <Col className="text-right">$200,000,000</Col>
                </Row>
                <div className="mt-2">
                  <Button
                    className="green-ground w-100 btn-login"
                    type="submit"
                  >
                    {portfolioId ? `Update Portfolio` : `Create Portfolio`}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        />
      </Col>
    </Row>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isRegisterSuccess: state.user.isRegisterSuccess,
    portfolioCreate: state.portfolio.portfolioCreate,
    editreload: state.portfolio.editreload,
  }),
  mapDispatchToProps: {
    getPortfolioList,
    addPortfolio,
    editportfolio,
    portfolioupdate,
  },
  component: AddPortfolio,
});
