import React from 'react';
import { useEffect } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

   
interface ProtectedRouteProps extends RouteProps {
    component: React.ComponentType<any>;
    path: string;
    isAuthenticated: any
}

export const PrivateRoute : React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('loggedInUserData')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
   
)