import React, { useState, useEffect } from "react";

import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";

import { FiBookmark } from "react-icons/fi";
import { AiOutlineArrowUp } from "react-icons/ai";
import ReactGA from "react-ga4";
import { GiBaseballBat } from "react-icons/gi";
import { RiShareFill } from "react-icons/ri";
import { stringify } from "querystring";
import { FaTimes, FaTrashAlt } from "react-icons/fa";

import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  RouteComponentProps,
} from "react-router-dom";

import moment from "moment";
import Accordion from "react-bootstrap/Accordion";

import Form from "react-bootstrap/Form";
import { connect } from "./data/connect";

import Card from "react-bootstrap/Card";
import { Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom";
import Header from "./header";
import Footer from "./footer";
import EmptyPitch from "./Pages/Pitches/EmptyPitch";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  getpitches,
  buypitch,
  deletetrackedpitches,
  trackedpitchupdate,
  reloadpitchtrack,
} from "./data/Pitches/pitches.actions";
import { getquotespitch } from "./data/portfolio/portfolio.actions";
import {
  getPortfolioList,
  track,
  viewStockProfile,
} from "./data/portfolio/portfolio.actions";
import Investmodal from "./Investmodal";
import { GrFilter } from "react-icons/gr";
import {
  BiDownArrowAlt,
  BiLinkExternal,
  BiSearch,
  BiUpArrowAlt,
} from "react-icons/bi";
import { HiOutlineFilter } from "react-icons/hi";
import NumberFormat from "react-number-format";
import MyValuationpe from "./Pages/Market/Myvaluationpe";
import MyValuationpb from "./Pages/Market/Myvaluationpb";
import MyValuationeve from "./Pages/Market/Myvaluationeve";
import Myvaluationps from "./Pages/Market/Myvaluationps";
import { marketprofile } from "./data/market/market.actions";
import { getvaluationbyid } from "./data/stock/stock.actions";

const config = { apiUrl: "http://api.laacreators.com/" };

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  allpitcheslist: any;
  viewvaluationbyid: any;
  portfolioList: any;
  getuserprofile: any;
  pitchadd: any;
  getstockProfile: any;
  profileget: any;
  trackedpitchesdeletereload: any;
  afterpitchreload: any;
  getquotereturnpitch: any;
  trackupdatereload: any;
}

interface DispatchProps {
  getvaluationbyid: typeof getvaluationbyid;
  getpitches: typeof getpitches;
  buypitch: typeof buypitch;
  getPortfolioList: typeof getPortfolioList;
  track: typeof track;
  viewStockProfile: typeof viewStockProfile;
  trackedpitchupdate: typeof trackedpitchupdate;
  deletetrackedpitches: typeof deletetrackedpitches;
  marketprofile: typeof marketprofile;
  getquotespitch: typeof getquotespitch;
  reloadpitchtrack: typeof reloadpitchtrack;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface PitchesProps extends OwnProps, StateProps, DispatchProps {}

const Pitches: React.FC<PitchesProps> = ({
  pitchadd,
  getvaluationbyid,
  deletetrackedpitches,
  history,
  getuserprofile,
  getstockProfile,
  trackedpitchupdate,
  track,
  getpitches,
  trackedpitchesdeletereload,
  buypitch,
  getPortfolioList,
  portfolioList,
  allpitcheslist,
  viewvaluationbyid,
  viewStockProfile,
  marketprofile,
  profileget,
  afterpitchreload,
  trackupdatereload,
  reloadpitchtrack,
  getquotereturnpitch,
  getquotespitch,
}) => {
  const [valuationid, setValuationid] = useState("");
  const [stocksymbol, setStocksymbol] = useState("");
  const [portfolioid, setPortfolioid] = useState("");
  const [valuationtypeid, setValuationtypeid] = useState("");
  const [valuationoutputid, setValuationoutputid] = useState("");
  const [valshow, setvalshow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [holdingperiod, setholdingperiod] = useState<any>(1);
  const [showModalinvest, setShowModalinvest] = useState(false);
  const [selectedPitch, setSelectedPitch] = useState<any>({});
  const [portfoliolistselect, setPortfoliolistselect] = useState("");
  const [pitchvalue, setpitchvalue] = useState("");
  const [valuation_type, setvaluation_type] = useState(0);
  const [invalue, setinvalue] = useState("");
  const [setViewBusiness, setsetViewBusiness] = useState(false);
  const [valuation_text, setvaluation_text] = useState("");
  const [stockId, setstockId] = useState("");
  const [valuation, setvaluation] = useState("1");
  const [root, setroot] = useState("1");
  const [size, setsize] = useState("12");
  const [update, setupdate] = useState("text");
  const [displayType, setDisplayType] = useState("input");
  const [historys, sethistorys] = useState("12");
  const [facechange, setfacechange] = useState("");
  const [facechangein, setfacechangein] = useState("");
  const [usd, setusd] = useState("");
  const [hideset, sethideset] = useState("1");
  const [pitchedited, Setpitchedited] = useState([]);
  const [pitchsymbol, SetPitchsymbol] = useState([]);
  const [found, setFound] = useState<any>({});

  const [searchvalue, setsearchvalue] = useState("");
  const [ed, seted] = useState("edit");
  const [sr, setsr] = useState("1");
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  const [trackupdate, settrackupdate] = useMergeState({
    valuation_id: "",
    tracked_market_price: "",
    tracked_premium: "",
    tracked_cumulative_loss: "",
    tracked_irr: "",
    tracked_moic: "",
    tracked_intrinsic_value_per_share: "",
    tracked_ebitda_at_endof_hold: "",
    tracked_intrinsic_value_of_business: "",
    tracked_enterprise_value: "",
  });

  useEffect(() => {
    settrackupdate({
      valuation_id: viewvaluationbyid?.id,
      tracked_market_price: viewvaluationbyid?.market_price,
      tracked_premium: viewvaluationbyid?.premium,
      tracked_cumulative_loss: viewvaluationbyid?.cumulative_loss,
      tracked_irr: viewvaluationbyid?.irr,
      tracked_moic: viewvaluationbyid?.moic,
      tracked_intrinsic_value_per_share:
        viewvaluationbyid?.intrinsic_value_per_share,
      tracked_ebitda_at_endof_hold:
        viewvaluationbyid?.earnings_per_share_at_endof_hold,
      tracked_intrinsic_value_of_business:
        viewvaluationbyid?.intrinsic_value_of_business,
      tracked_enterprise_value: viewvaluationbyid?.enterprise_value,
    });
  }, [viewvaluationbyid]);

  //a.join(', ')

  useEffect(() => {
    let mySymbol: any = [];
    allpitcheslist.map((pitches: any, index: any) => {
      //SetPitchsymbol([...prevState.pitches.stock_symbol]);
      mySymbol.push(pitches.stock_symbol);
    });

    SetPitchsymbol(mySymbol);
  }, [allpitcheslist]);

  //   .filter(
  //     (pitch: any) =>
  //       pitch.stock_name
  //         ?.toLowerCase()
  //         .includes(searchvalue?.toLowerCase()) ||
  //       pitch.stock_symbol?.toLowerCase().includes(searchvalue.toLowerCase())
  //   )

  useEffect(() => {
  }, [pitchsymbol]);

  useEffect(() => {
    viewStockProfile(viewvaluationbyid?.stock_symbol);
    marketprofile(viewvaluationbyid?.stock_symbol);
  }, [viewvaluationbyid]);

  useEffect(() => {
    getPortfolioList();
  }, []);

  useEffect(() => {
    setvaluation(viewvaluationbyid?.valuation_type_id);
  }, [viewvaluationbyid]);

  useEffect(() => {
    if (pitchadd) {
      getpitches();
      setShowModal(false);
    }
  }, [pitchadd]);

  // useEffect(() => {
  //   getquotespitch(stockId);
  // }, [stockId]);

  useEffect(() => {
    var pitchSymbolTimeOut = setInterval(() => {
      getquotespitch(pitchsymbol);
    }, 30000);
    return () => {
      clearInterval(pitchSymbolTimeOut);
    };
  }, [pitchsymbol]);

  useEffect(() => {
    getpitches();
  }, []);

  const reloadpitches = () => {
    getpitches();
    setvalshow(false);
  };
  const tracks = () => {
    trackedpitchupdate(trackupdate);
  };

  useEffect(() => {
    if (trackupdatereload == true) {
      getpitches();
      reloadpitchtrack();
      history.push("/Mytrackedpitches");
    }
  }, [trackupdatereload]);

  useEffect(() => {
    setFound(
      getquotereturnpitch?.find(
        (element: any) => element?.symbol == viewvaluationbyid?.stock_symbol
      )
    );
  }, [getquotereturnpitch, viewvaluationbyid]);

  const showvalsection = () => {
    setvalshow(valshow ? true : true);
  };
  useEffect(() => {
    if (trackedpitchesdeletereload == true) {
      getpitches();
    }
  }, [trackedpitchesdeletereload]);

  const deletepic = () => {
    deletetrackedpitches(viewvaluationbyid.id);
    getpitches();
    setvalshow(false);
  };

  useEffect(() => {}, [viewvaluationbyid?.stock_symbol]);
  const pitchbuy = async () => {
    //e.preventDefault();

    await buypitch(
      viewvaluationbyid.stock_symbol,
      portfoliolistselect,
      viewvaluationbyid.valuation_type_id,
      viewvaluationbyid.valuation_id,
      viewvaluationbyid.id
    );
  };

  useEffect(() => {
  }, [searchvalue]);

  useEffect(() => {
    let percentage = viewvaluationbyid?.premium;
    let market_price = parseFloat(viewvaluationbyid?.market_price)
    let intrinsic_value_per_share = parseFloat(viewvaluationbyid?.intrinsic_value_per_share)
    if (
      percentage >= 0 &&
      percentage < 10 &&
      market_price <= intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/S1.png");
      setfacechangein("/assets/images/H1.png");
    } else if (
      percentage >= 10 &&
      percentage < 25 &&
      market_price <=
        intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/S2.png");
      setfacechangein("/assets/images/H2.png");
    } else if (
      percentage >= 25 &&
      percentage < 35 &&
      market_price <=
        intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/S3.png");
      setfacechangein("/assets/images/H3.png");
    } else if (
      percentage >= 35 &&
      market_price <=
        intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/S4.png");
      setfacechangein("/assets/images/H4.png");
    } else if (
      percentage >= 0 &&
      percentage < 10 &&
      market_price >=
        intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/H1.png");
      setfacechangein("/assets/images/S1.png");
    } else if (
      percentage >= 10 &&
      percentage < 25 &&
      market_price >=
        intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/H2.png");
      setfacechangein("/assets/images/S2.png");
    } else if (
      percentage >= 25 &&
      percentage < 35 &&
      market_price >=
        intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/H3.png");
      setfacechangein("/assets/images/S3.png");
    } else if (
      percentage >= 35 &&
      market_price >=
        intrinsic_value_per_share
    ) {
      setfacechange("/assets/images/H4.png");
      setfacechangein("/assets/images/S4.png");
    }
  }, [
    viewvaluationbyid?.market_price,
    viewvaluationbyid?.premium,
    viewvaluationbyid?.intrinsic_value_per_share,
    viewvaluationbyid,
  ]);

  const actionHandler = (pitches: any) => {
    setSelectedPitch(pitches);
    showvalsection();
    setStocksymbol(pitches.stock_symbol);
    setValuationid(pitches.id);
    setValuationtypeid(pitches.valuation_type_id);
    getvaluationbyid(pitches.id);
    setstockId(pitches.stock_symbol);
    setFound(
      getquotereturnpitch.find(
        (element: any) => element.symbol == pitches?.stock_symbol
      )
    );
  };

  // useEffect(() => {
  //   var pitchTimeOut = setInterval(() => {
  //     marketprofile(stockId);
  //   }, 30000);
  //   return () => {
  //     clearInterval(pitchTimeOut);
  //   };
  // }, [stockId]);

  return (
    <div className="login-back bg_strike">
      <Header />
      <Container fluid className="contact_101">
        <Row>
          <Col sm={12} className="">
            <div className="">
              <Row className="pb-3">
                <Col sm={6}>
                  <div className="dash-table-div p-3">
                    <div className="input-relative mb-2">
                      <HiOutlineFilter className="filter-i green-text"></HiOutlineFilter>{" "}
                      <BiSearch className="same-icons-rit green-text"></BiSearch>
                      <input
                        autoFocus
                        className="search-dash mr-sm-2 gray-gradient gray-border"
                        type="search"
                        placeholder="Search for Pitches"
                        value={searchvalue}
                        onChange={(e) => {
                          setsearchvalue(e.target.value);
                        }}
                      />
                    </div>
                    <table className="table dash-table scrolltable">
                      <thead className="pr-2">
                        <tr className="gradent-black">
                          <th className="pr-0" style={{ width: "30%" }}>
                            My Pitches
                          </th>

                          <th className="px-0" style={{ width: "15%" }}>
                            Market Price
                          </th>
                          <th className="px-0" style={{ width: "15%" }}>
                            Intrinsic Value
                          </th>
                          <th className="px-0" style={{ width: "30%" }}>
                            Margin of Safety/Premium
                          </th>
                          <th className="px-0" style={{ width: "10%" }}></th>
                        </tr>
                      </thead>

                      <tbody className="scroll market-scroll pointer">
                        <>
                          {allpitcheslist
                            .filter(
                              (pitch: any) =>
                                pitch.stock_name
                                  ?.toLowerCase()
                                  .includes(searchvalue?.toLowerCase()) ||
                                pitch.stock_symbol
                                  ?.toLowerCase()
                                  .includes(searchvalue.toLowerCase())
                            )
                            .map((pitches: any, index: any) => {
                              const found = getquotereturnpitch.find(
                                (element: any) =>
                                  element.symbol == pitches.stock_symbol
                              );
                              let marketPrice = found?.price
                                ? found?.price
                                : pitches.market_price;
                              let premiumcalculation = Math.abs(
                                parseFloat(marketPrice) /
                                  parseFloat(
                                    pitches.intrinsic_value_per_share
                                  ) -
                                  1
                              );
                              return (
                                <>
                                  <tr>
                                    <td
                                      onClick={() => {
                                        actionHandler(pitches);
                                      }}
                                      style={{ width: "30%" }}
                                    >
                                      <img
                                        className="img-fluid"
                                        width="30px"
                                        src="/assets/images/3862.png"
                                      />
                                      <span className="bold-16 m-0 ml-2">
                                        {pitches.stock_name
                                          ? pitches.stock_name
                                          : pitches.stock_symbol}
                                      </span>
                                    </td>
                                    <td
                                      onClick={() => {
                                        actionHandler(pitches);
                                      }}
                                      style={{ width: "15%" }}
                                    >
                                      <span className="green-text">
                                        {parseFloat(marketPrice).toFixed(2)}
                                      </span>
                                      <small
                                        style={{
                                          opacity: 0.5,
                                          marginLeft: 5,
                                        }}
                                      >
                                        USD
                                      </small>
                                    </td>
                                    <td
                                      style={{ width: "15%" }}
                                      className=" ml-1"
                                      onClick={() => {
                                        actionHandler(pitches);
                                      }}
                                    >
                                      <span className="green-text">
                                        {" "}
                                        {parseFloat(
                                          pitches.intrinsic_value_per_share
                                        ).toFixed(2)}
                                      </span>
                                      <small
                                        style={{
                                          opacity: 0.5,
                                          marginLeft: 5,
                                        }}
                                      >
                                        USD
                                      </small>
                                    </td>
                                    <td
                                      style={{
                                        width: "30%",
                                        verticalAlign: "middle",
                                      }}
                                      className="green-text"
                                      onClick={() => {
                                        actionHandler(pitches);
                                      }}
                                    >
                                      {" "}
                                      <span
                                        style={{
                                          marginRight: 5,
                                        }}
                                      >
                                        {parseFloat(marketPrice) <=
                                          parseFloat(
                                            pitches.intrinsic_value_per_share
                                          ) && <>Margin Of Safety :</>}
                                        

                                        {parseFloat(marketPrice) >
                                          parseFloat(
                                            pitches.intrinsic_value_per_share
                                          ) && <> Premium :</>}
                                        {" "}
                                      </span>
                                      {(premiumcalculation * 100).toFixed(2) +
                                        " %"}{" "}
                                    </td>

                                    <td
                                      style={{ width: "10%" }}
                                      className="green-text ml-3"
                                      id={"pitchAction"}
                                      onClick={() => {
                                        deletetrackedpitches(pitches.id);
                                        setvalshow(false);
                                        //reloadpitches();
                                      }}
                                    >
                                      {" "}
                                      <FaTrashAlt></FaTrashAlt>{" "}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </>
                      </tbody>
                    </table>
                  </div>
                </Col>

                <Col sm={6} className="mt-3">
                  {(() => {
                    if (valshow == true) {
                      return (
                        <>
                          <div className="dash_pich p-2 mt-0">
                            <Row>
                              <Col sm={6} className="align-self-center">
                                <p className="one-two mb-0">
                                  Valuation Methodology
                                </p>
                                <h4 className="one-five mb-0">
                                  {viewvaluationbyid?.valuation_type_id == 1 &&
                                    `P/E `}
                                  {viewvaluationbyid?.valuation_type_id == 2 &&
                                    `P/B `}
                                  {viewvaluationbyid?.valuation_type_id == 3 &&
                                    `P/S `}
                                  {viewvaluationbyid?.valuation_type_id == 4 &&
                                    `EV/ EBITDA `}
                                  Valuation
                                </h4>
                              </Col>
                              {root == "1" && (
                                <Col
                                  sm={6}
                                  className="text-right align-self-center"
                                >
                                  <a
                                    href="#"
                                    className="bold_13_76 mr-3"
                                    onClick={() => {
                                      setroot("2");
                                      sethideset("2");
                                      setDisplayType("text");
                                    }}
                                  >
                                    View
                                  </a>

                                  <button
                                    type="button"
                                    className="btn btn-danger mr-3 editBtn"
                                    onClick={() => {
                                      setroot("2");
                                      history.push(
                                        `${`/Market/${viewvaluationbyid?.id}`}`
                                      );
                                    }}
                                  >
                                    Edit
                                  </button>

                                  <FaTimes
                                    className="text-right cursor-pointer"
                                    onClick={() => {
                                      setvalshow(false);
                                    }}
                                  ></FaTimes>
                                </Col>
                              )}
                              {root == "2" && (
                                <Col
                                  sm={6}
                                  className="text-right align-self-center"
                                >
                                  <a
                                    href="#"
                                    className="bold_15_76 mr-3"
                                    onClick={() => {
                                      setroot("1");
                                    }}
                                  >
                                    Back
                                  </a>

                                  <button
                                    type="button"
                                    className="btn btn-danger editBtn"
                                    onClick={() => {
                                      setroot("2");
                                      history.push(
                                        `${`/Market/${viewvaluationbyid?.id}`}`
                                      );
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <FaTimes
                                    className="text-right cursor-pointer"
                                    onClick={() => {
                                      setvalshow(false);
                                    }}
                                  ></FaTimes>
                                </Col>
                              )}
                            </Row>
                          </div>
                          <div className="dash_pich p-2 mt-2">
                            <Row className="row">
                              <Col sm={6} className="align-self-center col-12">
                                <p className="m-0">
                                  <span className="bold_14">
                                    {profileget?.companyName} :
                                  </span>
                                  <span className="regular_14_a2">
                                    {" "}
                                    {profileget?.symbol}
                                  </span>{" "}
                                  <span className="regular_14"></span>
                                </p>
                              </Col>
                              <Col
                                sm={6}
                                className="align-self-center text-sm-right col-sm-6 col-12 pl-2 "
                              >
                                {profileget?.changes >= 0 && (
                                  <span className="top-icon-up">
                                    <BiUpArrowAlt className=""></BiUpArrowAlt>
                                    {(profileget?.changes).toFixed(2)}(
                                    {(
                                      (profileget?.changes /
                                        (profileget?.price -
                                          profileget?.changes)) *
                                      100
                                    ).toFixed(2) + "%"}
                                    )
                                  </span>
                                )}
                                {profileget?.changes < 0 && (
                                  <span className="top-icon-end">
                                    <BiDownArrowAlt className=""></BiDownArrowAlt>
                                    {(profileget?.changes).toFixed(2)}(
                                    {(
                                      (profileget?.changes /
                                        (profileget?.price -
                                          profileget?.changes)) *
                                      100
                                    ).toFixed(2) + "%"}
                                    )
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </div>
                          {/* <div className="dash_pich p-2 mt-1">
                      <Row>
                        <Col  sm={6}>
                          <p className="m-0 bold_15">Facebook, Inc. <span className="regular_13_70">NASDAQ:</span><span className="regular_13"> FB</span></p>
                        </Col>
                        <Col  sm={6} className="text-right">
                        <p className="m-0 regular_19">340.59 <span className="regular_13_70">USD</span> <span className="regular_13_76"> +1.56 (0.46%) <AiOutlineArrowUp></AiOutlineArrowUp></span></p>
                        </Col>
                      </Row>
                                                </div> */}

                          {root == "1" && (
                            <div className="dash-table-div py-2 px-3">
                              <Row className="justify-content-center">
                                <Col sm={3} className="">
                                  <p className="regular_18 mb-0">
                                    Market Price
                                  </p>
                                  <p className="bold_20_76 mb-0">
                                    {parseFloat(
                                      found?.price
                                        ? found?.price
                                        : viewvaluationbyid.market_price
                                    ).toFixed(2)}
                                    <span className="regular_15_76"> USD</span>
                                  </p>
                                  <div className="ball_grad text-center ball_pich">
                                    <img
                                      className="img-fluid mt-1"
                                      src={facechange}
                                    />
                                    <h4 className="bold_15 mb-0 mt-2">
                                      Mr. Market
                                    </h4>
                                  </div>
                                </Col>
                                <Col sm={1} className="align-self-center mt-4">
                                  <span className="regular_35">vs</span>
                                </Col>
                                <Col sm={3} className="">
                                  <p className="regular_18 mb-0">
                                    Intrinsic Value:{" "}
                                  </p>

                                  <p className="bold_20_76 mb-0">
                                    {parseFloat(
                                      viewvaluationbyid?.intrinsic_value_per_share
                                    ).toFixed(2)}{" "}
                                    <span className="regular_15_76"> USD</span>
                                  </p>
                                  <div className="ball_grad text-center ball_pich">
                                    <img
                                      className="img-fluid mt-1"
                                      src={facechangein}
                                    />

                                    {getuserprofile?.name && (
                                      <div className="img-text">
                                        <h4 className="bold_15 mb-0 mt-2">
                                          {getuserprofile.name}
                                        </h4>
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              <div className="p-2 mt-2 ball_grad">
                                <Row className="padding-para">
                                  <Col
                                    sm={6}
                                    xs={6}
                                    className="align-self-center"
                                  >
                                    {/* <p className="regular_13 mb-0">
                                      Margin of Safety:
                                    </p> */}

                                           {parseFloat(viewvaluationbyid?.market_price) <=
                                          parseFloat(
                                            viewvaluationbyid?.intrinsic_value_per_share
                                          ) &&  <p className="regular_13 mb-0">
                                      Margin of Safety:
                                    </p> }
                                        

                                        {parseFloat(viewvaluationbyid?.market_price) >
                                          parseFloat(
                                             viewvaluationbyid?.intrinsic_value_per_share
                                          ) &&  <p className="regular_13 mb-0">
                                      Premium:
                                    </p> }
                                  </Col>
                                  <Col
                                    sm={6}
                                    xs={6}
                                    className="text-right align-self-center"
                                  >
                                    <NumberFormat
                                      className="green-text regular_13 text-right"
                                      value={Math.abs(
                                        (parseFloat(
                                          found?.price
                                            ? found?.price
                                            : viewvaluationbyid.market_price
                                        ) /
                                          parseFloat(
                                            viewvaluationbyid?.intrinsic_value_per_share
                                          ) -
                                          1) *
                                          100
                                      ).toFixed(2)}
                                      suffix={`%`}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </Col>
                                </Row>
                                <Row className="padding-para">
                                  <Col
                                    sm={6}
                                    xs={6}
                                    className="align-self-center"
                                  >
                                    <p className="regular_13 mb-0">
                                      Expected Cumulative Gain:
                                    </p>
                                  </Col>
                                  <Col
                                    sm={6}
                                    xs={6}
                                    className="text-right align-self-center"
                                  >
                                    <NumberFormat
                                      className="green-text regular_13 text-right"
                                      value={(
                                        (parseFloat(
                                          viewvaluationbyid?.intrinsic_value_per_share
                                        ) /
                                          parseFloat(
                                            found?.price
                                              ? found?.price
                                              : viewvaluationbyid.market_price
                                          ) -
                                          1) *
                                        100
                                      ).toFixed(2)}
                                      suffix={`%`}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </Col>
                                </Row>
                                <Row className="padding-para">
                                  <Col
                                    sm={6}
                                    xs={6}
                                    className="align-self-center"
                                  >
                                    <p className="regular_13 mb-0">
                                      Expected IRR:
                                    </p>
                                  </Col>
                                  <Col
                                    sm={6}
                                    xs={6}
                                    className="text-right align-self-center"
                                  >
                                    <NumberFormat
                                      className="green-text regular_13 text-right"
                                      value={(
                                        (Math.pow(
                                          viewvaluationbyid?.intrinsic_value_per_share /
                                            parseFloat(
                                              found?.price
                                                ? found?.price
                                                : viewvaluationbyid.market_price
                                            ),
                                          1 / viewvaluationbyid?.holding_period
                                        ) -
                                          1) *
                                        100
                                      ).toFixed(2)}
                                      suffix={`%`}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </Col>
                                </Row>
                                <Row className="padding-para">
                                  <Col
                                    sm={6}
                                    xs={6}
                                    className="align-self-center"
                                  >
                                    <p className="regular_13 mb-0">
                                      Expected MOIC:
                                    </p>
                                  </Col>
                                  <Col
                                    sm={6}
                                    xs={6}
                                    className="text-right align-self-center"
                                  >
                                    <NumberFormat
                                      className="green-text regular_13 text-right"
                                      value={(
                                        parseFloat(
                                          viewvaluationbyid?.intrinsic_value_per_share
                                        ) /
                                        parseFloat(
                                          found?.price
                                            ? found?.price
                                            : viewvaluationbyid.market_price
                                        )
                                      ).toFixed(2)}
                                      suffix={`X`}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </Col>
                                </Row>
                                <Row className="padding-para">
                                  <Col
                                    sm={6}
                                    xs={6}
                                    className="align-self-center"
                                  >
                                    <p className="regular_13 mb-0">
                                      Hold Period:
                                    </p>
                                  </Col>
                                  <Col
                                    sm={6}
                                    xs={6}
                                    className="text-right align-self-center"
                                  >
                                    <NumberFormat
                                      className="green-text regular_13 text-right"
                                      displayType={"text"}
                                      value={viewvaluationbyid?.holding_period}
                                      suffix={
                                        viewvaluationbyid?.holding_period > 1
                                          ? " years"
                                          : " year"
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          )}

                          {root != "1" && (
                            <>
                              <Row className="">
                                <Col sm={12} className="">
                                  {valuation == "1" && (
                                    <MyValuationpe
                                      usd={usd}
                                      sr={sr}
                                      viewvaluationbyid={viewvaluationbyid}
                                      hideset={hideset}
                                      Setpitchedited={Setpitchedited}
                                      historys={historys}
                                      displayType={displayType}
                                      update={update}
                                      size={size}
                                      pitchvalue={pitchvalue}
                                      found={found}
                                      setpitchvalue={setpitchvalue}
                                      valuation_type={valuation_type}
                                      invalue={invalue}
                                      setinvalue={setinvalue}
                                      showModal={showModal}
                                      setShowModal={setShowModal}
                                      setViewBusiness={setViewBusiness}
                                      valuation_text={valuation_text}
                                      stockId={stockId}
                                    />
                                  )}

                                  {valuation == "2" && (
                                    <MyValuationpb
                                      usd={usd}
                                      sr={sr}
                                      viewvaluationbyid={viewvaluationbyid}
                                      hideset={hideset}
                                      Setpitchedited={Setpitchedited}
                                      historys={historys}
                                      displayType={displayType}
                                      update={update}
                                      size={size}
                                      found={found}
                                      pitchvalue={pitchvalue}
                                      setpitchvalue={setpitchvalue}
                                      valuation_type={valuation_type}
                                      invalue={invalue}
                                      setinvalue={setinvalue}
                                      showModal={showModal}
                                      setShowModal={setShowModal}
                                      setViewBusiness={setViewBusiness}
                                      valuation_text={valuation_text}
                                      stockId={stockId}
                                    />
                                  )}
                                  {valuation == "3" && (
                                    <Myvaluationps
                                      usd={usd}
                                      sr={sr}
                                      viewvaluationbyid={viewvaluationbyid}
                                      hideset={hideset}
                                      Setpitchedited={Setpitchedited}
                                      historys={historys}
                                      displayType={displayType}
                                      update={update}
                                      size={size}
                                      found={found}
                                      pitchvalue={pitchvalue}
                                      setpitchvalue={setpitchvalue}
                                      valuation_type={valuation_type}
                                      invalue={invalue}
                                      setinvalue={setinvalue}
                                      showModal={showModal}
                                      setShowModal={setShowModal}
                                      setViewBusiness={setViewBusiness}
                                      valuation_text={valuation_text}
                                      stockId={stockId}
                                    />
                                  )}

                                  {valuation == "4" && (
                                    <MyValuationeve
                                      usd={usd}
                                      sr={sr}
                                      viewvaluationbyid={viewvaluationbyid}
                                      hideset={hideset}
                                      Setpitchedited={Setpitchedited}
                                      historys={historys}
                                      displayType={displayType}
                                      update={update}
                                      size={size}
                                      found={found}
                                      pitchvalue={pitchvalue}
                                      setpitchvalue={setpitchvalue}
                                      valuation_type={valuation_type}
                                      invalue={invalue}
                                      setinvalue={setinvalue}
                                      showModal={showModal}
                                      setShowModal={setShowModal}
                                      setViewBusiness={setViewBusiness}
                                      valuation_text={valuation_text}
                                      stockId={stockId}
                                    />
                                  )}
                                </Col>
                              </Row>
                            </>
                          )}

                          {/*<div className="min-radius-7 green-gradent p-4 mt-4">
                      <Row className="padding-para">
                        <Col sm={6} xs={6} className="">
                        <p className="regular-15">Purchase Price:</p>
                        </Col>
                        <Col sm={6} xs={6} className="text-right">
                          <p className="bold-15 green-text green-text"> {viewvaluationbyid?.enterprise_value}</p>
                        </Col>
                      </Row>
                      <Row className="padding-para">
                        <Col sm={6} xs={6} className="">
                        <p className="regular-15">Intrinsic Value:</p>
                        </Col>
                        <Col sm={6} xs={6} className="text-right">
                          <p className="bold-15 green-text green-text"> {viewvaluationbyid?.intrinsic_value_per_share}</p>
                        </Col>
                      </Row>
                      <Row className="padding-para">
                        <Col sm={6} xs={6} className="">
                        <p className="regular-15">Margin Of Safety:</p>
                        </Col>
                        <Col sm={6} xs={6} className="text-right">
                          <p className="bold-15 green-text green-text"> {viewvaluationbyid?.margin_of_safety}</p>
                        </Col>
                      </Row>
                      <Row className="padding-para">
                        <Col sm={6} xs={6} className="">
                        <p className="regular-15">Cumulative Gain:</p>
                        </Col>
                        <Col sm={6} xs={6} className="text-right">
                          <p className="bold-15 green-text green-text"> {viewvaluationbyid?.cumulative_gain_or_loss}</p>
                        </Col>
                      </Row>
                      <Row className="padding-para">
                        <Col sm={6} xs={6} className="">
                        <p className="regular-15">IRR:</p>
                        </Col>
                        <Col sm={6} xs={6} className="text-right">
                          <p className="bold-15 green-text green-text"> {viewvaluationbyid?.irr}</p>
                        </Col>
                      </Row>
                      <Row className="padding-para">
                        <Col sm={6} xs={6} className="">
                        <p className="regular-15">MOIC:</p>
                        </Col>
                        <Col sm={6} xs={6} className="text-right">
                          <p className="bold-15 green-text green-text"> {viewvaluationbyid?.moic}</p>
                        </Col>
                      </Row>
                      <Row className="padding-para">
                        <Col sm={6} xs={6} className="">
                        <p className="regular-15">Hold Period:</p>
                        </Col>
                        <Col sm={6} xs={6} className="text-right">
                          <p className="bold-15 green-text green-text"> {viewvaluationbyid?.earnings_per_share_at_endof_hold}</p>
                        </Col>
                      </Row>
                                                </div> */}

                          <Col sm={12} className="text-right mt-3 p-0">
                            <button
                              type="button"
                              className="btn btn_pitch mr-5 float-left"
                              onClick={() => {
                                history.push(`${`/Market`}`);
                              }}
                            >
                              Pitch Another Stock
                            </button>
                            {/*<button type="button" className="btn share_btn mr-3"><RiShareFill></RiShareFill> Share</button>
                      <button type="button" className="btn invest_btn mr-3" onClick={() => setShowModalinvest(true)}><GiBaseballBat></GiBaseballBat> Invest</button> */}

                            <button
                              type="button"
                              className="btn invest_btn mr-3"
                              onClick={tracks}
                            >
                              <GiBaseballBat></GiBaseballBat> Track
                            </button>

                            <button
                              type="button"
                              className="btn remove_btn mr-3"
                              onClick={deletepic}
                            >
                              Remove
                            </button>
                            {/* <a className="mt-2 bold_15" onClick={() => setShowModal(true)} ><FiBookmark className="two-five"></FiBookmark> Add to watchlist</a> */}
                          </Col>
                        </>
                      );
                    }
                  })()}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="row justify-content-center mt-2 pt-3">
          <Col sm={12} className="align-self-center">
            {/*}   <Row className="mt-4">
                    <Col sm={8} className="">
                      <a href="" className="btn-folio"> My Portfolio</a>
                    </Col>
                    <Col sm={4} xs={12} className="text-right">
                      <a href="" className="btn-dwn"><BiLinkExternal></BiLinkExternal> Export & Download</a>
                    </Col>
                  </Row> */}
            {!allpitcheslist && (
              <>
                <Row className="row justify-content-center mt-2 pt-3">
                  <Col sm={12} className="align-self-center text-center pb-5">
                    <div className="dash-table-div p-5 pb-5">
                      <img
                        className="img-fluid mt-1"
                        src="/assets/images/Group73.png"
                      />
                      <p className="regular-15 m-0 color-wh-8">
                        You have not created your portfolio yet.
                      </p>
                      <p className="regular-15 color-wh-8">
                        Mr. Market is waiting for you to add stocks to your
                        portfolio.
                      </p>
                      <a href="" className="btn-folio-create mb-5">
                        Create Portfolio
                      </a>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Footer />

      <Modal
        show={showModal}
        cssClass="modalhome"
        onHide={() => setShowModal(false)}
      >
        <div className="modalselect p-2">
          <label className="mt-3 mb-2 pl-4"> Select Portfolio </label>
          <Row>
            <Col>
              <div
                className="card w-100  pl-0
                  "
              >
                <select
                  className="form-select hei "
                  aria-label="Default select example"
                  value={portfoliolistselect}
                  onChange={(e) => setPortfoliolistselect(e.target.value)}
                >
                  <option selected value="">
                    Select Portfolio
                  </option>
                  {portfolioList.map((portfolio: any, index: any) => {
                    return (
                      <option key={index} value={portfolio.id}>
                        {portfolio.portfolio_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="mt-4 ml-4 mr-4
            mb-5"
        >
          <Button className="green-ground w-100 btn-login" onClick={pitchbuy}>
            Buy
          </Button>
        </div>
      </Modal>
      <>
        <Modal
          show={showModalinvest}
          cssClass="modalhome"
          onHide={() => setShowModalinvest(false)}
        >
          <div className="modalselect p-2">
            <Investmodal />
          </div>
        </Modal>
      </>
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    allpitcheslist: state.pitches.allpitcheslist,
    viewvaluationbyid: state.stock.viewvaluationbyid,
    getuserprofile: state.user.getuserprofile,
    portfolioList: state.portfolio.portfolioList,
    pitchadd: state.pitches.pitchadd,
    getstockProfile: state.portfolio.getstockProfile,
    profileget: state.market.profileget,
    trackedpitchesdeletereload: state.pitches.trackedpitchesdeletereload,
    afterpitchreload: state.portfolio.afterpitchreload,
    trackupdatereload: state.pitches.trackupdatereload,
    getquotereturnpitch: state.portfolio.getquotereturnpitch,
  }),
  mapDispatchToProps: {
    getvaluationbyid,
    marketprofile,
    getpitches,
    buypitch,
    getPortfolioList,
    track,
    viewStockProfile,
    trackedpitchupdate,
    deletetrackedpitches,
    reloadpitchtrack,
    getquotespitch,
  },
  component: Pitches,
});
