import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";
import { BiFilterAlt, BiBookmark, BiLinkExternal } from "react-icons/bi";
import "../../App.css";
import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../header";
import Footer from "../../footer";

//import MyPitch from './MyPitch';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { RouteComponentProps } from "react-router";
import { getValuationOutput } from "../../data/stock/stock.actions";
interface OwnProps {}

interface StateProps {
  valuationDetail: any;
}

interface DispatchProps {
  getValuationOutput: typeof getValuationOutput;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface EmptyWatchlistProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const EmptyWatchlist: React.FC<EmptyWatchlistProps> = ({}) => {
  const [showModal, setShowModal] = useState(false);
  const [pitchId, setPitchId] = useState(0);
  return (
    <div className="">
      <h1>EMpty</h1>
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    valuationDetail: state.stock.valuationDetail,
  }),
  mapDispatchToProps: { getValuationOutput },
  component: EmptyWatchlist,
});
