import { Schedule, Session } from '../models/Schedule';
import { Speaker } from '../models/Speaker';
import { Location } from '../models/Location';

const dataUrl = '/assets/data/data.json';
const locationsUrl = '/assets/data/locations.json';

const HAS_LOGGED_IN     = 'hasLoggedIn';
const HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
const USERNAME            = 'username';
const LOGGED_IN_USER_DATA = 'loggedInUserData';
const CURRENT_LOC         = 'CurrentLocation';
const LOAD_FILTER         = 'LoadFilter';

export const getConfData = async () => {
  const response = await Promise.all([
  fetch(dataUrl),
  fetch(locationsUrl)]);
  const responseData = await response[0].json();
  const schedule = responseData.schedule[0] as Schedule;
  const sessions = parseSessions(schedule);
  const speakers = responseData.speakers as Speaker[];
  const locations = await response[1].json() as Location[];
  const allTracks = sessions
  .reduce((all, session) => all.concat(session.tracks), [] as string[])
  .filter((trackName, index, array) => array.indexOf(trackName) === index)
  .sort();

  const data = {
    schedule,
    sessions,
    locations,
    speakers,
    allTracks,
    filteredTracks: [...allTracks]
  }
  return data;
}

export const getUserData = async () => {
  const response = await Promise.all([
    localStorage.getItem(  HAS_LOGGED_IN ),
    localStorage.getItem(  HAS_SEEN_TUTORIAL ),
    localStorage.getItem(  LOGGED_IN_USER_DATA ),
    localStorage.getItem(  USERNAME )
  ]);
  const isLoggedin = localStorage.getItem(  HAS_LOGGED_IN ) === 'true';
  const hasSeenTutorial = localStorage.getItem(  HAS_SEEN_TUTORIAL ) === 'true';
  const userData = localStorage.getItem(  LOGGED_IN_USER_DATA ) || undefined;
  const username = localStorage.getItem(  USERNAME ) || undefined;

  const data = {
    isLoggedin,
    hasSeenTutorial,
    username,
    userData
  }
  return data;
}


export const getUserToken = () =>{
  return localStorage.getItem( LOGGED_IN_USER_DATA );
}

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  if(!isLoggedIn){
    localStorage.removeItem( LOGGED_IN_USER_DATA );
    localStorage.removeItem( HAS_LOGGED_IN );
    localStorage.removeItem( HAS_SEEN_TUTORIAL );
    localStorage.removeItem( USERNAME );
  }else{
    localStorage.setItem( HAS_LOGGED_IN, JSON.stringify(isLoggedIn) );
  }
}

export const userLoggedInData = async (userData: any) => {
  localStorage.setItem( LOGGED_IN_USER_DATA, JSON.stringify(userData) );
  if(userData) setIsLoggedInData(true);
}

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean) => {
  localStorage.setItem( HAS_SEEN_TUTORIAL, JSON.stringify(hasSeenTutorial) );
}

export const setCurrentLocation = async (currentLocation: any) => {
  localStorage.setItem( CURRENT_LOC, JSON.stringify(currentLocation) );
}

export const setLoadFilter = async (loadFilter: any) => {
  localStorage.setItem( LOAD_FILTER, JSON.stringify(loadFilter) );
}

export const setUsernameData = async (username?: string) => {
  if (!username) {
    localStorage.removeItem( USERNAME );
  } else {
    localStorage.setItem( USERNAME, username );
  }
}

export const clearUserData = async () => {
  localStorage.removeItem( USERNAME );
  localStorage.removeItem( LOGGED_IN_USER_DATA );
  localStorage.removeItem( HAS_LOGGED_IN );
}

function parseSessions(schedule: Schedule) {
  const sessions: Session[] = [];
  schedule.groups.forEach(g => {
    g.sessions.forEach(s => sessions.push(s))
  });
  return sessions;
}
