import React, { useState, useEffect } from "react";

import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";

import { BiSearch } from "react-icons/bi";
import { FiEdit, FiArrowRight } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { stringify } from "querystring";
//import { CSVLink, CSVDownload } from "react-csv";
import "./App.css";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";
import Header from "./header";
import Footer from "./footer";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Link } from "react-router-dom";

import { connect } from "./data/connect";
import * as selectors from "./data/selectors";
import { RouteComponentProps } from "react-router";
import AddPortfolio from "./Pages/Portfolio/AddPortfolio";

import MyPortfolio from "./Pages/Portfolio/MyPortfolio";
import {
  deleteportfolio,
  editportfolio,
} from "./data/portfolio/portfolio.actions";

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  isRegisterSuccess: any;
}

interface DispatchProps {
  deleteportfolio: typeof deleteportfolio;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface DashboardProps extends OwnProps, StateProps, DispatchProps {}

const Dashboard: React.FC<DashboardProps> = ({
  isRegisterSuccess,
  deleteportfolio,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [portfolioId, setPortfolioId] = useState(0);
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  useEffect(() => {
    if (portfolioId) {
      setShowModal(true);
    }
  }, [portfolioId]);

  return (
    <div className="">
      <div className="login-back">
        <Header />
        <Container fluid>
          <h1>gyiuyi</h1>
          <>
            <Modal
              show={showModal}
              cssClass="modalhome"
              onHide={() => setShowModal(false)}
            >
              <div className="modalselect">
                <AddPortfolio
                  portfolioId={portfolioId}
                  setShowModal={setShowModal}
                />
              </div>
            </Modal>
          </>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isRegisterSuccess: state.user.isRegisterSuccess,
  }),
  mapDispatchToProps: { deleteportfolio },
  component: Dashboard,
});
