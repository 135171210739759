import React, { useState, useEffect } from "react";

import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";

import { BiSearch } from "react-icons/bi";
import { RiArrowDownSLine, RiCloseFill } from "react-icons/ri";
import { BiFilterAlt, BiBookmark } from "react-icons/bi";

import "../../App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import moment from "moment";
import Accordion from "react-bootstrap/Accordion";

import Form from "react-bootstrap/Form";

import Card from "react-bootstrap/Card";
import { Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom";
import Header from "../../header";
import Footer from "../../footer";
import StockProfile from "./StockProfile";
import {
  IoIosNotificationsOutline,
  IoIosHelpCircle,
  IoMdCheckmark,
  IoIosCloseCircleOutline,
  IoIosArchive,
  IoIosBackspace,
  IoMdAdd,
  IoMdExit,
  IoIosHome,
  IoIosSwap,
  IoIosAdd,
  IoIosShare,
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosCloudUpload,
  IoMdNotifications,
} from "react-icons/io";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTrue } from "typescript";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import ReactGA from "react-ga4";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { RouteComponentProps } from "react-router";
import { getStockList } from "../../data/stock/stock.actions";
import StockList from "./StockList";
import MyValuation from "./Myvaluation";
import MyValuationpb from "./Myvaluationps";
import MyValuationeve from "./Myvaluationeve";
import EmptyStock from "./EmptyStock";
import General from "../../General";
import Valuation from "../../Valuation";
import Snapshort from "../../Snapshort";
import Financial from "../../Financial";
import Performance from "../../Performance";
import { FiArrowUp } from "react-icons/fi";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

interface OwnProps extends RouteComponentProps {
  stockId: any;
  viewbusiness: any;
  SetValuation_type: any;
  SetValuation_text: any;
}

interface StateProps {
  stockList: any;
}

interface DispatchProps {
  getStockList: typeof getStockList;
}

function useMergeState(initialState: any) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState: any) =>
    setState((prevState: any) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

interface StockProps extends OwnProps, StateProps, DispatchProps {}

const Market: React.FC<StockProps> = ({
  getStockList,
  stockList,
  stockId,
  SetValuation_type,
  SetValuation_text,
  viewbusiness,
}) => {
  const [historicalData, setHistoricalData] = useState<any>([]);

  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  return (
    <>
      <div className="login-back">
        <Header />
        <Container fluid className="pb-5">
          <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="mt-2 mb-0 user_tab"
          >
            <Tab eventKey="general" title="GENERAL">
              <span className="tab_gen">
                <General />
              </span>
            </Tab>
            <Tab eventKey="valuation" title="VALUATION METRICS">
              <Valuation />
            </Tab>
            <Tab eventKey="financial" title="FINANCIAL METRICS">
              <Financial />
            </Tab>
            <Tab eventKey="performance" title="PERFORMANCE">
              <Performance />
            </Tab>
            <Tab eventKey="snapshot" title="SNAPSHOT">
              {/* <StockProfile stockId = {stockId} viewbusiness = {viewbusiness} SetValuation_type = {SetValuation_type}
               SetValuation_text = {SetValuation_text} /> */}
            </Tab>
          </Tabs>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isRegisterSuccess: state.user.isRegisterSuccess,
    stockList: state.stock.stockList,
  }),
  mapDispatchToProps: { getStockList },
  component: Market,
});
