import React, { useEffect, useState } from "react";
import { connect } from "./data/connect";
import ReactGA from "react-ga4";

interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

interface ValuationProps extends OwnProps, StateProps, DispatchProps {}

const Valuation: React.FC<ValuationProps> = ({}) => {
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  return (
    <table className="table dash-table scrolltable grad_table">
      <thead>
        <tr className="clr">
          <th>No</th>
          <th>Ticker</th>
          <th>Company</th>
          <th>Market Cap</th>
          <th>Price</th>
          <th>Change</th>
          <th>Volume</th>
          <th>P/E</th>
          <th>P/S</th>
          <th>P/B</th>
          <th>P/FCF</th>
          <th>P/c</th>
          <th>EPS growth past year</th>
          <th>EPS growth past 5 years</th>
          <th>Sales growth past 5 years</th>
        </tr>
      </thead>
      <tbody className="scroll market-scroll pr-0">
        <tr className="clr">
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
        </tr>
        <tr className="clr">
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
          <td>1.23445</td>
        </tr>
      </tbody>
    </table>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: Valuation,
});
