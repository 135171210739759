import {
  getUserData,
  setIsLoggedInData,
  setUsernameData,
  setHasSeenTutorialData,
  userLoggedInData,
  setCurrentLocation,
} from "../dataApi";
import { ActionType } from "../../util/types";
import { UserState } from "./user.state";
import { UserService } from "./user.service";
import { setShowLoading, showToast } from "../global/global.actions";
import { GiMailbox } from "react-icons/gi";
import { toast } from "react-toastify";

export const loadUserData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setShowLoading(true));
  const data = await getUserData();
  dispatch(setData(data));
  dispatch(setShowLoading(false));
};

export const doRegister =
  (user: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await UserService.register(user).then(
      (success) => {
        //setuserLoggedInData(success, dispatch);
        localStorage.setItem("LOGGED_IN_EMAIL", user.email);
        dispatch(setShowLoading(false));
        dispatch(toast.success("Registered sucessfully"));
        dispatch(setRegisterSuccess(true));
      },
      (errors) => {
        //console.log(errors, "987654321987654321");
        dispatch(
          toast.error(errors ? errors : "Please correct the input you entered!")
        );
      }
    );
  };

export const doLogin =
  (email: string, password: string) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await UserService.login(email, password).then(
      (user) => {
        localStorage.setItem("currentUser", JSON.stringify(user));

        setuserLoggedInData(user, dispatch);
        dispatch(toast.success("Login Successfull!"));
        dispatch(setShowLoading(false));
      },
      (error) => {
        dispatch(setShowLoading(false));
        dispatch(
          toast.error(
            "Please check that you've entered the right username and password!"
          )
        );
      }
    );
  };

export const otpVerify =
  (email: any, otp: string) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await UserService.otpCheck(email, otp).then(
      (user) => {
        setuserLoggedInData(user, dispatch);
        dispatch(setShowLoading(false));
        dispatch(setEmailSuccess(true));
      },
      (error) => {
        dispatch(setShowLoading(false));
        dispatch(showToast({ isOpen: true, color: "danger", message: error }));
      }
    );
  };
export const Resetpasswordemail =
  (username: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await UserService.forgetpassword(username).then(
      (user) => {
        localStorage.setItem("LOGGED_IN_EMAIL", username);

        setuserLoggedInData(user, dispatch);
        dispatch(setShowLoading(false));
        dispatch(setEmailSuccess(true));
      },
      (error) => {
        dispatch(setShowLoading(false));
        dispatch(showToast({ isOpen: true, color: "danger", message: error }));
      }
    );
  };

export const Resetpassword =
  (newpassword: any, confirmpassword: any) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    var email = localStorage.getItem("RESET_IN_EMAILS");
    console.log(email, "email");
    await UserService.resetpassword(email, newpassword, confirmpassword).then(
      (user) => {
        setuserLoggedInData(user, dispatch);
        dispatch(setShowLoading(false));
        dispatch(setRepassword(true));
      },
      (error) => {
        dispatch(setShowLoading(false));
        dispatch(showToast({ isOpen: true, color: "danger", message: error }));
      }
    );
  };
export const getDispatchpage =
  (status: false) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setEmailSuccess(status));
  };

export const forget = (email: any) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setShowLoading(true));
  await UserService.forget(email).then(
    (success) => {
      localStorage.setItem("FORGET_IN_EMAIL", email.email);
      localStorage.setItem("FORGET_IN_TOKEN", email.token);
      dispatch(setShowLoading(false));
      dispatch(setForgetSuccess(true));
    },
    (errors) => {
      dispatch(setShowLoading(false));
      dispatch(toast.error(errors));
    }
  );
};

export const getprofile = () => async (dispatch: React.Dispatch<any>) => {
  await UserService.getprofile().then(
    (profileget) => {
      dispatch(setProfileget(profileget.user));
    },
    (error) => {}
  );
};

export const reset =
  (password: any, confirmpassword: any) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    var email = localStorage.getItem("RESET_IN_EMAILS");
    // var token = localStorage.getItem("FORGET_IN_TOKEN");
    await UserService.reset(password, confirmpassword).then(
      (success) => {
        dispatch(setShowLoading(false));
        dispatch(toast.success("Reset sucessfully"));
        dispatch(setResetSuccess(true));
      },
      (errors) => {
        dispatch(setShowLoading(false));
        dispatch(toast.error(errors));
      }
    );
  };

export const updateprofile =
  (name: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    dispatch(setUpdateprofile(false));
    await UserService.updateprofile(name).then(
      (success) => {
        dispatch(setShowLoading(false));
        dispatch(toast.success("Changes Saved!"));
        dispatch(setUpdateprofile(true));
      },
      (errors) => {
        dispatch(setShowLoading(false));
        dispatch(toast.error("Error Reset "));
      }
    );
  };

export const getupdatePassword =
  (user: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await UserService.passwordReset(user).then(
      (success) => {
        dispatch(setShowLoading(false));
        dispatch(toast.success("Password Reset Successful!"));
        dispatch(setpasswordUpdate(true));
      },
      (errors) => {
        dispatch(setShowLoading(false));
        dispatch(toast.error(errors));
      }
    );
  };

export const updateimage =
  (profileimage: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    dispatch(setProfileimage(false));
    await UserService.updateimage(profileimage).then(
      (success) => {
        dispatch(setShowLoading(false));
        dispatch(toast.success("Upload Successful!"));
        dispatch(setProfileimage(true));
      },
      (errors) => {
        dispatch(setShowLoading(false));
        dispatch(toast.error("Error Update Image "));
      }
    );
  };
export const getDelete = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setShowLoading(true));

  await UserService.deleteuser().then(
    (success) => {
      dispatch(setShowLoading(false));
      dispatch(setprofileDelete(true));
      dispatch(toast.success("Account Deleted!"));
    },
    (errors) => {
      dispatch(setShowLoading(false));
      dispatch(toast.error("Error Delete !"));
    }
  );
};
export const getVerifyuser =
  (token: any) => async (dispatch: React.Dispatch<any>) => {
    // dispatch(setShowLoading(true));
    await UserService.verifyUser(token).then(
      (success) => {
        // dispatch(setShowLoading(false));
        // dispatch(toast.success("Request found sucessfully"));
        dispatch(setVerificateUSer(true));
      },
      (errors) => {
        //dispatch(setShowLoading(false));
        dispatch(toast.error("Request not found"));
      }
    );
  };

export const resetresetpassword =
  (token: any, password: any, confirmpassword: any) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    var email = localStorage.getItem("FORGET_IN_EMAIL");
    await UserService.resetreset(token, email, password, confirmpassword).then(
      (success) => {
        dispatch(setShowLoading(false));
        dispatch(toast.success("Reset sucessfully"));
        dispatch(setResetResetSuccess(true));
      },
      (errors) => {
        dispatch(setShowLoading(false));
        dispatch(toast.error(errors));
      }
    );
  };

export const setuserLoggedInData = async (userData: any, dispatch: any) => {
  await userLoggedInData(userData);
  dispatch(setData(userData));
  if (userData) dispatch(setIsLoggedIn(true));
  else dispatch(setIsLoggedIn(false));
};

export const setLoginSuccess = (loginSuccess: any) =>
  ({
    type: "set-login-success",
    loginSuccess,
  } as const);

export const setRegisterSuccess = (registerSuccess: boolean) =>
  ({
    type: "register-success",
    registerSuccess,
  } as const);

export const setEmailSuccess = (emailsuccess: boolean) =>
  ({
    type: "emailregister-success",
    emailsuccess,
  } as const);
export const setRepassword = (repassword: boolean) =>
  ({
    type: "passwordreset-success",
    repassword,
  } as const);

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-user-loading",
    isLoading,
  } as const);

export const setData = (data: Partial<UserState>) =>
  ({
    type: "set-user-data",
    data,
  } as const);

export const logoutUser = () => async (dispatch: React.Dispatch<any>) => {
  await setIsLoggedInData(false);
  dispatch(setUsername());
};

export const setIsLoggedIn = (loggedIn: boolean) => {
  setIsLoggedInData(loggedIn);
  return {
    type: "set-is-loggedin",
    loggedIn,
  } as const;
};

export const setUsername =
  (username?: string) => async (dispatch: React.Dispatch<any>) => {
    await setUsernameData(username);
    return {
      type: "set-username",
      username,
    } as const;
  };

export const setHasSeenTutorial =
  (hasSeenTutorial: boolean) => async (dispatch: React.Dispatch<any>) => {
    await setHasSeenTutorialData(hasSeenTutorial);
    return {
      type: "set-has-seen-tutorial",
      hasSeenTutorial,
    } as const;
  };

export const setDarkMode = (darkMode: boolean) =>
  ({
    type: "set-dark-mode",
    darkMode,
  } as const);

export const getCurrentLocation = (currentLocation: any) => {
  return {
    type: "get-current-location",
    currentLocation,
  } as const;
};

export const getLoadFilter = (loadFilter: any) => {
  return {
    type: "get-load-filter",
    loadFilter,
  } as const;
};
export const setForgetSuccess = (forgetSuccess: boolean) =>
  ({
    type: "register-forgetSuccess",
    forgetSuccess,
  } as const);
export const setResetSuccess = (resetSuccess: boolean) =>
  ({
    type: "register-resetSuccess",
    resetSuccess,
  } as const);
export const setResetResetSuccess = (resetresetSuccess: boolean) =>
  ({
    type: "register-resetdata",
    resetresetSuccess,
  } as const);
export const setProfileget = (profileget: any) => {
  return {
    type: "profile-get",
    profileget,
  } as const;
};
export const setUpdateprofile = (profileupdate: boolean) => {
  return {
    type: "profile-update",
    profileupdate,
  } as const;
};
export const setpasswordUpdate = (passwordUpdate: boolean) => {
  return {
    type: "password-update",
    passwordUpdate,
  } as const;
};
export const setProfileimage = (profileimage: boolean) => {
  return {
    type: "profile-profileimage",
    profileimage,
  } as const;
};
export const setprofileDelete = (profiledelete: boolean) => {
  return {
    type: "profile-delete",
    profiledelete,
  } as const;
};
export const setVerificateUSer = (VerifyUSer: boolean) => {
  return {
    type: "verify-user",
    VerifyUSer,
  } as const;
};
export type UserActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof setIsLoggedIn>
  | ActionType<typeof setUsername>
  | ActionType<typeof setHasSeenTutorial>
  | ActionType<typeof setDarkMode>
  | ActionType<typeof setRegisterSuccess>
  | ActionType<typeof setEmailSuccess>
  | ActionType<typeof setRepassword>
  | ActionType<typeof getCurrentLocation>
  | ActionType<typeof setLoginSuccess>
  | ActionType<typeof getLoadFilter>
  | ActionType<typeof setForgetSuccess>
  | ActionType<typeof setResetSuccess>
  | ActionType<typeof setProfileget>
  | ActionType<typeof setUpdateprofile>
  | ActionType<typeof setProfileimage>
  | ActionType<typeof setprofileDelete>
  | ActionType<typeof setVerificateUSer>
  | ActionType<typeof setpasswordUpdate>
  | ActionType<typeof setResetResetSuccess>;
