import { StockActions } from "./stock.actions";
import { StockState } from "./stock.state";

export function StockReducer(
  state: StockState,
  action: StockActions
): StockState {
  switch (action.type) {
    case "stock-list":
      return { ...state, stockList: action.stockList };
    case "stock-profile":
      return { ...state, stockProfile: action.stockProfile };
    case "stock-historicaldata":
      return { ...state, datahistorical: action.historicaldata };
    case "stock-valuation":
      return { ...state, reloadvaluation: action.valuation };
    case "valuation-types":
      return { ...state, valuationTypes: action.valuationTypes };
    case "valuation-detail":
      return { ...state, valuationDetail: action.valuationDetail };
    case "portfolio-valuationbyid":
      return { ...state, viewvaluationbyid: action.valuationbyid };
  }
}
