import { ActionType } from "../../util/types";
import { StockState } from "./stock.state";
import { Toast } from "../../models/OtrModel";
import { StockService } from "./stock.service";
import { setShowLoading } from "../global/global.actions";
import { toast } from "react-toastify";

export const getStockList =
  (marketcap: any, sector: any, region: any) =>
  async (dispatch: React.Dispatch<any>) => {
    await StockService.getStockList(marketcap, sector, region).then(
      (stockList: any) => {
        dispatch(setStock(stockList.data));
      },
      (error: any) => {}
    );
  };

export const getStockProfile =
  (stock: any) => async (dispatch: React.Dispatch<any>) => {
    await StockService.getStockProfile(stock).then(
      (stockProfile: any) => {
        dispatch(setStockProfile(stockProfile));
      },
      (error: any) => {}
    );
  };
export const gethistoricaldata =
  (stocksymbol: any) => async (dispatch: React.Dispatch<any>) => {
    await StockService.getHistoricalData(stocksymbol).then(
      (historicaldata) => {
        dispatch(setHistoricalData(historicaldata));
      },
      (error) => {}
    );
  };

export const addvaluation =
  (valuation: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await StockService.addvaluation(valuation).then(
      (valuation) => {
        dispatch(setShowLoading(false));
        dispatch(setValuationDetail(valuation.valuation_output[0]));
      },
      (error) => {
        dispatch(setShowLoading(false));
      }
    );
  };

export const addPitch =
  (pitch: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await StockService.addPitch(pitch).then(
      (pitch) => {
        dispatch(setShowLoading(false));
        dispatch(toast.success("Portfolio updated sucessfully"));
      },
      (error) => {
        dispatch(setShowLoading(false));
      }
    );
  };

export const getValuationTypes =
  () => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await StockService.getValuationTypes().then(
      (types) => {
        dispatch(valuationTypes(types.data));
      },
      (error) => {
        dispatch(setShowLoading(false));
      }
    );
  };

export const getValuationOutput =
  (valuationId: any) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setShowLoading(true));
    await StockService.getValuationOutput(valuationId).then(
      (types) => {
        //dispatch(valuationTypes(types.data));
      },
      (error) => {
        dispatch(setShowLoading(false));
      }
    );
  };

export const getvaluationbyid =
  (vid: any) => async (dispatch: React.Dispatch<any>) => {
    await StockService.getvaluationbyid(vid).then(
      (valuationbyid) => {
        dispatch(setvaluationbyid(valuationbyid.data));
      },
      (error) => {}
    );
  };

export const setvaluationSuccess =
  () => async (dispatch: React.Dispatch<any>) => {
    dispatch(setvaluationbyid({}));
  };
export const setStock = (stockList: any) =>
  ({
    type: "stock-list",
    stockList,
  } as const);

export const setStockProfile = (stockProfile: any) =>
  ({
    type: "stock-profile",
    stockProfile,
  } as const);

export const setHistoricalData = (historicaldata: any) =>
  ({
    type: "stock-historicaldata",
    historicaldata,
  } as const);

export const setValuation = (valuation: boolean) =>
  ({
    type: "stock-valuation",
    valuation,
  } as const);

export const valuationTypes = (valuationTypes: boolean) =>
  ({
    type: "valuation-types",
    valuationTypes,
  } as const);

export const setValuationDetail = (valuationDetail: boolean) =>
  ({
    type: "valuation-detail",
    valuationDetail,
  } as const);
export const setvaluationbyid = (valuationbyid: any) =>
  ({
    type: "portfolio-valuationbyid",
    valuationbyid,
  } as const);

export type StockActions =
  | ActionType<typeof setStock>
  | ActionType<typeof valuationTypes>
  | ActionType<typeof setStockProfile>
  | ActionType<typeof setHistoricalData>
  | ActionType<typeof setValuation>
  | ActionType<typeof setValuationDetail>
  | ActionType<typeof setvaluationbyid>;
//| ActionType<typeof setvaluationSuccess>
