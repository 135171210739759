import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../App.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-rangeslider/lib/index.css";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { FaTwitter } from "react-icons/fa";
import { getnews } from "../../data/portfolio/portfolio.actions";
import moment from "moment";
import ReactGA from "react-ga4";

interface OwnProps {
  stockId: any;
  profileget: any;
}

interface StateProps {
  viewnews: any;
}

interface DispatchProps {
  getnews: typeof getnews;
}

interface NewsProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const News: React.FC<NewsProps> = ({ stockId, getnews, viewnews }) => {
  const [stockid, setstockid] = useState(stockId);
  const [limit, setlimit] = useState("5");

  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  useEffect(() => {
    getnews(stockid, limit);
    console.log(viewnews, "beeeee");
  }, []);

  return (
    <>
      <div className="market-scroll scroll news_hight">
        {viewnews.map((data: any, index: any) => {
          return (
            <>
              <div className="news_page">
                <Row>
                  <Col sm={3}>
                    <img className="news_img" src={data.image} />
                  </Col>
                  <Col sm={9} className="py-3 pl-0 align-self-center">
                    <a target="_blank" href={data.url}>
                      <h3 className="bold_15_76">{data.title}</h3>
                    </a>
                    <p className="regular_11 mb-1">
                      {" "}
                      {moment(data.publishedDate).format("MM-DD-YYYY")}
                      {}
                    </p>
                    <p className="regular_11 mb-0"> {data.text}</p>
                  </Col>
                  <hr></hr>
                </Row>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    viewnews: state.portfolio.viewnews,
  }),
  mapDispatchToProps: { getnews },
  component: News,
});
