import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

//import { store } from './_helpers/store';
import { App } from './App/App';
import './index.css';

render( <App />, document.getElementById('root'));


//ReactDOM.render(<App />, document.getElementById('root'));