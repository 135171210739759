import { PitchesActions } from "./pitches.actions";
import { PitchesState } from "./pitches.state";

export function PitchesReducer(
  state: PitchesState,
  action: PitchesActions
): PitchesState {
  switch (action.type) {
    case "portfolio-pitcheslist":
      return { ...state, allpitcheslist: action.pitcheslist };
    case "portfolio-addpitch":
      return { ...state, pitchadd: action.addpitch };
    case "portfolio-updatepitch":
      return { ...state, updatepitchsuccess: action.updatepitch };
    case "portfolio-deletetrackedpitches":
      return {
        ...state,
        trackedpitchesdeletereload: action.deletetrackedpitches,
      };
    case "portfolio-trakedpitchesupdate":
      return { ...state, gettrakedpitchesupdate: action.trakedpitchesupdate };
    case "portfolio-trackupdate":
      return { ...state, trackupdatereload: action.trackupdate };
  }
}
