import React, { useState, useEffect } from "react";

import {
  Button,
  Container,
  Toast,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";

import { TiCameraOutline } from "react-icons/ti";
import { HiOutlineMail } from "react-icons/hi";
import { FiLock } from "react-icons/fi";
import { FaRegEye, FaRegUser,FaMapMarkerAlt } from "react-icons/fa";
import { AiOutlineEye,AiOutlineMail } from "react-icons/ai";
import { stringify } from "querystring";
import { BiPhone,BiMessageDetail } from "react-icons/bi";
import ReactGA from "react-ga4";

import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import moment from "moment";
import Accordion from "react-bootstrap/Accordion";

import Form from "react-bootstrap/Form";

import Card from "react-bootstrap/Card";
import { Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom";
import Header from "./header";
import Footer from "./footer";
import { RouteComponentProps } from "react-router";
import { Plugins, CameraResultType } from "@capacitor/core";

import {
  IoIosNotificationsOutline,
  IoIosHelpCircle,
  IoMdCheckmark,
  IoIosCloseCircleOutline,
  IoIosArchive,
  IoIosBackspace,
  IoMdAdd,
  IoMdExit,
  IoIosHome,
  IoIosSwap,
  IoIosAdd,
  IoIosShare,
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosCloudUpload,
  IoMdNotifications,
} from "react-icons/io";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTrue } from "typescript";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { connect } from "./data/connect";
import { updateimage, updateprofile } from "./data/user/user.actions";
import { Textarea } from "react-bootstrap-icons";

interface OwnProps {}

interface StateProps {
  reloadprofileupdate: any;
  getuserprofile: any;
}

interface DispatchProps {
  updateprofile: typeof updateprofile;
  updateimage: typeof updateimage;
}

interface MyaccountProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const Contactus: React.FC<MyaccountProps> = ({
  updateprofile,
  reloadprofileupdate,
  getuserprofile,
  updateimage,
}) => {
  const { Camera } = Plugins;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [showToastMsg, setShowToastMsg] = useState("");

  useEffect(() => {
    console.log(getuserprofile, "dfg");
    setName(getuserprofile.name);
    setEmail(getuserprofile.email);
  }, [getuserprofile]);

  const profileupdate = async () => {
    //e.preventDefault();

    await updateprofile(name);
  };
  async function takePicture() {
    var imageUrl: any = "";
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
    }).then(function (imageURI) {
      imageUrl = imageURI.dataUrl;
      setImage(imageUrl);
      updateimage(imageUrl);
    });
  }
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  useEffect(() => {
    if (reloadprofileupdate) return function cleanup() {};
  }, [reloadprofileupdate, updateprofile]);

  return (
    <div className="">
      <div className="login-back">
        <Header />
        <Container fluid className="pb-2 contact_100">
          <Row className="justify-content-center pt-sm-2 mt-sm-2 pb-sm-2 mb-sm-2">
            <Col xl={12} md={10} className="">
              <div className="dash-table-div px-sm-3 pb-sm-4 pt-sm-4 p-4">
                <Row className="justify-content-center">
                  <Col sm={11} className="">
                    <h2 className="bold_35 text-white text-center">Contact Us</h2>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={6} className="text-center">
            <div className="dash-table-div px-sm-3 pb-sm-5 pt-sm-4 p-4">
            <FaMapMarkerAlt  className="contact_i"/>
              <h3>Address</h3>
              <p className="two-zero">7 Temasek Boulevard, #12-07, Suntec Tower One,<br/>
              Singapore 038987 </p>
              Email : support@mystockpitch.com
              </div>
            </Col>
            {/* <Col sm={6} className="text-center">
            <div className="dash-table-div px-sm-3 pb-sm-5 pt-sm-4 p-4">
            <h3>Email</h3>
            <AiOutlineMail className="contact_i"/>
            <p className="two-zero mb-0">contact@stockpitch.com</p>
            <p className="two-zero">contact@stockpitch.com</p>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    reloadprofileupdate: state.user.reloadprofileupdate,
    getuserprofile: state.user.getuserprofile,
  }),
  mapDispatchToProps: { updateprofile, updateimage },
  component: Contactus,
});
