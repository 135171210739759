import { Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button,Row,Col, Dropdown } from 'react-bootstrap';
import Slider from 'react-rangeslider';
import { connect } from './data/connect';
import NumberFormat from 'react-number-format';
import { GiBaseballBat } from "react-icons/gi";
import { CgClose } from "react-icons/cg";

interface OwnProps  {}

interface StateProps {

}

interface DispatchProps {
}

interface InvestmodalProps extends OwnProps, StateProps, DispatchProps { }

const Investmodal: React.FC<InvestmodalProps> = ({  }) => {

const [ insvalue, setInsvalue ] = useState(100000000); 


        return (
                <div className="p-2 green_place">
                  <Row>
                  <Col sm={12}>
                    <GiBaseballBat></GiBaseballBat>
                     <label className="bold-15 ml-2">Invests</label>  
                     <CgClose className="float-right two-zero color_d6"></CgClose>
                  </Col>
                  </Row>
                  <Row className="text-left mb-2 mt-3">
                    <Col sm={3}>
                    <p className="m-0 regular_15_97">Date​</p>
                    </Col>
                    <Col sm={5}>
                    <p className="m-0 regular_15_2f">Order Types​</p>
                    </Col>
                    <Col sm={4}>
                    <p className="m-0 regular_15_76">Cash Available</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                    <p className="m-0 bold_15_97">22.12.2020</p>
                    </Col>
                    <Col sm={5}>
                    <div className="form-check form-check-inline radio_box">
                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                      <label className="form-check-label">Market</label>
                    </div>
                    <div className="form-check form-check-inline radio_box">
                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                      <label className="form-check-label">Limit</label>
                    </div>
                    </Col>
                    <Col sm={4}>
                    <p className="m-0 bold_15_76">$80,000,000</p>
                    </Col>
                  </Row>
                  <Row className="text-left mb-2">
                    <Col sm={12}>
                    <p className="mb-2 regular_15_97">Market Price</p>
                    <p className="bold_15_97">$3435.90 USD</p>
                    <div className="form-group">
                    <label className="regular_15_2f">Choose Portfolio</label>
                    <select className="form-control form_border_hgt bold_15_76" id="exampleFormControlSelect1">
                      <option className="bold_15_76">Portfolio 1</option>
                      <option className="bold_15_76">Net Asset Value</option>
                      <option className="bold_15_76">$100,000,000</option>
                    </select>
                  </div>
                  <div className="form-group mt-4">
                    <label className="regular_15_2f">Invest By</label>
                    <select className="form-control form_border_hgt bold_15_76" id="exampleFormControlSelect1">
                      <option className="bold_15_76">Portfolio Weight</option>
                      <option className="bold_15_76">Net Asset Value</option>
                      <option className="bold_15_76">$100,000,000</option>
                    </select>
                  </div>
                  <div className="">
                    <label className="regular_15_2f mt-3">Seed Capital</label>
                    <Slider className="popover_slider"
                      value = {insvalue}
                      min={0}
                      max={100}
                      onChange = { (value:any) => setInsvalue(value)}
                    />
                      <Row>
                        <Col>0%</Col>
                        <Col className="text-center" style={{"fontWeight": "bold","color": "#00CF76"}}><NumberFormat  value={insvalue} displayType={'text'} thousandSeparator={true} prefix={'%'} /></Col>
                        <Col className="text-right">100%</Col>  
                    </Row>
                  </div>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col sm={6}>
                    <div className="form-group">
                        <label className="regular_15_a2">Position Size</label>
                        <input type="text" className="form-control bg_f0" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="10%"/>
                      </div>
                    </Col>
                    <Col sm={6}>
                    <div className="form-group">
                        <label className="regular_15_76">Required Position Size</label>
                        <input type="text" className="form-control bg_f0" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="$20,000,125.98"/>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col sm={6}>
                    <div className="form-group">
                        <label className="regular_15_a2">Amount</label>
                        <input type="text" className="form-control bg_f0" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="$20,000,125.98"/>
                      </div>
                    </Col>
                    <Col sm={6}>
                    <div className="form-group">
                        <label className="regular_15_a2">Quantity of Shares</label>
                        <input type="text" className="form-control bg_f0" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="$20,000,125.98"/>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={12} className="">
                    <label className="regular_15_a2">Limit Price</label>
                    <div className="relativec">
                    <input type="text"className="form-control form_border_hgt bold_15_76" placeholder="300.00" name="search"/>
                    <button type="submit" className="pop_usd">USD </button>
                    </div>
                    </Col>
                  </Row>
                <Row className="mt-3">
                  <Col sm={12}>
                    <p className="regular_15_2f mb-2">Transaction Value</p> 
                    <p className="bold_15_2f m-0">$20,000,125.98</p> 
                  </Col>
                </Row>
                <div className="mt-4 mb-2">
                  <Button className="green-ground w-100 btn-login" type="submit">Invest</Button>
                </div>
              </div>
         );
};

export default connect<OwnProps, {}, DispatchProps>({
    mapStateToProps: (state) => ({
   
    }),
    mapDispatchToProps: {
   
       
    },
    component: Investmodal
    });