import React, { useEffect, useState } from 'react';
import { connect } from './data/connect';

interface OwnProps  {}

interface StateProps {

}

interface DispatchProps {
}

interface FinancialProps extends OwnProps, StateProps, DispatchProps { }

const Financial: React.FC<FinancialProps> = ({  }) => {

        return (
            <table className="table dash-table scrolltable grad_table">
            <thead>
              <tr className="clr">
              <th>No</th>
              <th>Ticker</th>
              <th>Company</th>
              <th>Market Cap</th>
              <th>Price</th>
              <th>Change</th>
              <th>Volume</th>
              <th>Dividened Yield</th>
              <th>ROA</th>          
              <th>ROE</th>  
              <th>ROI</th>  
              <th>Current Ratio</th>  
              <th>Quick Ratio</th>  
              <th>Lt Debt/Equity</th> 
              <th>Debt/Equity</th> 
              <th>Cross Margin </th> 
              <th>Operating Margin </th> 
              <th>Net Profit Margin </th> 
              <th>Earning Date </th> 
              </tr>
            </thead>
            <tbody className="scroll market-scroll pr-0">
            <tr className="clr">
            <td>1.54</td>
            <td>0.234</td>  
            <td>1.54</td>
            <td>0.234</td> 
            <td>1.54</td>
            <td>1.54</td>
            <td>0.234</td>  
            <td>1.54</td>
            <td>0.234</td> 
            <td>1.54</td>
            <td>1.54</td>
            <td>0.234</td>  
            <td>1.54</td>
            <td>0.234</td> 
            <td>1.54</td>
            <td>1.54</td>
            <td>0.234</td>  
            <td>1.54</td>
            <td>0.234</td>  
            </tr>
            <tr className="clr">
            <td>1.54</td>
            <td>0.234</td>  
            <td>1.54</td>
            <td>0.234</td> 
            <td>1.54</td>
            <td>1.54</td>
            <td>0.234</td>  
            <td>1.54</td>
            <td>0.234</td> 
            <td>1.54</td>
            <td>1.54</td>
            <td>0.234</td>  
            <td>1.54</td>
            <td>0.234</td> 
            <td>1.54</td>
            <td>1.54</td>
            <td>0.234</td>  
            <td>1.54</td>
            <td>0.234</td>  
            </tr>
            
            

              
            </tbody>
          </table>
         );
};

export default connect<OwnProps, {}, DispatchProps>({
    mapStateToProps: (state) => ({
   
    }),
    mapDispatchToProps: {
   
       
    },
    component: Financial
    });