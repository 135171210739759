import { config } from '../_config/config';
import { authHeader, handleResponse } from '../_helpers';

export const WatchlistService = {
    watchlist,removewatchlist,addwatchlist
};
function watchlist() {
    const requestOptions = {
        method  : 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}api/getWatchList`, requestOptions).then(handleResponse);
}
    
function removewatchlist( stocksymbol:any ) {
    const requestOptions = {
        method  : 'POST',
        headers: authHeader(),
        body: JSON.stringify( {stock_symbol:stocksymbol} )
    };

    return fetch(`${config.apiUrl}api/removeWatchList`, requestOptions).then(handleResponse);
}
function addwatchlist( stocksymbol:any ) {
    const requestOptions = {
        method  : 'POST',
        headers: authHeader(),
        body: JSON.stringify( {stock_symbol:stocksymbol} )
    };

    return fetch(`${config.apiUrl}api/addWatchList`, requestOptions).then(handleResponse);
}
