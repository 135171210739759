import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Col, Container, Row, Modal, Spinner } from "react-bootstrap";
import { FaRegEye, FaRegUser } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import "./App.css";
import { connect } from "./data/connect";
import * as selectors from "./data/selectors";
import { forget } from "./data/user/user.actions";
import BarLoader from "react-spinners/ClipLoader";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  resetforget: boolean;
  showLoading: any;
}

interface DispatchProps {
  forget: typeof forget;
}

type ForgetProps = OwnProps & StateProps & DispatchProps;

const Forget: React.FC<ForgetProps> = ({
  forget,
  resetforget,
  history,
  showLoading,
}) => {
  const ForgetSchema = Yup.object().shape({
    email: Yup.string().required("Please enter an appropriate e-mail address"),
  });
  const [color, setColor] = useState("black");
  useEffect(() => {
    if (resetforget) history.push(`${"/ResetSucess"}`);
    return function cleanup() {};
  }, [resetforget]);

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={ForgetSchema}
        onSubmit={({ email }, { setStatus, setSubmitting }) => {
          console.log(setSubmitting, "setSubmitting");
          forget({ email });
        }}
        render={({ errors, status, touched, isSubmitting, isValid }) => (
          <Form>
            <div className="login-back gradientlog">
              <Container fluid className="">
                <Row className="justify-content-center vh-100">
                  <Col sm={6} className="align-self-center">
                    <div className="mt-5 mb-4">
                      <Row className="justify-content-center">
                        <Col md={9} lg={8}>
                          <img
                            className="img-fluid login-img-top"
                            src="/assets/images/Mask Group 9.png"
                          />
                          <div className="pl-sm-5 pr-sm-5 pl-3 pr-3 bg-white log-bg-radius">
                            <div className="text-center">
                              <img
                                className="img-fluid img-small"
                                src="/assets/images/icon.005ba8da.png"
                              />
                            </div>
                            <h2 className="bold-19 text-center pt-3 pb-3">
                              FORGOT PASSWORD
                            </h2>

                            <div className="form-group">
                              {errors.email && touched.email ? (
                                <div className="errorCont">{errors.email}</div>
                              ) : null}
                              <div className="input-relative">
                                <HiOutlineMail className="same-icons color-97"></HiOutlineMail>
                                <Field
                                  name="email"
                                  placeholder="Enter your email"
                                  type="text"
                                  className="form-control log-input"
                                />
                              </div>
                            </div>

                            <Row>
                              <Col sm={12} className="form-group mt-3 mb-5">
                                <Button
                                  className="green-ground w-100 btn-login"
                                  type="submit"
                                  disabled={!isValid}
                                >
                                  {" "}
                                  Submit{" "}
                                  <BarLoader
                                    color={color}
                                    loading={showLoading}
                                    size={13}
                                  />
                                </Button>

                                <p className="text-center regular-14 color-97 mt-2">
                                  Already have an account?{" "}
                                  <Link
                                    to="/login"
                                    className="regular-14 green-text"
                                  >
                                    Login
                                  </Link>{" "}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    schedule: selectors.getSearchedSchedule(state),
    favoritesSchedule: selectors.getGroupedFavorites(state),
    resetforget: state.user.resetforget,
    showLoading: state.global.showLoading,
  }),
  mapDispatchToProps: {
    forget,
  },
  component: Forget,
});
